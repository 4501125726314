import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { PageLoader } from '@/elements/PageLoader';

import { useSettings } from '@/hooks/useSettings';

import LeagueInfoMobile from '@/modules/LeagueManage/components/LeagueInfoMobile';
import {
  getIsLeagueLoading,
  getLeagueData,
} from '@/modules/LeagueManage/leagueManage.selectors';
import { LeagueInfo as LeagueInfoData } from '@/modules/LeagueManage/leagueManage.types';
import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { leagueIcons } from '@/modules/Leagues/leagues.mocks';

import { LeagueInfo as LeagueInfoComponent } from '../components/LeagueInfo';

export const LeagueInfo: React.FC = () => {
  const { isMobile } = useSettings();
  const isLeagueLoading: boolean = useSelector(getIsLeagueLoading);
  const leagueData: LeagueInfoData | undefined = useSelector(getLeagueData);

  const leagueImageSrc = useMemo(() => {
    if (leagueData) {
      return leagueIcons[leagueData.icon as LeagueIconKey]
        ? leagueIcons[leagueData.icon as LeagueIconKey]
        : leagueData.icon;
    }
  }, [leagueData]);

  if (isLeagueLoading) {
    return <PageLoader />;
  }

  return isMobile ? (
    <LeagueInfoMobile
      leagueImageSrc={leagueImageSrc}
      leagueName={leagueData?.name}
      leagueSize={leagueData?.size}
    />
  ) : (
    <LeagueInfoComponent
      leagueImageSrc={leagueImageSrc}
      leagueName={leagueData?.name}
      leagueSize={leagueData?.size}
    />
  );
};
