import React from 'react';

import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text fontSize='9px' px='8px' py='16px'>
        {t(tKeys.sweepstakesDetails)}
      </Text>
      <Text fontSize='9px' mb='8px' px='8px'>
        {t(tKeys.footerDetails)}
      </Text>
    </>
  );
};
