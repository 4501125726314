export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
export const AUTH_APP_CODE = process.env.REACT_APP_AUTH_APP_CODE;
export const GAMEZONE_URL = process.env.REACT_APP_GAMEZONE_URL;
export const ENABLED_LANGUAGES =
  process.env.REACT_APP_ENABLED_LANGUAGES || 'en';

export const SHARE_URL = process.env.REACT_APP_SHARE_URL;
export const ELIGIBLE_LEAGUE_ID = process.env.REACT_APP_ELIGIBLE_LEAGUE_ID;
export const NOT_ELIGIBLE_LEAGUE_ID =
  process.env.REACT_APP_NOT_ELIGIBLE_LEAGUE_ID;

export const ELIGIBLE_SECOND_CHANCE_LEAGUE_ID =
  process.env.REACT_APP_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID;
export const NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID =
  process.env.REACT_APP_NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID;
