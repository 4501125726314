import { Helmet } from 'react-helmet-async';

import { BracketsList } from '@/modules/Bracket/containers/BracketsList';

const BracketsListPage = () => (
  <>
    <Helmet>
      <title>Brackets | Bracket Challenge</title>
      <meta
        content='Who is your Stanley Cup Champion, create your bracket and make your picks today!'
        name='description'
      />
    </Helmet>
    <BracketsList />
  </>
);
export default BracketsListPage;
