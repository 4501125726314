import { ChangeEvent, FC } from 'react';

import { Checkbox, Flex, Heading, Input, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppSelector } from '@/store';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import {
  selectBracket,
  SET_READ_OFF_RULES,
  SET_TIEBRAKER_VALUE,
  SET_TIEBRAKER_VALUE_SC,
} from '@/modules/Bracket/bracket.slice';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

interface StepTwoContentProps {
  onRulesOpen(): void;
}

export const StepTwoContent: FC<StepTwoContentProps> = ({ onRulesOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { entry, entrySC, isReadOffRules, bracketMode } =
    useAppSelector(selectBracket);

  const tiebreaker =
    pathname === bracketsSecondChancePath()
      ? entrySC?.tiebreaker
      : entry?.tiebreaker;

  const onTieBrakerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    const regexp = /^[0-9]+$/;

    if (value === '' || regexp.test(value)) {
      dispatch(
        pathname === bracketsSecondChancePath()
          ? SET_TIEBRAKER_VALUE_SC(e.target.value)
          : SET_TIEBRAKER_VALUE(e.target.value),
      );
    }
  };

  const onRulesChange = (value: boolean) => dispatch(SET_READ_OFF_RULES(value));

  return (
    <>
      <Heading mt={'15px'} size='sm' textAlign='center'>
        {t(tKeys.tieBreakerQuestion)}
      </Heading>
      <Text m={'15px 0 30px'} textAlign='center'>
        {t(tKeys.enterTotalGoals)}
      </Text>

      <Flex direction={'column'} gap={'15px'}>
        <Input
          _focusVisible={{
            boxShadow: '0px 4px 10px 0px #00000082 inset',
          }}
          bg={'inputBg'}
          border={'none'}
          borderRadius='50px'
          boxShadow={'0px 4px 10px 0px #00000073 inset'}
          fontFamily='"Antonio", sans-serif'
          fontSize={'30px'}
          fontWeight={700}
          h={'70px'}
          maxLength={3}
          onChange={onTieBrakerChange}
          outline={'none'}
          textAlign={'center'}
          value={String(tiebreaker)}
        />
        <Checkbox
          isChecked={isReadOffRules}
          onChange={(e) => onRulesChange(e.target.checked)}
        >
          {t(tKeys.readOfficialRules)}{' '}
          <Text as='span' onClick={onRulesOpen} textDecoration='underline'>
            {t(tKeys.officialRules)}
          </Text>
        </Checkbox>
      </Flex>
    </>
  );
};
