import { useMemo } from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import {
  bracketsSecondChancePath,
  MAX_BRACKET_SELECTIONS_AMOUNT,
  MAX_SC_BRACKET_SELECTIONS_AMOUNT,
} from '@/modules/Bracket/bracket.constants';
import { useExitEdit } from '@/modules/Bracket/hooks/useExitEdit';

import { selectBracket } from '../../../bracket.slice';
import { useBracketSettings } from '../../../hooks/useBracketSettings';
import { useSubmitButton } from '../../../hooks/useSubmitButton';
import { GotItButton } from '../../OnboardingOverlay/SecondStepText';
import { ProgressLine } from '../ProgressLine';
import { StepsLabel } from '../StepsLabel';
import {
  parseStepByEntrySCSelections,
  parseStepByEntrySelections,
} from '../parseStepByEntrySelections';

export const MobileStatusBarBlock = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isSubmitDisabled, isLoading, handleSubmit } = useSubmitButton();
  const { isOnboardingActive } = useBracketSettings();
  const { handleExitEdit } = useExitEdit();

  const { entry, entrySC } = useSelector(selectBracket);

  const step = useMemo(
    () =>
      pathname === bracketsSecondChancePath()
        ? parseStepByEntrySCSelections(entrySC)
        : parseStepByEntrySelections(entry),
    [entry, entrySC, pathname],
  );

  const MAX_AMOUNT =
    pathname === bracketsSecondChancePath()
      ? MAX_SC_BRACKET_SELECTIONS_AMOUNT
      : MAX_BRACKET_SELECTIONS_AMOUNT;

  return (
    <>
      <Flex
        background='#192E3B'
        bottom='0'
        boxShadow='0px -4px 10px 0px #0000001A'
        direction='column'
        gap={'16px'}
        p='16px'
        position='fixed'
        width='100%'
        zIndex={20}
      >
        <StepsLabel {...{ step }} />
        <ProgressLine {...{ percent: step / MAX_AMOUNT }} />

        {isOnboardingActive ? (
          <GotItButton />
        ) : (
          <Flex gap='10px'>
            <Button
              onClick={handleExitEdit}
              size='md'
              variant='outline'
              w='full'
            >
              {t(tKeys.exit)}
            </Button>
            <Button
              isDisabled={isSubmitDisabled}
              isLoading={isLoading}
              onClick={handleSubmit}
              size='md'
              w='full'
            >
              {t(tKeys.saveChanges)}
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  );
};
