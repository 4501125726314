import React, { useCallback, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { LeaguesFormValues } from '@/modules/Leagues/leagues.types';

import { CreateLeagueSelectImage as CreateLeagueSelectImageComponent } from '../components/CreateLeagueSelectImage';

type Props = {
  onGoToLeagueSettings: () => void;
  isMobile: boolean;
};

export const CreateLeagueSelectImage: React.FC<Props> = (props) => {
  const { onGoToLeagueSettings, isMobile } = props;

  const { getValues, setValue } = useFormContext<LeaguesFormValues>();
  const { icon: iconKey } = getValues();

  const [selectedIconKey, setSelectedIconKey] =
    useState<LeagueIconKey>(iconKey);

  const handleSelectImageKey = useCallback((key: LeagueIconKey) => {
    setSelectedIconKey(key);
  }, []);

  const handleSaveImage = useCallback(() => {
    setValue('icon', selectedIconKey, { shouldValidate: true });
    onGoToLeagueSettings();
  }, [selectedIconKey]);

  const handleGoBack = useCallback(() => {
    onGoToLeagueSettings();
  }, []);

  return (
    <CreateLeagueSelectImageComponent
      isMobile={isMobile}
      onGoBack={handleGoBack}
      onSaveImage={handleSaveImage}
      onSelectImageKey={handleSelectImageKey}
      selectedIconKey={selectedIconKey}
    />
  );
};
