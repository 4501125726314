import { defineStyleConfig } from '@chakra-ui/react';

const baseStyle = {
  borderRadius: '8px',
  fontWeight: 700,
  color: '#121212',
  p: '14px',
};

const Tooltip = defineStyleConfig({ baseStyle });

export default Tooltip;
