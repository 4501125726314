import { FC } from 'react';

import { Button, Flex, Image, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { ModalWindow } from '@/elements/ModalWindow';

import { internalLinkClickEvent } from '@/utils/analyticEvents';

import { AnalysisContent } from './AnalysisContent';
import search from './search.svg';

export const AnalysisButton: FC<{ team_1_id: number; team_2_id: number }> = ({
  team_1_id,
  team_2_id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: `analysis-${team_1_id}-${team_2_id}`,
  });
  const { t } = useTranslation();

  const handleOpenModal = () => {
    internalLinkClickEvent('analysis', window.location.href);
    onOpen();
  };

  return (
    <>
      {/* Button */}
      <Flex
        align='center'
        as='button'
        bgColor='white'
        fontFamily='AvertaStd-Bold, sans-serif'
        fontWeight='bold'
        gap={'5px'}
        h='25px'
        justify='center'
        onClick={handleOpenModal}
        p={'5px 20px'}
        w='100%'
      >
        <Image h={'100%'} src={search} />
        <Text
          color='#071520'
          fontSize='9px'
          textTransform={'uppercase'}
          whiteSpace='nowrap'
        >
          {t(tKeys.analysis)}
        </Text>
      </Flex>

      {/*  Modal */}
      <ModalWindow
        BodyContent={
          <AnalysisContent
            {...{
              team_1_id,
              team_2_id,
            }}
          />
        }
        FooterContent={
          <>
            <Button onClick={onClose} variant='link'>
              {t(tKeys.close)}
            </Button>
          </>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
