import { RootState } from '@/store';

import {
  LeagueInfo,
  MemberType,
} from '@/modules/LeagueManage/leagueManage.types';

export const getIsManageMode = (state: RootState): boolean =>
  state.leagueManage.isManageMode;

export const getIsLeagueLoading = (state: RootState): boolean =>
  state.leagueManage.league.isLoading;
export const getIsLeagueFetching = (state: RootState): boolean =>
  state.leagueManage.league.isFetching;
export const getLeagueData = (state: RootState): LeagueInfo | undefined =>
  state.leagueManage.league.data;

export const getIsLeaderboardLoading = (state: RootState): boolean =>
  state.leagueManage.leaderboard.isLoading;
export const getIsLeaderboardFetching = (state: RootState): boolean =>
  state.leagueManage.leaderboard.isFetching;
export const getLeaderboardData = (state: RootState): any[] | undefined =>
  state.leagueManage.leaderboard.data;

export const getIsMembersLoading = (state: RootState): boolean =>
  state.leagueManage.members.isLoading;
export const getIsMembersFetching = (state: RootState): boolean =>
  state.leagueManage.members.isFetching;
export const getMembersData = (state: RootState): MemberType[] | undefined =>
  state.leagueManage.members.data;
