import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useSettings } from '@/hooks/useSettings';

import {
  bracketsNewPath,
  bracketsSecondChancePath,
} from '@/modules/Bracket/bracket.constants';

import { selectBracket } from '../../bracket.slice';
import { BracketMode } from '../../brackets.enums';

import { EditModeStatusBar } from './EditModeStatusBar';
import { NewBracketStatusBar } from './NewBracketStatusBar';
import { WarningBar } from './WarningBar';
import { MobileStatusBarBlock } from './mobile/MobileStatusBarBlock';

export const StatusBar = () => {
  const { bracketMode } = useSelector(selectBracket);
  const { isMobile } = useSettings();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const bracketViewType = searchParams.get(SearchParamsEnum.viewType) as
    | BracketMode
    | undefined;

  if (isMobile)
    return bracketMode === BracketMode.edit ||
      pathname === bracketsNewPath() ||
      (pathname === bracketsSecondChancePath() && !bracketViewType) ? (
      <MobileStatusBarBlock />
    ) : null;

  return (
    <>
      <Flex alignItems='center' position='relative' w='full'>
        <WarningBar /> {/* position absolute */}
        {pathname === bracketsNewPath() ||
        (pathname === bracketsSecondChancePath() && !bracketViewType) ? (
          <NewBracketStatusBar />
        ) : bracketMode === BracketMode.edit ? (
          <EditModeStatusBar />
        ) : null}
      </Flex>
    </>
  );
};
