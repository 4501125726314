import React from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

type Props = {
  isSelected: boolean;
  isLoading: boolean;
  onAddEntryToLeague: () => void;
};

export const AddEntryToLeagueButton: React.FC<Props> = (props) => {
  const { isSelected, isLoading, onAddEntryToLeague } = props;
  const { t } = useTranslation();

  return isSelected ? (
    <Flex
      alignItems='center'
      bgColor='#1E3543'
      borderRadius='50px'
      fontSize='15px'
      fontWeight='600'
      justifyContent='center'
      px='16px'
      py='8px'
    >
      {t(tKeys.selected)}
    </Flex>
  ) : (
    <Button
      borderRadius='60px'
      fontSize={14}
      isLoading={isLoading}
      onClick={onAddEntryToLeague}
      paddingBottom={2}
      paddingLeft={6}
      paddingRight={6}
      paddingTop={2}
      size='sm'
      variant={'success'}
      width='fit-content'
    >
      {t(tKeys.select)}
    </Button>
  );
};
