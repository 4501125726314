import { TFunction } from 'i18next';

import { tKeys } from '@/i18n';

import anaheimDucks from '@/assets/leagues/anaheim-ducks.svg';
import arizonaCoyotes from '@/assets/leagues/arizona-coyotes.svg';
import bostonBruins from '@/assets/leagues/boston-bruins.svg';
import buffaloSabres from '@/assets/leagues/buffalo-sabres.svg';
import calgaryFlames from '@/assets/leagues/calgary-flames.svg';
import carolinaHurricanes from '@/assets/leagues/carolina-hurricanes.svg';
import chicagoBlackhawks from '@/assets/leagues/chicago-blackhawks.svg';
import coloradoAvalanche from '@/assets/leagues/colorado-avalanche.svg';
import columbusBlueJackets from '@/assets/leagues/columbus-blue-jackets.svg';
import dallasStars from '@/assets/leagues/dallas-stars.svg';
import detroitRedWings from '@/assets/leagues/detroit-red-wings.svg';
import edmontonOilers from '@/assets/leagues/edmonton-oilers.svg';
import floridaPanthers from '@/assets/leagues/florida-panthers.svg';
import hockeySkate from '@/assets/leagues/hockey-skate.png';
import losAngelesKings from '@/assets/leagues/los-angeles-kings.svg';
import minnesotaWild from '@/assets/leagues/minnesota-wild.svg';
import montrealCanadiens from '@/assets/leagues/montreal-canadiens.svg';
import nashvillePredators from '@/assets/leagues/nashville-predators.svg';
import newJerseyDevils from '@/assets/leagues/new-jersey-devils.svg';
import newYorkIslanders from '@/assets/leagues/new-york-islanders.svg';
import newYorkRangers from '@/assets/leagues/new-york-rangers.svg';
import nhlLeague from '@/assets/leagues/nhl-league.svg';
import ottawaSenators from '@/assets/leagues/ottawa-senators.svg';
import philadelphiaFlyers from '@/assets/leagues/philadelphia-flyers.svg';
import pittsburghPenguins from '@/assets/leagues/pittsburgh-penguins.svg';
import sanJoseSharks from '@/assets/leagues/san-jose-sharks.svg';
import seattleKraken from '@/assets/leagues/seattle-kraken.svg';
import stLouisBlues from '@/assets/leagues/st-louis-blues.svg';
import stanleyCup from '@/assets/leagues/stanley-cup.svg';
import tampaBayLightning from '@/assets/leagues/tampa-bay-lightning.svg';
import torontoMapleLeafs from '@/assets/leagues/toronto-maple-leafs.svg';
import vancouverCanucks from '@/assets/leagues/vancouver-canucks.svg';
import vegasGoldenKnights from '@/assets/leagues/vegas-golden-knights.svg';
import washingtonCapitals from '@/assets/leagues/washington-capitals.svg';
import winnipegJets from '@/assets/leagues/winnipeg-jets.svg';

import { LeagueIconKey, LeagueType } from '@/modules/Leagues/leagues.enums';
import {
  LeagueIcons,
  LeaguesFormValues,
  TypeCheckbox,
} from '@/modules/Leagues/leagues.types';

import { Radio } from '@/types/common';

export const leaguesTypeCheckboxes = (t: TFunction): TypeCheckbox[] => [
  {
    value: LeagueType.Prize,
    text: t(tKeys.prize),
  },
  {
    value: LeagueType.Club,
    text: t(tKeys.club),
  },
  {
    value: LeagueType.Partner,
    text: t(tKeys.partner),
  },
  {
    value: LeagueType.MyLeagues,
    text: t(tKeys.myLeagues),
  },
];

export const leagueIcons: LeagueIcons = {
  [LeagueIconKey.NhlLeague]: nhlLeague,
  [LeagueIconKey.AnaheimDucks]: anaheimDucks,
  [LeagueIconKey.ArizonaCoyotes]: arizonaCoyotes,
  [LeagueIconKey.BostonBruins]: bostonBruins,
  [LeagueIconKey.BuffaloSabres]: buffaloSabres,
  [LeagueIconKey.CalgaryFlames]: calgaryFlames,
  [LeagueIconKey.CarolinaHurricanes]: carolinaHurricanes,
  [LeagueIconKey.ChicagoBlackhawks]: chicagoBlackhawks,
  [LeagueIconKey.ColoradoAvalanche]: coloradoAvalanche,
  [LeagueIconKey.ColumbusBlueJackets]: columbusBlueJackets,
  [LeagueIconKey.DallasStars]: dallasStars,
  [LeagueIconKey.DetroitRedWings]: detroitRedWings,
  [LeagueIconKey.EdmontonOilers]: edmontonOilers,
  [LeagueIconKey.FloridaPanthers]: floridaPanthers,
  [LeagueIconKey.LosAngelesKings]: losAngelesKings,
  [LeagueIconKey.MinnesotaWild]: minnesotaWild,
  [LeagueIconKey.MontrealCanadiens]: montrealCanadiens,
  [LeagueIconKey.NashvillePredators]: nashvillePredators,
  [LeagueIconKey.NewJerseyDevils]: newJerseyDevils,
  [LeagueIconKey.NewYorkIslanders]: newYorkIslanders,
  [LeagueIconKey.NewYorkRangers]: newYorkRangers,
  [LeagueIconKey.OttawaSenators]: ottawaSenators,
  [LeagueIconKey.PhiladelphiaFlyers]: philadelphiaFlyers,
  [LeagueIconKey.PittsburghPenguins]: pittsburghPenguins,
  [LeagueIconKey.SanJoseSharks]: sanJoseSharks,
  [LeagueIconKey.SeattleKraken]: seattleKraken,
  [LeagueIconKey.StLouisBlues]: stLouisBlues,
  [LeagueIconKey.TampaBayLightning]: tampaBayLightning,
  [LeagueIconKey.TorontoMapleLeafs]: torontoMapleLeafs,
  [LeagueIconKey.VancouverCanucks]: vancouverCanucks,
  [LeagueIconKey.VegasGoldenKnights]: vegasGoldenKnights,
  [LeagueIconKey.WashingtonCapitals]: washingtonCapitals,
  [LeagueIconKey.WinnipegJets]: winnipegJets,
  [LeagueIconKey.StanleyCup]: stanleyCup,
  [LeagueIconKey.HockeySkate]: hockeySkate,
};

export const initialCreateLeaguesFormValues: LeaguesFormValues = {
  name: '',
  passcode: '',
  is_private: 'false',
  icon: LeagueIconKey.BostonBruins,
};

export const createLeagueRadioGroup = (t: TFunction): Radio[] => [
  {
    label: t(tKeys.publicLeague),
    helpText: t(tKeys.leagueOpenAllUsers),
    value: 'false',
  },
  {
    label: t(tKeys.leaguePrivate),
    helpText: t(tKeys.passwordRequired),
    value: 'true',
  },
];
