import { TFunction } from 'i18next';

import { tKeys } from '@/i18n';

export const getRemainTime = ({ date, t }: { date?: Date; t: TFunction }) => {
  if (!date) return '';

  const now = new Date();
  const timeDiff = date.getTime() - now.getTime();

  if (timeDiff <= 0) {
    return '00:00:00';
  }

  const remainDate = new Date(timeDiff);
  const leftDays = (remainDate.getUTCDate() - 1).toLocaleString('en-US');
  const leftHours = remainDate.getUTCHours().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });
  const leftMinutes = remainDate.getUTCMinutes().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });
  const leftSeconds = remainDate.getUTCSeconds().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });

  const isPlural = Number(leftDays) > 1;

  return Number(leftDays)
    ? `${leftDays} ${t(isPlural ? tKeys.days : tKeys.day)}`
    : `${leftHours}:${leftMinutes}:${leftSeconds}`;
};
