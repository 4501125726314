import React, { useMemo } from 'react';

import { Navigate } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';

import { useLang } from '@/hooks/useLang';

import { createPath } from '@/routes/types';

const NotFoundPage: React.FC = () => {
  const { lang } = useLang();

  const homePage = useMemo(() => createPath({ path: RoutesEnum.home }), [lang]);

  return <Navigate replace={true} to={homePage} />;
};

export default NotFoundPage;
