import { useCallback, useEffect, useMemo, useState } from 'react';

import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import { ELIGIBLE_LEAGUE_ID, NOT_ELIGIBLE_LEAGUE_ID } from '@/core/env';
import { tKeys } from '@/i18n';

import { PageLoader } from '@/elements/PageLoader';

import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';

import { selectBracket } from '@/modules/Bracket/bracket.slice';
import { LeaderboardTable as LeaderboardTableComponent } from '@/modules/LeagueManage/components/LeaderboardTable';
import { useGetLeagueLeaderboardQuery } from '@/modules/LeagueManage/leagueManage.api';
import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';
import { LIMIT } from '@/modules/Leagues/leagues.constants';

export const LeaderboardTable = () => {
  const { t } = useTranslation();
  const { jwt } = useJWT();

  const [leaderboardRef, inViewLeaderboard] = useInView();

  const [leaderboardOffset, setLeaderboardOffset] = useState(0);

  const { user } = useSelector(selectBracket);

  const { isComing, isLoading } = useGameDate();

  const defaultLeagueId = useMemo(
    () =>
      user && jwt
        ? String(
            !user?.is_eligible ? NOT_ELIGIBLE_LEAGUE_ID : ELIGIBLE_LEAGUE_ID,
          )
        : ELIGIBLE_LEAGUE_ID,
    [jwt, user],
  );

  const {
    isLoading: isLeaderboardLoading,
    isFetching: isLeaderboardFetching,
    data: leaderboard,
  } = useGetLeagueLeaderboardQuery(
    {
      offset: leaderboardOffset,
      limit: LIMIT,
      jwt,
      id: defaultLeagueId ?? '',
    },
    { skip: isComing || !defaultLeagueId, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (
      inViewLeaderboard &&
      !isLeaderboardFetching &&
      !leaderboard?.isLastPage &&
      (leaderboard?.entries?.length || 0) >= LIMIT
    ) {
      setLeaderboardOffset((prevOffset) => prevOffset + LIMIT);
    }
  }, [inViewLeaderboard, isLeaderboardFetching, leaderboard]);

  const handleResetLeaderboardOffset = useCallback(() => {
    setLeaderboardOffset(0);
  }, []);

  if (jwt && (!user || !defaultLeagueId)) {
    return <PageLoader />;
  }
  return (
    <>
      {isLoading || isLeaderboardLoading ? (
        <Flex align='center' justify='center' minH='200px'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <>
          {!isComing ? (
            <LeaderboardTableComponent
              handleResetLeaderboardOffset={handleResetLeaderboardOffset}
              isFetching={isLeaderboardFetching}
              leaderboardData={leaderboard?.entries}
              leagueId={defaultLeagueId}
              offset={leaderboardOffset}
              ref={leaderboardRef}
            />
          ) : (
            <Flex align='center' justify='center' minH='200px'>
              <Text
                bg='primaryDarkGradient'
                fontWeight='700'
                p='8px 16px'
                shadow='containerShadow'
                textTransform='uppercase'
              >
                {t(tKeys.bracketsNotOpenYet)}
              </Text>
            </Flex>
          )}
        </>
      )}
    </>
  );
};
