import { Helmet } from 'react-helmet-async';

import { Leaderboard } from '@/modules/Leaderboard';

const LeaderboardPage = () => {
  return (
    <>
      <Helmet>
        <title>Leaderboards | Bracket Challenge</title>
        <meta
          content='How is your bracket doing? Check out the Bracket Challenge leaderboards to see how you stack up!'
          name='description'
        />
      </Helmet>
      <Leaderboard />
    </>
  );
};

export default LeaderboardPage;
