import { IBracketEntry, UserType } from '@/modules/Bracket/bracket.types';

export const checkHasWestBonusAnswered = <
  T extends { [key: string]: any } | undefined,
>(
  entry: T,
  isSecondChance: boolean,
) =>
  isSecondChance
    ? entry?.match_201_match_played > 0 && entry?.match_202_match_played > 0
    : entry?.match_101_match_played > 0 &&
      entry?.match_102_match_played > 0 &&
      entry?.match_103_match_played > 0 &&
      entry?.match_104_match_played > 0;
export const checkHasEastBonusAnswered = <
  T extends { [key: string]: any } | undefined,
>(
  entry: T,
  isSecondChance: boolean,
) =>
  isSecondChance
    ? entry?.match_203_match_played > 0 && entry?.match_204_match_played > 0
    : entry?.match_105_match_played > 0 &&
      entry?.match_106_match_played > 0 &&
      entry?.match_107_match_played > 0 &&
      entry?.match_108_match_played > 0;

export const getRandomName = (
  user: UserType,
  entries: IBracketEntry[],
): string => {
  return `${user?.username}'s bracket ${(entries?.length || 0) + 1}`;
};

export const getRandomNameSC = (user: UserType): string => {
  return `${user?.username}'s 2nd Chance`;
};
