import { FC, ReactNode } from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

import { useBonusAnswer } from './useBonusAnswer';

interface OptionProps {
  status?: 'selected' | 'correct' | 'wrong';
  value: string | number | ReactNode;
}

const Option: FC<OptionProps> = ({ status = 'selected', value }) => {
  const isCorrect = status === 'correct';
  const isWrong = status === 'wrong';
  return (
    <Flex
      align='center'
      bgColor={
        isCorrect ? '#128B38' : isWrong ? '#DE2626' : 'rgba(255,255,255,0.5)'
      }
      border='2px solid'
      borderColor={isCorrect ? '#1FBF40' : isWrong ? '#F05858' : 'transparent'}
      color={isCorrect ? 'white' : 'black'}
      fontWeight='bold'
      h='25px'
      justify='center'
      w='25px'
    >
      {value}
    </Flex>
  );
};

export const BonusAnswer: FC<{
  matchId: number;
  winnerScorePosition: 'top' | 'bottom';
}> = ({ matchId, winnerScorePosition }) => {
  const { bonusAnswer, isBonusAnswerVisible, isCorrect, isScored, position } =
    useBonusAnswer(matchId);

  const winnerWinMatches = 4;
  const outsiderWinMatches = bonusAnswer - winnerWinMatches;

  if (!isBonusAnswerVisible) return null;

  return (
    <>
      <Box
        left={position === 'left' ? 0 : 'auto'}
        position='absolute'
        right={position === 'right' ? 0 : 'auto'}
        top='50%'
        transform='translateY(-50%)'
        zIndex={10}
      >
        <Option
          status={isScored ? (isCorrect ? 'correct' : 'wrong') : 'selected'}
          value={
            winnerScorePosition === 'top'
              ? winnerWinMatches
              : outsiderWinMatches
          }
        />
        <Option
          status={isScored ? (isCorrect ? 'correct' : 'wrong') : 'selected'}
          value={isScored ? isCorrect ? <CheckIcon /> : <CloseIcon /> : ''}
        />
        <Option
          status={isScored ? (isCorrect ? 'correct' : 'wrong') : 'selected'}
          value={
            winnerScorePosition === 'bottom'
              ? winnerWinMatches
              : outsiderWinMatches
          }
        />
      </Box>
    </>
  );
};
