import { RoutesEnum } from '@/enums/routes.enum';

import BracketPage from '@/pages/Bracket';
import BracketsPage from '@/pages/Brackets';
import BracketsListPage from '@/pages/BracketsList';
import HomePage from '@/pages/Home';
import HowToPlayPage from '@/pages/HowToPlay';
import LeaderboardPage from '@/pages/Leaderboard';
import LeagueManagePage from '@/pages/LeagueManage';
import LeaguesPage from '@/pages/Leagues';
import PrizesPage from '@/pages/Prizes';
import RulesPage from '@/pages/Rules';

export const AppRoutes = [
  { path: RoutesEnum.home, element: <HomePage /> },

  { path: RoutesEnum.brackets, element: <BracketsPage /> },
  { path: RoutesEnum.bracketsList, element: <BracketsListPage /> },
  { path: RoutesEnum.bracketsSpecific, element: <BracketPage /> },
  { path: RoutesEnum.bracketsPreRegistration, element: <BracketPage /> },
  { path: RoutesEnum.bracketsNew, element: <BracketPage /> },
  { path: RoutesEnum.bracketsSecondChance, element: <BracketPage /> },

  { path: RoutesEnum.leagues, element: <LeaguesPage /> },
  { path: RoutesEnum.leagueManage, element: <LeagueManagePage /> },

  { path: RoutesEnum.leaderboard, element: <LeaderboardPage /> },
  { path: RoutesEnum.howToPlay, element: <HowToPlayPage /> },
  { path: RoutesEnum.rules, element: <RulesPage /> },
  { path: RoutesEnum.prizes, element: <PrizesPage /> },
];
