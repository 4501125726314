import React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGameDate } from '@/hooks/useGameDate';

import { JoinLeagueAction } from '@/modules/LeagueManage/components/JoinLeagueAction';
import { AssignBracket } from '@/modules/LeagueManage/containers/AssignBracket';
import { DeleteLeague } from '@/modules/LeagueManage/containers/DeleteLeague';
import { LeagueManageButton } from '@/modules/LeagueManage/containers/LeagueManageButton';
import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';
import { LeagueType } from '@/modules/Leagues/leagues.enums';

export const LeagueActionsBar: React.FC = () => {
  const { t } = useTranslation();
  const { isLive } = useGameDate();
  const leagueData = useSelector(getLeagueData);

  return (
    <Grid
      backgroundColor='rgba(7, 21, 32, 0.90)'
      boxShadow='4px 4px 4px 0px rgba(0, 0, 0, 0.55)'
      gap={2}
      padding='8px 12px'
      templateColumns='repeat(4, 1fr)'
      width='100%'
    >
      <GridItem colSpan={1} w='100%'>
        {leagueData?.is_owner && <LeagueManageButton />}
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        justifyContent='center'
        w='100%'
      >
        <JoinLeagueAction />
      </GridItem>
      <GridItem colSpan={1} display='flex' justifyContent='flex-end' w='100%'>
        <DeleteLeague />
      </GridItem>
    </Grid>
  );
};
