import { Navigate } from 'react-router-dom';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';

import { useJWT } from '@/hooks/useJWT';

import { createPath } from '@/routes/types';

import { checkIsDateInPastOrNow } from '@/utils/checkIsDateInPastOrNow';

import { useGetDashboardQuery, useGetGameQuery } from '../bracket.api';

export const Brackets = () => {
  const { jwt } = useJWT();
  const { entries, isLoading } = useGetDashboardQuery(
    { jwt },
    {
      selectFromResult: ({ data, isLoading }) => ({
        entries: data?.entries || [],
        isLoading,
      }),
      skip: !jwt,
    },
  );

  const { allowPicksFrom } = useGetGameQuery(
    { jwt },
    {
      selectFromResult: ({ data }) => ({
        allowPicksFrom: data?.game.allow_picks_from,
      }),
    },
  );
  const isPreRegistrationPhase = !checkIsDateInPastOrNow(
    allowPicksFrom as string,
  );

  if (isLoading) {
    return <PageLoader />;
  }

  const path = isPreRegistrationPhase
    ? createPath({
        path: RoutesEnum.bracketsPreRegistration,
      })
    : createPath({
        path: RoutesEnum.bracketsSpecific,
        params: {
          id: entries[0]?.entry_id || 'new',
        },
      });

  return <Navigate replace={true} to={path} />;
};
