import { IBracketEntry, SeriesResultsType } from '../bracket.types';
import { MATCHES_TO_FILL_ID } from '../helpers/constants';

export const fillMatchesFromEntry = <
  T extends { [key: string]: any } | undefined,
>(
  seriesResult: SeriesResultsType[],
  entry: T,
  start: number,
  end: number = start,
): SeriesResultsType[] => {
  if (!entry) return seriesResult;

  const result = seriesResult?.length
    ? JSON.parse(JSON.stringify(seriesResult))
    : [];
  seriesResult.forEach((series, index) => {
    if (start <= index && index <= end) {
      // Add series for first 8 matches
      result[index] = series;

      const nextMatch = MATCHES_TO_FILL_ID.find((item) =>
        item.prev.includes(series.id),
      );

      if (!nextMatch) return;

      const team_1_id = entry[`match_${nextMatch.prev[0]}_pick`];
      const team_2_id = entry[`match_${nextMatch.prev[1]}_pick`];

      const nextMatchIndex = seriesResult.findIndex(
        (series) => series.id === nextMatch?.match,
      );

      if (index % 2 === 0) {
        result[nextMatchIndex].team_1_id = Number(team_1_id);
      }
      if (index % 2 !== 0) {
        result[nextMatchIndex].team_2_id = Number(team_2_id);
      }
    }
  });

  return result;
};

export const fillAllMatches = <T extends { [key: string]: any } | undefined>(
  initialSeriesResults: SeriesResultsType[],
  entry: T,
  isSecondChance: boolean,
): SeriesResultsType[] => {
  const withSecondRounds = fillMatchesFromEntry(
    initialSeriesResults,
    entry,
    0,
    7,
  );

  const withThirdRounds = fillMatchesFromEntry(
    isSecondChance ? initialSeriesResults : withSecondRounds,
    entry,
    8,
    11,
  );
  return fillMatchesFromEntry(withThirdRounds, entry, 12, 13);
};
