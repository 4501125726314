import React from 'react';

import { Flex } from '@chakra-ui/react';

import { useSettings } from '@/hooks/useSettings';

import { IBracketEntry } from '@/modules/Bracket/bracket.types';
import { LeagueLeaderboardType } from '@/modules/LeagueManage/leagueManage.types';

import { AssignBracketTableItem } from './AssignBracketTableItem';
import { AssignBracketTableMobileItem } from './AssignBracketTableMobileItem';

type Props = {
  entries: IBracketEntry[];
  leagueId: string;
  onViewBracket: (bracketId: string | number) => void;
  onClose: () => void;
  onRefetchData?: any;
  leagueEntry: LeagueLeaderboardType | null;
};

export const AssignBracketTable: React.FC<Props> = (props) => {
  const { entries } = props;

  const { isMobile } = useSettings();

  const TableItem = isMobile
    ? AssignBracketTableMobileItem
    : AssignBracketTableItem;

  return (
    <Flex direction='column' gap={['8px', '4px']} w='100%'>
      {entries.map((entry) => (
        <TableItem entry={entry} key={entry.entry_id} {...props} />
      ))}
    </Flex>
  );
};
