import { useMemo } from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { tKeys } from '@/i18n';

import {
  OPEN_EAST_BONUS_MODAL,
  OPEN_WEST_BONUS_MODAL,
  selectBracket,
} from '../../bracket.slice';

export const WarningBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { entry, hasBonusValues, isBonusModalVisible } =
    useSelector(selectBracket);

  const allWestMatchesSelected =
    entry?.match_101_pick > 0 &&
    entry?.match_102_pick > 0 &&
    entry?.match_103_pick > 0 &&
    entry?.match_104_pick > 0;
  const allWestMatchesPlayedFilled =
    entry?.match_101_match_played > 0 &&
    entry?.match_102_match_played > 0 &&
    entry?.match_103_match_played > 0 &&
    entry?.match_104_match_played > 0;

  const allEastMatchesSelected =
    entry?.match_105_pick > 0 &&
    entry?.match_106_pick > 0 &&
    entry?.match_107_pick > 0 &&
    entry?.match_108_pick > 0;
  const allEastMatchesPlayedFilled =
    entry?.match_105_match_played > 0 &&
    entry?.match_106_match_played > 0 &&
    entry?.match_107_match_played > 0 &&
    entry?.match_108_match_played > 0;

  const isWestNeed = useMemo(
    () =>
      allWestMatchesSelected &&
      !isBonusModalVisible &&
      (!allWestMatchesPlayedFilled || !hasBonusValues.west),
    [entry, hasBonusValues],
  );
  const isEastNeed = useMemo(
    () =>
      allEastMatchesSelected &&
      (!allEastMatchesPlayedFilled || !hasBonusValues.east) &&
      !isBonusModalVisible,
    [entry, hasBonusValues],
  );

  // const langKey = isWestNeed tKeys.eastConf :
  const confNeed = isWestNeed
    ? t(tKeys.westConf)
    : isEastNeed
      ? t(tKeys.eastConf)
      : null;

  if (!confNeed) return null;

  const clickHandler = () => {
    return isWestNeed
      ? dispatch(OPEN_WEST_BONUS_MODAL())
      : isEastNeed
        ? dispatch(OPEN_EAST_BONUS_MODAL())
        : null;
  };

  return (
    <>
      <Flex
        align='center'
        bgColor='#E44242'
        gap='30px'
        h='50px'
        inset={0}
        justify='center'
        position='absolute'
        w='100%'
      >
        <Flex alignItems='center' gap='10px' justifyContent='space-between'>
          <WarningIcon />
          <Text>
            {t(tKeys.missing)}: {confNeed} {t(tKeys.gameSeriesOutcomes)}
          </Text>
        </Flex>
        <Button
          bgColor='white'
          color='#E44242'
          h={'36px'}
          onClick={clickHandler}
          p={'6px 16px'}
          variant='unstiled'
          w={'fit-content'}
        >
          {t(tKeys.completeHere)}
        </Button>
      </Flex>
    </>
  );
};

const WarningIcon = () => (
  <svg
    fill='none'
    height='18'
    viewBox='0 0 18 18'
    width='18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='9' cy='9' fill='white' r='8.25' />
    <mask
      height='12'
      id='mask0_827_178770'
      maskUnits='userSpaceOnUse'
      width='12'
      x='3'
      y='3'
    >
      <rect
        fill='#D9D9D9'
        height='10.3889'
        width='10.3889'
        x='3.80566'
        y='3.80566'
      />
    </mask>
    <g mask='url(#mask0_827_178770)'>
      <path
        d='M8.99842 13.4803C8.66764 13.4803 8.38501 13.3625 8.15054 13.127C7.91606 12.8914 7.79883 12.6082 7.79883 12.2774C7.79883 11.9467 7.9166 11.664 8.15216 11.4296C8.38772 11.1951 8.67089 11.0779 9.00167 11.0779C9.33245 11.0779 9.61508 11.1956 9.84955 11.4312C10.084 11.6667 10.2013 11.9499 10.2013 12.2807C10.2013 12.6115 10.0835 12.8941 9.84793 13.1286C9.61237 13.3631 9.3292 13.4803 8.99842 13.4803ZM9.00148 10.2121C8.71916 10.2121 8.47699 10.1115 8.27499 9.91012C8.07298 9.7088 7.97198 9.46677 7.97198 9.18405V5.63451C7.97198 5.35179 8.0725 5.10977 8.27355 4.90844C8.4746 4.70711 8.71629 4.60645 8.9986 4.60645C9.28093 4.60645 9.5231 4.70711 9.7251 4.90844C9.92711 5.10977 10.0281 5.35179 10.0281 5.63451V9.18405C10.0281 9.46677 9.92759 9.7088 9.72654 9.91012C9.52549 10.1115 9.28381 10.2121 9.00148 10.2121Z'
        fill='#E44242'
      />
    </g>
  </svg>
);
