import { useMemo } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppSelector } from '@/store';

import {
  bracketsSecondChancePath,
  MAX_BRACKET_SELECTIONS_AMOUNT,
  MAX_SC_BRACKET_SELECTIONS_AMOUNT,
} from '@/modules/Bracket/bracket.constants';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

import { selectBracket } from '../../bracket.slice';

import { ProgressLine } from './ProgressLine';
import {
  parseStepByEntrySCSelections,
  parseStepByEntrySelections,
} from './parseStepByEntrySelections';

export const NewBracketStatusBar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { entry, bracketMode, entrySC } = useAppSelector(selectBracket);
  const step = useMemo(
    () =>
      pathname === bracketsSecondChancePath()
        ? parseStepByEntrySCSelections(entrySC)
        : parseStepByEntrySelections(entry),
    [entry, entrySC, pathname],
  );
  const MAX_AMOUNT =
    pathname === bracketsSecondChancePath()
      ? MAX_SC_BRACKET_SELECTIONS_AMOUNT
      : MAX_BRACKET_SELECTIONS_AMOUNT;
  return (
    <>
      <Flex
        align='center'
        bg='#071520E5'
        gap='24px'
        h='50px'
        justify='center'
        w='100%'
      >
        <Text>{t(tKeys.selectionsMade, { step, total: MAX_AMOUNT })}</Text>

        <ProgressLine {...{ percent: step / MAX_AMOUNT }} />
      </Flex>
    </>
  );
};
