import { BracketMode } from '../brackets.enums';

export const getModeByPathname = ({
  pathname,
  bracketViewType,
  isOver,
  isSecondChanceLive,
}: {
  pathname: string;
  bracketViewType: BracketMode | undefined;
  isOver: boolean;
  isSecondChanceLive: boolean;
}): BracketMode => {
  const lastItem = pathname.split('/').pop();
  if (isOver) return BracketMode.view;
  if (lastItem === 'new') return BracketMode.edit;
  return bracketViewType === BracketMode.edit
    ? BracketMode.edit
    : BracketMode.view;
};
