import { FC } from 'react';

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import ArrowLeftImg from '@/assets/icons/arrow-left.svg';
import CircleAddFilled from '@/assets/icons/circle-add-filled.svg';

import AdsBanner from '@/components/AdsBanner';

import { RoutesEnum } from '@/enums/routes.enum';

import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

import { LeaguesHeader } from '@/modules/Leagues/components/LeaguesHeader';
import { LeaguesList } from '@/modules/Leagues/containers/LeaguesList';
import { useGetFilteredLeaguesQuery } from '@/modules/Leagues/leagues.api';
import { LeagueType } from '@/modules/Leagues/leagues.enums';

import { createPath } from '@/routes/types';

export const AssignLeagueModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useSettings();
  const navigate = useNavigate();

  const { jwt } = useJWT();
  const { isFetching, data } = useGetFilteredLeaguesQuery({
    limit: 50,
    jwt,
    type: LeagueType.MyLeagues,
  });

  const leagues = data?.leagues || [];

  return (
    <Modal scrollBehavior='inside' {...props} size={['full', 'md']}>
      <ModalOverlay />
      <ModalContent borderRadius='10px' maxW={['100%', '840px']}>
        <ModalHeader display={['block', 'none']} p='19px 16px'>
          <Grid templateColumns='1fr auto 1fr' w='100%'>
            <GridItem alignSelf='start' h='100%'>
              <IconButton
                aria-label='back'
                h='100%'
                icon={<Image alt='go back' src={ArrowLeftImg} />}
                onClick={props.onClose}
                variant='unstyled'
              />
            </GridItem>
            <GridItem colStart={2}>
              <Heading as='h2' fontSize='18px' fontWeight='600'>
                {t(tKeys.assignLeague)}
              </Heading>
            </GridItem>
          </Grid>
        </ModalHeader>
        <ModalBody>
          <Box h='81px' m={'0 auto'}>
            <AdsBanner slotId='modal-ad' slotSize={[226, 81]} />
          </Box>
          <Flex
            align='center'
            direction={{ base: 'column', md: 'row' }}
            gap='16px'
            justify='center'
            my='26px'
            w='100%'
          >
            <Text fontSize='20px' fontWeight='700' lineHeight='1.3'>
              {t(tKeys.assignLeague)}
            </Text>
            <Text
              color='ignisGrey'
              fontSize='12px'
              fontWeight='600'
              textTransform='uppercase'
            >
              {t(tKeys.or)}
            </Text>
            <Button
              onClick={() => navigate(createPath({ path: RoutesEnum.leagues }))}
              rightIcon={<Image src={CircleAddFilled} />}
              width='fit-content'
            >
              {t(tKeys.createNewLeague)}
            </Button>
          </Flex>

          <LeaguesHeader isMobile={isMobile} isSelectMode />
          <LeaguesList
            isAssignLeague
            isFetching={isFetching}
            leagues={leagues}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.onClose} variant='link'>
            {t(tKeys.cancel)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
