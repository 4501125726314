import React, { SVGProps } from 'react';

export const GeoPinIcon: React.FC<SVGProps<any>> = ({ fill = '#99ABB7' }) => {
  return (
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M16.7117 13.8056C17.5402 12.7721 18.0344 11.4639 18.0344 10.0348C18.0344 6.70186 15.3325 4 11.9996 4C8.66671 4 5.96484 6.70186 5.96484 10.0348C5.96484 11.4637 6.459 12.7718 7.28737 13.8053L7.29333 13.8127C7.46871 14.0312 7.63108 14.2613 7.79977 14.4849C8.09276 14.8734 8.50592 15.4189 8.98595 16.0462C9.88049 17.2153 10.9979 18.6565 12.0003 19.8938C13.0059 18.6539 14.1259 17.2104 15.0222 16.0398C15.5031 15.4117 15.9169 14.8657 16.2103 14.4769C16.3781 14.2545 16.5374 14.023 16.7117 13.8056ZM18.2751 15.0589C18.2751 15.0589 18.2734 15.0611 18.2722 15.0627C18.2209 15.1312 17.7507 15.7595 17.0693 16.6544C16.0584 17.9819 14.5827 19.8961 13.3211 21.4393C12.631 22.2834 11.3685 22.2831 10.6788 21.4386C8.52246 18.7985 5.73361 15.0646 5.73361 15.0646L5.7271 15.0565C4.62442 13.681 3.96484 11.9349 3.96484 10.0348C3.96484 5.5973 7.56214 2 11.9996 2C16.4371 2 20.0344 5.5973 20.0344 10.0348C20.0344 11.217 19.7791 12.3396 19.3205 13.3505C19.0431 13.962 18.6914 14.5326 18.2768 15.0507L18.2739 15.0543L18.2722 15.0565H18.2768L18.2751 15.0589Z'
        fill={fill}
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  );
};
