import {
  IBracketEntry,
  SecondChanceEntryType,
  SeriesResultsType,
} from '@/modules/Bracket/bracket.types';
import {
  BONUS_GAMES_NUMBERS,
  MATCHES_TO_FILL_ID,
} from '@/modules/Bracket/helpers/constants';

const randomPickRounds = (
  seriesResult: SeriesResultsType[],
  start: number,
  end: number = start,
) => {
  const result: any[] = seriesResult?.length
    ? JSON.parse(JSON.stringify(seriesResult))
    : [];

  seriesResult.forEach((series, index) => {
    if (start <= index && index <= end) {
      // Add series for first 8 matches
      result[index] = series;

      const teams = [series.team_1_id, series.team_2_id];
      const randomIndex = Math.random() * 100 >= 50 ? 1 : 0;
      const randomTeamIdOne = teams[randomIndex];
      const randomTeamIdTwo = teams.find(
        (item, index) => index !== randomIndex,
      );

      const nextMatch = MATCHES_TO_FILL_ID.find((item) =>
        item.prev.includes(series.id),
      );

      const nextMatchIndex = seriesResult.findIndex(
        (series) => series.id === nextMatch?.match,
      );

      if (index % 2 === 0) {
        result[nextMatchIndex].team_1_id = randomTeamIdOne;
      }
      if (index % 2 !== 0) {
        result[nextMatchIndex].team_2_id = randomTeamIdTwo;
      }
    }
  });

  return result;
};

// It's only for UI part of bracket (what user should se)
export const randomPick = (
  seriesResult: SeriesResultsType[],
  isSecondChance: boolean,
) => {
  const withSecondRound = isSecondChance
    ? seriesResult
    : randomPickRounds(seriesResult, 0, 7);
  const withThirdRound = randomPickRounds(withSecondRound, 8, 11);
  return randomPickRounds(withThirdRound, 12, 13);
};

/**
 * Return filled entry
 * @param seriesResult
 * @param entry
 */
export const randomEntry = (
  seriesResult: SeriesResultsType[],
  entry: IBracketEntry,
): IBracketEntry => {
  const resultEntry = seriesResult?.length
    ? JSON.parse(JSON.stringify(entry))
    : [];

  // Update teamsId
  seriesResult.forEach((series, index) => {
    const currentMatch = MATCHES_TO_FILL_ID.find(
      (item) => item.match === series.id,
    );

    if (!currentMatch) return;

    resultEntry[`match_${currentMatch.prev[0]}_pick`] = series.team_1_id || 0;
    resultEntry[`match_${currentMatch.prev[1]}_pick`] = series.team_2_id || 0;

    if (index === 14) {
      const teams = [series.team_1_id, series.team_2_id];
      const randomIndex = Math.random() * 100 >= 50 ? 1 : 0;
      resultEntry['match_401_pick'] = teams[randomIndex];
    }
  });

  // Update match played
  for (let i = 101; i <= 108; i++) {
    const getRandomNumber = (): number => {
      const randomIndex = Math.floor(
        Math.random() * BONUS_GAMES_NUMBERS.length,
      );
      return BONUS_GAMES_NUMBERS[randomIndex];
    };

    resultEntry[`match_${i}_match_played`] = getRandomNumber();
  }

  // Update tiebreaker
  // It does not choose the final tie-breaker question (this is required by the user.)
  // This in addition to agreeing to the official rules,
  // must be completed by the user in order to successfully submit their bracket

  return resultEntry;
};

export const randomEntrySC = (
  seriesResult: SeriesResultsType[],
  entry: SecondChanceEntryType,
): SecondChanceEntryType => {
  const resultEntry = seriesResult?.length
    ? JSON.parse(JSON.stringify(entry))
    : [];

  // Update teamsId
  seriesResult
    .filter((x) => x.round_sequence > 1)
    .map((series, index) => {
      const currentMatch = MATCHES_TO_FILL_ID.filter((x) => x.match > 204).find(
        (item) => item.match === series.id,
      );

      if (!currentMatch) return;

      resultEntry[`match_${currentMatch.prev[0]}_pick`] = series.team_1_id || 0;
      resultEntry[`match_${currentMatch.prev[1]}_pick`] = series.team_2_id || 0;

      if (index === 6) {
        const teams = [series.team_1_id, series.team_2_id];
        const randomIndex = Math.random() * 100 >= 50 ? 1 : 0;
        resultEntry['match_401_pick'] = teams[randomIndex];
      }
    });

  // Update match played
  for (let i = 201; i <= 204; i++) {
    const getRandomNumber = (): number => {
      const randomIndex = Math.floor(
        Math.random() * BONUS_GAMES_NUMBERS.length,
      );
      return BONUS_GAMES_NUMBERS[randomIndex];
    };

    resultEntry[`match_${i}_match_played`] = getRandomNumber();
  }

  // Update tiebreaker
  // It does not choose the final tie-breaker question (this is required by the user.)
  // This in addition to agreeing to the official rules,
  // must be completed by the user in order to successfully submit their bracket

  return resultEntry;
};
