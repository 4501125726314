import { FC, PropsWithChildren, useCallback } from 'react';

import { Box, Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { Footer } from '@/components/Layout/Footer';

import { RoutesEnum } from '@/enums/routes.enum';

import { useSettings } from '@/hooks/useSettings';

import { createPath } from '@/routes/types';

import { hiddenScrollbarStyles } from '@/utils/common.util';

export const GuideLayout: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { isLgDesktop, isMobile } = useSettings();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const getIsSelected = useCallback(
    (route: string) =>
      Boolean(matchPath(createPath({ path: route }), pathname)),
    [pathname],
  );

  const TABS = [
    { title: t(tKeys.howToPlay), to: RoutesEnum.howToPlay },
    { title: t(tKeys.rules), to: RoutesEnum.rules },
    //{ title: t(tKeys.prizes), to: RoutesEnum.prizes },
  ];

  const handleLinkClick = (to: RoutesEnum) => {
    const path = createPath({ path: to });
    navigate(path);
  };

  return (
    <Box
      height={isLgDesktop ? '650px' : '100%'}
      maxW={isLgDesktop ? '1000px' : '100%'}
      mt={isMobile ? '87px' : 0}
      overflow={isLgDesktop ? 'auto' : 'unset'}
      sx={hiddenScrollbarStyles}
      w='100%'
    >
      <Flex
        bg='primaryDarkGradient'
        h='57px'
        justify='center'
        mt='20px'
        py='8px'
        w='100%'
      >
        {TABS.map((tab) => (
          <Button
            alignItems='center'
            borderBottom='2px solid'
            borderColor={getIsSelected(tab.to) ? '#A7DB11' : 'transparent'}
            fontSize='18px'
            fontWeight='700'
            justifyContent='center'
            key={tab.title}
            maxW='240px'
            onClick={() => handleLinkClick(tab.to)}
            variant='unstyled'
            w='100%'
          >
            {tab.title}
          </Button>
        ))}
      </Flex>
      {children}
      <Footer />
    </Box>
  );
};
