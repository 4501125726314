import React, { useCallback } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { tKeys } from '@/i18n';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import { useGameDate } from '@/hooks/useGameDate';
import { useToast } from '@/hooks/useToast';

import { selectBracket } from '@/modules/Bracket/bracket.slice';
import { RemoveUserModal } from '@/modules/LeagueManage/components/RemoveUserModal';
import { DELETE_USER_FROM_LEAGUE } from '@/modules/LeagueManage/leagueManage.slice';
import { useRemoveUserFromLeagueMutation } from '@/modules/Leagues/leagues.api';

import { api } from '@/services/api.service';

type Props = {
  userId: string;
  leagueId: string;
  handleResetMembersOffset?: () => void;
};

export const RemoveUser: React.FC<Props> = (props) => {
  const { userId, leagueId, handleResetMembersOffset } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { user } = useSelector(selectBracket);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const [removeUser, { isLoading: isSubmitting }] =
    useRemoveUserFromLeagueMutation();

  const handleRemoveUser = useCallback(async () => {
    try {
      await removeUser({ league_id: leagueId, user_id: userId }).unwrap();
      if (handleResetMembersOffset) {
        handleResetMembersOffset();
      }
      dispatch(api.util.invalidateTags([ApiTagsEnum.GET_FILTERED_MEMBERS]));
      dispatch(DELETE_USER_FROM_LEAGUE(userId));
      toast(t(tKeys.successRemoveUser), 'success');
      onClose();
    } catch (error) {
      toast(error?.data?.message || error?.message, 'error');
    }
  }, [
    removeUser,
    leagueId,
    userId,
    handleResetMembersOffset,
    dispatch,
    toast,
    t,
    onClose,
  ]);

  if (Number(userId) === user?.user_id) return null;
  return (
    <RemoveUserModal
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onClose={onClose}
      onOpen={onOpen}
      onRemove={handleRemoveUser}
    />
  );
};
