import React, { useCallback } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { BigLangSwitcher } from '@/components/LangSwitcher/big';
import { SmallLangSwitcher } from '@/components/LangSwitcher/small';

import { useLang } from '@/hooks/useLang';

import { createPath } from '@/routes/types';
import { removeLangFromPathname } from '@/routes/utils';

interface LangSwitcherProps {
  variant: 'big' | 'small';
}
export interface LangSwitcherVariantProps {
  isOpen: boolean;
  onOpen(): void;
  onLangChange(item: string): void;
  onClose(): void;
  t(key: keyof typeof tKeys): string;
}
export const LangSwitcher: React.FC<LangSwitcherProps> = ({ variant }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { lang } = useLang();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onLangChange = useCallback(
    (lng: string) => {
      i18n.changeLanguage(lng);
      navigate(`/${lng}${removeLangFromPathname(pathname, lang)}`);
      onClose();
    },
    [i18n, lang, navigate, onClose, pathname],
  );

  return variant === 'small' ? (
    <SmallLangSwitcher
      isOpen={isOpen}
      onClose={onClose}
      onLangChange={onLangChange}
      onOpen={onOpen}
      t={t}
    />
  ) : (
    <BigLangSwitcher
      isOpen={isOpen}
      onClose={onClose}
      onLangChange={onLangChange}
      onOpen={onOpen}
      t={t}
    />
  );
};
