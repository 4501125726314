import React, { useState } from 'react';

import {
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { tKeys } from '@/i18n';

import circleRemoveLight from '@/assets/icons/circle-remove-light.svg';

import AdsBanner from '@/components/AdsBanner';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useJWT } from '@/hooks/useJWT';
import { useToast } from '@/hooks/useToast';

import { useJoinLeagueMutation } from '@/modules/LeagueManage/leagueManage.api';

import { api } from '@/services/api.service';

interface LeagueEnterPasswordModalProps {
  isOpen: boolean;
  onClose(): void;
  league_id: number | string | undefined;
  handleRefetchData(): void;
}
export const LeagueEnterPasswordModal: React.FC<
  LeagueEnterPasswordModalProps
> = ({ isOpen, onClose, league_id, handleRefetchData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast();
  const { withAuthCheck } = useAuthContext();
  const [password, setPassword] = useState('');

  const [triggerJoin, { isLoading, isError }] = useJoinLeagueMutation();

  const handleJoinLeague = () => {
    withAuthCheck(async () => {
      try {
        await triggerJoin({
          league_id: Number(league_id),
          code: password,
        }).unwrap();
        await handleRefetchData();
        dispatch(api.util.invalidateTags([ApiTagsEnum.GET_FILTERED_MEMBERS]));
        toast(t(tKeys.successJoinLeague), 'success');
        onClose();
        setPassword('');
      } catch (e) {
        toast(t(tKeys.joinLeagueError), 'error');
      }
    });
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb='0'>
          <AdsBanner
            mb='8px'
            mx='auto'
            slotId='modal-ad'
            slotSize={[226, 81]}
          />
        </ModalHeader>
        <ModalBody pb='0'>
          <Text fontSize='20px' fontWeight='900' pb='8px'>
            {t(tKeys.pleaseEnterPassword)}
          </Text>
          <Text fontSize='18px' fontWeight='400' mb='24px'>
            {t(tKeys.leagueAdminShouldHaveProvided)}
          </Text>
          <Input
            background='#1E3647'
            border='none'
            borderRadius='50px'
            boxShadow='0px 4px 10px 0px rgba(0, 0, 0, 0.45) inset'
            fontFamily='Antonio'
            fontSize='24px'
            fontWeight='700'
            h='55px'
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t(tKeys.password)}
            type='text'
            w='315px'
          />
          {isError && (
            <Flex
              alignItems='center'
              bgColor={'bruxRed'}
              color='white'
              fontSize='16px'
              fontWeight='600'
              h='40px'
              justifyContent='center'
              my='24px'
              w='100%'
            >
              {t(tKeys.incorrectPassword)}
            </Flex>
          )}
        </ModalBody>
        <ModalFooter
          flexDirection='column'
          gap='8px'
          pt={isError ? '0' : '40px'}
        >
          <Button
            isDisabled={!password || isLoading}
            isLoading={isLoading}
            maxW='205px'
            maxWidth='200px'
            mx='auto'
            onClick={handleJoinLeague}
            width={'100%'}
          >
            {t(tKeys.joinLeague)}
          </Button>
          <Button mt='16px' onClick={onClose} variant='link'>
            {t(tKeys.cancel)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
