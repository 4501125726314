import { FC } from 'react';

import { Flex, Image, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import cupImage from '@/assets/images/cup.png';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import {
  selectBracket,
  SET_ENTRY_MATCH_PICK,
  SET_ENTRY_MATCH_PICK_SC,
} from '@/modules/Bracket/bracket.slice';
import { TeamType } from '@/modules/Bracket/bracket.types';
import { BracketMode } from '@/modules/Bracket/brackets.enums';
import { useGameHook } from '@/modules/Bracket/hooks/useGame.hook';

import colors from '@/theme/foundations/colors';

import { onImageError } from '@/utils/onImageError.util';

export const TeamCard: FC<{
  id: number;
  position: number;
}> = ({ id: teamId, position }) => {
  const { teams } = useGameHook();
  const { entry, entrySC } = useSelector(selectBracket);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const currentTeam = teams.find(
    (_) => _.team_id === Number(teamId),
  ) as TeamType;

  if (!currentTeam) return null;

  const { abbreviation, display_seed, hex_code, team_logo } = currentTeam;

  const isLeft = position === 1;
  const isRight = position === 2;

  const onTeamClick = (teamId: number) => {
    dispatch(
      pathname === bracketsSecondChancePath()
        ? SET_ENTRY_MATCH_PICK_SC({ matchId: 401, teamId })
        : SET_ENTRY_MATCH_PICK({ matchId: 401, teamId }),
    );
  };

  const isSelected =
    pathname === bracketsSecondChancePath()
      ? Number(entrySC.match_401_pick) === Number(teamId)
      : Number(entry.match_401_pick) === Number(teamId);

  return (
    <Flex
      align='center'
      as={'button'}
      bg={hex_code ? `#${hex_code}` : colors.primaryDarkGradient}
      border={'3px solid'}
      borderColor={isSelected ? 'white' : 'transparent'}
      color={'#FFFFFF'}
      h='150px'
      justify='center'
      onClick={() => onTeamClick(Number(teamId))}
      position='relative'
      w='150px'
    >
      <Text
        fontFamily='Averta, sans-serif'
        fontSize='11px'
        fontWeight={700}
        left={isLeft ? '5px' : 'auto'}
        letterSpacing='0em'
        lineHeight='14px'
        opacity='0.85'
        position='absolute'
        right={isRight ? '5px' : 'auto'}
        top={'5px'}
      >
        {display_seed}
      </Text>

      {!!team_logo && <Image onError={onImageError} p='10px' src={team_logo} />}

      {/* TEAM NAME */}
      <Text
        bottom={'5px'}
        fontFamily='Antonio, sans-serif'
        fontSize='17px'
        fontWeight='700'
        left={isRight ? '5px' : 'auto'}
        letterSpacing='0em'
        lineHeight='22px'
        position='absolute'
        right={isLeft ? '5px' : 'auto'}
      >
        {abbreviation}
      </Text>

      {/* Cup Image */}
      {isSelected && (
        <Image
          h={'65px'}
          position='absolute'
          src={cupImage}
          top={0}
          transform='translateY(-50%)'
        />
      )}
    </Flex>
  );
};
