import React, { ReactNode } from 'react';

import { Button, Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';

type Props = {
  isMobile: boolean;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

export const CreateLeagueModal: React.FC<Props> = (props) => {
  const { isMobile, isOpen, onClose, children } = props;

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={isMobile ? 'inside' : undefined}
      size={isMobile ? 'full' : undefined}
    >
      <ModalOverlay />
      <ModalContent maxWidth={isMobile ? 'none' : '750px'}>
        {children}
      </ModalContent>
    </Modal>
  );
};
