import { FC } from 'react';

import { Box } from '@chakra-ui/react';

export const ProgressLine: FC<{ percent: number }> = ({ percent }) => {
  return (
    <Box bg='#14242E' borderRadius='3px' h='6px' w='325px'>
      <Box
        bgColor='#54C872'
        borderRadius='3px'
        h='100%'
        transition='width 0.8s ease-in-out'
        w={`${325 * percent}px`}
      />
    </Box>
  );
};
