import React, { memo, useEffect, useId } from 'react';

import { Flex, BoxProps } from '@chakra-ui/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLang } from '@/hooks/useLang';
import { useLocalStorage } from '@/hooks/useLocalStorage';

import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';
import { LeagueInfo as LeagueInfoData } from '@/modules/LeagueManage/leagueManage.types';

import { addIdToRoute } from '@/routes/utils';

import { getStorageItem, setStorageItem } from '@/utils/localStorage.util';

type AdsBannerSecondaryProps = {
  slotSize:
    | [1680, 900]
    | [728, 90]
    | [300, 250]
    | [1, 1]
    | [226, 81]
    | [331, 138]
    | [320, 50];
  slotId:
    | 'modal-ad'
    | 'skin-ad'
    | 'main-logo-ad'
    | 'populogo-ad'
    | 'mobile-bottom-banner-ad';
} & BoxProps;
declare const window: any;
const AdsBanner: React.FC<AdsBannerSecondaryProps> = ({
  slotSize,
  slotId,
  ...containerProps
}) => {
  //const slotId = useId();
  const { id, lang = 'en' } = useParams();
  const { pathname } = useLocation();
  const leagueData: LeagueInfoData | undefined = useSelector(getLeagueData);
  const { value: storageSponsor, setItem: setStorageSponsor } =
    useLocalStorage<string>(StorageKeysEnum.sponsor);
  const { value: storageSponsorLang, setItem: setStorageSponsorLang } =
    useLocalStorage<string>(StorageKeysEnum.sponsorLang);
  const { value: storageLogo, setItem: setStorageLogo } =
    useLocalStorage<string>(StorageKeysEnum.logo);
  const { value: storageLogoLang, setItem: setStorageLogoLang } =
    useLocalStorage<string>(StorageKeysEnum.logoLang);

  useEffect(() => {
    const handleAd = () => {
      const googletag = window.googletag || {};

      if (slotId === 'modal-ad') {
        googletag.cmd.push(() => {
          googletag.pubads().setTargeting('lang', lang);
          googletag.pubads().setTargeting('sponsor', '');
          DFPManager.refresh(slotId);
        });
        return;
      }
      //skin-ad
      //main-logo-ad
      //populogo-ad
      if (
        pathname === `/${lang}/${addIdToRoute(RoutesEnum.leagueManage, id)}` &&
        leagueData
      ) {
        const sponsor = leagueData?.google_ads_tag
          ? leagueData?.google_ads_tag
          : '';

        const logoSponsor = leagueData?.google_logo_ads_tag
          ? leagueData?.google_logo_ads_tag
          : '';
        if (slotId === 'skin-ad') {
          googletag.cmd.push(() => {
            if (storageSponsorLang !== lang) {
              googletag.pubads().setTargeting('lang', lang);
            }

            if (storageSponsor !== sponsor) {
              googletag.pubads().setTargeting('sponsor', sponsor);
            }
            if (storageSponsor !== sponsor || storageSponsorLang !== lang) {
              DFPManager.reload(slotId);
            }
          });
          setStorageSponsor(sponsor);
          setStorageSponsorLang(lang);
          if (storageSponsor === sponsor && storageSponsorLang === lang) {
            googletag.pubads().setTargeting('lang', lang);
            googletag.pubads().setTargeting('sponsor', sponsor);
          }
        }
        if (slotId === 'main-logo-ad' || slotId === 'populogo-ad') {
          googletag.cmd.push(() => {
            if (storageLogoLang !== lang) {
              googletag.pubads().setTargeting('lang', lang);
            }

            if (storageLogo !== logoSponsor) {
              googletag.pubads().setTargeting('sponsor', logoSponsor);
            }
            if (storageLogo !== logoSponsor || storageLogoLang !== lang) {
              if (slotId === 'populogo-ad') {
                DFPManager.reload();
              }
              DFPManager.refresh();
            }
          });
          setStorageLogo(logoSponsor);
          setStorageLogoLang(lang);
        }
      } else {
        const defaultSponsor = '';
        if (
          slotId === 'skin-ad' ||
          slotId === 'main-logo-ad' ||
          slotId === 'populogo-ad'
        ) {
          googletag.cmd.push(() => {
            googletag.pubads().setTargeting('lang', lang);
            if (storageSponsor !== '' || storageLogo !== '') {
              googletag.pubads().setTargeting('sponsor', '');
              DFPManager.reload();
            } else {
              googletag.pubads().setTargeting('sponsor', '');
            }
          });
        }
        if (slotId === 'skin-ad') {
          setStorageSponsor('');
          setStorageSponsorLang(lang);
        }
        if (slotId === 'main-logo-ad' || slotId === 'populogo-ad') {
          setStorageLogo('');
          setStorageLogoLang(lang);
        }
      }
    };
    handleAd();
    //eslint-disable-next-line
  }, [id, lang, leagueData, pathname]);

  return (
    <Flex
      alignItems='center'
      h='auto'
      id={`${slotSize}`}
      justifyContent='center'
      {...containerProps}
    >
      <DFPSlotsProvider dfpNetworkId='21668666170' singleRequest>
        <div className='ads-banner-container'>
          <AdSlot
            adUnit={'nhl_web_en/bracketchallenge'}
            sizes={[slotSize]}
            slotId={slotId}
          />
        </div>
      </DFPSlotsProvider>
    </Flex>
  );
};

export default AdsBanner;
