import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { LeaguesFormValues } from '@/modules/Leagues/leagues.types';

import { CreateLeagueSettings as CreateLeagueSettingsComponent } from '../components/CreateLeagueSettings';

type Props = {
  onGoToSelectImage: () => void;
  onClose: () => void;
  isMobile: boolean;
  isSubmitting: boolean;
};

export const CreateLeagueSettings: React.FC<Props> = (props) => {
  const { onGoToSelectImage, onClose, isMobile, isSubmitting } = props;

  const { getValues, resetField, unregister } =
    useFormContext<LeaguesFormValues>();
  const { icon: iconKey, is_private } = getValues();

  const isPasscodeDisabled = Boolean(is_private && !JSON.parse(is_private));

  useEffect(() => {
    if (isPasscodeDisabled) {
      resetField('passcode');
      unregister('passcode');
    }
  }, [isPasscodeDisabled]);

  return (
    <CreateLeagueSettingsComponent
      isMobile={isMobile}
      isPasscodeDisabled={isPasscodeDisabled}
      isSubmitting={isSubmitting}
      onClose={onClose}
      onGoToSelectImage={onGoToSelectImage}
      selectedImageKey={iconKey}
    />
  );
};
