import React, { useCallback } from 'react';

import { Flex, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { tKeys } from '@/i18n';

import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useToast } from '@/hooks/useToast';

import {
  useGetEntriesQuery,
  useLazyGetEntryQuery,
  useLazyGetGameQuery,
} from '@/modules/Bracket/bracket.api';
import {
  SET_ENTRY,
  SET_GAME,
  SET_USER,
  UPDATE_SERIES_RESULT,
} from '@/modules/Bracket/bracket.slice';
import { IBracketEntry } from '@/modules/Bracket/bracket.types';
import { fillAllMatches } from '@/modules/Bracket/helpers/fillMatchesFromEntry';
import { LeagueLeaderboardType } from '@/modules/LeagueManage/leagueManage.types';

import { errorHandler } from '@/utils/api.util';

import { AssignBracketTable as AssignBracketTableComponent } from '../components/AssignBracketTable';

type Props = {
  leagueId: string;
  onClose: () => void;
  onRefetchData?: any;
  leagueEntry: LeagueLeaderboardType | null;
  onViewOpen: () => void;
};

export const AssignBracketTable: React.FC<Props> = (props) => {
  const { leagueId, onClose, onRefetchData, leagueEntry, onViewOpen } = props;

  const toast = useToast();
  const { t } = useTranslation();

  const { jwt } = useJWT();
  const { isSecondChanceLive } = useGameDate();
  const dispatch = useDispatch();

  const [getGame] = useLazyGetGameQuery();
  const [getEntry] = useLazyGetEntryQuery();

  const { data, isLoading } = useGetEntriesQuery(
    { jwt },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleViewBracket = useCallback(
    async (bracketId: string | number) => {
      try {
        const game = await getGame({ jwt })
          .unwrap()
          .then(({ game }) => {
            dispatch(SET_GAME(game));
            dispatch(SET_USER(game.user));

            return game;
          });

        getEntry({ entry_id: Number(bracketId), jwt })
          .unwrap()
          .then(({ entry }) => {
            dispatch(SET_ENTRY(entry as IBracketEntry));

            const filledBracket = fillAllMatches(
              game.series_results,
              entry,
              isSecondChanceLive,
            );
            dispatch(UPDATE_SERIES_RESULT(filledBracket));
          })
          .then(() => onViewOpen());
      } catch (error) {
        errorHandler({ toast, t, error });
      }
    },
    [dispatch, getEntry, getGame, onViewOpen, t, toast],
  );

  if (isLoading || !data) {
    return (
      <Flex justifyContent='center' padding='15px'>
        <Spinner color='#ffffff' size='lg' />
      </Flex>
    );
  }

  return (
    <AssignBracketTableComponent
      entries={data?.entries}
      leagueEntry={leagueEntry}
      leagueId={leagueId}
      onClose={onClose}
      onRefetchData={onRefetchData}
      onViewBracket={handleViewBracket}
    />
  );
};
