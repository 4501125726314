import { FC, PropsWithChildren } from 'react';

import { Box, Flex } from '@chakra-ui/react';

export const MobileHeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {/* Note: This component placed under Layout/Header */}
      <Flex
        alignItems='center'
        bgColor='darkBlue'
        gap='2px'
        h='87px'
        justifyContent='space-between'
        left='0'
        position='fixed'
        px='8px'
        top='0'
        w='calc(100vw - 50px)' // should overlay content, but not menu button
        zIndex='999' // +1 to Layout/Header
      >
        {children}
      </Flex>
    </>
  );
};

export const SubHeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {/* Note: This component placed under Layout/Header */}
      <Box
        bgColor='#071520'
        left='0'
        position='fixed'
        px='8px'
        top='87px'
        w='calc(100vw)'
        zIndex='998' // same as Layout/Header
      >
        {children}
      </Box>
    </>
  );
};
