import { RootState } from '@/store';

import { LeagueType } from '@/modules/Leagues/leagues.enums';

export const getIsLeaguesLoading = (state: RootState): boolean =>
  state.leagues.isLoading;

export const getIsLeaguesFetching = (state: RootState): boolean =>
  state.leagues.isFetching;

export const getFilterTypes = (state: RootState): LeagueType[] =>
  state.leagues.filter.types;
