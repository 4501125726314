import { Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppSelector } from '@/store';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

import { selectBracket } from '../../bracket.slice';

import { TeamCard } from './TeamCard';

export const StepOneContent = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { entry, entrySC, seriesResults } = useAppSelector(selectBracket);

  const team301PickedId =
    pathname === bracketsSecondChancePath()
      ? seriesResults[14]?.team_1_id || entrySC.match_301_pick
      : seriesResults[14]?.team_1_id || entry.match_301_pick;
  const team302PickedId =
    pathname === bracketsSecondChancePath()
      ? seriesResults[14]?.team_2_id || entrySC.match_302_pick
      : seriesResults[14]?.team_2_id || entry.match_302_pick;

  return (
    <>
      <Heading mt={'15px'} size='sm' textAlign='center'>
        {t(tKeys.selectYourStanleyCupChampion)}
      </Heading>
      <Text m={'15px 0 30px'} textAlign='center'>
        {t(tKeys.selectTeam)}
      </Text>

      <Flex gap={'20px'} mt={'10px'}>
        <TeamCard id={team301PickedId} position={1} />
        <TeamCard id={team302PickedId} position={2} />
      </Flex>
    </>
  );
};
