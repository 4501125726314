import { useSelector } from 'react-redux';

import { selectBracket } from '@/modules/Bracket/bracket.slice';
import { getMatchesByRounds } from '@/modules/Bracket/helpers/getMatchesByRounds';

export const useFirstRound = () => {
  const { seriesResults } = useSelector(selectBracket);

  return getMatchesByRounds(seriesResults);
};
