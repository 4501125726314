import { createSlice } from '@reduxjs/toolkit';

import {
  LeagueInfo,
  LeagueManageState,
  MemberType,
} from '@/modules/LeagueManage/leagueManage.types';

const initialState: LeagueManageState = {
  isManageMode: false,
  league: {
    isLoading: true,
    isFetching: true,
    data: undefined,
  },
  leaderboard: {
    isLoading: true,
    isFetching: true,
    data: undefined,
  },
  members: {
    isLoading: true,
    isFetching: true,
    data: undefined,
  },
};

const leagueManageSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    TOGGLE_IS_MANAGE_MODE: (state) => {
      state.isManageMode = !state.isManageMode;
    },
    SET_IS_MANAGE_MODE: (state, action: { payload: boolean }) => {
      state.isManageMode = action.payload;
    },
    SET_LEAGUE_IS_LOADING: (state, action: { payload: boolean }) => {
      state.league.isLoading = action.payload;
    },
    SET_LEAGUE_IS_FETCHING: (state, action: { payload: boolean }) => {
      state.league.isFetching = action.payload;
    },
    SET_LEAGUE_DATA: (state, action: { payload: LeagueInfo | undefined }) => {
      state.league.data = action.payload;
    },
    SET_LEADERBOARD_IS_LOADING: (state, action: { payload: boolean }) => {
      state.leaderboard.isLoading = action.payload;
    },
    SET_LEADERBOARD_IS_FETCHING: (state, action: { payload: boolean }) => {
      state.leaderboard.isFetching = action.payload;
    },
    SET_LEADERBOARD_DATA: (state, action: { payload: any[] | undefined }) => {
      state.leaderboard.data = action.payload;
    },
    DELETE_USER_FROM_LEAGUE: (state, action: { payload: string }) => {
      state.leaderboard.data = state.leaderboard.data?.filter(
        (user) => user.user_id !== action.payload,
      );
    },
    SET_MEMBERS_IS_LOADING: (state, action: { payload: boolean }) => {
      state.members.isLoading = action.payload;
    },
    SET_MEMBERS_IS_FETCHING: (state, action: { payload: boolean }) => {
      state.members.isFetching = action.payload;
    },
    SET_MEMBERS_DATA: (
      state,
      action: { payload: MemberType[] | undefined },
    ) => {
      state.members.data = action.payload;
    },
  },
});

export const {
  TOGGLE_IS_MANAGE_MODE,
  SET_LEAGUE_IS_LOADING,
  SET_LEAGUE_IS_FETCHING,
  SET_LEAGUE_DATA,
  SET_LEADERBOARD_IS_LOADING,
  SET_LEADERBOARD_IS_FETCHING,
  SET_LEADERBOARD_DATA,
  DELETE_USER_FROM_LEAGUE,
  SET_IS_MANAGE_MODE,
  SET_MEMBERS_IS_FETCHING,
  SET_MEMBERS_IS_LOADING,
  SET_MEMBERS_DATA,
} = leagueManageSlice.actions;

export const leagueManageReducer = leagueManageSlice.reducer;
