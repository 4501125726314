import React from 'react';

import {
  Button,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
} from '@chakra-ui/react';

import { enabledLanguages, tKeys } from '@/i18n';

import { LangSwitcherVariantProps } from '@/components/LangSwitcher';

import { useLanguages } from '@/hooks/useLanguages';

export const BigLangSwitcher: React.FC<LangSwitcherVariantProps> = ({
  isOpen,
  onOpen,
  onLangChange,
  t,
  onClose,
}) => {
  const { languages, currentLang } = useLanguages();

  return (
    <>
      <Button
        alignItems='center'
        bg='#39444C'
        bgColor='dionisBlue'
        border='4px solid'
        borderColor='tyrisBlue'
        borderRadius='40px'
        display='flex'
        gap='8px'
        h='auto'
        justifyContent='center'
        maxW='100%'
        minH='auto'
        minW='100%'
        onClick={onOpen}
        p='4px 8px'
        variant='unstyled'
        w='100%'
      >
        {currentLang?.code.toUpperCase()}
        <Image
          alt='language-flag-image'
          src={currentLang?.image}
          width='24px'
        />
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg='rgba(25, 46, 59, 0.75)'
          borderRadius='12px'
          boxShadow='0px 4px 12px 0px #00000073'
          p='0'
        >
          <ModalCloseButton />
          <ModalHeader fontSize='16px' fontWeight='600' px='0' py='12px'>
            {t(tKeys.selectLanguage)}:
          </ModalHeader>
          <ModalBody gap='2px' p='0'>
            {languages.map((lang, idx) => (
              <Button
                alignItems='center'
                bgColor={
                  lang.code === currentLang?.code ? 'ignisGrey' : 'darkBlue'
                }
                borderRadius={
                  idx === enabledLanguages.length - 1 ? '0 0 12px 12px' : '0'
                }
                display='flex'
                gap='8px'
                h='auto'
                justifyContent='center'
                key={lang.code}
                minH='auto'
                minW='100%'
                onClick={() => onLangChange(lang.code)}
                py='12px'
                variant='unstyled'
                w='100%'
              >
                <Image
                  alt='language-flag-image'
                  src={lang.image}
                  width='24px'
                />
                {lang.title}
              </Button>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
