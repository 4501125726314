import React, { FC, useCallback } from 'react';

import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import deleteIcon from '@/assets/icons/delete.svg';
import { ReactComponent as LogoutIcon } from '@/assets/icons/logout.svg';

import AdsBanner from '@/components/AdsBanner';

import { ApiTagsEnum } from '@/enums/apiTags.enum';
import { RoutesEnum } from '@/enums/routes.enum';

import { useSettings } from '@/hooks/useSettings';
import { useToast } from '@/hooks/useToast';

import { useLeaveLeagueMutation } from '@/modules/LeagueManage/leagueManage.api';
import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';
import { TOGGLE_IS_MANAGE_MODE } from '@/modules/LeagueManage/leagueManage.slice';
import { useDeleteLeagueMutation } from '@/modules/Leagues/leagues.api';

import { createPath } from '@/routes/types';

import { api } from '@/services/api.service';

import { errorHandler } from '@/utils/api.util';

interface LeaveLeagueModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const LeaveLeagueModal: FC<LeaveLeagueModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useSettings();
  const leagueData = useSelector(getLeagueData);
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const [triggerLeave, { isLoading: isLoadingLeave }] =
    useLeaveLeagueMutation();
  const [removeUser, { isLoading: isLoadingDelete }] =
    useDeleteLeagueMutation();

  const handleDeleteLeague = useCallback(async () => {
    try {
      await removeUser({ league_id: leagueData?.league_id as string }).unwrap();
      toast('Your league was deleted', 'success');
      dispatch(TOGGLE_IS_MANAGE_MODE());
      dispatch(api.util.invalidateTags([ApiTagsEnum.GET_LEAGUE_ENTRY]));
      navigate(
        createPath({
          path: RoutesEnum.leagues,
        }),
      );
    } catch (error) {
      toast(error?.data?.message || error?.message, 'error');
    }
  }, [dispatch, leagueData?.league_id, navigate, removeUser, toast]);

  const handleLeaveLeague = useCallback(async () => {
    await triggerLeave({ id: leagueData?.league_id })
      .unwrap()
      .then(({ success }) => {
        if (success) {
          toast('Successfully leave the league', 'success');
          dispatch(api.util.invalidateTags([ApiTagsEnum.GET_LEAGUE_ENTRY]));
          navigate(createPath({ path: RoutesEnum.leagues }));
        }
      })
      .catch((error) => {
        errorHandler({ toast, t, error });
      });
  }, [leagueData?.league_id, navigate, t, toast, triggerLeave]);

  const isOwner = leagueData?.is_owner;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flexDirection='column' justifyContent='center' pt='0'>
          <AdsBanner mb='24px' slotId='modal-ad' slotSize={[226, 81]} />
          <Heading as='h3' fontSize='20px' textAlign='center'>
            {isOwner ? t(tKeys.confDeleteLeague) : t(tKeys.confLeaveLeague)}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign='center'>
          {t(tKeys.plsNavigateToLeagues)}
        </ModalBody>
        <ModalFooter flexDirection='column' marginTop='10px'>
          <Button
            isLoading={isLoadingLeave || isLoadingDelete}
            onClick={isOwner ? handleDeleteLeague : handleLeaveLeague}
            rightIcon={<Image src={deleteIcon} />}
            variant='error'
            width='fit-content'
          >
            {isOwner ? t(tKeys.deleteLeague) : t(tKeys.leaveLeague)}
          </Button>
          <Button marginTop='20px' onClick={onClose} variant='link'>
            {t(tKeys.goBack)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
