import React, { forwardRef } from 'react';

import { Image, Td, Tr } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
  NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
} from '@/core/env';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useSettings } from '@/hooks/useSettings';

import { selectBracket } from '@/modules/Bracket/bracket.slice';
import { RemoveUser } from '@/modules/LeagueManage/containers/RemoveUser';
import { LeagueLeaderboardType } from '@/modules/LeagueManage/leagueManage.types';
import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { leagueIcons } from '@/modules/Leagues/leagues.mocks';

import { createPath } from '@/routes/types';

import { formatNumberWithComma } from '@/utils/common.util';

interface LeaderboardTableItemProps {
  isManageMode: boolean;
  leagueId: string | undefined;
  handleResetLeaderboardOffset(): void;
  user: LeagueLeaderboardType;
  isOver: boolean;
  isCurrentUserPinned: boolean;
}
const LeaderboardTableItem = forwardRef<
  HTMLTableRowElement,
  LeaderboardTableItemProps
>(
  (
    {
      isManageMode,
      leagueId,
      handleResetLeaderboardOffset,
      user,
      isOver,
      isCurrentUserPinned,
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const { isMobile } = useSettings();
    const { user: userStore } = useSelector(selectBracket);

    const onEntryClick = (userData: LeagueLeaderboardType) => {
      if (
        [
          ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
          NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
        ].includes(String(leagueId))
      ) {
        return Number(userStore?.user_id) === Number(userData?.user_id)
          ? navigate(createPath({ path: RoutesEnum.bracketsSecondChance }))
          : navigate(
              `${createPath({ path: RoutesEnum.bracketsSecondChance })}?${SearchParamsEnum.entryId}=${userData.entry_id}`,
            );
      } else {
        return navigate(
          createPath({
            path: RoutesEnum.bracketsSpecific,
            params: { id: userData?.entry_id },
          }),
        );
      }
    };
    return (
      <Tr
        background={
          isCurrentUserPinned || user?.pinned
            ? 'rgba(52, 87, 112, 0.50)'
            : 'rgba(18, 18, 18, 0.50)'
        }
        ref={ref}
        w='100%'
      >
        {isMobile && isManageMode ? (
          <Td>
            <RemoveUser
              handleResetMembersOffset={handleResetLeaderboardOffset}
              leagueId={leagueId as string}
              userId={String(user?.user_id)}
            />
          </Td>
        ) : (
          <Td
            fontFamily='Antonio'
            fontSize='24px'
            fontWeight='700'
            textAlign='center'
          >
            {formatNumberWithComma(user?.rank) || '--'}
          </Td>
        )}
        {isOver ? (
          <Td
            _hover={{
              textDecoration: 'underline',
            }}
            cursor='pointer'
            fontSize='15px'
            onClick={() => onEntryClick(user)}
          >
            {user?.entry_name || '--'}
          </Td>
        ) : (
          <Td fontSize='15px'>{user?.entry_name || '--'}</Td>
        )}
        {isOver && (
          <Td alignItems='center' display='flex' justifyContent='center'>
            {/* TODO */}
            <Image
              height='35px'
              src={
                user?.match_401_pick
                  ? `https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${user?.match_401_pick}.svg`
                  : leagueIcons[LeagueIconKey.NhlLeague]
              }
            />
          </Td>
        )}
        <Td fontSize='15px' textAlign='center'>
          {formatNumberWithComma(user?.possible_points) || '--'}
        </Td>
        <Td
          color='white'
          fontFamily='Antonio'
          fontSize='24px'
          fontWeight='700'
          textAlign='center'
        >
          {formatNumberWithComma(user?.points) || '--'}
        </Td>
        {!isMobile && (
          <Td>
            {isManageMode && leagueId && (
              <RemoveUser
                handleResetMembersOffset={handleResetLeaderboardOffset}
                leagueId={leagueId}
                userId={user?.user_id}
              />
            )}
          </Td>
        )}
      </Tr>
    );
  },
);

LeaderboardTableItem.displayName = 'LeaderboardTableItem';

export default LeaderboardTableItem;
