import { ApiTagsEnum } from '@/enums/apiTags.enum';

import {
  FilteredLeagueLeaderboardQuery,
  FilteredMembersQuery,
  LeagueInfo,
  LeagueLeaderboardResType,
  LeagueLeaderboardType,
  LeagueMembersResType,
} from '@/modules/LeagueManage/leagueManage.types';
import { LIMIT } from '@/modules/Leagues/leagues.constants';
import { LeaguesQuery, LeaguesResponse } from '@/modules/Leagues/leagues.types';

import { api } from '@/services/api.service';

const leaguesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLeagueById: builder.query<
      { league: LeagueInfo },
      { id: string; jwt: string | undefined }
    >({
      query: ({ id }) => ({
        url: `/leagues/${id}`,
      }),
      providesTags: [ApiTagsEnum.GET_LEAGUE_BY_ID],
    }),
    joinLeague: builder.mutation<
      { league_id: number; success: boolean },
      { league_id: number; code?: string }
    >({
      query: (body) => ({
        url: '/leagues/join',
        method: 'POST',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.GET_FILTERED_LEAGUES],
    }),
    leaveLeague: builder.mutation({
      query: ({ id }) => ({
        url: `/leagues/leave/${id}`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagsEnum.GET_LEAGUE_BY_ID],
    }),
    getFilteredMembers: builder.query<
      LeagueMembersResType,
      FilteredMembersQuery
    >({
      query: ({ jwt, id, ...params }) => ({
        url: `/leagues/${id}/search-members`,
        params: params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const last7CharactersInJWT = queryArgs.jwt?.slice(-7);
        return `${endpointName}({"search":${queryArgs.search}, "jwt": ${last7CharactersInJWT})`;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (Number(arg?.offset) === 0) {
          return newItems;
        }
        currentCache.members = [
          ...currentCache.members,
          ...newItems.members.filter(
            (x) => !currentCache.members.some((y) => y.user_id === x.user_id),
          ),
        ];
        currentCache.isLastPage = newItems.members.length < LIMIT;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      },
      keepUnusedDataFor: 0,
      providesTags: [ApiTagsEnum.GET_FILTERED_MEMBERS],
    }),
    getLeagueLeaderboard: builder.query<
      LeagueLeaderboardResType,
      FilteredLeagueLeaderboardQuery
    >({
      query: ({ jwt, id, ...params }) => ({
        url: `/leagues/${id}/leaderboard`,
        params: params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const last7CharactersInJWT = queryArgs.jwt?.slice(-7);
        return `${endpointName}("id": ${queryArgs.id}, "jwt": ${last7CharactersInJWT})`;
      },
      merge: (currentCache, newItems, { arg }) => {
        currentCache.entries.push(...newItems.entries);
        currentCache.isLastPage = newItems.entries.length < LIMIT;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      },
      keepUnusedDataFor: 0,
      providesTags: [ApiTagsEnum.GET_LEAGUE_LEADERBOARD],
    }),
    getLeagueEntry: builder.query<
      LeagueLeaderboardType,
      { id: string; jwt: string | undefined }
    >({
      query: ({ id }) => ({
        url: `/leagues/${id}/bracket`,
      }),
      providesTags: [ApiTagsEnum.GET_LEAGUE_ENTRY],
    }),
  }),
});

export const {
  useGetLeagueByIdQuery,
  useGetLeagueLeaderboardQuery,
  useJoinLeagueMutation,
  useGetFilteredMembersQuery,
  useLeaveLeagueMutation,
  useGetLeagueEntryQuery,
} = leaguesApi;
