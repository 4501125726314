import { forwardRef, useMemo } from 'react';

import {
  Flex,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

import LeaderboardTableItem from '@/modules/LeagueManage/components/LeaderboardTableItem';
import { RemoveUser } from '@/modules/LeagueManage/containers/RemoveUser';
import {
  useGetLeagueByIdQuery,
  useGetLeagueEntryQuery,
} from '@/modules/LeagueManage/leagueManage.api';
import { LeagueLeaderboardType } from '@/modules/LeagueManage/leagueManage.types';
import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { leagueIcons } from '@/modules/Leagues/leagues.mocks';

import { createPath } from '@/routes/types';

import { nhlThinScrollbarStyles } from '@/utils/common.util';

type Props = {
  isManageMode?: boolean;
  leaderboardData: LeagueLeaderboardType[] | undefined;
  leagueId: string | undefined;
  handleResetLeaderboardOffset(): void;
  offset: number;
  isFetching: boolean;
};
export const LeaderboardTable = forwardRef<HTMLTableRowElement, Props>(
  (
    {
      isManageMode = false,
      leaderboardData,
      leagueId,
      handleResetLeaderboardOffset,
      offset,
      isFetching,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { isMobile } = useSettings();
    const navigate = useNavigate();
    const { jwt } = useJWT();

    const { isOver } = useGameDate();
    const tableHeaders = isOver
      ? [
          { align: 'center', label: t(tKeys.rank), isHighlighted: false },
          { align: 'left', label: t(tKeys.bracketName), isHighlighted: false },
          { align: 'center', label: t(tKeys.cupPick), isHighlighted: false },
          {
            align: 'center',
            label: t(tKeys.maxPossiblePts),
            isHighlighted: false,
          },
          {
            align: 'center',
            label: t(tKeys.totailPoints),
            isHighlighted: true,
          },
        ]
      : [
          { align: 'center', label: t(tKeys.rank), isHighlighted: false },
          { align: 'left', label: t(tKeys.bracketName), isHighlighted: false },
          {
            align: 'center',
            label: t(tKeys.maxPossiblePts),
            isHighlighted: false,
          },
          {
            align: 'center',
            label: t(tKeys.totailPoints),
            isHighlighted: true,
          },
        ];

    const { data: leagueUserEntry } = useGetLeagueEntryQuery(
      {
        id: String(leagueId),
        jwt: String(jwt),
      },
      { refetchOnMountOrArgChange: true },
    );

    return (
      <TableContainer
        height='410px'
        overflowX='auto'
        overflowY='unset'
        position='relative'
        sx={nhlThinScrollbarStyles}
      >
        <Table
          minW='max-content'
          style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}
          variant='unstyled'
        >
          <Thead>
            <Tr padding='8px'>
              {tableHeaders.map((item, idx) => (
                <Th
                  background='#192E3B'
                  color={item.isHighlighted ? 'white' : 'ignisGrey'}
                  fontSize='14px'
                  fontWeight={item.isHighlighted ? '900' : '600'}
                  key={idx}
                  position='sticky'
                  textAlign={item.align as any}
                  textTransform='capitalize'
                  top='-1px'
                  zIndex='1'
                >
                  {item.label}
                </Th>
              ))}
              {!isMobile && (
                <Th
                  background='#192E3B'
                  position='sticky'
                  top='-1px'
                  zIndex='1'
                />
              )}
            </Tr>
          </Thead>
          <Tbody>
            {leagueUserEntry && (
              <LeaderboardTableItem
                handleResetLeaderboardOffset={handleResetLeaderboardOffset}
                isCurrentUserPinned
                isManageMode={isManageMode}
                isOver={isOver}
                leagueId={leagueId}
                ref={undefined}
                user={leagueUserEntry}
              />
            )}

            {leaderboardData?.map((user, index) => (
              <LeaderboardTableItem
                handleResetLeaderboardOffset={handleResetLeaderboardOffset}
                isCurrentUserPinned={false}
                isManageMode={isManageMode}
                isOver={isOver}
                key={user?.user_id}
                leagueId={leagueId}
                ref={index === leaderboardData?.length - 1 ? ref : undefined}
                user={user}
              />
            ))}

            {Boolean(isFetching && offset) && (
              <Tr background='rgba(18, 18, 18, 0.50)' w='100%'>
                <Td
                  colSpan={
                    !isMobile ? tableHeaders?.length + 1 : tableHeaders?.length
                  }
                >
                  <Flex
                    alignItems='center'
                    justifyContent='center'
                    width='100%'
                  >
                    <Spinner size='lg' />
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    );
  },
);

LeaderboardTable.displayName = 'LeaderboardTable';
