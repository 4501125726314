import React from 'react';

import {
  FormControlProps,
  Input as ChakraInput,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { useFormContext } from 'react-hook-form';

export type Props = FormControlProps & {
  name: string;
  placeholder?: string;
  type?: string;
  value?: string;
  sx?: SystemStyleObject;
};
export const Input: React.FC<Props> = (props) => {
  const { name, ...rest } = props;

  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = errors[name]?.message as string;

  return (
    <FormControl isInvalid={Boolean(errors[name]?.message)}>
      <ChakraInput {...register(name)} {...rest} />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
