import { ReactNode, useCallback } from 'react';

import {
  Box,
  Flex,
  IconButton,
  Text,
  useToast as useChakraToast,
  UseToastOptions,
} from '@chakra-ui/react';

export const useToast = () => {
  const toast = useChakraToast();

  return useCallback(
    (
      message: string | ReactNode,
      type: 'success' | 'error',
      title?: string | null,
      params?: UseToastOptions,
    ) => {
      toast({
        position: 'top',
        isClosable: false,
        duration: 3000,
        ...params,
        render: ({ onClose, isClosable }) => (
          <Flex
            align='center'
            bgColor={type === 'success' ? '#157f3e' : 'tomato'}
            borderRadius='12px'
            color='white'
            fontWeight='bold'
            gap='16px'
            p='16px'
            pos='relative'
          >
            {type === 'success' && <CheckIcon />}
            <Box>
              {title && (
                <Text fontSize='14px' fontWeight='700'>
                  {title}
                </Text>
              )}
              <Text fontSize='12px'>{message}</Text>
            </Box>
            {isClosable && (
              <IconButton
                aria-label='close'
                icon={<CloseIcon />}
                onClick={onClose}
              />
            )}
          </Flex>
        ),
      });
    },
    [toast],
  );
};

const CloseIcon = () => (
  <svg
    fill='none'
    height='9'
    viewBox='0 0 10 9'
    width='10'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.73892 2.0441C2.87519 1.90783 3.09612 1.90783 3.23239 2.0441L5.07927 3.89098L6.92615 2.0441C7.06242 1.90783 7.28336 1.90783 7.41962 2.0441C7.55589 2.18036 7.55589 2.4013 7.41962 2.53757L5.57274 4.38445L7.41962 6.23133C7.55589 6.3676 7.55589 6.58853 7.41962 6.7248C7.28336 6.86107 7.06242 6.86107 6.92615 6.7248L5.07927 4.87792L3.23239 6.7248C3.09612 6.86107 2.87519 6.86107 2.73892 6.7248C2.60265 6.58853 2.60265 6.3676 2.73892 6.23133L4.5858 4.38445L2.73892 2.53757C2.60265 2.4013 2.60265 2.18036 2.73892 2.0441Z'
      fill='white'
    />
    <path
      d='M2.73892 2.0441C2.87519 1.90783 3.09612 1.90783 3.23239 2.0441L5.07927 3.89098L6.92615 2.0441C7.06242 1.90783 7.28336 1.90783 7.41962 2.0441C7.55589 2.18036 7.55589 2.4013 7.41962 2.53757L5.57274 4.38445L7.41962 6.23133C7.55589 6.3676 7.55589 6.58853 7.41962 6.7248C7.28336 6.86107 7.06242 6.86107 6.92615 6.7248L5.07927 4.87792L3.23239 6.7248C3.09612 6.86107 2.87519 6.86107 2.73892 6.7248C2.60265 6.58853 2.60265 6.3676 2.73892 6.23133L4.5858 4.38445L2.73892 2.53757C2.60265 2.4013 2.60265 2.18036 2.73892 2.0441Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit='10'
      strokeWidth='0.930496'
    />
  </svg>
);

const CheckIcon = () => (
  <svg
    fill='none'
    height='9'
    viewBox='0 0 9 9'
    width='9'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_662_150060)'>
      <path
        d='M7.35905 2.09205C7.50309 2.22008 7.51606 2.44063 7.38803 2.58467L3.66604 6.7719C3.59983 6.84639 3.50491 6.88902 3.40524 6.88902C3.30558 6.88902 3.21066 6.84639 3.14445 6.7719L1.28345 4.67828C1.15542 4.53425 1.1684 4.3137 1.31243 4.18566C1.45647 4.05763 1.67702 4.07061 1.80505 4.21464L3.40524 6.01486L6.86643 2.12103C6.99446 1.97699 7.21502 1.96402 7.35905 2.09205Z'
        fill='white'
      />
      <path
        d='M7.35905 2.09205C7.50309 2.22008 7.51606 2.44063 7.38803 2.58467L3.66604 6.7719C3.59983 6.84639 3.50491 6.88902 3.40524 6.88902C3.30558 6.88902 3.21066 6.84639 3.14445 6.7719L1.28345 4.67828C1.15542 4.53425 1.1684 4.3137 1.31243 4.18566C1.45647 4.05763 1.67702 4.07061 1.80505 4.21464L3.40524 6.01486L6.86643 2.12103C6.99446 1.97699 7.21502 1.96402 7.35905 2.09205Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.930496'
      />
    </g>
    <defs>
      <clipPath id='clip0_662_150060'>
        <rect
          fill='white'
          height='8.37447'
          transform='translate(0.148438 0.259277)'
          width='8.37447'
        />
      </clipPath>
    </defs>
  </svg>
);
