import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useJWT } from '@/hooks/useJWT';

import {
  useGetLeagueByIdQuery,
  useGetLeagueLeaderboardQuery,
} from '@/modules/LeagueManage/leagueManage.api';
import {
  SET_LEAGUE_DATA,
  SET_LEAGUE_IS_FETCHING,
  SET_LEAGUE_IS_LOADING,
  SET_LEADERBOARD_IS_LOADING,
  SET_LEADERBOARD_IS_FETCHING,
  SET_LEADERBOARD_DATA,
  SET_MEMBERS_IS_LOADING,
  SET_MEMBERS_IS_FETCHING,
  SET_MEMBERS_DATA,
} from '@/modules/LeagueManage/leagueManage.slice';

import { LeagueLeaderboardType } from './leagueManage.types';

export const useLeague = (id?: string): { refetchLeague: any } => {
  const dispatch = useDispatch();
  const { jwt } = useJWT();

  const {
    isLoading,
    isFetching,
    data,
    refetch: refetchLeague,
  } = useGetLeagueByIdQuery(
    { id: id as string, jwt },
    { skip: !id, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    dispatch(SET_LEAGUE_IS_LOADING(isLoading));
    dispatch(SET_LEAGUE_IS_FETCHING(isFetching));
    dispatch(SET_LEAGUE_DATA(data?.league));
  }, [isLoading, isFetching, data]);

  return { refetchLeague };
};

export const useLeagueLeaderboard = (
  id?: string,
): { refetchLeaderboard: any; entries: LeagueLeaderboardType[] } => {
  const dispatch = useDispatch();
  const { jwt } = useJWT();

  const {
    isLoading,
    isFetching,
    data,
    refetch: refetchLeaderboard,
  } = useGetLeagueLeaderboardQuery({ id: id as string, jwt }, { skip: !id });

  useEffect(() => {
    dispatch(SET_LEADERBOARD_IS_LOADING(isLoading));
    dispatch(SET_LEADERBOARD_IS_FETCHING(isFetching));
    dispatch(SET_LEADERBOARD_DATA(data?.entries));
  }, [isLoading, isFetching, data]);

  return { refetchLeaderboard, entries: data?.entries || [] };
};
