import { ApiTagsEnum } from '@/enums/apiTags.enum';

import { api } from '@/services/api.service';

import {
  AddEntryToLeague,
  CreateSecondChanceBody,
  GetDashboardResponse,
  GetGameResponse,
  GetSecondChanceEntryRes,
  IBracketEntry,
  PostEntryBody,
  SecondChanceEntryType,
  UpdateSecondChanceBody,
} from './bracket.types';

const leaderboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGame: builder.query<GetGameResponse, { jwt: string | undefined }>({
      query: () => ({
        url: '/game',
      }),
    }),

    getDashboard: builder.query<
      GetDashboardResponse,
      { jwt: string | undefined }
    >({
      query: () => ({
        url: '/dashboard',
      }),
    }),

    getEntries: builder.query<
      GetDashboardResponse,
      { jwt: string | undefined }
    >({
      query: () => ({
        url: '/entries',
      }),
      providesTags: [ApiTagsEnum.GET_ENTRIES],
    }),

    postEntry: builder.mutation<any, PostEntryBody>({
      query: (body) => ({
        url: '/entry',
        method: 'POST',
        body,
      }),
    }),

    updateEntry: builder.mutation<any, PostEntryBody>({
      query: (body) => ({
        url: '/entry',
        method: 'PUT',
        body,
      }),
    }),

    addEntryToLeague: builder.mutation<any, AddEntryToLeague>({
      query: ({ entryId, leagueId }) => ({
        url: `/entry/${entryId}/add`,
        method: 'POST',
        body: { league_id: leagueId },
      }),
    }),

    checkEntryName: builder.query<{ exists: boolean }, { entry_name: string }>({
      query: ({ entry_name }) => ({
        url: `/entries/check-name?name=${entry_name}`,
      }),
    }),

    getEntry: builder.query<
      { entry: IBracketEntry },
      { entry_id: number; jwt: string | undefined }
    >({
      query: ({ entry_id }) => ({
        url: `/entry/${entry_id}`,
      }),
    }),

    getSecondChanceEntry: builder.query<
      GetSecondChanceEntryRes,
      { jwt: string | undefined }
    >({
      query: () => ({
        url: '/second-chance-entry',
      }),
    }),
    getSecondChanceEntryById: builder.query<
      GetSecondChanceEntryRes,
      { entry_id: number; jwt: string | undefined }
    >({
      query: ({ entry_id }) => ({
        url: `/second-chance-entry/${entry_id}`,
      }),
    }),
    createSecondChanceEntry: builder.mutation<any, CreateSecondChanceBody>({
      query: (body) => ({
        url: '/second-chance-entry',
        method: 'POST',
        body,
      }),
    }),
    updateSecondChanceEntry: builder.mutation<any, UpdateSecondChanceBody>({
      query: (body) => ({
        url: '/second-chance-entry',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useGetGameQuery,
  useLazyGetGameQuery,
  useGetEntriesQuery,
  useGetDashboardQuery,
  usePostEntryMutation,
  useUpdateEntryMutation,
  useAddEntryToLeagueMutation,
  useLazyCheckEntryNameQuery,
  useLazyGetEntryQuery,
  useGetEntryQuery,

  useGetSecondChanceEntryQuery,
  useLazyGetSecondChanceEntryQuery,
  useCreateSecondChanceEntryMutation,
  useUpdateSecondChanceEntryMutation,
  useLazyGetSecondChanceEntryByIdQuery,
} = leaderboardApi;
