import { IBracketEntry, SecondChanceEntryType } from '../../bracket.types';

export const parseStepByEntrySelections = (entry: IBracketEntry): number => {
  let steps = 0;

  const {
    match_101_pick,
    match_102_pick,
    match_103_pick,
    match_104_pick,
    match_101_match_played,
    match_102_match_played,
    match_103_match_played,
    match_104_match_played,
    match_105_pick,
    match_106_pick,
    match_107_pick,
    match_108_pick,
    match_105_match_played,
    match_106_match_played,
    match_107_match_played,
    match_108_match_played,
    match_201_pick,
    match_202_pick,
    match_203_pick,
    match_204_pick,
    match_301_pick,
    match_302_pick,
    match_401_pick,
    tiebreaker,
  } = entry;

  if (match_101_pick > 0) {
    steps++;
  }
  if (match_102_pick > 0) {
    steps++;
  }
  if (match_103_pick > 0) {
    steps++;
  }
  if (match_104_pick > 0) {
    steps++;
  }
  if (match_105_pick > 0) {
    steps++;
  }
  if (match_106_pick > 0) {
    steps++;
  }
  if (match_107_pick > 0) {
    steps++;
  }
  if (match_108_pick > 0) {
    steps++;
  }

  if (
    match_101_match_played > 0 &&
    match_102_match_played > 0 &&
    match_103_match_played > 0 &&
    match_104_match_played > 0
  ) {
    steps++;
  }
  if (
    match_105_match_played > 0 &&
    match_106_match_played > 0 &&
    match_107_match_played > 0 &&
    match_108_match_played > 0
  ) {
    steps++;
  }

  if (match_201_pick > 0) {
    steps++;
  }
  if (match_202_pick > 0) {
    steps++;
  }
  if (match_203_pick > 0) {
    steps++;
  }
  if (match_204_pick > 0) {
    steps++;
  }
  if (match_301_pick > 0) {
    steps++;
  }
  if (match_302_pick > 0) {
    steps++;
  }
  if (match_401_pick > 0) {
    steps++;
  }
  if (Boolean(tiebreaker)) {
    steps++;
  }

  return steps;
};

export const parseStepByEntrySCSelections = (
  entry: SecondChanceEntryType,
): number => {
  let steps = 0;

  const {
    match_201_pick,
    match_202_pick,
    match_203_pick,
    match_204_pick,
    match_301_pick,
    match_302_pick,
    match_401_pick,
    match_201_match_played,
    match_202_match_played,
    match_203_match_played,
    match_204_match_played,
    tiebreaker,
  } = entry;

  if (match_201_pick > 0) {
    steps++;
  }
  if (match_202_pick > 0) {
    steps++;
  }
  if (match_203_pick > 0) {
    steps++;
  }
  if (match_204_pick > 0) {
    steps++;
  }

  if (match_201_match_played > 0 && match_202_match_played > 0) {
    steps++;
  }
  if (match_203_match_played > 0 && match_204_match_played > 0) {
    steps++;
  }

  if (match_301_pick > 0) {
    steps++;
  }
  if (match_302_pick > 0) {
    steps++;
  }
  if (match_401_pick > 0) {
    steps++;
  }
  if (Boolean(tiebreaker)) {
    steps++;
  }

  return steps;
};
