import { useCallback, useMemo } from 'react';

import { useJWT } from '@/hooks/useJWT';

import { useGetGameQuery } from '@/modules/Bracket/bracket.api';

import colors from '@/theme/foundations/colors';

export const useGameTeamHexCode = () => {
  const { jwt } = useJWT();
  const { data } = useGetGameQuery({ jwt });

  const teams = useMemo(() => data?.game?.teams || [], [data]);

  const getHexCode = useCallback(
    (teamId: number | string) => {
      const team = teams.find((_) => _.team_id === Number(teamId));

      return team && team.hex_code
        ? `#${team.hex_code}`
        : colors.primaryDarkGradient;
    },
    [teams],
  );

  return { getHexCode };
};
