import React from 'react';

import {
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import searchIcon from '@/assets/icons/search.svg';

import { useSettings } from '@/hooks/useSettings';

type Props = {
  onSearch: (value: string) => void;
};

export const MembersFilter: React.FC<Props> = (props) => {
  const { onSearch } = props;
  const { isMobile } = useSettings();
  const { t } = useTranslation();

  return (
    <InputGroup width={isMobile ? '100%' : '190px'}>
      <InputLeftElement pointerEvents='none'>
        <Image alt='search' src={searchIcon} />
      </InputLeftElement>
      <Input
        background='#1E3647'
        border='none'
        borderRadius='50px'
        boxShadow='0px 4px 10px 0px rgba(0, 0, 0, 0.45) inset'
        onChange={(e) => onSearch(e.target.value)}
        placeholder={t(tKeys.search)}
        type='text'
      />
    </InputGroup>
  );
};
