import { FC } from 'react';

import { Box, Flex, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { ReactComponent as CorrectIcon } from '@/assets/icons/correct-mark.svg';
import { ReactComponent as IncorrectIcon } from '@/assets/icons/incorrect.svg';
import cupSrc from '@/assets/images/cup.png';

import { TeamType } from '@/modules/Bracket/bracket.types';

import { onImageError } from '@/utils/onImageError.util';

import { selectBracket } from '../../../bracket.slice';
import { useGameHook } from '../../../hooks/useGame.hook';

interface ViewTeamProps {
  id: number | null;
  is_scored: boolean;
  position: number;
  matchId: number;
  winner_id: number | null;
  aspectRatio?: string;
  round_sequence: number;
}

export const ViewTeam: FC<ViewTeamProps> = ({
  id,
  is_scored,
  position,
  matchId,
  winner_id,
  aspectRatio = 1,
  round_sequence,
}) => {
  const { teams } = useGameHook();
  const { entry, seriesResults } = useSelector(selectBracket);
  const selectedTeamId = entry[`match_${matchId}_pick`];

  const isWinner = Boolean(id) && is_scored && winner_id === id;

  const currTeamInPrevScoredRound = seriesResults?.find(
    (x) =>
      x.round_sequence < round_sequence &&
      (id === x.team_1_id || id === x.team_2_id) &&
      x.winner_id &&
      x.winner_id !== id,
  );

  const isEliminated =
    (is_scored && winner_id !== id) || currTeamInPrevScoredRound;

  const currentTeam = teams.find((_) => _.team_id === id) || ({} as TeamType);

  const { hex_code, team_logo } = currentTeam;

  const isLeftCol = [101, 102, 103, 104, 201, 202, 301].includes(matchId);
  const isRightCol = [105, 106, 107, 108, 203, 204, 302].includes(matchId);

  const isFinalMatch = matchId === 401;

  const isFinalEntryId = Number(entry.match_401_pick);
  const showWinnerCup = isFinalMatch && isFinalEntryId === id && id !== 0;

  return (
    <Flex
      align='center'
      aspectRatio={aspectRatio}
      bg={
        hex_code
          ? `#${hex_code}`
          : position === 1
            ? 'primaryDarkGradient'
            : 'primaryLightGradient'
      }
      color='#FFFFFF'
      justify='center'
      position='relative'
      w='100%'
    >
      {!!team_logo && <Image onError={onImageError} src={team_logo} />}

      {/* Cup image for final cell */}
      {showWinnerCup && (
        <Image
          h='40%'
          position='absolute'
          right='5px'
          src={cupSrc}
          top={'5px'}
        />
      )}

      {/* Correct/Incorrect Mark */}
      {is_scored && selectedTeamId === id && (
        <Box
          bottom={position === 1 ? '25px' : 'auto'}
          left={isRightCol ? '5px' : 'auto'}
          position='absolute'
          right={isLeftCol ? '5px' : 'auto'}
          top={position === 2 ? '25px' : 'auto'}
          zIndex={2}
        >
          {isWinner ? <CorrectIcon /> : <IncorrectIcon />}
        </Box>
      )}

      {/* Black overlay */}
      {isEliminated && (
        <Box bg='#000000cc' h='100%' position='absolute' w='100%' zIndex={1} />
      )}
    </Flex>
  );
};
