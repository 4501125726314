import { useEffect, useMemo } from 'react';

import { useGameDate } from '@/hooks/useGameDate';
import { useTimer } from '@/hooks/useTimer';

export const useGameTimer = () => {
  const {
    isComing,
    startDate,
    endDate,
    secondChanceEndDate,
    secondChanceStartDate,
    isSecondChanceComing,
    isSecondChanceOver,
    isOver,
    ...rest
  } = useGameDate();
  const date = useMemo(() => {
    if (isSecondChanceComing) return secondChanceStartDate;
    if (!isSecondChanceComing && isOver) return secondChanceEndDate;
    if (isComing) return startDate;
    return endDate;
  }, [
    endDate,
    isComing,
    isOver,
    isSecondChanceComing,
    secondChanceEndDate,
    secondChanceStartDate,
    startDate,
  ]);

  const { timeLeft, isExpired } = useTimer(date as string | Date);

  useEffect(() => {
    if (isExpired && !isSecondChanceOver) {
      window.location.reload();
    }
  }, [isExpired, isSecondChanceOver]);

  return {
    timeLeft,
    isExpired,
    isComing,
    isSecondChanceComing,
    isOver,
    secondChanceStartDate,
    secondChanceEndDate,
    isSecondChanceOver,
    ...rest,
  };
};
