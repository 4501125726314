import React from 'react';

import {
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { FullWidthModalHeader } from '@/modules/Leagues/components/FullWidthModalHeader';
import { LeagueIconLayout } from '@/modules/Leagues/components/LeagueIconLayout';
import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { leagueIcons } from '@/modules/Leagues/leagues.mocks';

type Props = {
  selectedIconKey: LeagueIconKey;
  onSelectImageKey: (value: LeagueIconKey) => void;
  onSaveImage: () => void;
  onGoBack: () => void;
  isMobile: boolean;
};

export const CreateLeagueSelectImage: React.FC<Props> = (props) => {
  const { selectedIconKey, onSelectImageKey, onSaveImage, onGoBack, isMobile } =
    props;

  const { t } = useTranslation();

  return (
    <>
      <ModalHeader>
        {isMobile ? (
          <FullWidthModalHeader
            onClick={onGoBack}
            text={t(tKeys.selectImage)}
          />
        ) : (
          <LeagueIconLayout
            borderRadius='20px'
            height='112px'
            iconKey={selectedIconKey}
            padding='17px'
            width='112px'
          />
        )}
      </ModalHeader>
      {!isMobile && <ModalCloseButton />}
      <ModalBody>
        {isMobile && (
          <LeagueIconLayout
            borderRadius='20px'
            height='90px'
            iconKey={selectedIconKey}
            padding='17px'
            sx={{ marginTop: '20px', marginBottom: '30px' }}
            width='90px'
          />
        )}

        <Flex flexWrap='wrap' gap='8px 3px' justifyContent='center'>
          {Object.keys(leagueIcons).map((icon, index) => (
            <LeagueIconLayout
              borderRadius='12px'
              height='63px'
              iconKey={icon as LeagueIconKey}
              isHoverDisabled={isMobile}
              key={index}
              onClick={() => onSelectImageKey(icon as LeagueIconKey)}
              padding='8px'
              width='63px'
            />
          ))}
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Flex flexDirection='column' width={isMobile ? '100%' : 'auto'}>
          <Button
            marginBottom='8px'
            maxWidth='none'
            onClick={onSaveImage}
            width={isMobile ? '100%' : '350px'}
          >
            {t(tKeys.saveImage)}
          </Button>
          <Button
            maxWidth='none'
            onClick={onGoBack}
            textDecoration='underline'
            variant='outlined'
          >
            {t(tKeys.goBack)}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
