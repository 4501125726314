import { FC, PropsWithChildren, useMemo } from 'react';

import { Box, BoxProps, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import BracketsImage from '@/assets/images/guide-brackets.png';
import LeaderboardImage from '@/assets/images/guide-leaderboard.png';
import WinTripCZImage from '@/assets/images/win-a-trip-for-two-cs.jpg';
import WinTripDEImage from '@/assets/images/win-a-trip-for-two-de.jpg';
import WinTripENImage from '@/assets/images/win-a-trip-for-two-en.png';
import WinTripFIImage from '@/assets/images/win-a-trip-for-two-fi.jpg';
import WinTripFRImage from '@/assets/images/win-a-trip-for-two-fr.jpg';
import WinTripSWImage from '@/assets/images/win-a-trip-for-two-sv.jpg';

import { RightArrowButton } from '@/components/RightArrowButton';

import { RoutesEnum } from '@/enums/routes.enum';

import { useGameDate } from '@/hooks/useGameDate';
import { useLang } from '@/hooks/useLang';
import { useSettings } from '@/hooks/useSettings';

import { createPath } from '@/routes/types';

import { MobileSlider } from './MobileSlider';

const LOCALIZED_WIN_TRIP_IMAGE: { [key: string]: string } = {
  en: WinTripENImage,
  sv: WinTripSWImage,
  fi: WinTripFIImage,
  cs: WinTripCZImage,
  de: WinTripDEImage,
  fr: WinTripFRImage,
};

const getBeforeStyles = (order: number) => ({
  alignItems: 'center',
  bg: 'lightBlue',
  border: '3px solid',
  borderColor: '#0D2434',
  color: 'white',
  content: `"${order}"`,
  display: 'flex',
  fontSize: '24px',
  fontWeight: '600',
  justifyContent: 'center',
  transform: 'translateY(-50%)',
  h: '42px',
  w: '42px',
});

interface GuideItemProps {
  order: number;
}

const GuideItem: FC<PropsWithChildren<GuideItemProps>> = ({
  children,
  order,
}) => (
  <Flex
    _before={getBeforeStyles(order)}
    align='center'
    bg='primaryDarkGradient'
    direction='column'
    h='415px'
    justify='space-between'
    my='24px'
    pb='40px'
    px='8px'
    w='100%'
  >
    {children}
  </Flex>
);

export const Guide: FC<BoxProps> = (props) => {
  const { t } = useTranslation();
  const { lang } = useLang();
  const navigate = useNavigate();
  const { isMobile } = useSettings();
  const { isComing, isOver } = useGameDate();

  const localizedWinTripImage = useMemo(
    () => LOCALIZED_WIN_TRIP_IMAGE[lang],
    [lang],
  );

  return (
    <Box {...props}>
      {isMobile && (
        <Text
          fontFamily='Antonio'
          fontSize='36px'
          fontWeight='700'
          my='24px'
          textAlign='center'
          textTransform='uppercase'
        >
          {t(tKeys.scoring)}
        </Text>
      )}

      <Flex gap='16px'>
        <MobileSlider>
          <GuideItem order={1}>
            <Image alt='guide-brackets' mb='24px' src={BracketsImage} />
            <Box textAlign='center'>
              <Text
                fontSize='18px'
                fontWeight='700'
                lineHeight='1.4'
                mb='15px'
                mt='-15px'
              >
                {t(tKeys.scoring)}
              </Text>
              <Text fontSize='14px' lineHeight='1.4' mb='0px'>
                {t(tKeys.points1)}
              </Text>
              <Text fontSize='14px' lineHeight='1.4' mb='0px'>
                {t(tKeys.points2)}
              </Text>
              <Text fontSize='14px' lineHeight='1.4' mb='0px'>
                {t(tKeys.points3)}
              </Text>
              <Text fontSize='14px' lineHeight='1.4' mb='10px'>
                {t(tKeys.points4)}
              </Text>
              <Text fontSize='14px' lineHeight='1.4'>
                {t(tKeys.selectTotalNumberOfGames)}
              </Text>
            </Box>
          </GuideItem>

          <GuideItem order={2}>
            <Image alt='guide-lines' mb='24px' src={LeaderboardImage} />
            <Box textAlign='center'>
              <Text fontSize='18px' fontWeight='700' lineHeight='1.4' mb='15px'>
                {t(tKeys.topTheLeaderboard)}
              </Text>
              <Text fontSize='14px' lineHeight='1.4' mb='10px'>
                {t(tKeys.earnPoints)}
              </Text>
              <Text fontSize='14px' lineHeight='1.4'>
                {t(tKeys.earnBonusPoints)}
              </Text>
            </Box>
          </GuideItem>

          <GuideItem order={3}>
            <Image alt='guide-square' mb='24px' src={localizedWinTripImage} />
            <Box textAlign='center'>
              <Text fontSize='18px' fontWeight='700' lineHeight='1.4' mb='15px'>
                {t(tKeys.winTheGrandPrize)}
              </Text>
              <Text fontSize='14px' lineHeight='1.4' mb='10px'>
                {t(tKeys.finishOnTopOfLeaderboard)}
              </Text>
              {isComing ? (
                <RightArrowButton
                  onClick={() =>
                    navigate(createPath({ path: RoutesEnum.leagues }))
                  }
                >
                  {t(tKeys.joinLeagues)}
                </RightArrowButton>
              ) : (
                <RightArrowButton
                  isDisabled={isOver}
                  onClick={() =>
                    navigate(createPath({ path: RoutesEnum.brackets }))
                  }
                >
                  {t(tKeys.playNow)}
                </RightArrowButton>
              )}
            </Box>
          </GuideItem>
        </MobileSlider>
      </Flex>
    </Box>
  );
};
