import React from 'react';

import {
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import HierarchyDark from '@/assets/icons/hierarchy-dark.svg';
import searchIcon from '@/assets/icons/search.svg';

import { RoutesEnum } from '@/enums/routes.enum';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';

import { createPath } from '@/routes/types';

type Props = {
  onFilter: (value: boolean) => void;
  onSearch: (value: string) => void;
  isTablet: boolean;
};

export const BracketsFilter: React.FC<Props> = (props) => {
  const { isTablet, onSearch, onFilter } = props;
  const { withAuthCheck } = useAuthContext();
  const { jwt } = useJWT();
  const { isOver } = useGameDate();

  const navigate = useNavigate();

  const handleCreateNewBracket = () => {
    withAuthCheck(() => {
      navigate(createPath({ path: RoutesEnum.bracketsNew }));
    });
  };

  return (
    <Flex
      alignItems='center'
      background='linear-gradient(141.41deg, #153449 1.32%, #3D617B 98.18%)'
      flexDirection={isTablet ? 'column' : 'row'}
      justifyContent={isTablet ? 'normal' : 'space-between'}
      mb='24px'
      padding={isTablet ? '16px' : '40px 24px'}
      rowGap='16px'
      width='100%'
    >
      <Text
        fontFamily='Antonio'
        fontSize='30px'
        fontWeight='700'
        marginBottom={isTablet ? '12px' : '0'}
        textAlign={isTablet ? 'center' : 'left'}
      >
        {t(tKeys.brackets)}
      </Text>
      <InputGroup width={isTablet ? '100%' : '190px'}>
        <InputLeftElement pointerEvents='none'>
          <Image alt='search' src={searchIcon} />
        </InputLeftElement>
        <Input
          background='#1E3647'
          border='none'
          borderRadius='50px'
          boxShadow='0px 4px 10px 0px rgba(0, 0, 0, 0.45) inset'
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t(tKeys.search)}
          type='text'
        />
      </InputGroup>

      <Flex
        align='center'
        flexDirection={{ base: 'column', md: 'row' }}
        gap='16px'
        justify='space-between'
        w={['100%', '50%']}
      >
        <Checkbox onChange={(e) => onFilter(e.target.checked)}>
          <Text fontSize='14px' lineHeight='1.3'>
            {t(tKeys.unassigned)}
          </Text>
        </Checkbox>

        <Button
          isDisabled={isOver}
          onClick={handleCreateNewBracket}
          rightIcon={<Image src={HierarchyDark} />}
          width='fit-content'
        >
          {t(tKeys.createNewBracket)}
        </Button>
      </Flex>
    </Flex>
  );
};
