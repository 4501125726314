import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LanguageCodeType, enabledLanguages, tKeys } from '@/i18n';

import CZImage from '@/assets/images/flags/CZE.png';
import FINImage from '@/assets/images/flags/FIN.png';
import FRAImage from '@/assets/images/flags/FRA.png';
import GERImage from '@/assets/images/flags/GER.png';
import SWEImage from '@/assets/images/flags/SWE.png';
import USAImage from '@/assets/images/flags/USA.png';

import { useLang } from '@/hooks/useLang';

import { formatStorageValue } from '@/utils/localStorage.util';

interface Language {
  code: LanguageCodeType;
  title: string;
  image: string;
}

export const useLanguages = () => {
  const { t } = useTranslation();
  const { lang } = useLang();
  const [currentLang, setCurrentLang] = useState<Language | null>();

  const languages = useMemo<Language[]>(
    () =>
      (
        [
          { code: 'en', title: t(tKeys.english), image: USAImage },
          { code: 'fr', title: t(tKeys.french), image: FRAImage },
          { code: 'cs', title: t(tKeys.czech), image: CZImage },
          { code: 'fi', title: t(tKeys.finnish), image: FINImage },
          { code: 'de', title: t(tKeys.german), image: GERImage },
          { code: 'sv', title: t(tKeys.swedish), image: SWEImage },
        ] as Language[]
      ).filter((lng) => enabledLanguages.includes(lng.code)),
    [t],
  );

  const getCurrentLang = useCallback(
    (code: string) => languages.find((x) => x.code === code),
    [languages],
  );

  useEffect(() => {
    setCurrentLang(getCurrentLang(formatStorageValue(lang)));
  }, [getCurrentLang, lang]);

  return { languages, currentLang };
};
