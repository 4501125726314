import {
  ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
  NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
} from '@/core/env';

export const TABLE_SEARCH_DEBOUNCE_DELAY_MS = 300;
export const UNIQUE_NAME_VALIDATION_DELAY_MS = 300;

export const nhlBoldScrollbarStyles = {
  '::-webkit-scrollbar': {
    width: '16px',
  },
  '::-webkit-scrollbar-track': {
    bgColor: '#1A3141',
    borderRadius: '20px',
  },
  '::-webkit-scrollbar-thumb': {
    bgColor: '#3C5F79',
    borderRadius: '8px',
  },
};

export const nhlThinScrollbarStyles = {
  '::-webkit-scrollbar': {
    width: '8px',
  },
  '::-webkit-scrollbar-track': {
    bgColor: '#1A3141',
    borderRadius: '20px',
  },
  '::-webkit-scrollbar-thumb': {
    bgColor: '#3C5F79',
    borderRadius: '8px',
  },
  '::-webkit-scrollbar-corner': {
    display: 'none',
  },
};

export const hiddenScrollbarStyles = {
  '::-webkit-scrollbar': {
    display: 'none',
    position: 'relative',
  },
};

export const formatNumberWithComma = <T>(num: T) => {
  if (!num) return '--';
  return Number(num).toLocaleString();
};

export const isExist = <T>(val: T) => val !== undefined && val !== null;

export const isSecondChanceLeague = (leagueId: number | string | undefined) =>
  [
    ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
    NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
  ].includes(String(leagueId));
