import { useMemo } from 'react';

import { Button, Flex, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import cup from '@/assets/images/cup.png';
import SecondChanceCup from '@/assets/images/second-chance-cup.svg';

import { useAuth } from '@/hooks/useAuth';
import { useAuthContext } from '@/hooks/useAuthContext';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

export const SignupTodayBlock = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const { onSignInOpen } = useAuthContext();

  const isSecondChancePage = useMemo(
    () => pathname === bracketsSecondChancePath(),
    [pathname],
  );

  return (
    <>
      <Flex align='center' direction='column' gap='20px'>
        <Image height='80px' src={isSecondChancePage ? SecondChanceCup : cup} />
        {!isAuthenticated && (
          <Button onClick={onSignInOpen}>{t(tKeys.signupToday)}</Button>
        )}
      </Flex>
    </>
  );
};
