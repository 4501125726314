import React, { useMemo } from 'react';

import { Box, Flex, IconButton, Image } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowLeft from '@/assets/icons/arrow-left.svg';
import BurgerIcon from '@/assets/icons/burger.svg';

import AdsBanner from '@/components/AdsBanner';

import { RoutesEnum } from '@/enums/routes.enum';

import { useLang } from '@/hooks/useLang';
import { useSettings } from '@/hooks/useSettings';

import { useSidebarStore } from '@/modules/Sidebar/hooks/useSidebarStore';

import { addIdToRoute, isPathEqualsToPathname } from '@/routes/utils';

export const Header: React.FC = () => {
  const { id } = useParams();
  const { handleOpenSidebar } = useSidebarStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useSettings();
  const { lang } = useLang();
  const isLeagueManagePage = useMemo(
    () => pathname === `/${lang}/${addIdToRoute(RoutesEnum.leagueManage, id)}`,
    [id, lang, pathname],
  );
  const isRootPage =
    pathname === RoutesEnum.root ||
    pathname === `/${lang}/${addIdToRoute(RoutesEnum.bracketsSpecific, id)}` ||
    pathname === `/${lang}/${RoutesEnum.bracketsNew}`;

  return (
    <Flex
      alignItems='center'
      bgColor='darkBlue'
      gap='2px'
      h='87px'
      justifyContent='space-between'
      left='0'
      paddingBottom='3px'
      paddingTop='3px'
      position='fixed'
      px='8px'
      top='0'
      w='100%'
      zIndex='998'
    >
      {isLeagueManagePage && (
        <IconButton
          aria-label='back'
          icon={<Image alt='go back' src={ArrowLeft} />}
          onClick={() => navigate(-1)}
          variant='unstyled'
        />
      )}

      {!isRootPage ? (
        <AdsBanner slotId='populogo-ad' slotSize={[226, 81]} />
      ) : (
        <Box width='226px'></Box>
      )}

      <IconButton
        aria-label='menu'
        icon={<Image alt='menu' src={BurgerIcon} />}
        onClick={handleOpenSidebar}
        variant='unstyled'
      />
    </Flex>
  );
};
