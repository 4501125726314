import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Text,
  Heading,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import circleRemoveLight from '@/assets/icons/circle-remove-light.svg';
import circleRemove from '@/assets/icons/circle-remove.svg';

import AdsBanner from '@/components/AdsBanner';

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRemove: () => void;
  isSubmitting: boolean;
};

export const RemoveUserModal: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose, onRemove, isSubmitting } = props;

  const { t } = useTranslation();

  return (
    <>
      <Button onClick={onOpen} variant='unstyled' width='fit-content'>
        <Image src={circleRemove} />
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader flexDirection='column' pt='0'>
            <AdsBanner mb='24px' slotId='modal-ad' slotSize={[226, 81]} />
            <Heading as='h3' fontSize='20px' textAlign='center'>
              {t(tKeys.youHaveRemovedUser)} {t(tKeys.pleaseConfirm)}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter flexDirection='column'>
            <Button
              isLoading={isSubmitting}
              onClick={onRemove}
              rightIcon={<Image src={circleRemoveLight} />}
              variant='error'
              width='fit-content'
            >
              {t(tKeys.removeUsers)}
            </Button>
            <Button marginTop='20px' onClick={onClose} variant='link'>
              {t(tKeys.goBack)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
