import React from 'react';

import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

import { useGameDate } from '@/hooks/useGameDate';

import { BracketLeague } from '@/modules/Bracket/bracket.types';
import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { leagueIcons } from '@/modules/Leagues/leagues.mocks';

import { createPath } from '@/routes/types';

import {
  nhlBoldScrollbarStyles,
  nhlThinScrollbarStyles,
} from '@/utils/common.util';

type Props = {
  leagues: BracketLeague[];
  isTablet: boolean;
  onAssign(): void;
};

export const EnteredLeagues: React.FC<Props> = (props) => {
  const { leagues, isTablet, onAssign } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOver } = useGameDate();

  return (
    <Flex
      background={
        leagues.length && isTablet ? 'rgba(28, 37, 44, 0.60)' : 'transparent'
      }
      justify='center'
      maxH='128px'
      mt={isTablet ? '12px' : undefined}
      overflowY='auto'
      sx={nhlThinScrollbarStyles}
      w='100%'
    >
      {leagues.length ? (
        <Box
          borderRadius={isTablet ? '8px' : undefined}
          padding={isTablet ? '12px 4px 4px 4px' : undefined}
          w='100%'
        >
          {isTablet && (
            <Text mb='12px' ml='12px'>
              {t(tKeys.leaguesEntered)}
            </Text>
          )}
          {leagues.map((league, index) => (
            <Flex key={index} marginBottom='4px'>
              <Image
                alt='league-image'
                fallbackSrc={leagueIcons[LeagueIconKey.NhlLeague]}
                h='18px'
                marginRight='12px'
                src={
                  leagueIcons[league.league_icon as LeagueIconKey]
                    ? leagueIcons[league.league_icon as LeagueIconKey]
                    : league.league_icon
                }
                w='18px'
              />
              <Text
                _hover={{
                  textDecoration: 'underline',
                }}
                cursor='pointer'
                fontSize='12px'
                onClick={() =>
                  navigate(
                    createPath({
                      path: RoutesEnum.leagueManage,
                      params: { id: league?.league_id },
                    }),
                  )
                }
                wordBreak='break-word'
              >
                {league.league_name}
              </Text>
            </Flex>
          ))}
        </Box>
      ) : (
        <Button height='36px' isDisabled={isOver} onClick={onAssign}>
          {t(tKeys.assignLeague)}
        </Button>
      )}
    </Flex>
  );
};
