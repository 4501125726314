import { Global } from '@emotion/react';

import AvertaExtraBold from '@/assets/fonts/AvertaStd-ExtraBold.ttf';
import AvertaRegular from '@/assets/fonts/AvertaStd-Regular.ttf';
import AvertaSemibold from '@/assets/fonts/AvertaStd-Semibold.ttf';
import AntonioBold from '@/assets/fonts/Antonio-Bold.ttf';

const Fonts = () => (
  <Global
    styles={`
        /* latin */
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-weight: 400;
          src: url(${AvertaRegular}) format("truetype");
        }
        /* latin */
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-weight: 600;
          src: url(${AvertaSemibold}) format("truetype");
        }
        /* latin */
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-weight: 700;
          src: url(${AvertaExtraBold}) format("truetype");
        }
        /* latin */
        @font-face {
          font-family: 'AvertaStd';
          font-style: normal;
          font-weight: 700;
          src: url(${AvertaExtraBold}) format("truetype");
        }
        @font-face {
          font-family: 'Antonio';
          font-style: normal;
          font-weight: 700;
          src: url(${AntonioBold}) format("truetype");
        }
        `}
  />
);

export default Fonts;
