import React from 'react';

import { Box, Grid, Text, Image, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { tKeys } from '@/i18n';

import { useSettings } from '@/hooks/useSettings';

import { JoinLeagueAction } from '@/modules/LeagueManage/components/JoinLeagueAction';
import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';

export const LeagueManagePrizes: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useSettings();
  const leagueData = useSelector(getLeagueData);

  return isMobile ? (
    <Box p='16px'>
      <Box
        bg={theme.colors.tyrelGradient}
        borderRadius='8px 8px 0 0'
        h='8px'
        mb='4px'
        w='100%'
      />
      <Box
        bgColor='darkBlue'
        borderRadius='0 0 8px 8px'
        px='16px'
        py='12px'
        textAlign='center'
      >
        <Text
          fontSize='18px'
          fontWeight='600'
          pb='8px'
          textTransform='uppercase'
        >
          {t(tKeys.prizes)}
        </Text>
        <Text fontSize='12px'>
          {leagueData?.prize_text || t(tKeys.emptyPrizes)}
        </Text>
        {leagueData?.has_prizing_image && !!leagueData?.banner_url && (
          <Image alt='prize banner' mt='8px' src={leagueData?.banner_url} />
        )}
      </Box>
    </Box>
  ) : (
    <>
      <Box
        bg={theme.colors.tyrelGradient}
        borderRadius='8px 8px 0 0'
        h='16px'
        mb='8px'
        w='100%'
      />
      <Grid
        color='white'
        gap='8px'
        gridTemplateRows='1fr'
        mb='24px'
        templateColumns='1fr 2fr'
      >
        <Box
          bgColor={theme.colors.bruxBlue}
          borderRadius='0 0 0 8px'
          px='16px'
          py='16px'
        >
          <Text
            fontSize='18px'
            fontWeight='600'
            pb='8px'
            textTransform='uppercase'
          >
            {t(tKeys.entry)}
          </Text>

          <JoinLeagueAction />
        </Box>
        <Box
          bgColor={theme.colors.bruxBlue}
          borderRadius='0 0 8px 0'
          px='16px'
          py='16px'
        >
          <Text
            fontSize='18px'
            fontWeight='600'
            pb='8px'
            textTransform='uppercase'
          >
            {t(tKeys.prizes)}
          </Text>
          <Text fontSize='12px'>
            {leagueData?.prize_text || t(tKeys.emptyPrizes)}
          </Text>
          {leagueData?.has_prizing_image && !!leagueData?.banner_url && (
            <Image alt='prize banner' mt='8px' src={leagueData?.banner_url} />
          )}
        </Box>
      </Grid>
    </>
  );
};
