import React from 'react';

import { Tab } from '@chakra-ui/react';

import { tKeys } from '@/i18n';

import { useSettings } from '@/hooks/useSettings';

interface LeagueTabProps {
  label: string;
  isOneTab: boolean;
}
export const LeagueTab: React.FC<LeagueTabProps> = ({ label, isOneTab }) => {
  const { isMobile } = useSettings();
  return (
    <Tab
      _selected={{
        color: 'white',
        bgColor: 'transparent',
        ...(isOneTab
          ? {}
          : { borderBottom: '2px solid', borderColor: 'ignisGrey' }),
      }}
      color='rgba(255, 255, 255, 0.3)'
      fontFamily='Antonio'
      fontSize='30px'
      mb={isMobile ? '16px' : '0'}
      textTransform={'uppercase'}
    >
      {label}
    </Tab>
  );
};
