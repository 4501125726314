import { Fragment } from 'react';

import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';

import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

import { createPath } from '@/routes/types';

import { useGetDashboardQuery } from '../../../bracket.api';
import { selectBracket } from '../../../bracket.slice';

export const BracketsDropdown = () => {
  const { entry } = useSelector(selectBracket);
  const { jwt } = useJWT();
  const { isMobile } = useSettings();

  const { entriesOptions } = useGetDashboardQuery(
    { jwt },
    {
      selectFromResult: ({ data }) => ({
        entriesOptions:
          data?.entries.map((x) => ({
            label: x?.entry_name,
            id: x?.entry_id,
          })) || [],
      }),
      skip: !jwt,
    },
  );

  const itemBorderRadius = (entries: typeof entriesOptions, index: number) => {
    if (entries?.length === 1) return '8px 8px 8px 8px';
    if (index === 0) return '8px 8px 0 0';
    if (index === entries?.length - 1) return '0 0 8px 8px';
    return '0';
  };

  return (
    <>
      <Menu matchWidth>
        {({ isOpen }) => (
          <>
            <MenuButton
              _active={{
                bgColor: 'transparent',
              }}
              _hover={{
                bgColor: 'transparent',
              }}
              as={Button}
              color={'white'}
              isActive={isOpen}
              maxW={isMobile ? '170px' : 'fit-content'}
              minW={'auto'}
              p={isMobile ? '0' : '12px 24px'}
              rightIcon={<ChevronDownIcon />}
              variant={'ghost'}
              w={'100%'}
            >
              <Text isTruncated>{entry?.entry_name}</Text>
            </MenuButton>
            <MenuList
              bgColor='#132633'
              border={'none'}
              borderRadius='8px'
              boxShadow='0px 4px 4px 0px #00000059'
            >
              {entriesOptions.map(({ label, id }, index) => (
                <MenuItem
                  _hover={{
                    bg: 'primaryDarkGradient',
                  }}
                  as={Link}
                  bg={
                    id === entry?.entry_id ? 'primaryDarkGradient' : '#192E3B'
                  }
                  borderRadius={itemBorderRadius(entriesOptions, index)}
                  justifyContent='flex-start'
                  key={id}
                  px='16px'
                  py='12px'
                  to={createPath({
                    path: RoutesEnum.bracketsSpecific,
                    params: { id },
                  })}
                >
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
};

const ChevronDownIcon = () => (
  <svg
    fill='none'
    height='24'
    viewBox='0 0 25 24'
    width='25'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.79289 9.29289C6.18342 8.90237 6.81658 8.90237 7.20711 9.29289L12.5 14.5858L17.7929 9.29289C18.1834 8.90237 18.8166 8.90237 19.2071 9.29289C19.5976 9.68342 19.5976 10.3166 19.2071 10.7071L13.2071 16.7071C12.8166 17.0976 12.1834 17.0976 11.7929 16.7071L5.79289 10.7071C5.40237 10.3166 5.40237 9.68342 5.79289 9.29289Z'
      fill='white'
    />
  </svg>
);
