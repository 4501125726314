import React, { useCallback } from 'react';

import { useMediaQuery } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { TABLE_SEARCH_DEBOUNCE_DELAY_MS } from '@/utils/common.util';

import { Search as SearchComponent } from '../components/Search';

type Props = {
  onResetOffset: () => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

export const Search: React.FC<Props> = (props) => {
  const { onResetOffset, setSearch } = props;

  // TODO global settings
  const [isMobile] = useMediaQuery('(max-width: 900px)');

  const handleSearch = useDebouncedCallback((value: string) => {
    onResetOffset();
    setSearch(value);
  }, TABLE_SEARCH_DEBOUNCE_DELAY_MS);

  return <SearchComponent isMobile={isMobile} onSearch={handleSearch} />;
};
