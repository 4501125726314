import React, { useCallback, useContext, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPath } from '@/routes/types';
import { tKeys } from '@/i18n';

import editIcon from '@/assets/icons/edit.svg';
import hierarchyDark from '@/assets/icons/hierarchy-dark.svg';
import joinedIcon from '@/assets/icons/joined.svg';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';
import { useToast } from '@/hooks/useToast';

import { LeagueEnterPasswordModal } from '@/modules/LeagueManage/components/LeagueEnterPasswordModal';
import { LeagueRulesModal } from '@/modules/LeagueManage/components/LeagueRulesModal';
import { LimitLeaguesModal } from '@/modules/LeagueManage/components/LimitLeaguesModal';
import { LeagueManageContext } from '@/modules/LeagueManage/context/LeagueManageProvider';
import { useJoinLeagueMutation } from '@/modules/LeagueManage/leagueManage.api';
import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';
import { LeagueInfo } from '@/modules/LeagueManage/leagueManage.types';
import { AssignBracketModal } from '@/modules/Leagues/containers/AssignBracketModal';
import { LeagueType } from '@/modules/Leagues/leagues.enums';

import { api } from '@/services/api.service';
import {
  ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
  NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
} from '@/core/env';
import { RoutesEnum } from '@/enums/routes.enum';

const LIMIT_STATUS = 423;
interface LeagueEntryContentProps {}
const LeagueEntryContent: React.FC<LeagueEntryContentProps> = () => {
  const navigate = useNavigate();
  const leagueData = useSelector(getLeagueData);
  const dispatch = useDispatch();

  const { isComing, isOver, isSecondChanceOver } = useGameDate();
  const { t } = useTranslation();
  const { withAuthCheck } = useAuthContext();
  const { handleRefetchData } = useContext(LeagueManageContext);

  const toast = useToast();
  const {
    isOpen: isAssignOpen,
    onOpen: onAssignOpen,
    onClose: onAssignClose,
  } = useDisclosure();
  const {
    isOpen: isRulesOpen,
    onOpen: onRulesOpen,
    onClose: onRulesClose,
  } = useDisclosure();
  const {
    isOpen: isLimitOpen,
    onOpen: onLimitOpen,
    onClose: onLimitClose,
  } = useDisclosure();
  const {
    isOpen: isPasswordOpen,
    onOpen: onPasswordOpen,
    onClose: onPasswordClose,
  } = useDisclosure();

  const [isChecked, setIsChecked] = useState(false);

  const [triggerJoin, { isLoading: isJoinLoading }] = useJoinLeagueMutation();

  const handleAssignBracketClick = useCallback(() => {
    withAuthCheck(() => onAssignOpen());
  }, [onAssignOpen, withAuthCheck]);

  const handleJoinLeague = useCallback(
    (league: LeagueInfo | undefined) => {
      withAuthCheck(async () => {
        if (league?.is_private) {
          onPasswordOpen();
          return;
        }
        try {
          const joinResponse = await triggerJoin({
            league_id: Number(league?.league_id),
          });

          if (
            (joinResponse as { error: FetchBaseQueryError })?.error?.status ===
            LIMIT_STATUS
          ) {
            onLimitOpen();
            return;
          }

          await handleRefetchData();
          await dispatch(
            api.util.invalidateTags([ApiTagsEnum.GET_FILTERED_MEMBERS]),
          );
          toast(t(tKeys.successJoinLeague), 'success');
        } catch (e) {
          toast(t(tKeys.joinLeagueError), 'error');
          console.log(e);
        }
      });
    },
    [
      dispatch,
      handleRefetchData,
      onLimitOpen,
      onPasswordOpen,
      t,
      toast,
      triggerJoin,
      withAuthCheck,
    ],
  );

  const render = useCallback(() => {
    if (isComing && leagueData?.is_member) {
      return (
        <Button
          alignItems='center'
          borderRadius='60px'
          fontSize='16px'
          isDisabled
          maxWidth='200px'
          paddingBottom={1}
          paddingLeft={4}
          paddingRight={4}
          paddingTop={1}
          py='8px'
          rightIcon={<Image src={joinedIcon} />}
          size='sm'
          variant='success'
          width={'100%'}
        >
          {t(tKeys.joined)}
        </Button>
      );
    }
    if (isComing && !leagueData?.is_member) {
      return (
        <Button
          borderRadius='60px'
          fontSize={14}
          isDisabled={
            leagueData &&
            leagueData?.rules &&
            leagueData?.type !== LeagueType.Friends
              ? !isChecked
              : false
          }
          isLoading={isJoinLoading}
          onClick={() => handleJoinLeague(leagueData)}
          paddingBottom={2}
          paddingLeft={6}
          paddingRight={6}
          paddingTop={2}
          rightIcon={<Image height='14px' src={hierarchyDark} width='14px' />}
          size='sm'
          width={'auto'}
        >
          {t(tKeys.joinLeague)}
        </Button>
      );
    }
    if (
      leagueData?.entry &&
      [
        ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
        NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
      ].includes(leagueData?.league_id.toString())
    ) {
      return (
        <Button
          alignItems='center'
          borderRadius='60px'
          fontSize={14}
          isDisabled={isSecondChanceOver}
          onClick={() =>
            navigate(createPath({ path: RoutesEnum.bracketsSecondChance }))
          }
          paddingBottom={2}
          paddingLeft={3}
          paddingRight={3}
          paddingTop={2}
          rightIcon={<Image src={editIcon} />}
          size='sm'
          sx={{ margin: '0 auto' }}
          variant='success'
          width={'auto'}
        >
          <Text overflow='hidden' textOverflow='ellipsis'>
            {leagueData?.entry?.entry_name}
          </Text>
        </Button>
      );
    }
    if (leagueData?.entry) {
      return (
        <Button
          alignItems='center'
          borderRadius='60px'
          fontSize={14}
          isDisabled={isOver}
          onClick={handleAssignBracketClick}
          paddingBottom={2}
          paddingLeft={3}
          paddingRight={3}
          paddingTop={2}
          rightIcon={<Image src={editIcon} />}
          size='sm'
          sx={{ margin: '0 auto' }}
          variant='success'
          width={'auto'}
        >
          <Text overflow='hidden' textOverflow='ellipsis'>
            {leagueData?.entry?.entry_name}
          </Text>
        </Button>
      );
    }

    if (
      leagueData?.entry === null &&
      leagueData?.is_member &&
      [
        ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
        NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
      ].includes(leagueData?.league_id.toString())
    ) {
      return (
        <Button
          borderRadius='60px'
          fontSize={14}
          isDisabled={isSecondChanceOver}
          onClick={() =>
            navigate(createPath({ path: RoutesEnum.bracketsSecondChance }))
          }
          paddingBottom={2}
          paddingLeft={6}
          paddingRight={6}
          paddingTop={2}
          rightIcon={<Image height='14px' src={hierarchyDark} width='14px' />}
          size='sm'
          sx={{ margin: '0 auto' }}
          width={'auto'}
        >
          {t(tKeys.createNewBracket)}
        </Button>
      );
    }
    if (leagueData?.entry === null && leagueData?.is_member) {
      return (
        <Button
          borderRadius='60px'
          fontSize={14}
          isDisabled={isOver}
          onClick={handleAssignBracketClick}
          paddingBottom={2}
          paddingLeft={6}
          paddingRight={6}
          paddingTop={2}
          rightIcon={<Image height='14px' src={hierarchyDark} width='14px' />}
          size='sm'
          sx={{ margin: '0 auto' }}
          width={'auto'}
        >
          {t(tKeys.assignBracket)}
        </Button>
      );
    }

    return (
      <Button
        borderRadius='60px'
        fontSize={14}
        isDisabled={
          isOver ||
          (leagueData &&
          leagueData?.rules &&
          leagueData?.type !== LeagueType.Friends
            ? !isChecked
            : false)
        }
        isLoading={isJoinLoading}
        onClick={() => handleJoinLeague(leagueData)}
        paddingBottom={2}
        paddingLeft={6}
        paddingRight={6}
        paddingTop={2}
        rightIcon={<Image height='14px' src={hierarchyDark} width='14px' />}
        size='sm'
        width={'auto'}
      >
        {t(tKeys.joinLeague)}
      </Button>
    );
  }, [
    handleAssignBracketClick,
    handleJoinLeague,
    isChecked,
    isComing,
    isJoinLoading,
    isOver,
    leagueData,
    t,
  ]);

  return (
    <>
      {!!leagueData &&
        leagueData?.rules &&
        leagueData?.type !== LeagueType.Friends && (
          <Flex alignItems='center' gap='16px' pb={'8px'}>
            <Checkbox
              isChecked={isChecked || leagueData?.is_member}
              isDisabled={isOver || leagueData?.is_member}
              my='16px'
              onChange={() => setIsChecked((prev) => !prev)}
              size='lg'
            />
            {/*TODO: add trans*/}
            <Text fontSize='12px'>
              I have read and agreed to the{' '}
              <Box
                as='span'
                cursor='pointer'
                onClick={onRulesOpen}
                textDecoration='underline'
              >
                Official Rules
              </Box>{' '}
              of the {leagueData?.name}.
            </Text>
          </Flex>
        )}

      <Flex alignItems='center' gap='16px' justifyContent='center'>
        {leagueData?.type === LeagueType.Friends && (
          <Text whiteSpace='nowrap'>{t(tKeys.bracketEntry)}:</Text>
        )}
        {render()}
      </Flex>

      <AssignBracketModal
        isOpen={isAssignOpen}
        leagueEntry={leagueData?.entry || null}
        leagueId={leagueData?.league_id as string}
        onClose={onAssignClose}
        onRefetchData={handleRefetchData}
      />
      <LeagueRulesModal
        isOpen={isRulesOpen}
        onClose={onRulesClose}
        rules={leagueData?.rules}
      />
      <LeagueEnterPasswordModal
        handleRefetchData={handleRefetchData}
        isOpen={isPasswordOpen}
        league_id={leagueData?.league_id}
        onClose={onPasswordClose}
      />
      <LimitLeaguesModal isOpen={isLimitOpen} onClose={onLimitClose} />
    </>
  );
};

export default LeagueEntryContent;
