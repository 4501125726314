import React from 'react';

import { Box, Image, Text } from '@chakra-ui/react';

import arrowLeft from '@/assets/icons/arrow-left.svg';

type Props = {
  onClick: () => void;
  text: string;
};

export const FullWidthModalHeader: React.FC<Props> = (props) => {
  const { onClick, text } = props;

  return (
    <>
      <Image onClick={onClick} src={arrowLeft} />
      <Text>{text}</Text>
      <Box width='16px' />
    </>
  );
};
