import React, { useCallback } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { MembersFilter as MembersFilterComponent } from '@/modules/LeagueManage/components/MembersFilter';

import { TABLE_SEARCH_DEBOUNCE_DELAY_MS } from '@/utils/common.util';

type Props = {
  onResetOffset(): void;
  setMembersSearch: React.Dispatch<React.SetStateAction<string>>;
};
export const MembersFilter: React.FC<Props> = ({
  onResetOffset,
  setMembersSearch,
}) => {
  const handleSearch = useDebouncedCallback((value: string) => {
    onResetOffset();
    setMembersSearch(value);
  }, TABLE_SEARCH_DEBOUNCE_DELAY_MS);

  return <MembersFilterComponent onSearch={handleSearch} />;
};
