import React, { useMemo } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Text,
  Box,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import AdsBanner from '@/components/AdsBanner';

import { nhlThinScrollbarStyles } from '@/utils/common.util';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  rules: string | undefined;
};

export const LeagueRulesModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, rules } = props;

  const rulesHTML = useMemo(() => parse(rules || ''), [rules]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW='800px'>
        <ModalHeader>
          <AdsBanner slotId='modal-ad' slotSize={[226, 81]} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            sx={{
              color: 'white',
              fontFamily: "'Averta', sans-serif",
              fontWeight: '400',
              fontSize: '14px',
              height: 'auto',
              maxH: '450px',
              overflowY: 'auto',
              ...nhlThinScrollbarStyles,
            }}
          >
            {rulesHTML}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
