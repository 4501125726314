import React, { SVGProps } from 'react';

export const CircleQuestionIcon: React.FC<SVGProps<any>> = ({
  fill = '#99ABB7',
}) => {
  return (
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        fill={fill}
        fillRule='evenodd'
      />
      <circle cx='12' cy='18' fill='white' r='1' />
      <path
        clipRule='evenodd'
        d='M12.0005 8C11.1312 8 10.389 8.5551 10.114 9.33325C9.92996 9.85396 9.35864 10.1269 8.83792 9.94284C8.31721 9.75879 8.04428 9.18747 8.22833 8.66675C8.77697 7.11451 10.2573 6 12.0005 6C14.2096 6 16.0005 7.79086 16.0005 10C16.0005 11.8638 14.7257 13.4299 13.0005 13.874V15C13.0005 15.5523 12.5527 16 12.0005 16C11.4482 16 11.0005 15.5523 11.0005 15V13C11.0005 12.4477 11.4482 12 12.0005 12C13.105 12 14.0005 11.1046 14.0005 10C14.0005 8.89543 13.105 8 12.0005 8Z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  );
};
