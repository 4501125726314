import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store';

import { useToast } from '@/hooks/useToast';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

import {
  useCreateSecondChanceEntryMutation,
  useLazyCheckEntryNameQuery,
  usePostEntryMutation,
  useUpdateEntryMutation,
  useUpdateSecondChanceEntryMutation,
} from '../bracket.api';
import { selectBracket } from '../bracket.slice';
import { CreateSecondChanceBody, PostEntryBody } from '../bracket.types';
import {
  checkAllDataFilled,
  checkAllDataFilledSC,
} from '../helpers/checkAllDataFilled';

export const useEntry = () => {
  const toast = useToast();
  const { pathname } = useLocation();

  const { entry, isReadOffRules, user, entrySC } =
    useAppSelector(selectBracket);

  const isSecondChancePage = useMemo(
    () => pathname === bracketsSecondChancePath(),
    [pathname],
  );

  const [postEntry, { isLoading: isLoadingPostEntry }] = usePostEntryMutation();
  const [updateEntry, { isLoading: isLoadingUpdateEntry }] =
    useUpdateEntryMutation();
  const [createSC, { isLoading: isLoadingCreateSC }] =
    useCreateSecondChanceEntryMutation();
  const [updateSC, { isLoading: isLoadingUpdateSC }] =
    useUpdateSecondChanceEntryMutation();

  const [checkName] = useLazyCheckEntryNameQuery();

  const isAllDataFilled = useMemo(
    () =>
      isSecondChancePage
        ? checkAllDataFilledSC(entrySC) && isReadOffRules
        : checkAllDataFilled(entry) && isReadOffRules,
    [entry, entrySC, isReadOffRules, isSecondChancePage],
  );

  const checkBracketName = async () => {
    // Check if Bracket with this name already exists
    const data = await checkName({
      entry_name: isSecondChancePage ? entrySC?.entry_name : entry.entry_name,
    }).unwrap();

    if (data?.exists) {
      throw new Error('Bracket with current name already exists');
    }
  };

  const submitNew = async () => {
    // If entry_id === 0 then it's a new bracket? so need to check entry_name to be unique
    if (!entry.entry_id) {
      await checkBracketName();
    }
    //   Check if all data
    if (!isAllDataFilled) {
      throw new Error();
    }

    const body: PostEntryBody = {
      league_id: null,
      entry: { ...entry, user_id: user?.user_id || 0 },
    };

    try {
      const response = await postEntry(body).unwrap();
      toast('Bracket successfully saved', 'success');
      return response;
    } catch (error) {
      throw new Error(error?.data?.message || error?.message);
    }
  };

  const submitNewSecondChance = async () => {
    // If entry_id === 0 then it's a new bracket? so need to check entry_name to be unique
    if (!entrySC.entry_id) {
      await checkBracketName();
    }
    //   Check if all data
    if (!isAllDataFilled) {
      throw new Error();
    }

    const body: CreateSecondChanceBody = {
      entry: { ...entrySC, user_id: user?.user_id || 0 },
    };

    try {
      const response = await createSC(body).unwrap();
      toast('Bracket successfully saved', 'success');
      return response;
    } catch (error) {
      throw new Error(error?.data?.message || error?.message);
    }
  };

  const updateExisting = async () => {
    //   Check if all data
    if (!isAllDataFilled) {
      throw new Error();
    }

    const body: PostEntryBody = {
      league_id: null,
      entry,
    };

    try {
      const response = await updateEntry(body).unwrap();
      toast('Bracket successfully saved', 'success');
      return response;
    } catch (error) {
      throw new Error(error?.data?.message || error?.message);
    }
  };

  const updateExistingSecondChance = async () => {
    //   Check if all data
    if (!isAllDataFilled) {
      throw new Error();
    }

    try {
      const response = await updateSC({ entry: entrySC }).unwrap();
      toast('Bracket successfully saved', 'success');
      return response;
    } catch (error) {
      throw new Error(error?.data?.message || error?.message);
    }
  };

  const handleSubmit = () => {
    if (isSecondChancePage) {
      if (entrySC.entry_id === 0) return submitNewSecondChance();
      else return updateExistingSecondChance();
    } else {
      if (entry.entry_id === 0) return submitNew();
      else return updateExisting();
    }
  };

  return {
    isLoading:
      isLoadingPostEntry ||
      isLoadingUpdateEntry ||
      isLoadingCreateSC ||
      isLoadingUpdateSC,
    submit: handleSubmit,
  };
};
