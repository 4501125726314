import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  menu: {
    placement: 'bottom-end',
  },
  list: {
    py: 0,
    border: 'none',
    bg: '#142631',
    display: 'flex',
    flexDir: 'column',
    gap: '2px',
    boxShadow: 'menuListShadow',
    zIndex: 900,
  },
  item: {
    bg: 'darkBlue',
    fontWeight: '600',
    py: '12px',
    justifyContent: 'center',
    _hover: {
      bg: 'primaryDarkGradient',
    },
  },
});

const Menu = defineMultiStyleConfig({
  baseStyle,
});

export default Menu;
