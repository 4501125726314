export const internalLinkClickEvent = (
  click_text: string,
  click_url: string,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'internal_link_click',
    click_text,
    click_url,
    tri_code: 'nhl',
  });
};

export const dataLayerInitializeEvent = (logged_in: boolean) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataLayer-initialized',
    tri_code: 'nhl',
    logged_in,
  });
};

export const pageViewEvent = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'page_view',
  });
};
