import { Children, FC, Fragment, PropsWithChildren } from 'react';

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './MobileSlider.styles.css';

import { useSettings } from '@/hooks/useSettings';

export const SliderContainer: FC<PropsWithChildren> = ({ children }) => (
  <Swiper
    modules={[Pagination]}
    pagination={{
      type: 'bullets',
      horizontalClass: 'swiper-custom-horizontal',
      bulletClass: 'swiper-custom-bullet',
      bulletActiveClass: 'swiper-custom-bullet-active',
      clickable: true,
    }}
    spaceBetween='16px'
  >
    {children}
  </Swiper>
);

export const MobileSlider = ({ children }: PropsWithChildren) => {
  const { isMobile } = useSettings();

  const ContainerElement = isMobile ? SliderContainer : Fragment;

  return (
    <ContainerElement>
      {Children.map(children, (child) =>
        isMobile ? <SwiperSlide>{child}</SwiperSlide> : child,
      )}
    </ContainerElement>
  );
};
