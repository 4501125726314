import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import {
  selectBracket,
  SET_ENTRY_MATCH_PLAYED,
  SET_ENTRY_MATCH_PLAYED_SC,
} from '@/modules/Bracket/bracket.slice';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

export const useQuestionMatch = (matchId: number) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const {
    entry,
    entrySC,
    game: { teams },
    bracketMode,
  } = useAppSelector(selectBracket);

  const key = `match_${matchId}_match_played`;

  const clickHandler = (value: number) => {
    dispatch(
      pathname === bracketsSecondChancePath()
        ? SET_ENTRY_MATCH_PLAYED_SC({ key, value })
        : SET_ENTRY_MATCH_PLAYED({ key, value }),
    );
  };

  const selectedTeamId =
    pathname === bracketsSecondChancePath()
      ? entrySC[`match_${matchId}_pick`]
      : entry[`match_${matchId}_pick`];

  const selectedTeam = teams.find(
    (team) => team.team_id === Number(selectedTeamId),
  );

  return {
    clickHandler,
    entry,
    key,
    selectedTeam,
    entrySC,
  };
};
