import { Navigate, useLocation } from 'react-router-dom';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useJWT } from '@/hooks/useJWT';
import { useLang } from '@/hooks/useLang';
import { useLocalStorage } from '@/hooks/useLocalStorage';

import { useGetDashboardQuery } from '@/modules/Bracket/bracket.api';

import { createPath } from '@/routes/types';
import { removeLangFromPathname } from '@/routes/utils';

// Make redirect to bracket
const RootRoute = () => {
  const { jwt } = useJWT();
  const { pathname } = useLocation();
  const { value } = useLocalStorage<string>(StorageKeysEnum.pageBeforeSignIn);
  const { isLoading } = useGetDashboardQuery({ jwt }, { skip: !jwt });
  const { lang } = useLang();

  if (isLoading || !lang) {
    return <PageLoader />;
  }

  const bracketsPreRegistration = createPath({
    path: RoutesEnum.bracketsPreRegistration,
  });
  const pathBeforeSignIn = createPath({
    path: removeLangFromPathname(value, lang),
  });
  const homePage = createPath({
    path: RoutesEnum.home,
  });

  return <Navigate to={`${lang}/${homePage}`} />;
  // TODO
  // return value ? (
  //   <Navigate to={`${lang}${pathBeforeSignIn}`} />
  // ) : (
  //   <Navigate to={`${lang}/${bracketsPreRegistration}`} />
  // );
};
export default RootRoute;
