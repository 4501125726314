import { createSlice } from '@reduxjs/toolkit';

import { LeagueType } from '@/modules/Leagues/leagues.enums';
import { LeaguesState } from '@/modules/Leagues/leagues.types';

const initialState: LeaguesState = {
  isLoading: true,
  isFetching: true,
  filter: {
    types: [],
  },
  leagues: undefined,
};

const leaguesSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    SET_LEAGUES_IS_LOADING: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    SET_LEAGUES_IS_FETCHING: (state, action: { payload: boolean }) => {
      state.isFetching = action.payload;
    },
    SET_FILTER_TYPES: (
      state,
      action: { payload: { value: LeagueType; isChecked: boolean } },
    ) => {
      const { value, isChecked } = action.payload;
      const { types } = state.filter;

      const caseInsensitiveValue = value.toUpperCase() as LeagueType;

      if (isChecked) {
        if (!types.includes(caseInsensitiveValue)) {
          state.filter.types = [...types, caseInsensitiveValue];
        }
      } else {
        state.filter.types = types.filter(
          (type) => type !== caseInsensitiveValue,
        );
      }
    },
  },
});

export const {
  SET_LEAGUES_IS_LOADING,
  SET_LEAGUES_IS_FETCHING,
  SET_FILTER_TYPES,
} = leaguesSlice.actions;

export const leaguesReducer = leaguesSlice.reducer;
