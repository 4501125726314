import React from 'react';

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import editIcon from '@/assets/icons/edit.svg';
import cupImg from '@/assets/images/cup.png';

import { RoutesEnum } from '@/enums/routes.enum';

import { useGameDate } from '@/hooks/useGameDate';
import { useSettings } from '@/hooks/useSettings';

import { getBracketPoints } from '@/modules/Bracket/bracket.helpers';
import { IBracketEntry } from '@/modules/Bracket/bracket.types';
import { BracketsFilter } from '@/modules/Bracket/components/BracketsFilter';
import { BracketsListHeader } from '@/modules/Bracket/components/BracketsListHeader';
import { EnteredLeagues } from '@/modules/Bracket/components/EnteredLeagues';
import { useGameTeamHexCode } from '@/modules/Bracket/hooks/useGameTeamHexCode';

import { createPath } from '@/routes/types';

import { nhlThinScrollbarStyles } from '@/utils/common.util';

type Props = {
  entries: IBracketEntry[];
  onAssignLeague: (entryId: string | number) => void;
  onFilter: (value: boolean) => void;
  onGoToEditBracket: (entryId: string | number) => void;
  onSearch: (value: string) => void;
  isTablet: boolean;
};

export const BracketsList: React.FC<Props> = (props) => {
  const {
    entries,
    onAssignLeague,
    onFilter,
    onSearch,
    onGoToEditBracket,
    isTablet,
  } = props;
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isMobile, isLgDesktop } = useSettings();
  const { getHexCode } = useGameTeamHexCode();
  const { isOver } = useGameDate();
  const [isMobileCustom] = useMediaQuery('(max-width: 770px)');

  return (
    <Box mt={isMobile ? '100px' : '0'} width='100%'>
      <BracketsFilter
        isTablet={isTablet}
        onFilter={onFilter}
        onSearch={onSearch}
      />
      <BracketsListHeader isTablet={isTablet} />

      <Box
        h={isMobileCustom ? '100%' : '435px'}
        overflow='auto'
        sx={nhlThinScrollbarStyles}
      >
        {entries.map((entry) => (
          <Grid
            background={isTablet ? 'rgba(35, 55, 65, 0.50)' : '#181818'}
            borderRadius={isTablet ? '8px' : undefined}
            gap={2}
            key={entry.entry_id}
            marginTop={isTablet ? '8px' : '3px'}
            padding='8px 12px'
            templateColumns='repeat(14, 1fr)'
          >
            <GridItem
              alignItems='center'
              colSpan={isTablet ? 14 : 3}
              display='flex'
              justifyContent={isTablet ? 'center' : 'unset'}
            >
              <Text
                _hover={{
                  textDecoration: 'underline',
                }}
                cursor='pointer'
                onClick={() =>
                  navigate(
                    entry?.is_second_chance === 'true'
                      ? createPath({
                          path: RoutesEnum.bracketsSecondChance,
                        })
                      : createPath({
                          path: RoutesEnum.bracketsSpecific,
                          params: { id: entry?.entry_id },
                        }),
                  )
                }
              >
                {entry.entry_name}
              </Text>
            </GridItem>
            <GridItem
              alignItems='center'
              colSpan={isTablet ? 8 : 2}
              display='flex'
              flexDirection={isTablet ? 'column' : 'row'}
            >
              {isTablet && (
                <Text fontSize='14px'>{t(tKeys.stanleyCupFinal)}</Text>
              )}
              <Flex gap='4px'>
                <Flex
                  align='center'
                  bg={getHexCode(entry.match_301_pick)}
                  h='61px'
                  justify='center'
                  position='relative'
                  w='61px'
                >
                  <Image
                    height='48px'
                    src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_301_pick}.svg`}
                    width='48px'
                  />
                </Flex>
                <Flex
                  align='center'
                  bg={getHexCode(entry.match_302_pick)}
                  h='61px'
                  justify='center'
                  position='relative'
                  w='61px'
                >
                  <Image
                    height='48px'
                    src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_302_pick}.svg`}
                    width='48px'
                  />
                </Flex>
              </Flex>
            </GridItem>
            <GridItem
              alignItems='center'
              colSpan={isTablet ? 6 : 1}
              display='flex'
              flexDirection={isTablet ? 'column' : 'row'}
              justifyContent='center'
            >
              {isTablet && (
                <Flex alignItems='center'>
                  <Image height='16px' mr='4px' src={cupImg} width='10px' />
                  <Text fontSize='14px'>{t(tKeys.champion)}</Text>
                </Flex>
              )}
              <Flex
                align='center'
                bg={getHexCode(entry.match_401_pick)}
                h='61px'
                justify='center'
                position='relative'
                w='61px'
              >
                <Image
                  height='48px'
                  src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_401_pick}.svg`}
                  width='48px'
                />
              </Flex>
            </GridItem>
            <GridItem
              alignItems='center'
              colSpan={isTablet ? 7 : 3}
              display='flex'
              justifyContent='center'
            >
              {isOver ? (
                <Button
                  height='36px'
                  onClick={() =>
                    entry?.is_second_chance === 'true'
                      ? navigate(
                          createPath({
                            path: RoutesEnum.bracketsSecondChance,
                          }),
                        )
                      : onGoToEditBracket(entry.entry_id)
                  }
                  variant='outline'
                  width={isTablet ? '100%' : 'auto'}
                >
                  {t(tKeys.details)}
                </Button>
              ) : (
                <Button
                  height='36px'
                  onClick={() => onGoToEditBracket(entry.entry_id)}
                  rightIcon={
                    <Image height='16px' src={editIcon} width='16px' />
                  }
                  variant='outline'
                  width={isTablet ? '100%' : 'auto'}
                >
                  {t(tKeys.edit)}
                </Button>
              )}
            </GridItem>
            <GridItem
              alignItems='center'
              colSpan={isTablet ? 7 : 2}
              display='flex'
              justifyContent='center'
            >
              <Flex
                alignItems={isTablet ? 'center' : undefined}
                background={isTablet ? '#192E3B' : 'transparent'}
                borderRadius={isTablet ? '60px' : undefined}
                height={isTablet ? '36px' : undefined}
                justifyContent={isTablet ? 'center' : undefined}
                width={isTablet ? '100%' : undefined}
              >
                {getBracketPoints(entry.points, entry.possible_points)}
                {isTablet && ' PTS'}
              </Flex>
            </GridItem>
            <GridItem
              alignItems='center'
              colSpan={isTablet ? 14 : 3}
              display='flex'
            >
              <EnteredLeagues
                isTablet={isTablet}
                leagues={entry.leagues || []}
                onAssign={() => onAssignLeague(entry.entry_id)}
              />
            </GridItem>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};
