import React from 'react';

import {
  Button,
  CloseButton,
  Grid,
  GridItem,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { tKeys } from '@/i18n';

import { LangSwitcherVariantProps } from '@/components/LangSwitcher';

import { useLanguages } from '@/hooks/useLanguages';

export const SmallLangSwitcher: React.FC<LangSwitcherVariantProps> = ({
  isOpen,
  onOpen,
  onLangChange,
  t,
  onClose,
}) => {
  const { languages, currentLang } = useLanguages();

  return (
    <Menu gutter={20} placement='bottom-end' {...{ isOpen, onOpen }}>
      <MenuButton
        _hover={{
          bg: 'hoverLightGradient',
        }}
        as={Button}
        bg='#39444C'
        borderRadius='40px'
        h='38px'
        iconSpacing='12px'
        p='0 4px 0 8px'
        rightIcon={
          <Image
            alt='language-flag-image'
            src={currentLang?.image}
            width='24px'
          />
        }
        variant='unstyled'
      >
        {currentLang?.code.toUpperCase()}
      </MenuButton>
      <MenuList borderRadius='12px' overflow='hidden'>
        <MenuItem
          _disabled={{ cursor: 'default' }}
          _hover={{ cursor: 'default' }}
          as={Grid}
          cursor='default'
          display='grid'
          gap='8px'
          gridTemplateColumns='1fr auto 1fr'
          isDisabled
        >
          <GridItem colStart={2}>{t(tKeys.selectLanguage)}:</GridItem>
          <GridItem>
            <CloseButton h='24px' onClick={onClose} w='24px' />
          </GridItem>
        </MenuItem>
        {languages.map((lang) => (
          <MenuItem
            gap='8px'
            key={lang.code}
            onClick={() => onLangChange(lang.code)}
          >
            <Image alt='language-flag-image' src={lang.image} width='24px' />
            {lang.title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
