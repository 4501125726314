import { ReactNode } from 'react';

import { UseToastOptions } from '@chakra-ui/react';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { TFunction } from 'i18next';

import { tKeys } from '@/i18n';

import { API_BASE_URL } from '../core/env';
import { StorageKeysEnum } from '../enums/storageKeys.enum';

import { getStorageItem } from './localStorage.util';

export const prepareHeaders = (headers: Headers) => {
  const jwt = getStorageItem<string>(StorageKeysEnum.jwt);

  if (jwt) {
    headers.set('authorization', `Bearer ${jwt}`);
  }

  return headers;
};

const baseQuery = fetchBaseQuery({ baseUrl: API_BASE_URL, prepareHeaders });
export const baseQueryWithRedirect: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const lang = getStorageItem<string>(StorageKeysEnum.i18nextLng);
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    localStorage.removeItem(StorageKeysEnum.jwt);
  }
  return result;
};

export const errorHandler = ({
  toast,
  error,
  t,
}: {
  toast: (
    message: ReactNode,
    type: 'success' | 'error',
    title?: string | null | undefined,
    params?: UseToastOptions | undefined,
  ) => void;
  error: any;
  t: TFunction;
}) => {
  console.error(error);
  if (error.originalStatus !== 401) {
    toast(error?.message || t(tKeys.somethingWentWrong), 'error');
  } else if (error.originalStatus === 401) {
    toast(t(tKeys.plsLoginAgain), 'error');
  }
};
