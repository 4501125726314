import * as React from 'react';
import { FC, ReactNode, useCallback, useEffect } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { GAMEZONE_URL } from '@/core/env';
import i18n, { tKeys } from '@/i18n';

import { LocalStorageContext } from '@/contexts/localStorage.context';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useJWT } from '@/hooks/useJWT';
import { useLang } from '@/hooks/useLang';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useToast } from '@/hooks/useToast';

import { LoginModal } from '@/modules/Main/components/LoginModal';

import { createPath } from '@/routes/types';

import {
  useCheckIsJWTValidMutation,
  useGetJWTMutation,
} from '@/services/api.service';

import {
  dataLayerInitializeEvent,
  pageViewEvent,
} from '@/utils/analyticEvents';

type AuthContextType = {
  isSignInOpen: boolean;
  onSignInOpen(): void;
  onSignInClose(): void;
  withAuthCheck: (func: () => void) => void;
};

const AuthContext = React.createContext<AuthContextType | null>(null);

const AuthContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { jwt, setJWT, removeJWT } = useJWT();
  const { lang: langLS, setLang } = useLang();
  const { lang: langParam } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const [triggerGetJWT, { isLoading }] = useGetJWTMutation();
  const [triggerCheckJWT, { isLoading: isCheckLoading }] =
    useCheckIsJWTValidMutation();

  const { value, removeItem } = useLocalStorage<string>(
    StorageKeysEnum.pageBeforeSignIn,
  );

  const lang = searchParams.get(SearchParamsEnum.lang);
  const token = searchParams.get(SearchParamsEnum.token);

  const {
    isOpen: isSignInOpen,
    onOpen: onSignInOpen,
    onClose: onSignInClose,
  } = useDisclosure();

  useEffect(() => {
    const handleLogout = () => {
      removeJWT();
      const homePage = createPath({ path: RoutesEnum.home });
      navigate(homePage);
      toast(t(tKeys.plsLoginAgain), 'error');
    };
    if (jwt) {
      triggerCheckJWT()
        .unwrap()
        .then((res) => {
          if (!res.valid) {
            handleLogout();
          }
        })
        .catch((error) => {
          if (error?.originalStatus === 401) {
            handleLogout();
          }
        });
    }
    //eslint-disable-next-line
  }, [jwt, triggerCheckJWT]);

  useEffect(() => {
    if (lang) {
      setLang(lang);
      searchParams.delete(SearchParamsEnum.lang);
      setSearchParams(searchParams);
    }
    if (token) {
      triggerGetJWT({ token })
        .unwrap()
        .then((response) => {
          if (response.token) {
            setJWT(response.token);
            searchParams.delete(SearchParamsEnum.token);
            setSearchParams(searchParams);
            if (value) {
              navigate(createPath({ path: value }));
              removeItem();
            }
          }
        })
        .catch(console.error);
    }
  }, [
    lang,
    navigate,
    removeItem,
    searchParams,
    setJWT,
    setLang,
    setSearchParams,
    token,
    triggerGetJWT,
    value,
  ]);

  useEffect(() => {
    const handleInitDataLayer = () => {
      if (token) return;
      dataLayerInitializeEvent(!!jwt);
    };

    handleInitDataLayer();
    //eslint-disable-next-line
  }, [jwt]); //IMPORTANT: DO NOT CHANGE

  useEffect(() => {
    const handlePageViewTrack = () => {
      if (token) return;
      if (pathname === RoutesEnum.root) return;
      pageViewEvent();
    };

    handlePageViewTrack();
    //eslint-disable-next-line
  }, [pathname]); //IMPORTANT: DO NOT CHANGE

  const withAuthCheck = useCallback(
    (func: () => void) => {
      if (!jwt) {
        onSignInOpen();
        return;
      }
      func();
    },
    [jwt, onSignInOpen],
  );

  return (
    <AuthContext.Provider
      value={{
        isSignInOpen,
        onSignInOpen,
        onSignInClose,
        withAuthCheck,
      }}
    >
      {isLoading && <PageLoader />}
      {children}
      <LoginModal isOpen={isSignInOpen} onClose={onSignInClose} />
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };
