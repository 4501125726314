import React from 'react';

import { Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import hierarchyIcon from '@/assets/icons/hierarchy.svg';
import previewIcon from '@/assets/icons/preview.svg';
import trophyIcon from '@/assets/icons/trophy.svg';
import userGroupIcon from '@/assets/icons/user-group.svg';

type Props = {
  isMobile?: boolean;
  isSelectMode?: boolean;
};

export const LeaguesHeader: React.FC<Props> = ({ isMobile, isSelectMode }) => {
  const { t } = useTranslation();

  if (isMobile) {
    return null;
  }

  return (
    <Grid
      background='#192E3B'
      padding='12px 16px'
      templateColumns='repeat(10, 1fr)'
      width='100%'
    >
      <GridItem w='250px' colSpan={3}>
        <Text color='#99ABB7' fontSize='16px'>
          {t(tKeys.leagueName)}
        </Text>
      </GridItem>
      <GridItem display='flex' justifyContent='center' w='100%' colSpan={1}>
        <Image height='24px' src={previewIcon} width='24px' />
      </GridItem>
      <GridItem display='flex' justifyContent='center' w='100%' colSpan={1}>
        <Image height='24px' src={hierarchyIcon} width='24px' />
      </GridItem>
      <GridItem display='flex' justifyContent='center' w='100%' colSpan={1}>
        <Image height='24px' src={trophyIcon} width='24px' />
      </GridItem>
      <GridItem display='flex' justifyContent='center' w='100%' colSpan={1}>
        <Image height='24px' src={userGroupIcon} width='24px' />
      </GridItem>
      <GridItem
        display='flex'
        justifyContent='center'
        width={isMobile ? '100%' : '250px'}
        colSpan={3}
      >
        <Text color='#99ABB7' fontSize='16px'>
          {t(isSelectMode ? tKeys.select : tKeys.entry)}
        </Text>
      </GridItem>
    </Grid>
  );
};
