export enum StorageKeysEnum {
  jwt = 'jwt',
  i18nextLng = 'i18nextLng',
  onboardingComplete = 'nbrdng-cmplt',
  sponsor = 'sponsor',
  sponsorLang = 'sponsor-lang',
  logo = 'logo',
  logoLang = 'logo-lang',
  pageBeforeSignIn = 'pageBeforeSignIn',
}
