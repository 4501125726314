import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { enabledLanguages } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

import { useNavigation } from '@/hooks/useNavigation';

import { Sidebar } from '@/modules/Sidebar/components/Sidebar';
import { useSidebarStore } from '@/modules/Sidebar/hooks/useSidebarStore';

import { createPath } from '@/routes/types';

export const SidebarContainer: React.FC = () => {
  const { isSidebarOpen, handleCloseSidebar } = useSidebarStore();
  const navigate = useNavigate();
  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);
  const { links } = useNavigation();

  const handleLinkClick = (to: RoutesEnum) => {
    if (Object.values(RoutesEnum).includes(to)) {
      navigate(createPath({ path: to }));
    } else {
      window.location.href = to;
    }
    handleCloseSidebar();
  };

  return isSidebarOpen ? (
    <Sidebar
      handleCloseSidebar={handleCloseSidebar}
      handleLinkClick={handleLinkClick}
      isLangSwitcherEnabled={isLangSwitcherEnabled}
      isSidebarOpen={isSidebarOpen}
      links={links}
    />
  ) : null;
};
