import React, { useCallback } from 'react';

import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

import { League } from '@/modules/Leagues/leagues.types';

import { createPath } from '@/routes/types';

import { LeaguesList as LeaguesListComponent } from '../components/LeaguesList';

type Props = {
  leagues: League[];
  lastLeagueRef?: (node?: Element | null) => void;
  isFetching: boolean;
  offset?: number;
  isAssignLeague?: boolean;
};

export const LeaguesList: React.FC<Props> = (props) => {
  const { leagues, lastLeagueRef, isFetching, offset, isAssignLeague } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleNavigateToLeagueManage = useCallback(
    (leagueId: string) => {
      const path = createPath({
        path: RoutesEnum.leagueManage,
        params: { id: leagueId },
      });

      navigate(path);
    },
    [navigate],
  );

  if (!leagues.length) {
    return (
      <Text fontWeight='bold' mt='16px' textAlign='center'>
        {t(tKeys.noLeaguesFound)}
      </Text>
    );
  }

  return (
    <LeaguesListComponent
      isAssignLeague={isAssignLeague}
      isFetching={isFetching}
      lastLeagueRef={lastLeagueRef}
      leagues={leagues}
      offset={offset}
      onNavigateToLeagueManage={handleNavigateToLeagueManage}
    />
  );
};
