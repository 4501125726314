import React from 'react';

import { Helmet } from 'react-helmet-async';

import { LeagueManage } from '@/modules/LeagueManage/containers/LeagueManage';

const LeagueManagePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Leagues | Bracket Challenge</title>
        <meta
          content='Create your own league to compete against friends and family, or join official Club or Partner leagues.'
          name='description'
        />
      </Helmet>
      <LeagueManage />
    </>
  );
};

export default LeagueManagePage;
