import React from 'react';

import { Button, Grid, GridItem, Image, Text, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import hierarchyIcon from '@/assets/icons/hierarchy.svg';

import { IBracketEntry, TeamType } from '@/modules/Bracket/bracket.types';
import { useGameHook } from '@/modules/Bracket/hooks/useGame.hook';
import { useGameTeamHexCode } from '@/modules/Bracket/hooks/useGameTeamHexCode';
import { LeagueLeaderboardType } from '@/modules/LeagueManage/leagueManage.types';
import { AddEntryToLeagueButton } from '@/modules/Leagues/containers/AddEntryToLeagueButton';

import colors from '@/theme/foundations/colors';

type Props = {
  entry: IBracketEntry;
  leagueId: string;
  onViewBracket: (bracketId: string | number) => void;
  onClose: () => void;
  onRefetchData?: any;
  leagueEntry: LeagueLeaderboardType | null;
};

export const AssignBracketTableItem: React.FC<Props> = (props) => {
  const {
    entry,
    leagueId,
    onClose,
    onRefetchData,
    onViewBracket,
    leagueEntry,
  } = props;

  const { t } = useTranslation();
  const { getHexCode } = useGameTeamHexCode();
  return (
    <Grid
      background='#18252f'
      gap={2}
      key={entry.entry_id}
      padding='8px 12px'
      templateColumns='repeat(10, 1fr)'
    >
      <GridItem alignItems='center' colSpan={3} display='flex'>
        <Text>{entry.entry_name}</Text>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        justifyContent='center'
      >
        <Flex
          align='center'
          bg={getHexCode(entry.match_301_pick)}
          h='61px'
          justify='center'
          mr={2}
          position='relative'
          w='61px'
        >
          <Image
            alt='language-flag-image'
            src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_301_pick}.svg`}
            width='61px'
          />
        </Flex>
        <Flex
          align='center'
          bg={getHexCode(entry.match_302_pick)}
          h='61px'
          justify='center'
          position='relative'
          w='61px'
        >
          <Image
            alt='language-flag-image'
            src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_302_pick}.svg`}
            width='61px'
          />
        </Flex>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={1}
        display='flex'
        justifyContent='center'
      >
        <Flex
          align='center'
          bg={getHexCode(entry.match_401_pick)}
          h='61px'
          justify='center'
          position='relative'
          w='61px'
        >
          <Image
            alt='language-flag-image'
            src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_401_pick}.svg`}
            width='61px'
          />
        </Flex>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        justifyContent='center'
      >
        <Button
          borderRadius='60px'
          fontSize='14px'
          onClick={() => onViewBracket(entry.entry_id)}
          p='8px 24px'
          rightIcon={<Image src={hierarchyIcon} />}
          size='sm'
          variant='outline'
        >
          {t(tKeys.viewBracket)}
        </Button>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        justifyContent='center'
      >
        <AddEntryToLeagueButton
          entryId={entry.entry_id}
          leagueEntry={leagueEntry}
          leagueId={leagueId}
          onClose={onClose}
          onRefetchData={onRefetchData}
        />
      </GridItem>
    </Grid>
  );
};
