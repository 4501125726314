import { FC, PropsWithChildren } from 'react';

import {
  Box,
  BoxProps,
  Flex,
  Grid,
  GridItem,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { tKeys } from '@/i18n';

import BgImage from '@/assets/images/bg-desktop.jpg';

import { useSettings } from '@/hooks/useSettings';

import { selectBracket } from '../bracket.slice';
import { ChampionLogo } from '../components/ChampionLogo';
import { ViewMatch } from '../components/Match/ViewMatch';
import { getMatchesByRounds } from '../helpers/getMatchesByRounds';

const RoundHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex align='center' h='30px' justify='center' w='100%'>
      <Text
        fontFamily='AvertaStd, sans-serif'
        fontSize={['6px', '9px']}
        fontWeight={700}
        maxW='100px'
        textAlign='center'
        textTransform={'uppercase'}
      >
        {children}
      </Text>
    </Flex>
  );
};

export const ViewBracket = forwardRef<BoxProps, 'div'>((props, ref) => {
  const { isMobile } = useSettings();

  const { entry, seriesResults } = useSelector(selectBracket);

  const { t } = useTranslation();

  const {
    roundOneWest,
    roundOneEast,
    roundTwoWest,
    roundTwoEast,
    roundThreeWest,
    roundThreeEast,
    final,
  } = getMatchesByRounds(seriesResults);

  return (
    <>
      <Text fontSize='20px' fontWeight='700' lineHeight='1.3' my='20px'>
        {entry.entry_name}
      </Text>

      <Box
        bg={`url(${BgImage})`}
        position='relative'
        ref={ref}
        w={isMobile ? '330px' : '500px'}
      >
        <Flex justify='center' p='8px' position='absolute' w='100%'>
          <ChampionLogo h='80px' teamId={Number(entry.match_401_pick)} />
        </Flex>
        <Grid
          alignContent='center'
          alignItems='center'
          gap='2%'
          p='8px'
          templateColumns='repeat(8, minmax(0, 1fr))'
          w='100%'
        >
          <Flex direction='column' gap='10px'>
            <RoundHeader>{t(tKeys.firstRound)}</RoundHeader>
            {roundOneWest.map((round) => (
              <ViewMatch key={round.id} {...round} />
            ))}
          </Flex>

          <Flex direction='column' gap='10px'>
            <RoundHeader>{t(tKeys.secondRound)}</RoundHeader>
            {roundTwoWest.map((round) => (
              <ViewMatch key={round.id} {...round} aspectRatio='0.54' />
            ))}
          </Flex>

          <Flex direction='column' gap='10px'>
            <RoundHeader>{t(tKeys.conferenceFinal)}</RoundHeader>
            <ViewMatch {...roundThreeWest[0]} aspectRatio='0.46' />
          </Flex>

          <GridItem as={Flex} colSpan={2} direction='column' gap='10px'>
            <RoundHeader>{t(tKeys.stanleyCupFinal)}</RoundHeader>
            <ViewMatch {...final} />
          </GridItem>

          <Flex direction='column' gap='10px'>
            <RoundHeader>{t(tKeys.conferenceFinal)}</RoundHeader>
            <ViewMatch {...roundThreeEast[0]} aspectRatio='0.46' />
          </Flex>
          <Flex direction='column' gap='10px'>
            <RoundHeader>{t(tKeys.secondRound)}</RoundHeader>
            {roundTwoEast.map((round) => (
              <ViewMatch key={round.id} {...round} aspectRatio='0.54' />
            ))}
          </Flex>

          <Flex direction='column' gap='10px'>
            <RoundHeader>{t(tKeys.firstRound)}</RoundHeader>
            {roundOneEast.map((round) => (
              <ViewMatch key={round.id} {...round} />
            ))}
          </Flex>
        </Grid>
      </Box>
    </>
  );
});

ViewBracket.displayName = 'ViewBracket';
