import React, { useCallback, useMemo, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

import { useGetEntriesQuery } from '@/modules/Bracket/bracket.api';
import { getFilteredEntries } from '@/modules/Bracket/bracket.helpers';
import { IBracketEntry } from '@/modules/Bracket/bracket.types';
import { BracketMode } from '@/modules/Bracket/brackets.enums';
import { usePathToBracket } from '@/modules/Bracket/hooks/usePathToBracket';

import { createPath } from '@/routes/types';

import { SET_ASSIGN_ENTRY_ID } from '../bracket.slice';
import { AssignLeagueModal } from '../components/AssignLeagueModal';
import { BracketsList as BracketsListComponent } from '../components/BracketsList';

export const BracketsList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bracketPathById } = usePathToBracket();
  const { jwt } = useJWT();

  const { data, isLoading: isEntriesLoading } = useGetEntriesQuery(
    { jwt },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { isLoading: isGameLoading } = useGameDate();

  const [showUnassigned, setShowUnassigned] = useState(false);
  const [search, setSearch] = useState('');

  const {
    isOpen: isAssignOpen,
    onOpen: onAssignOpen,
    onClose: onAssignClose,
  } = useDisclosure();
  const { isTablet } = useSettings();

  const openAssignModal = (entryId: string | number) => {
    dispatch(SET_ASSIGN_ENTRY_ID(entryId));
    onAssignOpen();
  };

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const handleFilter = useCallback((value: boolean) => {
    setShowUnassigned(value);
  }, []);

  const handleGoToEditBracket = useCallback(
    (entryId: string | number) => {
      const path = bracketPathById(entryId);
      navigate(path);
    },
    [bracketPathById, navigate],
  );

  const filteredEntries: IBracketEntry[] | undefined = useMemo(() => {
    const entries = getFilteredEntries(data?.entries, search);
    return showUnassigned
      ? entries?.filter((entry) => !entry.leagues?.length)
      : entries;
  }, [data?.entries, search, showUnassigned]);

  return (
    <>
      {(isEntriesLoading || isGameLoading) && <PageLoader />}
      <BracketsListComponent
        entries={filteredEntries || []}
        isTablet={isTablet}
        onAssignLeague={openAssignModal}
        onFilter={handleFilter}
        onGoToEditBracket={handleGoToEditBracket}
        onSearch={handleSearch}
      />

      <AssignLeagueModal isOpen={isAssignOpen} onClose={onAssignClose} />
    </>
  );
};
