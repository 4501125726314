import { WithBannersLayout } from '@/components/Layout/with-banners';
import { WithoutBannersLayout } from '@/components/Layout/without-banners';

import { useGameDate } from '@/hooks/useGameDate';
import { useSettings } from '@/hooks/useSettings';

export const Layout = () => {
  const { isLgDesktop } = useSettings();
  useGameDate();

  return isLgDesktop ? <WithBannersLayout /> : <WithoutBannersLayout />;
};
