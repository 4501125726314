import React from 'react';

import {
  FormControl,
  FormControlProps,
  Stack,
  Text,
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
} from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { useFormContext } from 'react-hook-form';

import { Radio } from '@/types/common';

export type Props = FormControlProps & {
  name: string;
  options: Radio[];
  resetFieldName?: string;
  sx?: SystemStyleObject;
};

export const RadioGroup: React.FC<Props> = (props) => {
  const { name, options } = props;

  const { setValue, getValues } = useFormContext();
  const value = getValues()[name];

  const handleChange = (value: string) => {
    setValue(name, value, { shouldValidate: true });
  };

  return (
    <FormControl>
      <ChakraRadioGroup
        defaultValue={value}
        onChange={(value) => handleChange(value)}
      >
        <Stack>
          {options.map((option, index) => (
            <ChakraRadio key={index} value={option.value}>
              <Text fontWeight='bold'>{option.label}</Text>
              {option.helpText && (
                <Text fontSize='12px'>{option.helpText}</Text>
              )}
            </ChakraRadio>
          ))}
        </Stack>
      </ChakraRadioGroup>
    </FormControl>
  );
};
