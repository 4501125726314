import { FC, PropsWithChildren } from 'react';

import { Flex } from '@chakra-ui/react';

export const AnswerButton: FC<
  PropsWithChildren & { isActive?: boolean; onClick: () => void }
> = ({ isActive, children, ...rest }) => {
  return (
    <Flex
      align='center'
      as='button'
      bg={isActive ? 'white' : '#000000'}
      color={isActive ? 'black' : '#27485F'}
      h='22px'
      justify='center'
      opacity={isActive ? 1 : '50%'}
      w='22px'
      {...rest}
    >
      {children}
    </Flex>
  );
};
