export enum RoutesEnum {
  root = '/',
  home = 'home',

  brackets = 'brackets',
  bracketsList = 'brackets/list', // TODO
  bracketsSpecific = 'brackets/:id',
  bracketsPreRegistration = 'brackets/pre-registration',
  bracketsNew = 'brackets/new',
  bracketsSecondChance = 'brackets/second-chance',

  leagues = 'leagues',
  leagueManage = 'leagues/:id',

  leaderboard = 'leaderboard',
  howToPlay = 'how-to-play',
  rules = 'rules',
  prizes = 'prizes',
}
