import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store';

import {
  DEFAULT_ENTRY,
  DEFAULT_ENTRY_SC,
  isAllMatchesFilledExceptChampion,
  isAllSCMatchesFilledExceptChampion,
} from '@/modules/Bracket/bracket.helpers';

import {
  IBracketEntry,
  SecondChanceEntryType,
  SeriesResultsType,
  TeamType,
  UserType,
} from './bracket.types';
import { BracketMode } from './brackets.enums';
import { getRandomName } from './helpers/smallUtils';

type InitialStateType = {
  game: {
    is_picks_locked?: boolean | null;
    allow_picks?: boolean | null;
    allow_picks_from?: string;
    allow_picks_until?: string;
    teams: TeamType[];
    series_results: SeriesResultsType[];
    tiebreaker?: number | null;
  };
  seriesResults: SeriesResultsType[];
  entry: IBracketEntry;
  entrySC: SecondChanceEntryType;
  initialEntrySC: SecondChanceEntryType | null;
  // Open/Close bonus modal window
  isBonusModalVisible: boolean;
  // Uses for show bonus modal content
  bonusVisibility: { west: boolean; east: boolean };
  //
  finalModal: {
    isOpen: boolean;
    isCompleted: boolean;
    step: number;
  };
  // Uses for disable submit button
  hasBonusValues: { west: boolean; east: boolean };
  // Prevent submit bracket
  isReadOffRules: boolean;
  bracketMode: BracketMode;
  onboardingStep: 0 | 1 | 2;
  user: UserType | undefined;
  assignEntryId: string | number | undefined;
};

const initialState: InitialStateType = {
  game: {
    teams: [],
    series_results: [],
  },
  seriesResults: [],

  entry: DEFAULT_ENTRY,
  entrySC: DEFAULT_ENTRY_SC,

  isBonusModalVisible: false,
  bonusVisibility: { west: false, east: false },
  finalModal: {
    isOpen: false,
    isCompleted: false,
    step: 1,
  },
  hasBonusValues: {
    west: false,
    east: false,
  },
  isReadOffRules: false,
  bracketMode: BracketMode.view,
  onboardingStep: 0,
  user: undefined,
  assignEntryId: undefined,
  initialEntrySC: null,
};

const bracketSlice = createSlice({
  name: 'bracket',
  initialState,
  reducers: {
    SET_GAME(state, action) {
      state.game = action.payload;
    },

    SET_USER(state, action: PayloadAction<InitialStateType['user']>) {
      state.user = action.payload;
    },

    UPDATE_SERIES_RESULT(state, action) {
      state.seriesResults = action.payload;
    },

    SET_ENTRY_ID: (state, action) => {
      state.entry.entry_id = action.payload;
    },
    SET_ENTRY_ID_SC: (state, action) => {
      state.entrySC.entry_id = action.payload;
    },

    SET_ENTRY_MATCH_PICK(
      state,
      action: { payload: { matchId: number; teamId: number } },
    ) {
      const matchPickKey = `match_${action.payload.matchId}_pick`;
      state.entry[matchPickKey] = action.payload.teamId;
      state.entrySC[matchPickKey] = action.payload.teamId;
    },
    SET_ENTRY_MATCH_PICK_SC(
      state,
      action: { payload: { matchId: number; teamId: number } },
    ) {
      const matchPickKey = `match_${action.payload.matchId}_pick`;
      state.entrySC[matchPickKey] = action.payload.teamId;
    },
    SET_ENTRY_MATCH_PLAYED: (state, action) => {
      state.entry = {
        ...state.entry,
        [action.payload.key]: action.payload.value,
      };
    },
    SET_ENTRY_MATCH_PLAYED_SC: (state, action) => {
      state.entrySC = {
        ...state.entrySC,
        [action.payload.key]: action.payload.value,
      };
    },

    OPEN_WEST_BONUS_MODAL: (state) => {
      state.bonusVisibility.west = true;
      state.hasBonusValues.west = false;
      state.isBonusModalVisible = true;
    },
    OPEN_EAST_BONUS_MODAL: (state) => {
      state.bonusVisibility.east = true;
      state.hasBonusValues.east = false;
      state.isBonusModalVisible = true;
    },

    OPEN_BONUS_MODAL: (
      state,
      action: PayloadAction<{ matchId: number; isSecondChance: boolean }>,
    ) => {
      const { matchId, isSecondChance } = action.payload;
      if (state.entry.match_301_pick > 0 && state.entry.match_302_pick > 0) {
        state.isBonusModalVisible = true;
        return;
      }

      if (!isSecondChance) {
        if (matchId <= 104) {
          if (
            state.entry['match_101_pick'] > 0 &&
            state.entry['match_102_pick'] > 0 &&
            state.entry['match_103_pick'] > 0 &&
            state.entry['match_104_pick'] > 0
          ) {
            state.isBonusModalVisible = true;
          }
        } else {
          if (
            state.entry['match_105_pick'] > 0 &&
            state.entry['match_106_pick'] > 0 &&
            state.entry['match_107_pick'] > 0 &&
            state.entry['match_108_pick'] > 0
          ) {
            state.isBonusModalVisible = true;
          }
        }
      } else {
        if (matchId <= 202) {
          if (
            state.entrySC['match_201_pick'] > 0 &&
            state.entrySC['match_202_pick'] > 0
          ) {
            state.isBonusModalVisible = true;
          }
        } else {
          if (
            state.entrySC['match_203_pick'] > 0 &&
            state.entrySC['match_204_pick'] > 0
          ) {
            state.isBonusModalVisible = true;
          }
        }
      }

      state.bonusVisibility = {
        ...state.bonusVisibility,
        [matchId]: true,
      };
    },
    CLOSE_BONUS_MODAL: (state) => {
      state.isBonusModalVisible = false;
    },

    TOGGLE_FINAL_BONUS_MODAL: (state, action: { payload: boolean }) => {
      state.finalModal.isOpen = action.payload;
    },

    RESET_FINAL_MODAL_STATE: (state) => {
      state.finalModal = initialState.finalModal;
    },

    CHANGE_FINAL_BONUS_MODAL_STEP: (state, action: { payload: number }) => {
      state.finalModal.step = action.payload;
    },

    CHANGE_BONUS_MODAL_VISIBILITY: (state, action: { payload: number }) => {
      const matchId = action.payload;
      const firstRoundMatchList = Object.keys(state.entry).filter(
        (key) => key.includes('match_1') && key.includes('pick'),
      );

      switch (matchId) {
        case 0:
          state.bonusVisibility = { west: false, east: false };
          break;
        case 1:
          state.bonusVisibility = { west: true, east: true };
          break;
        case 101:
        case 102:
        case 103:
        case 104:
          state.bonusVisibility = { west: true, east: false };
          break;
        case 105:
        case 106:
        case 107:
        case 108:
          state.bonusVisibility = { west: false, east: true };
          break;
        case 1000:
          const firstRoundMatchListCopy = firstRoundMatchList;
          const westernMutchups = firstRoundMatchListCopy.splice(0, 4);
          const easternMutchups = firstRoundMatchListCopy;
          const isWestMatchupsAllPicked = westernMutchups.every(
            (key) => !!state.entry[key],
          );
          const isEastMatchupsAllPicked = easternMutchups.every(
            (key) => !!state.entry[key],
          );
          state.bonusVisibility = {
            west: isWestMatchupsAllPicked,
            east: isEastMatchupsAllPicked,
          };
          break;
        default:
          const conferenceMatchupList =
            matchId <= 104
              ? firstRoundMatchList.splice(0, 4)
              : firstRoundMatchList.splice(4);
          const isConferenceMatchupsAllPicked = conferenceMatchupList.every(
            (key) => !!state.entry[key],
          );
          if (isConferenceMatchupsAllPicked) {
            state.bonusVisibility =
              matchId <= 104
                ? { ...state.bonusVisibility, west: true }
                : { ...state.bonusVisibility, east: true };
          }
          break;
      }
    },

    CHANGE_BONUS_MODAL_VISIBILITY_SC: (state, action: { payload: number }) => {
      const firstRoundMatchList = Object.keys(state.entrySC).filter(
        (key) => key.includes('match_2') && key.includes('pick'),
      );

      const matchId = action.payload;

      switch (matchId) {
        case 0:
          state.bonusVisibility = { west: false, east: false };
          break;
        case 1:
          state.bonusVisibility = { west: true, east: true };
          break;
        case 201:
        case 202:
          state.bonusVisibility = { west: true, east: false };
          break;
        case 203:
        case 204:
          state.bonusVisibility = { west: false, east: true };
          break;
        case 1000:
          const firstRoundMatchListCopy = firstRoundMatchList;
          const westernMutchups = firstRoundMatchListCopy.splice(0, 2);
          const easternMutchups = firstRoundMatchListCopy;
          const isWestMatchupsAllPicked = westernMutchups.every(
            (key) => !!state.entrySC[key],
          );
          const isEastMatchupsAllPicked = easternMutchups.every(
            (key) => !!state.entrySC[key],
          );
          state.bonusVisibility = {
            west: isWestMatchupsAllPicked,
            east: isEastMatchupsAllPicked,
          };
          break;
        default:
          const conferenceMatchupList =
            matchId <= 202
              ? firstRoundMatchList.splice(0, 2)
              : firstRoundMatchList.splice(2);
          const isConferenceMatchupsAllPicked = conferenceMatchupList.every(
            (key) => !!state.entrySC[key],
          );
          if (isConferenceMatchupsAllPicked) {
            state.bonusVisibility =
              matchId <= 202
                ? { ...state.bonusVisibility, west: true }
                : { ...state.bonusVisibility, east: true };
          }
          break;
      }
    },

    SET_WEST_BONUS_QUESTION_ANSWERED: (state, action: { payload: boolean }) => {
      state.hasBonusValues.west = action.payload;
    },
    SET_EAST_BONUS_QUESTION_ANSWERED: (state, action: { payload: boolean }) => {
      state.hasBonusValues.east = action.payload;
    },

    CHECK_IS_FINAL_FILLED: (state, action: PayloadAction<IBracketEntry>) => {
      if (isAllMatchesFilledExceptChampion(action.payload)) {
        state.finalModal.isOpen = true;
      }
    },
    CHECK_IS_FINAL_FILLED_SC: (
      state,
      action: PayloadAction<SecondChanceEntryType>,
    ) => {
      if (isAllSCMatchesFilledExceptChampion(action.payload)) {
        state.finalModal.isOpen = true;
      }
    },

    SET_READ_OFF_RULES: (state, action: { payload: boolean }) => {
      state.isReadOffRules = action.payload;
    },

    SET_TIEBRAKER_VALUE: (state, action: { payload: string }) => {
      state.entry.tiebreaker = action.payload;
    },
    SET_TIEBRAKER_VALUE_SC: (state, action: { payload: string }) => {
      state.entrySC.tiebreaker = action.payload;
    },

    CLEAR_ENTRY: (state) => {
      state.entry = { ...DEFAULT_ENTRY, entry_name: state.entry.entry_name };
      state.isReadOffRules = false;
    },
    CLEAR_ENTRY_SC: (state) => {
      state.entrySC = {
        ...DEFAULT_ENTRY_SC,
        entry_name: state.entrySC.entry_name,
        entry_id: state.entrySC.entry_id,
      };
      state.isReadOffRules = false;
    },

    REVERT_DEFAULT_ENTRY_STATE: (state) => {
      state.entry = initialState.entry;
      state.entrySC = initialState.entrySC;
      state.bracketMode = initialState.bracketMode;
      state.isReadOffRules = initialState.isReadOffRules;
      state.bonusVisibility = initialState.bonusVisibility;
      state.hasBonusValues = initialState.hasBonusValues;
      state.finalModal = initialState.finalModal;
      state.assignEntryId = initialState.assignEntryId;
      state.isBonusModalVisible = initialState.isBonusModalVisible;
    },

    SET_ENTRY: (state, action: { payload: IBracketEntry }) => {
      state.entry = action.payload;
    },
    SET_ENTRY_SC: (state, action: { payload: SecondChanceEntryType }) => {
      state.entrySC = action.payload;
    },
    SET_INITIAL_ENTRY_SC: (
      state,
      action: { payload: SecondChanceEntryType | null },
    ) => {
      state.initialEntrySC = action.payload;
    },

    CHANGE_BRACKET_NAME: (state, action: { payload: string }) => {
      state.entry.entry_name = action.payload;
    },
    CHANGE_BRACKET_NAME_SC: (state, action: { payload: string }) => {
      state.entrySC.entry_name = action.payload;
    },

    SET_BRACKET_MODE: (state, action: { payload: BracketMode }) => {
      state.bracketMode = action.payload;
    },
    SET_ONBOARDING_STEP: (state, action: { payload: 0 | 1 | 2 }) => {
      state.onboardingStep = action.payload;
    },
    SET_ASSIGN_ENTRY_ID: (state, action: { payload: string | number }) => {
      state.assignEntryId = action.payload;
    },
  },
});

export const {
  SET_GAME,
  UPDATE_SERIES_RESULT,
  SET_ENTRY_MATCH_PICK,
  SET_ENTRY_MATCH_PLAYED,
  OPEN_WEST_BONUS_MODAL,
  OPEN_EAST_BONUS_MODAL,
  OPEN_BONUS_MODAL,
  CLOSE_BONUS_MODAL,
  TOGGLE_FINAL_BONUS_MODAL,
  CHANGE_FINAL_BONUS_MODAL_STEP,
  CHANGE_BONUS_MODAL_VISIBILITY,
  SET_WEST_BONUS_QUESTION_ANSWERED,
  SET_EAST_BONUS_QUESTION_ANSWERED,
  CHECK_IS_FINAL_FILLED,
  SET_READ_OFF_RULES,
  SET_TIEBRAKER_VALUE,
  CLEAR_ENTRY,
  SET_ENTRY,
  SET_ENTRY_ID,
  CHANGE_BRACKET_NAME,
  SET_BRACKET_MODE,
  SET_ONBOARDING_STEP,
  SET_USER,
  SET_ASSIGN_ENTRY_ID,
  REVERT_DEFAULT_ENTRY_STATE,
  RESET_FINAL_MODAL_STATE,

  SET_ENTRY_SC,
  CHANGE_BRACKET_NAME_SC,
  CLEAR_ENTRY_SC,
  SET_TIEBRAKER_VALUE_SC,
  CHANGE_BONUS_MODAL_VISIBILITY_SC,
  SET_ENTRY_MATCH_PLAYED_SC,
  SET_ENTRY_MATCH_PICK_SC,
  SET_ENTRY_ID_SC,
  CHECK_IS_FINAL_FILLED_SC,
  SET_INITIAL_ENTRY_SC,
} = bracketSlice.actions;

export const selectBracket = createSelector(
  (state: RootState) => state.bracket,
  (bracket) => bracket,
);

export default bracketSlice.reducer;
