import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Text,
  Heading,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { tKeys } from '@/i18n';

import deleteIcon from '@/assets/icons/delete.svg';

import AdsBanner from '@/components/AdsBanner';

import { useSettings } from '@/hooks/useSettings';

import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onDelete: () => void;
  isSubmitting: boolean;
};

export const DeleteLeagueModal: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose, onDelete, isSubmitting } = props;
  const { t } = useTranslation();
  const { isMobile } = useSettings();
  const leagueData = useSelector(getLeagueData);

  const isOwner = leagueData?.is_owner;

  const btnText = () => {
    if (isMobile) {
      return isOwner ? t(tKeys.delete) : t(tKeys.leave);
    }
    return isOwner ? t(tKeys.deleteLeague) : t(tKeys.leaveLeague);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        rightIcon={<Image src={deleteIcon} />}
        variant='error'
        width='fit-content'
      >
        {btnText()}
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader flexDirection='column' justifyContent='center' pt='0'>
            <AdsBanner mb='24px' slotId='modal-ad' slotSize={[226, 81]} />
            <Heading as='h3' fontSize='20px' textAlign='center'>
              {isOwner ? t(tKeys.confDeleteLeague) : t(tKeys.confLeaveLeague)}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign='center'>
            {t(tKeys.plsNavigateToLeagues)}
          </ModalBody>
          <ModalFooter flexDirection='column' marginTop='10px'>
            <Button
              isLoading={isSubmitting}
              onClick={onDelete}
              rightIcon={<Image src={deleteIcon} />}
              variant='error'
              width='fit-content'
            >
              {isOwner ? t(tKeys.deleteLeague) : t(tKeys.leaveLeague)}
            </Button>
            <Button marginTop='20px' onClick={onClose} variant='link'>
              {t(tKeys.goBack)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
