import React from 'react';

import {
  Box,
  Button,
  Flex,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import editIcon from '@/assets/icons/edit.svg';

import AdsBanner from '@/components/AdsBanner';

import { Input } from '@/elements/Form/Input';
import { RadioGroup } from '@/elements/Form/RadioGroup';

import { FullWidthModalHeader } from '@/modules/Leagues/components/FullWidthModalHeader';
import { LeagueIconLayout } from '@/modules/Leagues/components/LeagueIconLayout';
import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { createLeagueRadioGroup } from '@/modules/Leagues/leagues.mocks';

type Props = {
  selectedImageKey: LeagueIconKey;
  onGoToSelectImage: () => void;
  onClose: () => void;
  isPasscodeDisabled: boolean;
  isMobile: boolean;
  isSubmitting: boolean;
};

export const CreateLeagueSettings: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    selectedImageKey,
    onGoToSelectImage,
    onClose,
    isPasscodeDisabled,
    isMobile,
    isSubmitting,
  } = props;

  return (
    <>
      <ModalHeader>
        {isMobile ? (
          <FullWidthModalHeader
            onClick={onClose}
            text={t(tKeys.createLeague)}
          />
        ) : (
          <AdsBanner slotId='modal-ad' slotSize={[226, 81]} />
        )}
      </ModalHeader>
      {!isMobile && <ModalCloseButton />}
      <ModalBody>
        <>
          {isMobile && (
            <AdsBanner
              marginBottom='40px'
              marginTop='24px'
              slotId='modal-ad'
              slotSize={[226, 81]}
            />
          )}
          <Box width={isMobile ? '100%' : 'auto'}>
            <Text textAlign='center'>{t(tKeys.leagueSettings)}</Text>
            <Input
              name='name'
              placeholder={t(tKeys.leagueName)}
              sx={{
                borderRadius: '50px',
                marginTop: '16px',
                background: '#1E3647',
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.45) inset',
                border: 'none',
              }}
              type='text'
            />
          </Box>

          <Flex
            alignItems='center'
            flexDirection={isMobile ? 'column' : 'row'}
            marginBottom='30px'
            marginTop='40px'
            width={isMobile ? '100%' : 'auto'}
          >
            <Box
              alignItems='center'
              borderBottom={isMobile ? '2px solid #99ABB7' : 'none'}
              borderRight={isMobile ? 'none' : '2px solid #99ABB7'}
              display='flex'
              flexDirection={isMobile ? 'row' : 'column'}
              justifyContent='center'
              paddingBottom={isMobile ? '40px' : '0'}
              paddingRight={isMobile ? '0' : '48px'}
              textAlign='center'
              width={isMobile ? '100%' : '338px'}
            >
              <LeagueIconLayout
                borderRadius='14px'
                height='80px'
                iconKey={selectedImageKey}
                padding='12px'
                width='80px'
              />
              <Button
                marginLeft={isMobile ? '24px' : '0'}
                marginTop={isMobile ? '0' : '24px'}
                onClick={onGoToSelectImage}
                padding='8px 30px'
                rightIcon={<Image src={editIcon} />}
                variant='success'
                width='auto'
              >
                {t(tKeys.selectImage)}
              </Button>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              paddingLeft={isMobile ? '0' : '48px'}
              paddingTop={isMobile ? '40px' : '0'}
              width={isMobile ? '230px' : '338px'}
            >
              <RadioGroup
                name='is_private'
                options={createLeagueRadioGroup(t)}
                resetFieldName='passcode'
              />
              <Input
                isDisabled={isPasscodeDisabled}
                name='passcode'
                placeholder={t(tKeys.password)}
                sx={{
                  borderRadius: '50px',
                  marginTop: '16px',
                  background: '#1E3647',
                  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.45) inset',
                  border: 'none',
                }}
                type='text'
              />
            </Box>
          </Flex>
        </>
      </ModalBody>
      <ModalFooter>
        <Flex flexDirection='column' width={isMobile ? '100%' : 'auto'}>
          <Button
            isLoading={isSubmitting}
            marginBottom='8px'
            maxWidth='none'
            type='submit'
            width={isMobile ? '100%' : '350px'}
          >
            {t(tKeys.saveLeague)}
          </Button>
          <Button
            maxWidth='none'
            onClick={onClose}
            textDecoration='underline'
            variant='outlined'
          >
            {t(tKeys.cancel)}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
