import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import bracketSlice from '@/modules/Bracket/bracket.slice';
import { leagueManageReducer } from '@/modules/LeagueManage/leagueManage.slice';
import { leaguesReducer } from '@/modules/Leagues/leagues.slice';
import sidebarSlice from '@/modules/Sidebar/sidebar.slice';

import { api } from '@/services/api.service';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  bracket: bracketSlice,
  leagues: leaguesReducer,
  leagueManage: leagueManageReducer,
  sidebar: sidebarSlice,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
