import React, { useEffect, useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { isValidLangInPath, tKeys } from '@/i18n';

import { Footer } from '@/components/Layout/Footer';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';
import { useToast } from '@/hooks/useToast';

import {
  bracketsNewPath,
  bracketsPreRegistrationPath,
  bracketsSecondChancePath,
} from '@/modules/Bracket/bracket.constants';
import { editSCPath } from '@/modules/Bracket/bracket.helpers';
import {
  IBracketEntry,
  SecondChanceEntryType,
} from '@/modules/Bracket/bracket.types';
import { BracketMode } from '@/modules/Bracket/brackets.enums';
import { useDownloadBracket } from '@/modules/Bracket/hooks/useDownloadBracket';

import { createPath } from '@/routes/types';

import { errorHandler } from '@/utils/api.util';
import { hiddenScrollbarStyles } from '@/utils/common.util';

import {
  useGetDashboardQuery,
  useGetSecondChanceEntryQuery,
  useLazyGetEntryQuery,
  useLazyGetGameQuery,
  useLazyGetSecondChanceEntryByIdQuery,
  useLazyGetSecondChanceEntryQuery,
} from '../bracket.api';
import {
  CHANGE_BRACKET_NAME,
  CHANGE_BRACKET_NAME_SC,
  REVERT_DEFAULT_ENTRY_STATE,
  selectBracket,
  SET_BRACKET_MODE,
  SET_EAST_BONUS_QUESTION_ANSWERED,
  SET_ENTRY,
  SET_ENTRY_SC,
  SET_GAME,
  SET_INITIAL_ENTRY_SC,
  SET_USER,
  SET_WEST_BONUS_QUESTION_ANSWERED,
  UPDATE_SERIES_RESULT,
} from '../bracket.slice';
import { Bracket } from '../components/Bracket';
import { BracketHeader } from '../components/Header';
import { OnboardingOverlay } from '../components/OnboardingOverlay';
import { SignupTodayBlock } from '../components/SignupTodayBlock';
import { StatusBar } from '../components/StatusBar';
import { TopPreRegistrationBlock } from '../components/TopPreRegistrationBlock';
import { fillAllMatches } from '../helpers/fillMatchesFromEntry';
import { getModeByPathname } from '../helpers/getModeByPathname';
import {
  checkHasEastBonusAnswered,
  checkHasWestBonusAnswered,
  getRandomName,
  getRandomNameSC,
} from '../helpers/smallUtils';
import { useBracketSettings } from '../hooks/useBracketSettings';

export const BracketContainer = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id, lang: langParam } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isLgDesktop } = useSettings();
  const { isPreRegistrationPage, isAppNavigationHide } = useBracketSettings();
  const { isOver, isSecondChanceLive, isSecondChanceOver } = useGameDate();
  const { jwt } = useJWT();

  const {
    game: { series_results },
    entry,
    user,
    bracketMode,
    entrySC,
    initialEntrySC,
  } = useSelector(selectBracket);

  const [getGame, { isLoading }] = useLazyGetGameQuery();
  const [getEntry, { isLoading: isLoadingEntry }] = useLazyGetEntryQuery();
  const { data } = useGetDashboardQuery({ jwt });
  const [getSecondChance, { isLoading: isSCLoading, isSuccess: isSCSuccess }] =
    useLazyGetSecondChanceEntryQuery();
  const [getEntrySCById, { isLoading: isLoadingSCEntryById }] =
    useLazyGetSecondChanceEntryByIdQuery();

  const bracketViewType = searchParams.get(SearchParamsEnum.viewType) as
    | BracketMode
    | undefined;
  const entryId = searchParams.get(SearchParamsEnum.entryId);

  const {
    handleDownload,
    bracketRef,
    isLoading: isLoadingDownload,
  } = useDownloadBracket();

  useEffect(() => {
    return () => {
      dispatch(REVERT_DEFAULT_ENTRY_STATE());
    };
  }, [dispatch]);

  useEffect(() => {
    if (bracketsNewPath() === pathname && user && data) {
      dispatch(CHANGE_BRACKET_NAME(getRandomName(user, data?.entries)));
    }
  }, [data, dispatch, pathname, user]);

  useEffect(() => {
    if (
      user &&
      data &&
      pathname === bracketsSecondChancePath() &&
      !bracketViewType &&
      isSCSuccess
    ) {
      dispatch(CHANGE_BRACKET_NAME_SC(getRandomNameSC(user)));
    }
  }, [
    bracketMode,
    bracketViewType,
    data,
    dispatch,
    isSCSuccess,
    pathname,
    user,
  ]);

  useEffect(() => {
    if (
      (!bracketViewType &&
        pathname === bracketsSecondChancePath() &&
        initialEntrySC) ||
      (isValidLangInPath(langParam) &&
        !bracketViewType &&
        pathname !== bracketsPreRegistrationPath() &&
        pathname !== bracketsNewPath() &&
        pathname !== bracketsSecondChancePath())
    ) {
      searchParams.set(SearchParamsEnum.viewType, BracketMode.view);
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    bracketViewType,
    initialEntrySC,
    langParam,
    pathname,
    searchParams,
    setSearchParams,
  ]);

  useEffect(() => {
    // GET game and store it
    (async () => {
      try {
        const { game } = await getGame({ jwt }).unwrap();
        dispatch(SET_GAME(game));
        dispatch(SET_USER(game.user));
      } catch (error) {
        errorHandler({ toast, t, error });
      }
    })();
  }, [jwt]);

  useEffect(() => {
    // GET entry and store it
    if (Number(id))
      (async () => {
        try {
          const { entry } = await getEntry({
            entry_id: Number(id),
            jwt,
          }).unwrap();
          dispatch(SET_ENTRY(entry));
        } catch (error) {
          errorHandler({ toast, t, error });
        }
      })();
    //eslint-disable-next-line
  }, [id, jwt]);

  useEffect(() => {
    // GET entry and store it
    if (Number(entryId) && pathname === bracketsSecondChancePath())
      (async () => {
        try {
          const { entry } = await getEntrySCById({
            entry_id: Number(entryId),
            jwt,
          }).unwrap();
          if (entry) {
            dispatch(SET_INITIAL_ENTRY_SC(entry));
            dispatch(SET_ENTRY_SC(entry));
          }
        } catch (error) {
          errorHandler({ toast, t, error });
        }
      })();
    //eslint-disable-next-line
  }, [entryId, pathname, jwt]);

  useEffect(() => {
    // GET entry and store it
    if (
      pathname === bracketsSecondChancePath() &&
      !entryId &&
      (isSecondChanceLive || isSecondChanceOver)
    )
      (async () => {
        try {
          const { entry } = await getSecondChance({ jwt }).unwrap();
          if (entry) {
            dispatch(SET_INITIAL_ENTRY_SC(entry));
            dispatch(SET_ENTRY_SC(entry));
          }
        } catch (error) {
          errorHandler({ toast, t, error });
        }
      })();
    //eslint-disable-next-line
  }, [isSecondChanceLive, isSecondChanceOver, pathname, entryId]);

  // Added bracket mode to store
  useEffect(() => {
    const mode = getModeByPathname({
      pathname,
      bracketViewType,
      isOver:
        pathname === bracketsSecondChancePath() ? isSecondChanceOver : isOver,
      isSecondChanceLive,
    });
    dispatch(SET_BRACKET_MODE(mode));
  }, [
    bracketViewType,
    dispatch,
    isOver,
    isSecondChanceLive,
    isSecondChanceOver,
    pathname,
  ]);

  useEffect(() => {
    const neededEntry =
      pathname === bracketsSecondChancePath() ? entrySC : entry;
    // Add data from entry to series results
    const filledBracket = fillAllMatches(
      series_results,
      neededEntry,
      pathname === bracketsSecondChancePath(),
    );
    dispatch(UPDATE_SERIES_RESULT(filledBracket));

    // Set flag for submit button
    if (
      checkHasWestBonusAnswered(
        neededEntry,
        pathname === bracketsSecondChancePath(),
      )
    ) {
      dispatch(SET_WEST_BONUS_QUESTION_ANSWERED(true));
    }
    // Set flag for submit button
    if (
      checkHasEastBonusAnswered(
        neededEntry,
        pathname === bracketsSecondChancePath(),
      )
    ) {
      dispatch(SET_EAST_BONUS_QUESTION_ANSWERED(true));
    }
  }, [series_results, entry, dispatch, bracketMode, entrySC, pathname]);

  if (isMobile) {
    return <MobileContent secondChanceEntry={initialEntrySC} />;
  }

  return (
    <>
      {(isLoading || isLoadingEntry || isLoadingDownload || isSCLoading) && (
        <PageLoader />
      )}
      <Box
        id='container'
        maxH={isAppNavigationHide ? '725px' : '650px'} // fixed height due to ads requirement
        overflow='auto'
        position='relative'
        sx={hiddenScrollbarStyles}
        width='100%'
      >
        <OnboardingOverlay />
        <BracketHeader
          handleDownload={handleDownload}
          secondChanceEntry={initialEntrySC}
        />
        <StatusBar />
        <Box
          color='white'
          maxW={isLgDesktop ? 'calc(430px + 560px)' : '100%'} // Linked to layout width
          minH='70vh'
          position='relative'
          w='100%'
        >
          <Box
            height='15%'
            left='50%'
            position='absolute'
            top='5%'
            transform='translateX(-50%)'
            zIndex={isPreRegistrationPage ? 10 : 1}
          >
            {isPreRegistrationPage ? <TopPreRegistrationBlock /> : null}
          </Box>

          <Bracket ref={bracketRef} />

          <Box
            bottom='0%'
            left='50%'
            position='absolute'
            transform='translate(-50%)'
          >
            <SignupTodayBlock />
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

const MobileContent: React.FC<{
  secondChanceEntry: SecondChanceEntryType | undefined | null;
}> = ({ secondChanceEntry }) => {
  const { pathname } = useLocation();
  const { isPreRegistrationPage } = useBracketSettings();
  const { bracketMode } = useSelector(selectBracket);

  const {
    handleDownload,
    bracketRef,
    isLoading: isLoadingDownload,
  } = useDownloadBracket();

  const calculatedMt = useMemo(
    () =>
      [BracketMode.edit].includes(bracketMode) ||
      pathname == createPath({ path: RoutesEnum.bracketsNew })
        ? '225px'
        : '100px',
    [bracketMode, pathname],
  );

  return (
    <>
      {isLoadingDownload && <PageLoader />}
      <Box id='container' mt={calculatedMt} position='relative'>
        <OnboardingOverlay />

        <BracketHeader
          handleDownload={handleDownload}
          secondChanceEntry={secondChanceEntry}
        />
        <StatusBar />
        {isPreRegistrationPage ? (
          <Box
            bg='primaryDarkGradient'
            borderRadius='8px'
            margin={4}
            mt='150px'
            padding={4}
          >
            <TopPreRegistrationBlock />
          </Box>
        ) : null}

        <Box
          color='white'
          maxW='100vw'
          minH='70vh'
          position='relative'
          w='100%'
        >
          <Bracket ref={bracketRef} />
        </Box>
      </Box>
    </>
  );
};
