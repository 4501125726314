import React from 'react';

import { Helmet } from 'react-helmet-async';

import { Leagues } from '@/modules/Leagues/containers/Leagues';

const LeaguesPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Leagues | Bracket Challenge</title>
        <meta
          content='Create your own league to compete against friends and family, or join official Club or Partner leagues.'
          name='description'
        />
      </Helmet>
      <Leagues />
    </>
  );
};

export default LeaguesPage;
