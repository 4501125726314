import { FC, useCallback, useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  Text,
  ModalProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import AdsBanner from '@/components/AdsBanner';
import { RightArrowButton } from '@/components/RightArrowButton';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { useLazyGetSSOQuery } from '@/services/api.service';

interface LoginModalProps extends Omit<ModalProps, 'children'> {}

export const LoginModal: FC<LoginModalProps> = ({ ...modalProps }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [trigger] = useLazyGetSSOQuery();
  const { setItem } = useLocalStorage<string>(StorageKeysEnum.pageBeforeSignIn);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isSingUpLoading, setIsSingUpLoading] = useState(false);

  const handleGetSSO = useCallback(
    () =>
      trigger()
        .unwrap()
        .then((response: any) => {
          if (response?.redirectUrl) {
            window.location.assign(response.redirectUrl);
          }
        })
        .catch(console.error),
    [trigger],
  );

  const handeLogin = useCallback(() => {
    setIsLoginLoading(true);
    setItem(pathname);
    handleGetSSO();
  }, [handleGetSSO, pathname, setItem]);

  const handleSignUp = useCallback(() => {
    setIsSingUpLoading(true);
    setItem(pathname);
    handleGetSSO();
  }, [handleGetSSO, pathname, setItem]);

  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <AdsBanner slotId='modal-ad' slotSize={[226, 81]} />
        </ModalHeader>
        <ModalBody gap='40px'>
          <Flex align='center' direction='column' gap='16px'>
            <Text>{t(tKeys.pleaseLoginToAccess)}</Text>
            <Text textAlign='center'>{t(tKeys.youMustBeLogged)}</Text>
            <RightArrowButton
              isLoading={isLoginLoading}
              onClick={handeLogin}
              variant='outline'
            >
              {t(tKeys.loginToNhlGamezone)}
            </RightArrowButton>
          </Flex>

          <Flex
            align='center'
            bg='darkBlue'
            borderRadius='20px'
            direction='column'
            gap='13px'
            p='24px'
            w='100%'
          >
            <Text textAlign='center' whiteSpace='break-spaces'>
              {t(tKeys.dontHaveNhlAccount)}
            </Text>
            <RightArrowButton
              isLoading={isSingUpLoading}
              onClick={handleSignUp}
            >
              {t(tKeys.signup)}
            </RightArrowButton>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={modalProps.onClose} variant='link'>
            {t(tKeys.cancel)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
