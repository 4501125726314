import React from 'react';

const list = [
  'bible basher',
  'bible beater',
  'bible thumper',
  'biblebasher',
  'bible-basher',
  'biblebeater',
  'bible-beater',
  'biblethumper',
  'bible-thumper',
  'fundie',
  'holy roller',
  'holyroller',
  'holy-roller',
  'infidel',
  'jewboy',
  'jewgirl',
  'kike',
  'kyke',
  'shylock',
  'boycottnra',
  'btsarmy',
  'buildthewall',
  'civilrights',
  'equalrights',
  'feminism',
  'feminist',
  'guncontrol',
  'guncontrolnow',
  'gundebate',
  'isis',
  'murder',
  'murdered',
  'murdering',
  'murdersuicide',
  'nazi',
  'nflboycott',
  'nra',
  'rifle',
  'sexualharrassment',
  'snowflake',
  'stateofemergency',
  'takeaknee',
  'takingaknee',
  'taxreform',
  'weremember',
  'womensmarch',
  'womensrights',
  'al qaeda',
  'assassinate',
  'assassination',
  'black lives matter',
  'blm',
  'border security',
  'boycott',
  'boycotted',
  'boycotting',
  'boycotts',
  'build the wall',
  'daesh',
  'dog fighting',
  'dogfighting',
  'dog-fighting',
  'feminism',
  'feminist',
  'genocide',
  'genocides',
  'gun control',
  'gun man',
  'guncontrol',
  'gun-control',
  'gunman',
  'hitler',
  'illegal alien',
  'immigrants',
  'immigration',
  'isil',
  'isis',
  'legal alien',
  'massacre',
  'massacred',
  'massacres',
  'migrant',
  'migrants',
  'misogynism',
  'misogynist',
  'misogyny',
  'molest',
  'molestation',
  'molested',
  'murder-suicide',
  'nazis',
  'nra',
  'pedophile',
  'pedophilia',
  'pervert',
  'perverted',
  'petaphile',
  'plane crash',
  'planecrash',
  'protest',
  'protesting',
  'protests',
  'rape',
  'raped',
  'raper',
  'rapist',
  'refugee',
  'refugees',
  'rip',
  'sexism',
  'sexist',
  'shooter',
  'shooters',
  'shooting',
  'slavery',
  'slaves',
  'suicide',
  'syria',
  'tax reform',
  'terror',
  'terrorism',
  'terrorist',
  'terrors',
  'weinstein',
  'welfare',
  'white lives matter',
  'womens march',
  "women's march",
  'equality',
  'abortion',
  'abort baby',
  'abort child',
  'abort children',
  'abort boy',
  'abort girl',
  'pro abortion',
  'anti abortion',
  'pro-abortion',
  'anti-abortion',
  '420',
  '4/20',
  '420',
  'amphetamine',
  'bath salts',
  'butane',
  'cocaine',
  'crystal meth',
  'drop acid',
  'dropping acid',
  'ecstasy',
  'flakka',
  'ghb',
  'heroin',
  'ketamine',
  'khat',
  'lsd',
  'meth',
  'meth head',
  'methadone',
  'methamphetamine',
  'methhead',
  'opioids',
  'rape drug',
  'scopolamine',
  'bum bandit',
  'bumbandit',
  'bum-bandit',
  'butch',
  'butchbroad',
  'butch-broad',
  'butt pirate',
  'buttpirate',
  'butt-pirate',
  'carpet muncher',
  'carpetmuncher',
  'carpet-muncher',
  'celesbian',
  'chi chi man',
  'chi-chi man',
  'cock jockey',
  'cockjockey',
  'cock-jockey',
  'cuntboy',
  'dickgirl',
  'dyke',
  'fag',
  'faggot',
  'fairy',
  'fruit cake',
  'fruitcake',
  'fruit-cake',
  'fudge packer',
  'fudgepacker',
  'fudge-packer',
  'futanari',
  'homo',
  'homosexual',
  'knob jockey',
  'knobjockey',
  'knob-jockey',
  'lesbian',
  'lesbians',
  'limpwrist',
  'limp-wrist',
  'limpwristed',
  'limp-wristed',
  'muff diver',
  'muffdiver',
  'muff-diver',
  'pansexual',
  'pillow biter',
  'pillowbiter',
  'pillow-biter',
  'poof',
  'poofer',
  'shemale',
  'soy boy',
  'soyboy',
  'soy-boy',
  'todger dodger',
  'todgerdodger',
  'todger-dodger',
  'tranny',
  'trannys',
  'turd burglar',
  'turdburglar',
  'turd-burglar',
  'twink',
  'androgynous',
  'bean flicker',
  'beanflicker',
  'bean-flicker',
  'bisexual',
  'bone smuggler',
  'bonesmuggler',
  'flamer',
  'bone-smuggler',
  'arse',
  'arse hole',
  'arsehole',
  'arse-hole',
  'ass',
  'ass bag',
  'ass clown',
  'ass face',
  'ass hat',
  'ass hole',
  'ass wad',
  'ass wipe',
  'assbag',
  'ass-bag',
  'assclown',
  'ass-clown',
  'assface',
  'ass-face',
  'asshat',
  'ass-hat',
  'asshole',
  'ass-hole',
  'asswad',
  'ass-wad',
  'asswipe',
  'ass-wipe',
  'bastard',
  'crap',
  'crapped',
  'crapper',
  'dammit',
  'damn',
  'dumb ass',
  'dumb asses',
  'dumbass',
  'dumb-ass',
  'dumbasses',
  'dumb-asses',
  'lame ass',
  'lameass',
  'lame-ass',
  'lard ass',
  'lardass',
  'lard-ass',
  'piss',
  'pissed',
  'pisser',
  'pissing',
  'retard',
  'retarded',
  'snowflake',
  'scumbag',
  '45thpresident',
  'altright',
  'bloomberg',
  'capitolhill',
  'cbs',
  'cbsnews',
  'chicagotimes',
  'cnbc',
  'cnn',
  'cpac',
  'fakenews',
  'foxnews',
  'government',
  'hesmypresident',
  'impeachtrump',
  'latimes',
  'maga',
  'marcorubio',
  'msnbc',
  'mypresident',
  'nbc',
  'nbcnews',
  'nevermypresident',
  'newyorkpost',
  'newyorktimes',
  'notmypresident',
  'nytimes',
  'obama',
  'paulmanafort',
  'presidentdonald',
  'presidentdonaldtrump',
  'presidenttrump',
  'primeminister',
  'rickgates',
  'tedcruz',
  'thewhitehouse',
  'trump',
  'usatoday',
  'wallstreetjournal',
  'washingtonpost',
  'whitehouse',
  'yourpresident',
  'abc',
  'abc news',
  'abcnews',
  'alt right',
  'alt+right',
  'altright',
  'alt-right',
  'barack obama',
  'barackobama',
  'bloomberg',
  'breitbart',
  'cap hill',
  'capitol hill',
  'cbs',
  'cbs news',
  'cbsnews',
  'chicago times',
  'civil suit',
  'civil-suit',
  'cnbc',
  'cnn',
  'common law',
  'confederates',
  'congress',
  'congressional',
  'conservative',
  'cpac',
  'democracy',
  'democrat',
  'election',
  'fake news',
  'fakenews',
  'fake-news',
  'federal',
  'flotus',
  'fox news',
  'foxnews',
  'gop',
  'government',
  'grand old party',
  'gwot',
  'hillary clinton',
  'hillaryclinton',
  'house of representatives',
  'ivanka trump',
  'james comey',
  'la times',
  'latimes',
  'left wing',
  'leftwing',
  'left-wing',
  'legislation',
  'legislative',
  'liberal',
  'make america great again',
  'marco rubio',
  'marcorubio',
  'melania',
  'msnbc',
  'nationalism',
  'nationalist',
  'nato',
  'nbc',
  'new york post',
  'new york times',
  'newsday',
  'newyorkpost',
  'ny times',
  'nytimes',
  'obama',
  'obamacare',
  'paul manafort',
  'political',
  'politically correct',
  'politicians',
  'potus',
  'president',
  'presidential',
  'putin',
  'realdonaldtrump',
  'republican',
  'rick gates',
  'right wing',
  'sanction',
  'sanctions',
  'senate',
  'separatists',
  'stalin',
  'state department',
  'steve bannon',
  'surpreme court',
  'ted cruz',
  'tedcruz',
  'trump',
  'usa today',
  'usatoday',
  'wall street',
  'wallstreetjournal',
  'washington post',
  'washtingtonpost',
  'white house',
  'whitehouse',
  'white-house',
  'socialist',
  'fascist',
  'propaganda',
  'marxism',
  'marxist',
  'commie',
  'libtard',
  'Socialist',
  'Fascist',
  'Propaganda',
  'Marxism',
  'Marxist',
  'Commie',
  'Libtard',
  'Kanye 2020',
  'right-wing',
  'rightwing',
  'kanye2020',
  'Joe Biden',
  'Biden',
  'joebiden',
  'biden',
  'biden2020',
  'joebiden2020',
  'Kamala Harris',
  'Kamala',
  'Harris',
  'kamalaharris',
  'harris',
  'bidenharris',
  'bidenharris2020',
  'Biden/Harris',
  'BidenHarris2020',
  'Antifa',
  'Libtarded',
  'Biden-Harris',
  'harrisbiden2020',
  'vote red',
  'vote blue',
  'votered',
  'voteblue',
  'radical left',
  'radical right',
  'commies',
  'trump2020',
  'trump 2020',
  'abbo',
  'abbos',
  'abo',
  'abos',
  'ape',
  'beaner',
  'beaners',
  'camel jockey',
  'cameljockey',
  'camel-jockey',
  'china men',
  'chinamen',
  'ching chong',
  'chingchong',
  'ching-chong',
  'cholo',
  'coon',
  'coon ass',
  'coonass',
  'coon-ass',
  'cracker',
  'curry muncher',
  'currymuncher',
  'curry-muncher',
  'dago',
  'dagos',
  'geebag',
  'gook',
  'gooks',
  'greaseball',
  'gringo',
  'gringos',
  'halfbreed',
  'half-breed',
  'heeb',
  'nigga',
  'paleface',
  'peckerwood',
  'pikey',
  'polack',
  'jigaboo',
  'jiggaboo',
  'jungle bunny',
  'junglebunny',
  'jungle-bunny',
  'kaffir',
  'micks',
  'negro',
  'nigger',
  'nigger rigging',
  'niggerrigging',
  'nigger-rigging',
  'niggers',
  'niglet',
  'niglets',
  'nignog',
  'nig-nog',
  'nignogs',
  'nig-nogs',
  'nip',
  'orientals',
  'polak',
  'polock',
  'porch monkey',
  'porchmonkey',
  'porch-monkey',
  'racialism',
  'racialists',
  'racist',
  'racists',
  'rag head',
  'raghead',
  'rag-head',
  'red neck',
  'redneck',
  'red-neck',
  'rednecks',
  'ruski',
  'sandnigga',
  'sandnigger',
  'shylock',
  'skinhead',
  'skinheads',
  'slant eye',
  'slanteye',
  'slant-eye',
  'slavery',
  'spearchucker',
  'spic',
  'spick',
  'spics',
  'spig',
  'spigotty',
  'spook',
  'spooks',
  'towel head',
  'towelhead',
  'towel-head',
  'uncle tom',
  'uncletom',
  'uncle-tom',
  'waap',
  'wegro',
  'wetback',
  'wetbacks',
  'whigger',
  'white power',
  'whitepower',
  'white-power',
  'whitey',
  'wigga',
  'wigger',
  'zipperhead',
  'Snowflake',
  'Nigga',
  'Nigar',
  'bitch',
  'bitch ass',
  'bitchass',
  'bitch-ass',
  'bitches',
  'bitchy',
  'bull shit',
  'bullshit',
  'bull-shit',
  'cluster fuck',
  'clusterfuck',
  'cluster-fuck',
  'fuck',
  'fuck bag',
  'fuck boy',
  'fuck face',
  'fuck head',
  'fuck hole',
  'fuck off',
  'fuck tard',
  'fuck up',
  'fuck wad',
  'fuckbag',
  'fuck-bag',
  'fuckboy',
  'fuck-boy',
  'fucked',
  'fucker',
  'fuckface',
  'fuck-face',
  'fuckhead',
  'fuck-head',
  'fuckhole',
  'fuck-hole',
  'fuckin',
  "fuckin'",
  'fucking',
  'fuckoff',
  'fuck-off',
  'fucks',
  'fucktard',
  'fuck-tard',
  'fuckup',
  'fuck-up',
  'fuckwad',
  'fuck-wad',
  'mother fucker',
  'motherfucker',
  'mother-fucker',
  'mutha fucka',
  'muthafucka',
  'mutha-fucka',
  'shit',
  'shit head',
  'shithead',
  'shit-head',
  'shits',
  'shitted',
  'shitter',
  'shitting',
  'shitbag',
  'Goddamn',
  'Horsecock',
  'Hellhole',
  'Shitty',
  'SOB',
  'STFU',
  'GTFO',
  'anal',
  'bigboobs',
  'blowjob',
  'boner',
  'booty',
  'brazzers',
  'camgirl',
  'cammodel',
  'chaturbate',
  'cock',
  'cocktribute',
  'cum',
  'cumming',
  'cumshot',
  'cumslut',
  'dreamgirl',
  'femdom',
  'financialdom',
  'financialdominatrix',
  'findom',
  'gangbang',
  'gayporn',
  'hardcock',
  'horny',
  'hornydm',
  'hot',
  'kikgirls',
  'kinky',
  'livecam',
  'naughty',
  'nsfw',
  'nude',
  'oral',
  'oralsex',
  'orgasm',
  'pinktaco',
  'porn',
  'porno',
  'pornstar',
  'pussy',
  'queerporn',
  'sex',
  'sexslave',
  'sexually',
  'sexy',
  'squirt',
  'tit',
  'tittyfuck',
  'webcamsex',
  'wet',
  'xxx',
  '3some',
  'anal',
  'anus',
  'arsehole',
  'ass bag',
  'ass bandit',
  'ass banger',
  'ass bite',
  'ass clown',
  'ass cock',
  'ass cracker',
  'ass face',
  'ass fuck',
  'ass fucker',
  'ass goblin',
  'ass hole',
  'ass holes',
  'ass hopper',
  'ass jabber',
  'ass jacker',
  'ass lick',
  'ass licker',
  'ass monkey',
  'ass munch',
  'ass muncher',
  'ass pirate',
  'ass sucker',
  'ass to mouth',
  'ass wad',
  'ass wipe',
  'assbag',
  'ass-bag',
  'assbandit',
  'ass-bandit',
  'assbanger',
  'ass-banger',
  'assbite',
  'ass-bite',
  'assclown',
  'ass-clown',
  'asscock',
  'ass-cock',
  'asscracker',
  'ass-cracker',
  'assface',
  'ass-face',
  'assfuck',
  'ass-fuck',
  'assfucker',
  'ass-fucker',
  'assgoblin',
  'ass-goblin',
  'cunt',
  '🤬',
  '🖕',
  '🖕🏻',
  '🖕🏼',
  '🖕🏽',
  '🖕🏾',
  '🖕🏿',
  '🇺🇸+👎',
  '🇺🇸+🖕',
  '🇺🇸+🤮',
  '🇺🇸+💀',
  '🇺🇸+☠',
  '🇺🇸+🤡',
  '🇺🇸+🙅',
  '🇺🇸+🙅',
  '🇺🇸+🙅',
  '🇺🇸+🚫',
  '🇺🇸+🗑',
  '🇺🇸+🤢',
  '🇺🇸+❌',
  '🇺🇸+🔪',
  '🇺🇸+🔫',
  '🇺🇸+⚰',
  '🇺🇸+⬇',
];

export const useCheckBlockPhrase = (value: string): boolean => {
  const [isAllowed, setIsAllowed] = React.useState(true);

  const regexList = React.useMemo(() => list.join('|'), []);

  React.useEffect(() => {
    setIsAllowed(!new RegExp(regexList, 'gi').test(value.toLocaleLowerCase()));
  }, [regexList, value]);
  return isAllowed;
};
