import { FC } from 'react';

import { TeamType } from '../../../bracket.types';
import { useGameHook } from '../../../hooks/useGame.hook';
import { Analysis } from '../../Analysis';

export const AnalysisContent: FC<{ team_1_id: number; team_2_id: number }> = ({
  team_1_id,
  team_2_id,
}) => {
  const { teams } = useGameHook();

  const team1 = teams.find((_) => _.team_id === team_1_id) as TeamType;
  const team2 = teams.find((_) => _.team_id === team_2_id) as TeamType;

  return (
    <>
      <Analysis {...{ team1, team2 }} />
    </>
  );
};
