import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

import { selectBracket } from '../../../bracket.slice';
import { takeDivision } from '../../../helpers/takeDivisionByMatchId';
import { useBracketSettings } from '../../../hooks/useBracketSettings';
import { useFirstRound } from '../../../hooks/useFirstRound.hook';

export const useBonusAnswer = (matchId: number) => {
  const { isPreRegistrationPage } = useBracketSettings();
  const { pathname } = useLocation();
  const { entry, entrySC, seriesResults } = useSelector(selectBracket);
  const { roundOne, roundTwo } = useFirstRound();

  const isSecondChancePage = pathname === bracketsSecondChancePath();

  const currentSeriesResult = useMemo(
    () => seriesResults.find((_) => _.id === matchId),
    [matchId, seriesResults],
  );

  const selectedTeamId = isSecondChancePage
    ? Number(entrySC?.[`match_${matchId}_pick`])
    : Number(entry?.[`match_${matchId}_pick`]);

  const isFirstRoundMatches = roundOne.map((_) => _.id).includes(matchId);
  const isSecondRoundMatches = roundTwo.map((_) => _.id).includes(matchId);
  const isRoundMatches = isSecondChancePage
    ? isSecondRoundMatches
    : isFirstRoundMatches;

  const isBonusAnswered = isSecondChancePage
    ? Number(entrySC?.[`match_${matchId}_match_played`]) > 0
    : Number(entry?.[`match_${matchId}_match_played`]) > 0;

  const isBonusAnswerVisible =
    isRoundMatches && isBonusAnswered && !isPreRegistrationPage;

  const bonusAnswer = isSecondChancePage
    ? Number(entrySC?.[`match_${matchId}_match_played`])
    : Number(entry?.[`match_${matchId}_match_played`]);

  const isScored = currentSeriesResult?.is_scored;
  const isCorrect =
    isScored &&
    Number(currentSeriesResult?.team_1_wins) +
      Number(currentSeriesResult?.team_2_wins) ===
      bonusAnswer &&
    currentSeriesResult?.winner_id === selectedTeamId;

  const position = takeDivision(matchId) === 'east' ? 'right' : 'left';

  return {
    isBonusAnswerVisible,
    isCorrect,
    isScored,
    bonusAnswer,
    position,
  };
};
