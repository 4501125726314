import { FC } from 'react';

import { Flex } from '@chakra-ui/react';

import { SeriesResultsType } from '../../bracket.types';

import { ViewTeam } from './Team/ViewTeam';

type ViewMatchProps = SeriesResultsType & { aspectRatio?: string };

export const ViewMatch: FC<ViewMatchProps> = ({
  id: matchId,
  is_scored,
  team_1_id,
  team_2_id,
  winner_id,
  aspectRatio,
  round_sequence,
}) => {
  return (
    <Flex
      align='center'
      direction='column'
      h='100%'
      position='relative'
      w='100%'
    >
      <ViewTeam
        id={team_1_id}
        {...{
          aspectRatio,
          is_scored,
          position: 1,
          matchId,
          winner_id,
          round_sequence,
        }}
      />
      <ViewTeam
        id={team_2_id}
        {...{
          aspectRatio,
          is_scored,
          position: 2,
          matchId,
          winner_id,
          round_sequence,
        }}
      />
    </Flex>
  );
};
