import { GuideLayout } from '@/components/GuideLayout';
import { OfficialRules } from '@/components/OfficialRules';

const RulesPage = () => (
  <GuideLayout>
    <OfficialRules />
  </GuideLayout>
);

export default RulesPage;
