import { createApi } from '@reduxjs/toolkit/query/react';

import { API_AUTH_URL, AUTH_APP_CODE } from '@/core/env';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import { LoginReqBody, LoginResponse } from '@/types/domain';

import { baseQueryWithRedirect } from '@/utils/api.util';

export const api = createApi({
  reducerPath: 'mainApi',
  baseQuery: baseQueryWithRedirect,
  tagTypes: Object.values(ApiTagsEnum),
  endpoints: (builder) => ({
    getJWT: builder.mutation<LoginResponse, LoginReqBody>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    getSSO: builder.query<void, void>({
      query: () => ({
        url: `${API_AUTH_URL}/sso/nhl?appCode=${AUTH_APP_CODE}`,
      }),
    }),
    checkIsJWTValid: builder.mutation<{ valid: boolean }, void>({
      query: () => ({
        url: '/is-valid',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLazyGetSSOQuery,
  useGetJWTMutation,
  useCheckIsJWTValidMutation,
} = api;
