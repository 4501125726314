import React, { useCallback, useMemo } from 'react';

import { Button, Flex, IconButton, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppSelector } from '@/store';

import DownloadIcon from '@/assets/icons/download.svg';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useGameDate } from '@/hooks/useGameDate';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { editSCPath } from '@/modules/Bracket/bracket.helpers';
import { selectBracket } from '@/modules/Bracket/bracket.slice';
import { BracketMode } from '@/modules/Bracket/brackets.enums';
import { usePathToBracket } from '@/modules/Bracket/hooks/usePathToBracket';

import { createPath } from '@/routes/types';

import { BracketsDropdown } from '../ViewModeDesktopHeader/BracketsDropdown';

import { MobileHeaderWrapper } from './MobileHeaderWrapper';

export const ViewModeMobileHeader: React.FC<{
  handleDownload(): void;
}> = ({ handleDownload }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { bracketPathById } = usePathToBracket();
  const { isOver, isSecondChanceOver } = useGameDate();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, entry, entrySC } = useAppSelector(selectBracket);

  const isSecondChancePage = useMemo(
    () => pathname === bracketsSecondChancePath(),
    [pathname],
  );
  const renderBracketName = useCallback(() => {
    if (isSecondChancePage) {
      return <Text>{entrySC.entry_name}</Text>;
    } else {
      if (user && user.user_id == entry.user_id) {
        return <BracketsDropdown />;
      } else {
        return <Text>{entry.entry_name}</Text>;
      }
    }
  }, [
    entry.entry_name,
    entry.user_id,
    entrySC.entry_name,
    isSecondChancePage,
    user,
  ]);

  const isBracketOver = useMemo(
    () => (isSecondChancePage ? isSecondChanceOver : isOver),
    [isOver, isSecondChanceOver, isSecondChancePage],
  );

  const isDownloadButtonVisible = useMemo(
    () =>
      isSecondChancePage
        ? user && Number(user.user_id) === Number(entrySC.user_id)
        : user && Number(user.user_id) === Number(entry.user_id),
    [entry.user_id, entrySC.user_id, isSecondChancePage, user],
  );

  const renderBtn = () => {
    if (!isBracketOver && Number(user?.user_id) !== Number(entrySC?.user_id))
      return null;
    else {
      return (
        <Flex alignItems='center' gap='16px'>
          <Button
            fontSize='16px'
            gap='8px'
            isDisabled={isSecondChancePage ? isSecondChanceOver : isOver}
            minW='94px'
            onClick={() =>
              navigate(isSecondChancePage ? editSCPath() : bracketPathById(id))
            }
            size='sm'
            variant='outline'
          >
            {t(tKeys.edit)}
            {/*<EditIcon />*/}
            <svg
              fill='none'
              height='24'
              viewBox='0 0 25 24'
              width='25'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.22656 17.2501V21.0001H6.97656L18.0366 9.94006L14.2866 6.19006L3.22656 17.2501ZM20.9366 7.04006C21.3266 6.65006 21.3266 6.02006 20.9366 5.63006L18.5966 3.29006C18.2066 2.90006 17.5766 2.90006 17.1866 3.29006L15.3566 5.12006L19.1066 8.87006L20.9366 7.04006Z'
                fill='white'
              />
            </svg>
          </Button>
          {isDownloadButtonVisible && (
            <IconButton
              aria-label='download'
              icon={<Image alt='' src={DownloadIcon} />}
              mr='8px'
              onClick={handleDownload}
              variant='unstyled'
            />
          )}
        </Flex>
      );
    }
  };

  return (
    <>
      <MobileHeaderWrapper>
        {renderBracketName()}

        {renderBtn()}
      </MobileHeaderWrapper>
    </>
  );
};
