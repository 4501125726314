import React from 'react';

import { Box, Container, Flex, Grid, GridItem, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import AdsBorderLeftImage from '@/assets/images/ads-border-left.png';
import AdsBorderRightImage from '@/assets/images/ads-border-right.png';
import AdsTopLeftImage from '@/assets/images/ads-top-left.png';
import AdsTopRightImage from '@/assets/images/ads-top-right.png';
import BgDesktopImage from '@/assets/images/bg-desktop.jpg';

import AdsBanner from '@/components/AdsBanner';
import { Footer } from '@/components/Layout/Footer';
import { Navigation } from '@/components/Navigation';

import { RoutesEnum } from '@/enums/routes.enum';

import { useLang } from '@/hooks/useLang';

import { useBracketSettings } from '@/modules/Bracket/hooks/useBracketSettings';
import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';

import { addIdToRoute } from '@/routes/utils';

export const WithBannersLayout: React.FC = () => {
  // Hide top navigation for /edit bracket page
  const { id } = useParams();
  const { pathname } = useLocation();
  const { lang } = useLang();

  const leagueData = useSelector(getLeagueData);
  const isLeagueManagePage =
    pathname === `/${lang}/${addIdToRoute(RoutesEnum.leagueManage, id)}`;
  const isRootPage = pathname === RoutesEnum.root;

  const renderBgAd = () => {
    if (isLeagueManagePage) {
      if (leagueData) {
        return <AdsBanner slotId='skin-ad' slotSize={[1680, 900]} />;
      }
      return null;
    } else {
      return <AdsBanner slotId='skin-ad' slotSize={[1680, 900]} />;
    }
  };
  const renderLogoAd = () => {
    if (isLeagueManagePage) {
      if (leagueData) {
        return (
          <AdsBanner
            h='138px'
            justifyContent='flex-start'
            slotId='main-logo-ad'
            slotSize={[331, 138]}
            w='100%'
          />
        );
      }
      return null;
    } else {
      return (
        <AdsBanner
          h='138px'
          justifyContent='flex-start'
          slotId='main-logo-ad'
          slotSize={[331, 138]}
          w='100%'
        />
      );
    }
  };
  return (
    <Flex
      bg={`url(${BgDesktopImage})`}
      bgAttachment='fixed'
      bgPosition='center'
      bgRepeat='no-repeat'
      bgSize='cover'
      justifyContent='center'
      minH='100vh'
      position='relative'
      w='100%'
    >
      <Box
        height='900px'
        left='0'
        margin='0 auto'
        overflow='hidden'
        position='absolute'
        top='0'
        width='100vw'
        zIndex='1'
      >
        {!isRootPage && renderBgAd()}
      </Box>
      <Box
        height='138px'
        left='50%'
        overflow='hidden'
        position='absolute'
        top='15px'
        transform='translate(-50%, 0%)'
        width='950px'
        zIndex='2'
      >
        {!isRootPage && renderLogoAd()}
      </Box>

      <Container
        alignItems='center'
        bg={`url(${BgDesktopImage})`}
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='flex-start'
        marginTop={170}
        maxW='990px'
        minH='730px'
        position='relative'
        w='100%'
        zIndex='2'
      >
        <Navigation />
        <Outlet />
      </Container>
    </Flex>
  );
};
