import { FC } from 'react';

import { Flex, Image, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

import colors from '@/theme/foundations/colors';

import { onImageError } from '@/utils/onImageError.util';

import { useGameHook } from '../../hooks/useGame.hook';
import { useQuestionMatch } from '../../hooks/useQuestionMatch.hook';

export const SquareTeamCard: FC<{
  id: number | null;
  position: number;
  matchId: number;
}> = ({ id, position, matchId }) => {
  const { teams } = useGameHook();
  const { pathname } = useLocation();

  const { selectedTeam } = useQuestionMatch(matchId);

  const currentTeam = teams.find((_) => _.team_id === id);

  const size = ['96px', '115px'];

  // if currentTeam === undefined (could be if not all teams was added on BE) then return empty box
  if (!currentTeam)
    return (
      <Flex
        align='center'
        bg={colors.primaryDarkGradient}
        color={'#FFFFFF'}
        h={size}
        justify='center'
        position='relative'
        w={size}
      ></Flex>
    );

  const { abbreviation, display_seed, hex_code, team_logo } = currentTeam;

  // TODO change style for left and right col.
  const isLeftCol =
    pathname === bracketsSecondChancePath()
      ? [201, 202].includes(matchId)
      : [101, 102, 103, 104].includes(matchId);

  const isSelected = Number(selectedTeam?.team_id) === id;

  return (
    <Flex
      align='center'
      bg={hex_code ? `#${hex_code}` : colors.primaryDarkGradient}
      color={'#FFFFFF'}
      h={size}
      justify='center'
      opacity={isSelected ? 1 : 0.2}
      position='relative'
      w={size}
    >
      <Text
        bottom={position === 1 ? 'auto' : 0}
        fontFamily='Averta, sans-serif'
        fontSize='11px'
        fontWeight={700}
        left={isLeftCol ? 0 : 'auto'}
        letterSpacing='0em'
        lineHeight='14px'
        opacity='0.85'
        position='absolute'
        right={!isLeftCol ? 0 : 'auto'}
        top={position === 1 ? 0 : 'auto'}
      >
        {display_seed}
      </Text>

      {!!team_logo && <Image onError={onImageError} p='10px' src={team_logo} />}

      <Text
        bottom={position === 1 ? 0 : 'auto'}
        fontFamily='Antonio, sans-serif'
        fontSize='17px'
        fontWeight='700'
        letterSpacing='0em'
        lineHeight='22px'
        position='absolute'
        right={0}
        top={position === 2 ? 0 : 'auto'}
      >
        {abbreviation}
      </Text>
    </Flex>
  );
};
