import { FC, PropsWithChildren } from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';

import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow-right.svg';

export const RightArrowButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <Button rightIcon={<ArrowRightIcon />} {...props}>
    {children}
  </Button>
);
