import { FC, useState } from 'react';

import { Button, Collapse, Flex, Text, useTheme } from '@chakra-ui/react';

import { RoutesEnum } from '@/enums/routes.enum';

import { NavigationLinkType } from '@/hooks/useNavigation';

interface NavItemProps extends NavigationLinkType {
  handleLink?(to: RoutesEnum): void;
  onClick?(): void;
}

const NavLink: FC<NavItemProps> = ({
  label,
  selected,
  to,
  handleLink,
  renderIcon,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Button
      alignItems='center'
      bgColor={selected ? 'dionisBlue' : 'transparent'}
      borderRadius='8px'
      display='flex'
      gap='12px'
      h='auto'
      justifyContent='flex-start'
      key={`${to}-${Date.now()}`}
      minH='auto'
      onClick={to ? () => handleLink?.(to as RoutesEnum) : onClick}
      p='12px'
      variant='unstyled'
    >
      {renderIcon?.({
        fill: selected ? 'white' : theme.colors.ignisGrey,
      })}
      <Text
        color={selected ? 'white' : 'ignisGrey'}
        fontSize='16px'
        fontWeight='600'
      >
        {label}
      </Text>
    </Button>
  );
};

export const NavItem: FC<NavItemProps> = ({ items, ...linkProps }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen((prev) => !prev);

  if (items) {
    return (
      <>
        <NavLink onClick={handleOpen} {...linkProps} />
        <Collapse animateOpacity in={isOpen} style={{ minHeight: '90px' }}>
          <Flex direction='column' pl='36px'>
            {items.map((item) => (
              <NavLink
                handleLink={linkProps.handleLink}
                key={item.label}
                {...item}
              />
            ))}
          </Flex>
        </Collapse>
      </>
    );
  }

  return <NavLink {...linkProps} />;
};
