import { IBracketEntry, SecondChanceEntryType } from '../bracket.types';

const getConditionsForSubmit = (
  entry: IBracketEntry,
): { [key: number]: boolean } => {
  const {
    match_101_pick,
    match_102_pick,
    match_103_pick,
    match_104_pick,
    match_101_match_played,
    match_102_match_played,
    match_103_match_played,
    match_104_match_played,
    match_105_pick,
    match_106_pick,
    match_107_pick,
    match_108_pick,
    match_105_match_played,
    match_106_match_played,
    match_107_match_played,
    match_108_match_played,
    match_201_pick,
    match_202_pick,
    match_203_pick,
    match_204_pick,
    match_301_pick,
    match_302_pick,
    match_401_pick,
    tiebreaker,
  } = entry;

  return {
    1: match_101_pick > 0,
    2: match_102_pick > 0,
    3: match_103_pick > 0,
    4: match_104_pick > 0,
    5: match_105_pick > 0,
    6: match_106_pick > 0,
    7: match_107_pick > 0,
    8: match_108_pick > 0,
    9:
      match_101_match_played > 0 &&
      match_102_match_played > 0 &&
      match_103_match_played > 0 &&
      match_104_match_played > 0,
    10:
      match_105_match_played > 0 &&
      match_106_match_played > 0 &&
      match_107_match_played > 0 &&
      match_108_match_played > 0,
    11: match_201_pick > 0,
    12: match_202_pick > 0,
    13: match_203_pick > 0,
    14: match_204_pick > 0,
    15: match_301_pick > 0,
    16: match_302_pick > 0,
    17: match_401_pick > 0,
    18: Boolean(tiebreaker),
  };
};

const getConditionsForSubmitSC = (
  entry: SecondChanceEntryType,
): { [key: number]: boolean } => {
  const {
    match_201_pick,
    match_202_pick,
    match_203_pick,
    match_204_pick,
    match_301_pick,
    match_302_pick,
    match_401_pick,
    match_201_match_played,
    match_202_match_played,
    match_203_match_played,
    match_204_match_played,
    tiebreaker,
  } = entry;

  return {
    1: match_201_pick > 0,
    2: match_202_pick > 0,
    3: match_203_pick > 0,
    4: match_204_pick > 0,
    5: match_301_pick > 0,
    6: match_302_pick > 0,
    7: match_401_pick > 0,
    8: match_201_match_played > 0 && match_202_match_played > 0,
    9: match_203_match_played > 0 && match_204_match_played > 0,
    10: Boolean(tiebreaker),
  };
};

export const checkAllDataFilled = (entry: IBracketEntry) => {
  const conditions = getConditionsForSubmit(entry);

  return Object.values(conditions).every((condition) => condition);
};

export const checkAllDataFilledSC = (entry: SecondChanceEntryType) => {
  const conditions = getConditionsForSubmitSC(entry);

  return Object.values(conditions).every((condition) => condition);
};
