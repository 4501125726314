import { FC, useMemo } from 'react';

import { Flex, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import {
  getBonusPtsByMatchId,
  getBonusPtsByMatchIdSC,
  getMainPtsByMatchId,
} from '@/modules/Bracket/bracket.helpers';

import { isExist } from '@/utils/common.util';

import { selectBracket } from '../../bracket.slice';
import { SeriesResultsType } from '../../bracket.types';
import { BracketMode } from '../../brackets.enums';
import { takeDivision } from '../../helpers/takeDivisionByMatchId';

import { AnalysisButton } from './AnalysisButton';
import { BonusAnswer } from './BonusAnswer';
import { useBonusAnswer } from './BonusAnswer/useBonusAnswer';
import { Team } from './Team';

export const Match: FC<SeriesResultsType> = ({
  id: matchId,
  is_scored,
  team_1_id,
  team_2_id,
  winner_id,
  round_sequence,
}) => {
  const { pathname } = useLocation();
  const { isBonusAnswerVisible, isCorrect } = useBonusAnswer(matchId);
  const { entry, entrySC, bracketMode } = useAppSelector(selectBracket);

  const isSecondChancePage = pathname === bracketsSecondChancePath();

  const selectedTeamId = isSecondChancePage
    ? Number(entrySC[`match_${matchId}_pick`])
    : Number(entry[`match_${matchId}_pick`]);

  const position = takeDivision(matchId) === 'east' ? 'right' : 'left';

  const mainPts = useMemo(
    () => getMainPtsByMatchId(matchId, selectedTeamId === winner_id),
    [matchId, selectedTeamId, winner_id],
  );
  const bonusPts = useMemo(
    () =>
      isSecondChancePage
        ? getBonusPtsByMatchIdSC(matchId, !!isCorrect)
        : getBonusPtsByMatchId(matchId, !!isCorrect),
    [isCorrect, isSecondChancePage, matchId],
  );

  const isAnalysisButtonVisible = !!team_1_id && !!team_2_id;

  return (
    <>
      <Flex
        align='center'
        direction='column'
        h={'100%'}
        id={`match_${matchId}`} // uses for onboarding overlay
        position='relative'
        w='100%'
      >
        {/* Team A */}
        <Team
          id={team_1_id}
          {...{
            is_scored,
            position: 1,
            matchId,
            winner_id,
            round_sequence,
          }}
        />

        {/* Points | AnalysisButton */}
        {is_scored && bracketMode === BracketMode.view ? (
          <Flex
            align='center'
            bgColor='white'
            fontFamily='AvertaStd-Bold, sans-serif'
            fontWeight='bold'
            gap={'5px'}
            h='25px'
            justify={isExist(bonusPts) ? 'space-between' : 'center'}
            paddingLeft={
              isExist(bonusPts) && position === 'left' ? '30px' : '5px'
            }
            paddingRight={
              isExist(bonusPts) && position === 'right' ? '30px' : '5px'
            }
            py='5px'
            w='100%'
          >
            {isExist(bonusPts) ? (
              <>
                <Text color='#071520' fontSize='9px' whiteSpace='nowrap'>
                  {position === 'right' ? mainPts : bonusPts} PTS
                </Text>
                <Text color='#071520' fontSize='9px' whiteSpace='nowrap'>
                  {position === 'left' ? mainPts : bonusPts} PTS
                </Text>
              </>
            ) : (
              <Text color='#071520' fontSize='9px' whiteSpace='nowrap'>
                {mainPts} PTS
              </Text>
            )}
          </Flex>
        ) : isAnalysisButtonVisible ? (
          <AnalysisButton
            {...{ team_1_id: Number(team_1_id), team_2_id: Number(team_2_id) }}
          />
        ) : null}

        {/* Team B */}
        <Team
          id={team_2_id}
          {...{
            is_scored,
            position: 2,
            matchId,
            winner_id,
            round_sequence,
          }}
        />

        {/* Bonus answers */}
        {isBonusAnswerVisible && (
          <BonusAnswer
            {...{
              matchId,
              position,
              winnerScorePosition:
                selectedTeamId === team_1_id ? 'top' : 'bottom',
            }}
          />
        )}
      </Flex>
    </>
  );
};
