import { FC } from 'react';

import { Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { TeamType } from '../../bracket.types';

import { StatisticRow } from './StatisticRow';

export const Analysis: FC<{ team1: TeamType; team2: TeamType }> = ({
  team1,
  team2,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        alignItems='center'
        gap={'20px'}
        gridTemplateColumns='1fr 60px 1fr'
        py={['10px']}
        w={['100%']}
      >
        <Flex align='center'>
          <Image h={['40px', '90px']} src={team1.team_logo} />
          <Flex direction='column'>
            <Text
              fontFamily='Antonio'
              fontSize={['10px', '20px']}
              fontWeight={600}
            >
              {team1.city}
            </Text>
            <Text
              fontFamily='Antonio'
              fontSize={['10px', '26px']}
              fontWeight={600}
              textTransform='uppercase'
            >
              {team1.nickname}
            </Text>
            <Text
              fontFamily='Antonio'
              fontSize={['10px', '14px']}
              fontWeight={600}
            >
              {team1.season_standings}
            </Text>
          </Flex>
        </Flex>
        <GridItem
          alignItems='center'
          bgColor='#00000066'
          borderRadius='50%'
          display='flex'
          fontFamily='Antonio'
          fontWeight={700}
          h='40px'
          justifyContent='center'
          textTransform='uppercase'
          w='40px'
        >
          {t(tKeys.vs)}
        </GridItem>
        <Flex align='center' justify='flex-end'>
          <Flex align='end' direction='column'>
            <Text
              fontFamily='Antonio'
              fontSize={['10px', '20px']}
              fontWeight={600}
            >
              {team2.city}
            </Text>
            <Text
              fontFamily='Antonio'
              fontSize={['10px', '26px']}
              fontWeight={600}
              textTransform='uppercase'
              textAlign={'right'}
            >
              {team2.nickname}
            </Text>
            <Text
              fontFamily='Averta'
              fontSize={['10px', '14px']}
              fontWeight={600}
            >
              {team2.season_standings}
            </Text>
          </Flex>
          <Image h={['40px', '90px']} src={team2.team_logo} />
        </Flex>
      </Grid>

      <Grid bgColor='#2222224D' gap='10px' p={['8px', '20px']} w='100%'>
        <StatisticRow
          property={'points'}
          title={t(tKeys.seasonPoints)}
          {...{ team1, team2 }}
        />
        <StatisticRow
          property={'goals_per_game'}
          title={t(tKeys.goalsPerGame)}
          {...{ team1, team2 }}
        />
        <StatisticRow
          property={'goals_against_per_game'}
          title={t(tKeys.goalsAgainstPerGame)}
          {...{ team1, team2 }}
        />
        <StatisticRow
          property={'power_play_pct'}
          title={t(tKeys.powerPlayPct)}
          {...{ team1, team2 }}
        />
        <StatisticRow
          property={'penalty_kill_pct'}
          title={t(tKeys.penaltyKillPct)}
          {...{ team1, team2 }}
        />

        <Grid gridTemplateColumns='1fr 2fr 1fr' mt={'0px'}>
          <Flex align='center' direction='column'>
            <Image
              bg='#1a2d3a'
              border='2px solid #1a2d3a'
              borderRadius='50%'
              h='48px'
              src={team1.leading_scorer_avatar}
              w='48px'
            />
            <Text
              fontWeight='600'
              fontSize={['12px', '20px']}
              textAlign='center'
            >
              {team1.leading_scorer}
            </Text>
            <Text
              fontWeight='600'
              fontSize={['12px', '20px']}
              textAlign='center'
            >
              {team1.leading_scorer_points}
            </Text>
          </Flex>
          <GridItem
            alignItems='center'
            display='flex'
            justifyContent='center'
            textAlign='center'
          >
            {t(tKeys.leadingScorer)}
          </GridItem>
          <Flex align='center' direction='column'>
            <Image
              bg='#1a2d3a'
              border='2px solid #1a2d3a'
              borderRadius='50%'
              h='48px'
              src={team2.leading_scorer_avatar}
              w='48px'
            />
            <Text
              fontWeight='600'
              fontSize={['12px', '20px']}
              textAlign='center'
            >
              {team2.leading_scorer}
            </Text>
            <Text
              fontWeight='600'
              fontSize={['12px', '20px']}
              textAlign='center'
            >
              {team2.leading_scorer_points}
            </Text>
          </Flex>
        </Grid>
      </Grid>
    </>
  );
};
