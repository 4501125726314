import { RoutesEnum } from '@/enums/routes.enum';

import { createPath } from '@/routes/types';

export const MAX_BRACKET_SELECTIONS_AMOUNT = 18;
export const MAX_SC_BRACKET_SELECTIONS_AMOUNT = 10;

export const bracketsNewPath = () =>
  createPath({
    path: RoutesEnum.bracketsNew,
  });
export const bracketsSecondChancePath = () =>
  createPath({
    path: RoutesEnum.bracketsSecondChance,
  });
export const bracketsPreRegistrationPath = () =>
  createPath({
    path: RoutesEnum.bracketsPreRegistration,
  });
