import { FC, PropsWithChildren } from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';

import { ReactComponent as PlusIcon } from '@/assets/icons/circle-add-outline.svg';

export const RightPlusButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <Button rightIcon={<PlusIcon />} variant='outline' {...props}>
    {children}
  </Button>
);
