import { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLang } from '@/hooks/useLang';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSettings } from '@/hooks/useSettings';

import {
  bracketsNewPath,
  bracketsSecondChancePath,
} from '@/modules/Bracket/bracket.constants';

import { createPath } from '@/routes/types';
import { isPathEqualsToPathname } from '@/routes/utils';

import { checkIsDateInPastOrNow } from '@/utils/checkIsDateInPastOrNow';

import { selectBracket } from '../bracket.slice';
import { BracketMode } from '../brackets.enums';

export const useBracketSettings = () => {
  const { pathname } = useLocation();
  const { lang } = useLang();
  const navigate = useNavigate();
  const { isMobile } = useSettings();
  const { value: onboardingComplete } = useLocalStorage(
    StorageKeysEnum.onboardingComplete,
  );
  const isPreRegistrationPage = useMemo(
    () => createPath({ path: RoutesEnum.bracketsPreRegistration }) === pathname,
    [pathname],
  );

  const { game, bracketMode, onboardingStep } = useSelector(selectBracket);
  const allowPicksFrom = useMemo(() => game.allow_picks_from, [game]);

  useEffect(() => {
    if (allowPicksFrom) {
      const isInFuture = !checkIsDateInPastOrNow(allowPicksFrom);
      if (isInFuture) {
        // TODO Need fix
        // navigate(RoutesEnum.bracketsPreRegistration);
      }
    }
  }, [allowPicksFrom, navigate]);

  const isAppNavigationHide = useMemo(
    () =>
      bracketMode === BracketMode.edit ||
      pathname === bracketsNewPath() ||
      isMobile,
    [bracketMode, isMobile, pathname],
  );
  // Active only for new bracket if step 1 OR 2, if LS empty
  const isOnboardingActive = useMemo(
    () =>
      (onboardingStep === 1 || onboardingStep === 2) &&
      !onboardingComplete &&
      pathname === createPath({ path: RoutesEnum.bracketsNew }),
    [onboardingComplete, onboardingStep, pathname],
  );
  return {
    isPreRegistrationPage,
    isAppNavigationHide,
    isOnboardingActive,
    onboardingComplete,
  };
};
