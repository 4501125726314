import { Box } from '@chakra-ui/react';

import { GuideLayout } from '@/components/GuideLayout';

const PrizesPage = () => {
  return (
    <GuideLayout>
      <Box bg='rgba(1, 2, 3, 0.50)' p='24px'>
        Lorem ipsum dolor sit amet consectetur. Mi nunc commodo et tincidunt
        nisl quis tellus ante. Viverra enim viverra eleifend diam amet porttitor
        eu pellentesque sed. Elementum neque orci quis eu ac. Dolor quis commodo
        turpis pellentesque sit. Quis quis sit aliquet dignissim viverra tortor.
        Nunc fermentum dictum urna in cum. Ac risus nisl tristique elit
        facilisis id non risus mattis. Tempor dignissim platea enim facilisis at
        enim mattis aliquam lorem. Eget id odio massa id nascetur lectus
        praesent. Et iaculis dui in libero. Fringilla fames in turpis turpis
        augue tempus proin eu. Lobortis fermentum ultrices vitae leo. Purus elit
        in justo felis fusce. Pellentesque sagittis auctor non proin ac massa
        egestas eget.
      </Box>
    </GuideLayout>
  );
};

export default PrizesPage;
