import { ReactNode } from 'react';

import { enabledLanguages } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

export interface IRoutes {
  path: RoutesEnum;
  element: ReactNode;
  children?: IRoutes[];
}

type TArgsWithParams = { path: RoutesEnum | string; params?: any };

export const createPath = (args: TArgsWithParams): string => {
  const basePath = window.location.pathname.split('/')[1]; // This needs to leave path till lang param
  const isValidLang = enabledLanguages.includes(
    basePath as (typeof enabledLanguages)[0],
  );

  const fullPath = isValidLang ? `/${basePath}/${args.path}` : args.path;

  if (!args.hasOwnProperty('params')) return fullPath;

  return Object.entries((args as TArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) =>
      previousValue.replace(`:${param}`, '' + value),
    fullPath,
  );
};
