import { useMemo } from 'react';

import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

import { nhlThinScrollbarStyles } from '@/utils/common.util';

import { selectBracket } from '../../bracket.slice';
import { useFirstRound } from '../../hooks/useFirstRound.hook';

import { QuestionMatchBlock } from './QuestionMatchBlock';

export const FirstRoundContent = () => {
  const { pathname } = useLocation();
  const { bonusVisibility } = useAppSelector(selectBracket);

  const { roundOneWest, roundOneEast, roundTwoEast, roundTwoWest } =
    useFirstRound();

  const dataToShow = useMemo(() => {
    if (pathname === bracketsSecondChancePath()) {
      if (bonusVisibility.west) return roundTwoWest;
      if (bonusVisibility.east) return roundTwoEast;
      return [];
    } else {
      if (bonusVisibility.west) return roundOneWest;
      if (bonusVisibility.east) return roundOneEast;
      return [];
    }
  }, [
    bonusVisibility.east,
    bonusVisibility.west,
    pathname,
    roundOneEast,
    roundOneWest,
    roundTwoEast,
    roundTwoWest,
  ]);

  return (
    <Flex
      flexWrap='wrap'
      gap={'20px'}
      justify='center'
      sx={nhlThinScrollbarStyles}
    >
      {dataToShow.map((match) => {
        return <QuestionMatchBlock key={match.id} {...match} />;
      })}
    </Flex>
  );
};
