import { Flex } from '@chakra-ui/react';

import { ClearButton } from './ClearButton';
import { EditBracketNameBlock } from './EditBracketNameBlock';
import { ExitEditModeButton } from './ExitEditModeButton';
import { RandomPicksButton } from './RandomPicksButton';
import { SubmitButton } from './SubmitButton';

export const EditModeDesktopHeader = () => {
  return (
    <>
      <Flex
        align='center'
        bgColor='#192E3B80'
        gap='8px'
        h='80px'
        justify={'space-between'}
        px={{ base: '8px', lg: '40px' }}
        w={'100%'}
      >
        <EditBracketNameBlock />

        <Flex gap={{ base: '8px', lg: '10px' }}>
          <RandomPicksButton h='52px' px={{ base: '12px', lg: '24px' }} />
          <ClearButton h='52px' px={{ base: '12px', lg: '24px' }} />
        </Flex>

        <Flex gap={{ base: '8px', lg: '10px' }}>
          <ExitEditModeButton h='52px' px={{ base: '12px', lg: '24px' }} />
          <SubmitButton h='52px' px={{ base: '12px', lg: '24px' }} />
        </Flex>
      </Flex>
    </>
  );
};
