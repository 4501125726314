import { FC, useMemo } from 'react';

import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import cupSrc from '@/assets/images/cup.png';

import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useGameDate } from '@/hooks/useGameDate';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

import { onImageError } from '@/utils/onImageError.util';

import { selectBracket } from '../../../bracket.slice';
import { SeriesResultsType, TeamType } from '../../../bracket.types';
import { BracketMode } from '../../../brackets.enums';
import { useGameHook } from '../../../hooks/useGame.hook';

export const Team: FC<{
  id: number | null;
  is_scored: boolean;
  position: number;
  matchId: number;
  winner_id: number | null;
  round_sequence: number;
}> = ({ id, is_scored, position, matchId, winner_id, round_sequence }) => {
  const { teams, updateEntry } = useGameHook();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { entry, entrySC, bracketMode, seriesResults } =
    useSelector(selectBracket);
  const { isSecondChanceLive } = useGameDate();

  const isSecondChancePage = pathname === bracketsSecondChancePath();

  const selectedTeamId = isSecondChancePage
    ? entrySC[`match_${matchId}_pick`]
    : entry[`match_${matchId}_pick`];

  const bracketViewType = searchParams.get(SearchParamsEnum.viewType) as
    | BracketMode
    | undefined;

  const currTeamInPrevScoredRound = seriesResults?.find(
    (x) =>
      x.round_sequence < round_sequence &&
      (id === x.team_1_id || id === x.team_2_id) &&
      x.winner_id &&
      x.winner_id !== id,
  );

  const currMatchInSeriesResults = seriesResults?.find(
    (x) => x.id === Number(matchId),
  );
  const isWinner = Boolean(id) && is_scored && winner_id === id;

  const isEliminated =
    (is_scored && winner_id !== id) || currTeamInPrevScoredRound;

  const currentTeam = teams.find((_) => _.team_id === id) || ({} as TeamType);

  const { abbreviation, display_seed, hex_code, team_logo } = currentTeam;

  const onMatchClickHandler = (team_id: number | null) => {
    if (!team_id) return;
    updateEntry({ matchId, teamId: team_id });
  };

  const currentMatch = seriesResults.find(
    (item) => item.id === matchId,
  ) as SeriesResultsType;

  /**
   *   Disable click for match if both team not selected
   *   AND if bracket in VIEW mode
   */
  const isClickable = useMemo(() => {
    if (
      pathname === bracketsSecondChancePath() &&
      isSecondChanceLive &&
      (bracketViewType ? bracketMode !== BracketMode.view : !bracketViewType)
    )
      return true;
    return (
      !!id &&
      currentMatch?.team_1_id !== null &&
      currentMatch?.team_2_id !== null &&
      bracketMode !== BracketMode.view
    );
  }, [
    bracketMode,
    bracketViewType,
    currentMatch?.team_1_id,
    currentMatch?.team_2_id,
    id,
    isSecondChanceLive,
    pathname,
  ]);

  const isLeftCol = [101, 102, 103, 104, 201, 202, 301].includes(matchId);
  const isRightCol = [105, 106, 107, 108, 203, 204, 302].includes(matchId);
  const isDisplaySeedVisible = matchId !== 401; // Hide display_seed for final UI

  const isSecondRoundMatch = [201, 202, 203, 204].includes(matchId);
  const isConferenceFinalMatch = [301, 302].includes(matchId);
  const isFinalMatch = matchId === 401;

  const isFinalEntryId = isSecondChancePage
    ? Number(entrySC.match_401_pick)
    : Number(entry.match_401_pick);
  const showWinnerCup = isFinalMatch && isFinalEntryId === id && id !== 0;

  const isLeadsSeriesVisible = useMemo(
    () => isSecondChancePage && [201, 202, 203, 204].includes(matchId),
    [isSecondChancePage, is_scored, matchId, winner_id],
  );
  const leadsSeries = useMemo(() => {
    if (
      currMatchInSeriesResults?.team_1_wins == '4' ||
      currMatchInSeriesResults?.team_2_wins == '4' ||
      (currMatchInSeriesResults?.team_1_wins == '' &&
        currMatchInSeriesResults?.team_2_wins == '')
    ) {
      return '';
    }
    const isTeam1 =
      currMatchInSeriesResults &&
      Number(currMatchInSeriesResults?.team_1_id) === id;
    const isTeam2 =
      currMatchInSeriesResults &&
      Number(currMatchInSeriesResults?.team_2_id) === id;
    if (
      isTeam1 &&
      Number(currMatchInSeriesResults?.team_1_wins) >
        Number(currMatchInSeriesResults?.team_2_wins)
    )
      return `Leads Series ${currMatchInSeriesResults?.team_1_wins}-${currMatchInSeriesResults?.team_2_wins}`;
    if (
      isTeam1 &&
      Number(currMatchInSeriesResults?.team_1_wins) ===
        Number(currMatchInSeriesResults?.team_2_wins)
    )
      return `Series Tied ${currMatchInSeriesResults?.team_1_wins}-${currMatchInSeriesResults?.team_2_wins}`;
    if (
      isTeam2 &&
      Number(currMatchInSeriesResults?.team_1_wins) <
        Number(currMatchInSeriesResults?.team_2_wins)
    )
      return `Leads Series ${currMatchInSeriesResults?.team_2_wins}-${currMatchInSeriesResults?.team_1_wins}`;
    if (
      isTeam2 &&
      Number(currMatchInSeriesResults?.team_1_wins) ===
        Number(currMatchInSeriesResults?.team_2_wins)
    )
      return `Series Tied ${currMatchInSeriesResults?.team_1_wins}-${currMatchInSeriesResults?.team_2_wins}`;
    return '';
  }, [currMatchInSeriesResults, id]);

  return (
    <>
      <Flex
        align='center'
        bg={
          hex_code
            ? `#${hex_code}`
            : position === 1
              ? 'primaryDarkGradient'
              : 'primaryLightGradient'
        }
        color={'#FFFFFF'}
        h={'20%'}
        id={`match_${matchId}_team_${position}`} // uses for OnboardingOverlay
        justify='center'
        minH={
          isConferenceFinalMatch || isFinalMatch
            ? '216px'
            : isSecondRoundMatch
              ? '177px'
              : '100px'
        }
        position='relative'
        w={'100%'}
        {...(isClickable
          ? {
              as: 'button',
              onClick: () => onMatchClickHandler(id),
            }
          : {})}
      >
        {isDisplaySeedVisible && (
          <Text
            bottom={position === 1 ? 'auto' : '3px'}
            fontFamily='Averta, sans-serif'
            fontSize='11px'
            fontWeight={700}
            left={isLeftCol ? '5px' : 'auto'}
            letterSpacing='0em'
            lineHeight='14px'
            opacity='0.85'
            position='absolute'
            right={!isLeftCol ? '5px' : 'auto'}
            top={position === 1 ? '3px' : 'auto'}
            whiteSpace='nowrap'
          >
            {display_seed}
          </Text>
        )}

        {!!team_logo && <Image onError={onImageError} src={team_logo} />}

        {/* Leading/tie series */}
        {isLeadsSeriesVisible && (
          <Text
            bottom={position === 1 ? '17px' : '0'}
            fontSize='11px'
            fontWeight='800'
            left={isRightCol ? '5px' : 'auto'}
            letterSpacing='0em'
            lineHeight='22px'
            position='absolute'
            right={isLeftCol ? '5px' : 'auto'}
            top={position === 2 ? '20px' : 'auto'}
            whiteSpace='nowrap'
          >
            {leadsSeries}
          </Text>
        )}
        {/* TEAM NAME */}
        <Text
          bottom={position === 1 ? '3px' : 'auto'}
          fontFamily='Antonio, sans-serif'
          fontSize='17px'
          fontWeight='700'
          left={isRightCol ? '5px' : 'auto'}
          letterSpacing='0em'
          lineHeight='22px'
          position='absolute'
          right={isLeftCol ? '5px' : 'auto'}
          top={position === 2 ? '3px' : 'auto'}
          whiteSpace='nowrap'
        >
          {abbreviation}
        </Text>

        {/* Cup image for final cell */}
        {showWinnerCup && (
          <Image
            h='40%'
            position='absolute'
            right='5px'
            src={cupSrc}
            top={'5px'}
          />
        )}

        {/* Correct/Incorrect Mark */}
        {is_scored && selectedTeamId == id && (
          <Box
            bottom={position === 1 ? '20px' : '3px'}
            left={isRightCol ? '3px' : 'auto'}
            position='absolute'
            right={isLeftCol ? '3px' : 'auto'}
            top={position === 2 ? 'unset' : '3px'}
            zIndex={2}
          >
            {isWinner ? <CorrectMark /> : <Incorrect />}
          </Box>
        )}

        {/* Black overlay */}
        {isEliminated && (
          <Box
            bg='#000000cc'
            h='100%'
            position='absolute'
            w='100%'
            zIndex={1}
          />
        )}
      </Flex>
    </>
  );
};

const CorrectMark = () => (
  <svg
    fill='none'
    height='22'
    viewBox='0 0 22 22'
    width='22'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_d_539_291504)'>
      <rect
        fill='#128B38'
        height='14.9525'
        rx='7.47627'
        stroke='#1FBF40'
        strokeWidth='1.39296'
        width='14.9525'
        x='1.88007'
        y='1.90156'
      />
      <path
        d='M12.1866 6.65633C12.3303 6.7841 12.3433 7.00421 12.2155 7.14796L8.50096 11.3268C8.43487 11.4012 8.34015 11.4437 8.24068 11.4437C8.14121 11.4437 8.04648 11.4012 7.9804 11.3268L6.12312 9.2374C5.99535 9.09365 6.00829 8.87354 6.15204 8.74576C6.29579 8.61799 6.5159 8.63094 6.64368 8.77468L8.24068 10.5713L11.695 6.68524C11.8227 6.5415 12.0428 6.52855 12.1866 6.65633Z'
        fill='white'
      />
      <path
        d='M12.1866 6.65633C12.3303 6.7841 12.3433 7.00421 12.2155 7.14796L8.50096 11.3268C8.43487 11.4012 8.34015 11.4437 8.24068 11.4437C8.14121 11.4437 8.04648 11.4012 7.9804 11.3268L6.12312 9.2374C5.99535 9.09365 6.00829 8.87354 6.15204 8.74576C6.29579 8.61799 6.5159 8.63094 6.64368 8.77468L8.24068 10.5713L11.695 6.68524C11.8227 6.5415 12.0428 6.52855 12.1866 6.65633Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.928639'
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
        height='21.1646'
        id='filter0_d_539_291504'
        width='21.1627'
        x='0.380437'
        y='0.401921'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx='1.60631' dy='1.60631' />
        <feGaussianBlur stdDeviation='1.20474' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          in2='BackgroundImageFix'
          mode='normal'
          result='effect1_dropShadow_539_291504'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_539_291504'
          mode='normal'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

const Incorrect = () => (
  <svg
    fill='none'
    height='22'
    viewBox='0 0 22 22'
    width='22'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_d_539_291541)'>
      <rect
        fill='#DE2626'
        height='14.9525'
        rx='7.47627'
        stroke='#F05858'
        strokeWidth='1.39296'
        width='14.9525'
        x='2.01289'
        y='2.19843'
      />
      <path
        d='M6.96918 6.96723C7.10518 6.83124 7.32567 6.83124 7.46167 6.96723L9.30487 8.81043L11.1481 6.96723C11.2841 6.83124 11.5045 6.83124 11.6405 6.96723C11.7765 7.10323 11.7765 7.32372 11.6405 7.45972L9.79735 9.30291L11.6405 11.1461C11.7765 11.2821 11.7765 11.5026 11.6405 11.6386C11.5045 11.7746 11.2841 11.7746 11.1481 11.6386L9.30487 9.7954L7.46167 11.6386C7.32567 11.7746 7.10518 11.7746 6.96918 11.6386C6.83319 11.5026 6.83319 11.2821 6.96918 11.1461L8.81238 9.30291L6.96918 7.45972C6.83319 7.32372 6.83319 7.10323 6.96918 6.96723Z'
        fill='white'
      />
      <path
        d='M6.96918 6.96723C7.10518 6.83124 7.32567 6.83124 7.46167 6.96723L9.30487 8.81043L11.1481 6.96723C11.2841 6.83124 11.5045 6.83124 11.6405 6.96723C11.7765 7.10323 11.7765 7.32372 11.6405 7.45972L9.79735 9.30291L11.6405 11.1461C11.7765 11.2821 11.7765 11.5026 11.6405 11.6386C11.5045 11.7746 11.2841 11.7746 11.1481 11.6386L9.30487 9.7954L7.46167 11.6386C7.32567 11.7746 7.10518 11.7746 6.96918 11.6386C6.83319 11.5026 6.83319 11.2821 6.96918 11.1461L8.81238 9.30291L6.96918 7.45972C6.83319 7.32372 6.83319 7.10323 6.96918 6.96723Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='0.928639'
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
        height='21.1646'
        id='filter0_d_539_291541'
        width='21.1627'
        x='0.513249'
        y='0.698796'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx='1.60631' dy='1.60631' />
        <feGaussianBlur stdDeviation='1.20474' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          in2='BackgroundImageFix'
          mode='normal'
          result='effect1_dropShadow_539_291541'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_539_291541'
          mode='normal'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
