import React from 'react';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { CircleQuestionIcon } from '@/assets/icon-components/CircleQuestion.icon';
import CloseSidebar from '@/assets/icons/close-sidebar.svg';

import { LangSwitcher } from '@/components/LangSwitcher';

import { RoutesEnum } from '@/enums/routes.enum';

import { useJWT } from '@/hooks/useJWT';

import { NavItem } from '@/modules/Sidebar/components/NavItem';
import { SidebarProps } from '@/modules/Sidebar/sidebar.types';

import { createPath } from '@/routes/types';

export const Sidebar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  handleCloseSidebar,
  isLangSwitcherEnabled,
  links,
  handleLinkClick,
}) => {
  const { t } = useTranslation();
  const { jwt, removeJWT } = useJWT();

  const navigate = useNavigate();
  const handleLogout = () => {
    removeJWT();

    navigate(createPath({ path: RoutesEnum.home }));
    handleCloseSidebar();
  };
  return (
    <Drawer
      isOpen={isSidebarOpen}
      onClose={handleCloseSidebar}
      placement='left'
    >
      <DrawerOverlay />
      <DrawerContent bg='sidebarGradient'>
        <DrawerHeader
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          pb='20px'
          pt='20px'
          px='16px'
        >
          <Flex alignItems='center' gap='16px'>
            {/** <Avatar size='sm' src='https://bit.ly/broken-link' />
            <Text fontSize='16px' fontWeight='600'>
              JBloggs
            </Text> */}
          </Flex>

          <IconButton
            aria-label='close'
            icon={<Image alt='close' src={CloseSidebar} />}
            onClick={handleCloseSidebar}
            variant='unstyled'
          />
        </DrawerHeader>

        <DrawerBody display='flex' flexDirection='column' gap='8px' px='16px'>
          {links.map((link) => (
            <NavItem handleLink={handleLinkClick} key={link.label} {...link} />
          ))}
          {jwt && (
            <Button
              alignItems='center'
              bgColor='transparent'
              borderRadius='8px'
              display='flex'
              gap='12px'
              h='auto'
              justifyContent='flex-start'
              key='logout-mobile'
              minH='auto'
              onClick={handleLogout}
              p='12px'
              variant='unstyled'
            >
              <CircleQuestionIcon />
              <Text color='ignisGrey' fontSize='16px' fontWeight='600'>
                {t(tKeys.logout)}
              </Text>
            </Button>
          )}
          {isLangSwitcherEnabled && (
            <Box maxW='100px' mb='20px' mr='auto' pt='20px' w='100%'></Box>
          )}
        </DrawerBody>

        <DrawerFooter alignItems='flex-start' flexDirection='column' px='16px'>
          <Box bgColor='ignisGrey' h='2px' w='100%' />
          <Flex
            alignItems='center'
            bgColor={'transparent'}
            borderRadius='8px'
            gap='12px'
            p='12px'
          >
            <CircleQuestionIcon fill='white' />
            <Text color={'white'} fontSize='16px' fontWeight='600'>
              {t(tKeys.selectLanguage)}
            </Text>
          </Flex>
          <LangSwitcher variant='big' />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
