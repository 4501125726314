import React from 'react';

import { Button, ButtonProps, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { SettingsIcon } from '@/assets/icon-components/Settings.icon';
import settingsIcon from '@/assets/icons/settings.svg';

import { useGameDate } from '@/hooks/useGameDate';

type Props = {
  isManageMode: boolean;
  onManageLeague: () => void;
} & ButtonProps;

export const LeagueManageButton: React.FC<Props> = (props) => {
  const { isManageMode, onManageLeague, ...btnProps } = props;
  const { t } = useTranslation();
  const { isOver } = useGameDate();
  return (
    <Button
      isDisabled={isOver}
      onClick={onManageLeague}
      rightIcon={
        !isManageMode ? (
          <SettingsIcon fill='currentColor' fontSize='16px' />
        ) : undefined
      }
      variant='outline'
      width='fit-content'
      {...btnProps}
    >
      {isManageMode ? t(tKeys.exitManageMode) : t(tKeys.manageLeague)}
    </Button>
  );
};
