import { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { EditConferenceBonus } from '@/modules/Bracket/components/EditConferenceBonus';
import { ModalEditButton } from '@/modules/Bracket/components/ModalEditButton';

import {
  CHANGE_BONUS_MODAL_VISIBILITY,
  CHANGE_FINAL_BONUS_MODAL_STEP,
  selectBracket,
  SET_READ_OFF_RULES,
} from '../../bracket.slice';
import { BonusModal } from '../BonusModal';
import { FinalBonusModal } from '../FinalBonusModal';

import { StepsLabel } from './StepsLabel';
import {
  parseStepByEntrySCSelections,
  parseStepByEntrySelections,
} from './parseStepByEntrySelections';

export const EditModeStatusBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { entry, entrySC } = useSelector(selectBracket);

  const step = useMemo(
    () =>
      pathname === bracketsSecondChancePath()
        ? parseStepByEntrySCSelections(entrySC)
        : parseStepByEntrySelections(entry),
    [entry, entrySC, pathname],
  );

  useEffect(() => {
    // If entry ID exists then rules already reed
    if (pathname === bracketsSecondChancePath()) {
      if (entrySC?.entry_id) dispatch(SET_READ_OFF_RULES(true));
    } else {
      if (entry.entry_id) dispatch(SET_READ_OFF_RULES(true));
    }
  }, [dispatch, entry, entrySC?.entry_id, pathname]);

  const {
    isOpen: isOpenFinal,
    onClose: onCloseFinal,
    onOpen: onOpenFinal,
  } = useDisclosure({
    id: 'final',
  });

  const onTiebreakerClick = () => {
    dispatch(CHANGE_FINAL_BONUS_MODAL_STEP(2));
    onOpenFinal();
  };

  return (
    <>
      <Flex
        align='center'
        bg='#071520E5'
        gap='24px'
        h='50px'
        justify='center'
        w='100%'
      >
        <StepsLabel {...{ step }} />

        <EditConferenceBonus />

        <ModalEditButton onClick={onTiebreakerClick}>
          {t(tKeys.tieBreaker)}
        </ModalEditButton>

        <FinalBonusModal isOpen={isOpenFinal} onClose={onCloseFinal} />
      </Flex>
    </>
  );
};
