export const MATCHES_TO_FILL_ID = [
  { match: 201, prev: [101, 102] },
  { match: 202, prev: [103, 104] },
  { match: 203, prev: [105, 106] },
  { match: 204, prev: [107, 108] },
  { match: 301, prev: [201, 202] },
  { match: 302, prev: [203, 204] },
  { match: 401, prev: [301, 302] },
];

export const BONUS_GAMES_NUMBERS: number[] = [4, 5, 6, 7];

export const UI_SETTINGS = {
  COL_MIN_W: '90px',
  COL_MIN_W_FINAL: '180px',
};
