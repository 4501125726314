import React, { FC, PropsWithChildren } from 'react';

import { ButtonProps, Flex, FlexProps, Text } from '@chakra-ui/react';

export const ModalEditButton: FC<
  PropsWithChildren & { onClick: () => void } & FlexProps
> = ({ children, onClick, ...btnProps }) => {
  return (
    <Flex
      align='center'
      as={'button'}
      gap='10px'
      onClick={onClick}
      {...btnProps}
    >
      <Text isTruncated textDecoration='underline'>
        {children}
      </Text>
      {/*  Pencil Icon */}
      <svg
        fill='none'
        height='16'
        viewBox='0 0 17 16'
        width='17'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.5 11.4997V13.9997H5L12.3733 6.62638L9.87333 4.12638L2.5 11.4997ZM14.3067 4.69305C14.5667 4.43305 14.5667 4.01305 14.3067 3.75305L12.7467 2.19305C12.4867 1.93305 12.0667 1.93305 11.8067 2.19305L10.5867 3.41305L13.0867 5.91305L14.3067 4.69305Z'
          fill='white'
        />
      </svg>
    </Flex>
  );
};
