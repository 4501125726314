import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppSelector } from '@/store';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useJWT } from '@/hooks/useJWT';
import { useToast } from '@/hooks/useToast';

import {
  useAddEntryToLeagueMutation,
  useLazyGetSecondChanceEntryQuery,
} from '@/modules/Bracket/bracket.api';
import {
  bracketsSecondChancePath,
  MAX_BRACKET_SELECTIONS_AMOUNT,
  MAX_SC_BRACKET_SELECTIONS_AMOUNT,
} from '@/modules/Bracket/bracket.constants';
import { BracketMode } from '@/modules/Bracket/brackets.enums';
import {
  parseStepByEntrySCSelections,
  parseStepByEntrySelections,
} from '@/modules/Bracket/components/StatusBar/parseStepByEntrySelections';

import { createPath } from '@/routes/types';

import { errorHandler } from '@/utils/api.util';

import {
  selectBracket,
  SET_ENTRY_SC,
  SET_INITIAL_ENTRY_SC,
} from '../bracket.slice';

import { useEntry } from './useEntry';

export const useSubmitButton = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { jwt } = useJWT();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { entry, hasBonusValues, isReadOffRules, entrySC } =
    useAppSelector(selectBracket);

  const leagueIdSearchParam = searchParams.get(SearchParamsEnum.leagueId);

  const isSubmitDisabled = useMemo(
    () =>
      (pathname === bracketsSecondChancePath()
        ? parseStepByEntrySCSelections(entrySC) <
          MAX_SC_BRACKET_SELECTIONS_AMOUNT
        : parseStepByEntrySelections(entry) < MAX_BRACKET_SELECTIONS_AMOUNT) ||
      !isReadOffRules,
    [entry, entrySC, isReadOffRules, pathname],
  );

  const { isLoading, submit } = useEntry();
  const [addEntryToLeague, { isLoading: isLoadingAddEntry }] =
    useAddEntryToLeagueMutation();
  const [getSC, { isLoading: isLoadingGetSC }] =
    useLazyGetSecondChanceEntryQuery();

  const handleSubmit = async () => {
    try {
      const response = await submit();
      if (leagueIdSearchParam && response?.entry_id) {
        await addEntryToLeague({
          entryId: response?.entry_id,
          leagueId: leagueIdSearchParam,
        }).unwrap();
      }

      if (pathname === bracketsSecondChancePath()) {
        const res = await getSC({ jwt }).unwrap();
        if (res.entry) {
          searchParams.set(SearchParamsEnum.viewType, BracketMode.edit);
          setSearchParams(searchParams);
          dispatch(SET_INITIAL_ENTRY_SC(res.entry));
          dispatch(SET_ENTRY_SC(res.entry));
        }
        return;
      }
      if (response?.entry_id) {
        return navigate(
          createPath({
            path: RoutesEnum.bracketsSpecific,
            params: { id: response?.entry_id },
          }),
        );
      }
    } catch (error) {
      errorHandler({ toast, t, error });
    }
  };

  return {
    isLoading: isLoading || isLoadingAddEntry || isLoadingGetSC,
    isSubmitDisabled,
    handleSubmit,
  };
};
