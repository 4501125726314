import React from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import { useAppSelector } from '@/store';

import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useGameDate } from '@/hooks/useGameDate';
import { useSettings } from '@/hooks/useSettings';

import {
  bracketsNewPath,
  bracketsSecondChancePath,
} from '@/modules/Bracket/bracket.constants';
import { SecondChanceEntryType } from '@/modules/Bracket/bracket.types';

import { selectBracket } from '../../bracket.slice';
import { BracketMode } from '../../brackets.enums';
import { useBracketSettings } from '../../hooks/useBracketSettings';

import { EditModeDesktopHeader } from './EditModeDesktopHeader';
import { EditModeMobileHeader } from './EditModeMobileHeader/index';
import { ViewModeDesktopHeader } from './ViewModeDesktopHeader';
import { ViewModeMobileHeader } from './ViewModeMobileHeader';

export const BracketHeader: React.FC<{
  handleDownload(): void;
  secondChanceEntry: SecondChanceEntryType | undefined | null;
}> = ({ handleDownload, secondChanceEntry }) => {
  const { isPreRegistrationPage } = useBracketSettings();
  const { bracketMode } = useAppSelector(selectBracket);
  const { isMobile } = useSettings();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { isSecondChanceLive } = useGameDate();

  const bracketViewType = searchParams.get(SearchParamsEnum.viewType) as
    | BracketMode
    | undefined;

  // Hide Header for pre-registration page
  if (isPreRegistrationPage) {
    return null;
  }

  if (isMobile) {
    return bracketMode === BracketMode.edit ||
      pathname === bracketsNewPath() ||
      (pathname === bracketsSecondChancePath() &&
        isSecondChanceLive &&
        (secondChanceEntry
          ? bracketViewType === BracketMode.edit
          : !bracketViewType)) ? (
      <EditModeMobileHeader />
    ) : (
      <ViewModeMobileHeader handleDownload={handleDownload} />
    );
  }

  if (
    bracketMode === BracketMode.edit ||
    pathname === bracketsNewPath() ||
    (pathname === bracketsSecondChancePath() &&
      isSecondChanceLive &&
      (secondChanceEntry
        ? bracketViewType === BracketMode.edit
        : !bracketViewType))
  ) {
    return <EditModeDesktopHeader />;
  }

  return <ViewModeDesktopHeader handleDownload={handleDownload} />;
};
