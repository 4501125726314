export const hideExtraTeams = (step: number) => {
  const match102 = document.getElementById('match_102');
  const match103 = document.getElementById('match_103');
  const match104 = document.getElementById('match_104');
  const match202 = document.getElementById('match_202');
  const match201team1 = document.getElementById('match_201_team_1');
  const match201team2 = document.getElementById('match_201_team_2');

  if (match102) {
    match102.style.opacity = step !== 0 ? '0' : '1';
  }
  if (match103) {
    match103.style.opacity = step !== 0 ? '0' : '1';
  }
  if (match104) {
    match104.style.opacity = step !== 0 ? '0' : '1';
  }
  if (match202) {
    match202.style.opacity = step !== 0 ? '0' : '1';
  }
  if (match201team2) {
    match201team2.style.opacity = step !== 0 ? '0' : '1';
  }

  if (match201team1) {
    match201team1.style.opacity = step === 1 ? '0' : '1';
  }
};
