import React, { SVGProps } from 'react';

export const HierarchyIcon: React.FC<SVGProps<any>> = ({
  fill = '#99ABB7',
}) => {
  return (
    <svg
      fill='none'
      height='18'
      viewBox='0 0 20 18'
      width='20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.66699 17.5H6.66699V14.5H2.66699V17.5H0.666992V12.5H8.66699V17.5Z'
        fill={fill}
      />
      <path
        d='M19.667 17.5H17.667V14.5H13.667V17.5H11.667V12.5H19.667V17.5Z'
        fill={fill}
      />
      <path
        d='M16.667 13.5H14.667V10.5H5.66699V13.5H3.66699V8.5H16.667V13.5Z'
        fill={fill}
      />
      <path d='M11.167 6.5H9.16699V9.5H11.167V6.5Z' fill={fill} />
      <path
        d='M15.167 6.5H5.16699V0.5H15.167V6.5ZM7.16699 4.5H13.167V2.5H7.16699V4.5Z'
        fill={fill}
      />
    </svg>
  );
};
