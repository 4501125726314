export type DivisionType = 'west' | 'east';

export const takeDivision = (matchId: number): DivisionType => {
  if ([101, 102, 103, 104].includes(matchId)) return 'west';
  if ([105, 106, 107, 108].includes(matchId)) return 'east';
  if ([201, 202].includes(matchId)) return 'west';
  if ([203, 204].includes(matchId)) return 'east';
  if ([301].includes(matchId)) return 'west';
  if ([302].includes(matchId)) return 'east';
  return 'east';
};
