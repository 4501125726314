import { useMemo } from 'react';

import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { tKeys } from '@/i18n';

import { useAuth } from '@/hooks/useAuth';
import { useAuthContext } from '@/hooks/useAuthContext';
import { useTimer } from '@/hooks/useTimer';

import { selectBracket } from '../bracket.slice';

export const TopPreRegistrationBlock = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { onSignInOpen } = useAuthContext();

  return (
    <>
      <Flex
        alignItems='center'
        direction='column'
        gap={'10px'}
        justifyContent='center'
      >
        <Box textAlign='center'>
          <Text textTransform='uppercase'>{t(tKeys.bracketsOpen)}:</Text>
          <Countdown />
        </Box>
        {!isAuthenticated && (
          <Button onClick={onSignInOpen}>{t(tKeys.signupToday)}</Button>
        )}
      </Flex>
    </>
  );
};

const Countdown = () => {
  const { t } = useTranslation();
  const { game } = useSelector(selectBracket);

  const allowPicksFrom = useMemo(() => game.allow_picks_from, [game]);

  const { timeLeft, isExpired } = useTimer(allowPicksFrom);

  const text = !allowPicksFrom || isExpired ? t(tKeys.comingSoon) : timeLeft;

  return (
    <Text
      fontFamily='Antonio, sans-serif'
      fontSize='32px'
      textTransform='uppercase'
    >
      {text}
    </Text>
  );
};
