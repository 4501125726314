import React, { useEffect, useState } from 'react';

import { Flex, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { PageLoader } from '@/elements/PageLoader';

import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

import { LeaderboardTable } from '@/modules/LeagueManage/components/LeaderboardTable';
import { LeagueTab } from '@/modules/LeagueManage/components/LeagueTab';
import { MembersTable } from '@/modules/LeagueManage/components/MembersTable';
import { LeagueManageButton } from '@/modules/LeagueManage/containers/LeagueManageButton';
import { MembersFilter } from '@/modules/LeagueManage/containers/MembersFilter';
import {
  useGetFilteredMembersQuery,
  useGetLeagueLeaderboardQuery,
} from '@/modules/LeagueManage/leagueManage.api';
import {
  getIsManageMode,
  getLeagueData,
} from '@/modules/LeagueManage/leagueManage.selectors';
import { LIMIT } from '@/modules/Leagues/leagues.constants';
import { LeagueType } from '@/modules/Leagues/leagues.enums';

import {
  isSecondChanceLeague,
  nhlThinScrollbarStyles,
} from '@/utils/common.util';

export const LeagueManageTable: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isMobile } = useSettings();
  const { jwt } = useJWT();

  const [membersRef, inViewMembers] = useInView();
  const [leaderboardRef, inViewLeaderboard] = useInView();
  const [membersOffset, setMembersOffset] = useState(0);
  const [membersSearch, setMembersSearch] = useState('');
  const [leaderboardOffset, setLeaderboardOffset] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const dispatch = useDispatch();
  const leagueData = useSelector(getLeagueData);
  const isManageMode = useSelector(getIsManageMode);
  const {
    isComing,
    isLive,
    isOver,
    isSecondChanceOver,
    isSecondChanceComing,
    isSecondChanceLive,
  } = useGameDate();

  const {
    isLoading: isMembersLoading,
    isFetching: isMembersFetching,
    data: members,
  } = useGetFilteredMembersQuery(
    {
      search: membersSearch,
      offset: membersOffset,
      limit: LIMIT,
      jwt,
      id: String(id),
    },
    { skip: isComing || !isSecondChanceOver ? false : tabIndex === 0 },
  );
  const {
    isLoading: isLeaderboardLoading,
    isFetching: isLeaderboardFetching,
    data: leaderboard,
  } = useGetLeagueLeaderboardQuery(
    {
      offset: leaderboardOffset,
      limit: LIMIT,
      jwt,
      id: String(id),
    },
    { skip: isComing ? true : tabIndex === 1 },
  );

  useEffect(() => {
    setMembersOffset(0);
    setLeaderboardOffset(0);
    setMembersSearch('');
  }, [tabIndex]);

  useEffect(() => {
    if (
      inViewMembers &&
      !isMembersFetching &&
      !members?.isLastPage &&
      (members?.members?.length || 0) >= LIMIT
    ) {
      setMembersOffset((prevOffset) => prevOffset + LIMIT);
    }
  }, [inViewMembers, isMembersFetching, members]);

  useEffect(() => {
    if (
      inViewLeaderboard &&
      !isLeaderboardFetching &&
      !leaderboard?.isLastPage &&
      (leaderboard?.entries?.length || 0) >= LIMIT
    ) {
      setLeaderboardOffset((prevOffset) => prevOffset + LIMIT);
    }
  }, [inViewLeaderboard, isLeaderboardFetching, leaderboard]);

  const handleResetMembersOffset = () => {
    setMembersOffset(0);
  };
  const handleResetLeaderboardOffset = () => {
    setLeaderboardOffset(0);
  };

  return (
    <Tabs onChange={(index) => setTabIndex(index)}>
      <Flex
        alignItems='center'
        background='linear-gradient(141.41deg, #153449 1.32%, #3D617B 98.18%)'
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent='space-between'
        padding={isMobile ? '16px' : '24px'}
      >
        <TabList borderBottom='0'>
          {(isSecondChanceLeague(leagueData?.league_id)
            ? isSecondChanceOver
            : !isComing) && (
            <LeagueTab
              isOneTab={
                isSecondChanceLeague(leagueData?.league_id)
                  ? !isSecondChanceOver
                  : isComing
              }
              label={t(tKeys.leaderboard)}
            />
          )}
          <LeagueTab
            isOneTab={
              isSecondChanceLeague(leagueData?.league_id)
                ? !isSecondChanceOver
                : isComing
            }
            label={t(tKeys.members)}
          />
        </TabList>
        {leagueData?.type === LeagueType.Friends &&
          isMobile &&
          leagueData?.is_owner && (
            <LeagueManageButton
              color='textDark'
              mb='24px'
              minW='100%'
              variant='primary'
              w='100%'
            />
          )}
        {/*Hide search feature for leaderboard table*/}
        {(isSecondChanceLeague(leagueData?.league_id)
          ? !isSecondChanceOver || tabIndex !== 0
          : isComing || tabIndex !== 0) && (
          <MembersFilter
            onResetOffset={handleResetMembersOffset}
            setMembersSearch={setMembersSearch}
          />
        )}
      </Flex>

      <TabPanels>
        {(isSecondChanceLeague(leagueData?.league_id)
          ? isSecondChanceOver
          : !isComing) && (
          <TabPanel p='0'>
            <LeaderboardTable
              handleResetLeaderboardOffset={handleResetLeaderboardOffset}
              isFetching={isLeaderboardFetching}
              isManageMode={isManageMode}
              leaderboardData={leaderboard?.entries}
              leagueId={leagueData?.league_id}
              offset={leaderboardOffset}
              ref={leaderboardRef}
            />
          </TabPanel>
        )}
        <TabPanel p='0'>
          <MembersTable
            handleResetMembersOffset={handleResetMembersOffset}
            isFetching={isMembersFetching}
            isManageMode={isManageMode}
            leagueId={leagueData?.league_id}
            membersData={members?.members}
            offset={membersOffset}
            ref={membersRef}
          />
        </TabPanel>
      </TabPanels>

      {isMembersLoading && <PageLoader />}
    </Tabs>
  );
};
