import React, { useEffect, useState } from 'react';

import { Box, useMediaQuery } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import AdsBanner from '@/components/AdsBanner';
import { Footer } from '@/components/Layout/Footer';

import { PageLoader } from '@/elements/PageLoader';

import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

import { Filter } from '@/modules/Leagues/components/Filter';
import { LeaguesHeader } from '@/modules/Leagues/components/LeaguesHeader';
import { LeaguesList } from '@/modules/Leagues/containers/LeaguesList';
import { useGetFilteredLeaguesQuery } from '@/modules/Leagues/leagues.api';
import { LIMIT } from '@/modules/Leagues/leagues.constants';
import { LeagueType } from '@/modules/Leagues/leagues.enums';
import { getFilterTypes } from '@/modules/Leagues/leagues.selectors';
import {
  SET_LEAGUES_IS_FETCHING,
  SET_LEAGUES_IS_LOADING,
} from '@/modules/Leagues/leagues.slice';

import {
  hiddenScrollbarStyles,
  nhlThinScrollbarStyles,
} from '@/utils/common.util';

type Props = {
  isMobile: boolean;
  isLgDesktop: boolean;
};

export const Leagues: React.FC<Props> = (props) => {
  const { isMobile, isLgDesktop } = props;
  const { isMobile: isMobileSettings } = useSettings();
  const [isMobileCustom] = useMediaQuery('(max-width: 770px)');

  const dispatch = useDispatch();

  const type: LeagueType[] = useSelector(getFilterTypes);

  const { jwt } = useJWT();

  const [ref, inView] = useInView();
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');

  const { isLoading, isFetching, data } = useGetFilteredLeaguesQuery(
    { search, type, offset: search.length > 2 ? 0 : offset, limit: LIMIT, jwt },
    { skip: false },
  );

  useEffect(() => {
    dispatch(SET_LEAGUES_IS_LOADING(isLoading));
  }, [isLoading]);

  useEffect(() => {
    dispatch(SET_LEAGUES_IS_FETCHING(isFetching));
  }, [isFetching]);

  useEffect(() => {
    if (inView && !isFetching && !data?.isLastPage) {
      setOffset((prevOffset) => prevOffset + LIMIT);
    }
  }, [inView]);

  const handleResetOffset = () => {
    setOffset(0);
  };

  if (isLoading || !data) {
    return <PageLoader />;
  }

  return (
    <Box
      height={isLgDesktop ? '650px' : '100%'}
      maxW={isLgDesktop ? '1000px' : '100%'}
      mt={isMobileSettings ? '104px' : '0px'}
      width={'100%'}
    >
      <Filter
        isMobile={isMobile}
        onResetOffset={handleResetOffset}
        setSearch={setSearch}
      />
      <LeaguesHeader isMobile={isMobile} />

      <Box
        h={isMobileCustom ? 'auto' : '475px'}
        overflow={isMobileCustom ? 'unset' : 'auto'}
        sx={nhlThinScrollbarStyles}
      >
        <LeaguesList
          isFetching={isFetching}
          lastLeagueRef={ref}
          leagues={data.leagues}
          offset={offset}
        />

        <AdsBanner
          my='30px'
          slotId='mobile-bottom-banner-ad'
          slotSize={isMobile ? [320, 50] : [728, 90]}
        />
        <Footer />
      </Box>
    </Box>
  );
};
