import { useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { useJWT } from '@/hooks/useJWT';

import { useGetGameQuery } from '@/modules/Bracket/bracket.api';
import { SET_USER } from '@/modules/Bracket/bracket.slice';
import { GameType } from '@/modules/Bracket/bracket.types';

export const useGameDate = () => {
  const dispatch = useDispatch();
  const { jwt } = useJWT();
  const { data, isLoading } = useGetGameQuery({ jwt });
  const {
    allow_picks_from,
    allow_picks_until,
    allow_second_chance_picks_from,
    allow_second_chance_picks_until,
  } = useMemo(() => data?.game || ({} as GameType), [data?.game]);

  const startDate = useMemo(
    () => allow_picks_from && new Date(allow_picks_from),
    [allow_picks_from],
  );
  const endDate = useMemo(
    () => allow_picks_until && new Date(allow_picks_until),
    [allow_picks_until],
  );
  const secondChanceStartDate = useMemo(
    () =>
      allow_second_chance_picks_from &&
      new Date(allow_second_chance_picks_from),
    [allow_second_chance_picks_from],
  );
  const secondChanceEndDate = useMemo(
    () =>
      allow_second_chance_picks_until &&
      new Date(allow_second_chance_picks_until),
    [allow_second_chance_picks_until],
  );

  useEffect(() => {
    dispatch(SET_USER(data?.game?.user));
  }, [data?.game?.user, dispatch]);

  const isComing = useMemo(
    () => (!!startDate ? startDate > new Date() : true),
    [startDate],
  ); // isComing - true as default state
  const isOver = useMemo(() => !!endDate && endDate < new Date(), [endDate]);
  const isLive = useMemo(() => !isComing && !isOver, [isComing, isOver]);
  const isSecondChanceComing = useMemo(
    () =>
      isOver && !!secondChanceStartDate && secondChanceStartDate > new Date(),
    [isOver, secondChanceStartDate],
  );
  const isSecondChanceOver = useMemo(
    () => isOver && !!secondChanceEndDate && secondChanceEndDate < new Date(),
    [isOver, secondChanceEndDate],
  );
  const isSecondChanceLive = useMemo(
    () => isOver && !isSecondChanceComing && !isSecondChanceOver,
    [isOver, isSecondChanceComing, isSecondChanceOver],
  );

  return {
    startDate,
    endDate,
    isComing,
    isLive,
    isLoading,
    isOver,

    secondChanceStartDate,
    secondChanceEndDate,
    isSecondChanceComing,
    isSecondChanceLive,
    isSecondChanceOver,
  };
};
