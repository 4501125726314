import { useCallback, useMemo } from 'react';

import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share';

import { SHARE_URL } from '@/core/env';
import { tKeys } from '@/i18n';

import { ReactComponent as FacebookIcon } from '@/assets/icons/Facebook.svg';
import { ReactComponent as LinkedInIcon } from '@/assets/icons/LinkedIn.svg';
import { ReactComponent as WhatsappIcon } from '@/assets/icons/Whatsapp.svg';
import { ReactComponent as XIcon } from '@/assets/icons/X.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { ReactComponent as EmailIcon } from '@/assets/icons/email.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/share-arrow.svg';

import { useSettings } from '@/hooks/useSettings';

import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';
import { LeagueInfo } from '@/modules/LeagueManage/leagueManage.types';

export const ShareLeague = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isMobile } = useSettings();

  const leagueData: LeagueInfo | undefined = useSelector(getLeagueData);

  const url = useMemo(
    () => leagueData?.vanity_slug || `${SHARE_URL}${pathname}`,
    [leagueData?.vanity_slug, pathname],
  );

  const cpoyUrlToClipboard = useCallback(
    () =>
      navigator.clipboard
        .writeText(url)
        .then(() => alert('Url copied to clipboard'))
        .catch(console.error),
    [url],
  );

  const mobileShare = useCallback(() => {
    navigator?.share?.({ text: t(tKeys.shareText), url });
  }, [t, url]);

  return (
    <Flex
      align='center'
      bg='primaryBlue'
      direction={{ base: 'column', md: 'row' }}
      gap='12px'
      justify='space-between'
      my='24px'
      p={isMobile ? '16px' : '40px 48px'}
      shadow='0px 4px 4px 0px rgba(0, 0, 0, 0.55)'
    >
      <Text
        fontFamily='Antonio'
        fontSize='30px'
        fontWeight='700'
        lineHeight='1.3'
        textTransform='uppercase'
      >
        {t(tKeys.shareLeague)}
      </Text>
      <Text fontSize='20px' fontWeight='700' lineHeight='1.3'>
        {t(tKeys.inviteYourFriends)}
      </Text>
      <Flex direction='column' gap='16px'>
        <Flex
          align='center'
          bg='#1A1B1F'
          borderRadius='57px'
          cursor='pointer'
          gap='16px'
          h='40px'
          justify='space-between'
          onClick={cpoyUrlToClipboard}
          px='10px'
        >
          <Text color='#C4C6D0' fontSize='12px' letterSpacing='0.357px'>
            {url}
          </Text>
          <Icon as={CopyIcon} h='18px' w='18px' />
        </Flex>

        {isMobile ? (
          <Button
            h='48px'
            mx='auto'
            onClick={mobileShare}
            rightIcon={<ShareIcon />}
          >
            {t(tKeys.share)}
          </Button>
        ) : (
          <Flex
            align='center'
            direction={{ base: 'column', md: 'row' }}
            gap='8px'
            justify='center'
          >
            <Text color='ignisGrey' fontWeight='700'>
              {t(tKeys.orShareVia)}
            </Text>
            <Flex align='center' gap='8px' justify='space-between'>
              <TwitterShareButton url={url}>
                <XIcon />
              </TwitterShareButton>
              <WhatsappShareButton url={url}>
                <WhatsappIcon />
              </WhatsappShareButton>
              <FacebookShareButton url={url}>
                <FacebookIcon />
              </FacebookShareButton>
              <LinkedinShareButton url={url}>
                <LinkedInIcon />
              </LinkedinShareButton>
              <EmailShareButton body={url} url={url}>
                <EmailIcon />
              </EmailShareButton>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
