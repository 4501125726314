import React, { Suspense } from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { AppRoutes } from '@/routes';

import { Layout } from '@/components/Layout';
import { URLParamsWatcherOutlet } from '@/components/URLParamsWatcherOutlet';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';

import HomePage from '@/pages/Home';
import NotFoundPage from '@/pages/NotFound';
import RootRoute from '@/pages/Root';

function App() {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route element={<Layout />}>
          <Route element={<RootRoute />} path={RoutesEnum.root} />
        </Route>
        <Route
          element={
            <URLParamsWatcherOutlet>
              <Layout />
            </URLParamsWatcherOutlet>
          }
          path='/:lang'
        >
          {AppRoutes.map((route) => (
            <Route element={route.element} key={route.path} path={route.path} />
          ))}
        </Route>
      </Route>
      <Route element={<NotFoundPage />} path={'*'} />
    </Routes>
  );
}

export default App;
