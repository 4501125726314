import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const buttonBaseStyles = {
  height: 'auto',
  minHeight: 'auto',
};
const sm = defineStyle({
  borderRadius: '60px',
  maxW: '327px',
  p: '4px 12px',
  h: '32px',
  fontSize: '12px',
});

const md = defineStyle({
  borderRadius: '60px',
  maxW: '327px',
  p: '12px 24px',
  w: '100%',
  h: '48px',
});

const primary = defineStyle({
  ...buttonBaseStyles,
  bg: 'button.primaryBg',
  color: 'button.primaryColor',
  border: '1px solid var(--chakra-colors-button-primaryBorder)',

  '& > .chakra-button__icon': {
    marginInlineStart: '8px',
    transition: 'all 300ms',
  },

  _hover: {
    bg: 'button.primaryBorder',

    '& > .chakra-button__icon': {
      marginInlineStart: '32px',
    },

    _disabled: {
      opacity: 0.3,
      bg: 'button.primaryBg',
      '& > .chakra-button__icon': {
        marginInlineStart: '8px',
      },
    },
    _active: {
      bg: 'button.primaryPressed',
    },
  },

  _disabled: {
    opacity: '0.3',
  },

  _loading: {
    opacity: '0.3',
  },
});

const outline = defineStyle({
  ...buttonBaseStyles,
  bg: 'button.outlineBg',
  color: 'button.outlineColor',
  borderColor: 'button.outlineBorder',
  border: '2px solid',

  '& > .chakra-button__icon': {
    marginInlineStart: '8px',
    transition: 'all 300ms',
  },

  _hover: {
    bg: 'button.outlineHover',

    '& > .chakra-button__icon': {
      marginInlineStart: '24px',
    },
    _disabled: {
      opacity: 0.3,
      bg: 'button.outlineBg',
      '& > .chakra-button__icon': {
        marginInlineStart: '8px',
      },
    },
  },
  _disabled: {
    opacity: '0.3',
    bg: 'button.outlineBg',
  },
  _active: {
    bg: 'button.outlineActive',
  },
});

const success = defineStyle({
  ...buttonBaseStyles,
  bg: 'button.successBg',
  color: 'button.outlineColor',
  border: '2px solid',
  borderColor: 'button.successBorder',
  boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.55)',
  _hover: {
    opacity: '0.9',
    _disabled: {
      opacity: '0.4',
      bg: 'button.successBg',
      borderColor: 'button.successBorder',
    },
  },
});

const error = defineStyle({
  ...buttonBaseStyles,
  bg: 'button.errorBg',
  color: 'button.outlineColor',
  border: '2px solid',
  borderColor: 'button.errorBorder',
  boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.55)',
  _hover: {
    opacity: '0.9',
  },
});

const link = defineStyle({
  ...buttonBaseStyles,
  color: 'button.outlineColor',
  textDecor: 'underline',
});
const unstyled = defineStyle({
  ...buttonBaseStyles,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  w: 'auto',
});

const ButtonTheme = defineStyleConfig({
  defaultProps: { variant: 'primary', size: 'md' },
  sizes: { sm, md },
  variants: { primary, outline, link, unstyled, success, error },
});

export default ButtonTheme;
