import { Box } from '@chakra-ui/react';

import AdsBanner from '@/components/AdsBanner';
import { Footer } from '@/components/Layout/Footer';

import { useSettings } from '@/hooks/useSettings';

import { Header } from '@/modules/Leaderboard/components/Header';

import { hiddenScrollbarStyles } from '@/utils/common.util';

import { LeaderboardTable } from './containers/LeaderboardTable';

export const Leaderboard = () => {
  const { isMobile, isLgDesktop } = useSettings();
  return (
    <Box
      height={isLgDesktop ? '650px' : '100%'}
      maxW={isLgDesktop ? '1000px' : '100%'}
      mt={isMobile ? '104px' : '0px'}
      overflow={'auto'}
      position='relative'
      sx={hiddenScrollbarStyles}
      width='100%'
    >
      <Header />
      <LeaderboardTable />

      <AdsBanner
        my='30px'
        slotId='mobile-bottom-banner-ad'
        slotSize={isMobile ? [320, 50] : [728, 90]}
      />
      <Footer />
    </Box>
  );
};
