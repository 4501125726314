import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevron-down.svg';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useJWT } from '@/hooks/useJWT';
import { useNavigation } from '@/hooks/useNavigation';
import { useSettings } from '@/hooks/useSettings';

import { useBracketSettings } from '@/modules/Bracket/hooks/useBracketSettings';

import { LangSwitcher } from '../LangSwitcher';

export const Navigation = () => {
  const { t } = useTranslation();
  const { links } = useNavigation();
  const { jwt, removeJWT } = useJWT();
  const { isLgDesktop } = useSettings();
  const { onSignInOpen } = useAuthContext();
  const { isAppNavigationHide } = useBracketSettings();

  const handleLogout = () => {
    removeJWT();
  };
  const handleLogin = () => {
    onSignInOpen();
  };

  if (isAppNavigationHide) return null;
  return (
    <Flex
      align='center'
      h='80px'
      maxW={isLgDesktop ? '1000px' : '100%'}
      w='100%'
    >
      {links.map(({ label, to, selected, items }) =>
        items ? (
          <Menu key={label} placement='bottom-end'>
            <MenuButton
              _hover={{
                bg: 'hoverLightGradient',
              }}
              as={Button}
              borderBottom={selected ? '2px solid' : 'none'}
              borderColor='#D9D9D9'
              p='8px 16px'
              rightIcon={<ChevronDownIcon />}
              sx={{
                '& > span:first-child': {
                  minW: 'auto',
                },
              }}
              variant='unstyled'
              whiteSpace='normal'
            >
              {label}
            </MenuButton>
            <MenuList>
              {items.map((item) => (
                <MenuItem as={NavLink} key={item.label} to={item.to}>
                  {item.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : (
          <Flex
            _hover={{
              bg: 'hoverLightGradient',
            }}
            align='center'
            as={NavLink}
            borderBottom={selected ? '2px solid' : 'none'}
            borderColor='#D9D9D9'
            flex='1 1 auto'
            fontWeight='600'
            justify='center'
            key={label}
            maxW='125px'
            p='8px 16px'
            to={to}
          >
            {label}
          </Flex>
        ),
      )}

      {jwt ? (
        <Flex
          _hover={{
            bg: 'hoverLightGradient',
          }}
          align='center'
          as={Button}
          borderColor='#D9D9D9'
          fontWeight='600'
          justify='center'
          onClick={handleLogout}
          p='8px 16px'
          variant='unstyled'
          w='125px'
        >
          {t(tKeys.logout)}
        </Flex>
      ) : (
        <Flex
          _hover={{
            bg: 'hoverLightGradient',
          }}
          align='center'
          as={Button}
          borderColor='#D9D9D9'
          fontWeight='600'
          justify='center'
          onClick={handleLogin}
          p='8px 16px'
          variant='unstyled'
          w='125px'
        >
          {t(tKeys.login)}
        </Flex>
      )}

      <Flex flex='1 0 auto' justify='end' px='16px'>
        <LangSwitcher variant='small' />
      </Flex>
    </Flex>
  );
};
