import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SidebarStateType } from '@/modules/Sidebar/sidebar.types';

const initialState: SidebarStateType = {
  isSidebarOpen: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    OPEN_SIDEBAR: (state) => {
      state.isSidebarOpen = true;
    },
    CLOSE_SIDEBAR: (state) => {
      state.isSidebarOpen = false;
    },
  },
});

export const { OPEN_SIDEBAR, CLOSE_SIDEBAR } = sidebarSlice.actions;

export default sidebarSlice.reducer;
