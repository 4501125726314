import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

import { useToast } from '@/hooks/useToast';

import { useAddEntryToLeagueMutation } from '@/modules/Bracket/bracket.api';
import { IBracketEntry } from '@/modules/Bracket/bracket.types';
import { LeagueLeaderboardType } from '@/modules/LeagueManage/leagueManage.types';

import { createPath } from '@/routes/types';

import { AddEntryToLeagueButton as AddEntryToLeagueButtonComponent } from '../components/AddEntryToLeagueButton';

type Props = {
  entryId: string | number;
  leagueId: string;
  onClose?: () => void;
  onRefetchData?: any;
  leagueEntry: LeagueLeaderboardType | null;
};

export const AddEntryToLeagueButton: React.FC<Props> = (props) => {
  const { entryId, leagueId, onClose, onRefetchData, leagueEntry } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const defaultSelectedValue = useMemo(
    () => leagueEntry?.entry_id === entryId,
    [entryId, leagueEntry?.entry_id],
  );
  const [isSelected, setSelected] = useState(defaultSelectedValue);

  const [addEntryToLeague, { isLoading }] = useAddEntryToLeagueMutation();

  const toast = useToast();

  const handleAddEntryToLeague = useCallback(async () => {
    try {
      await addEntryToLeague({ entryId, leagueId }).unwrap();
      setSelected(true);
      toast(t(tKeys.successAssignBracket), 'success');

      if (onRefetchData) {
        onRefetchData();
        onClose?.();
      }
    } catch (error) {
      throw new Error(error?.data?.message || error?.message);
    }
  }, [entryId, leagueId]);

  useEffect(() => {
    if (isSelected && !onRefetchData) {
      const timeoutId = setTimeout(() => {
        const path = createPath({
          path: RoutesEnum.leagueManage,
          params: {
            id: leagueId,
          },
        });
        navigate(path);
      }, 800);

      return () => clearTimeout(timeoutId);
    }
  }, [isSelected, leagueId, onRefetchData]);

  return (
    <AddEntryToLeagueButtonComponent
      isLoading={isLoading}
      isSelected={isSelected}
      onAddEntryToLeague={handleAddEntryToLeague}
    />
  );
};
