import { FC, PropsWithChildren } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

export const MetaProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <link href='https://fonts.googleapis.com' rel='preconnect' />
        <link href='https://fonts.gstatic.com' rel='preconnect' />

        <link
          href='/apple-touch-icon.png'
          rel='apple-touch-icon'
          sizes='180x180'
        />
        <link
          href='/favicon-32x32.png'
          rel='icon'
          sizes='32x32'
          type='image/png'
        />
        <link
          href='/favicon-16x16.png'
          rel='icon'
          sizes='16x16'
          type='image/png'
        />
        <link href='/site.webmanifest' rel='manifest' />
        <link color='#5bbad5' href='/safari-pinned-tab.svg' rel='mask-icon' />
        <meta content='#da532c' name='msapplication-TileColor' />
        <meta content='#ffffff' name='theme-color' />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};
