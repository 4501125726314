import React, { useCallback, useRef, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { toPng } from 'html-to-image';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import ArrowLeftImg from '@/assets/icons/arrow-left.svg';
import CircleAddImg from '@/assets/icons/circle-add-filled.svg';

import AdsBanner from '@/components/AdsBanner';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useAuthContext } from '@/hooks/useAuthContext';

import { ViewBracket } from '@/modules/Bracket/containers/ViewBracket';
import { useDownloadBracket } from '@/modules/Bracket/hooks/useDownloadBracket';
import { LeagueLeaderboardType } from '@/modules/LeagueManage/leagueManage.types';
import { AssignBracketHeader } from '@/modules/Leagues/components/AssignBracketHeader';
import { AssignBracketTable } from '@/modules/Leagues/containers/AsignBracketTable';

import { createPath } from '@/routes/types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  leagueId: string;
  onRefetchData?: any;
  leagueEntry: LeagueLeaderboardType | null;
};

export const AssignBracketModal: React.FC<Props> = ({
  leagueId,
  onRefetchData,
  leagueEntry,
  ...props
}) => {
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { withAuthCheck } = useAuthContext();

  const { bracketRef, handleDownload, isLoading } = useDownloadBracket();

  const handleCreateNewBracket = () => {
    withAuthCheck(() =>
      navigate(
        `${createPath({ path: RoutesEnum.bracketsNew })}?${SearchParamsEnum.leagueId}=${leagueId}`,
      ),
    );
  };

  return (
    <Modal scrollBehavior='inside' {...props} size={['full', 'md']}>
      <ModalOverlay />
      <ModalContent borderRadius='10px' maxW={['100%', '840px']}>
        <ModalHeader display={['block', 'none']} p='19px 16px'>
          <Grid templateColumns='1fr auto 1fr' w='100%'>
            <GridItem alignSelf='start' h='100%'>
              <IconButton
                aria-label='back'
                h='100%'
                icon={<Image alt='go back' src={ArrowLeftImg} />}
                onClick={props.onClose}
                variant='unstyled'
              />
            </GridItem>
            <GridItem colStart={2}>
              <Heading as='h2' fontSize='18px' fontWeight='600'>
                {t(tKeys.assignBracket)}
              </Heading>
            </GridItem>
          </Grid>
        </ModalHeader>
        <ModalBody>
          {isLoading && <PageLoader />}
          <Box h='81px' m={'0 auto'}>
            <AdsBanner slotId='modal-ad' slotSize={[226, 81]} />
          </Box>
          {isViewOpen ? (
            <ViewBracket ref={bracketRef} />
          ) : (
            <>
              <Flex
                align='center'
                direction={{ base: 'column', md: 'row' }}
                gap='16px'
                justify='center'
                my='26px'
                w='100%'
              >
                <Text fontSize='20px' fontWeight='700' lineHeight='1.3'>
                  {t(tKeys.assignBracket)}
                </Text>
                <Text
                  color='ignisGrey'
                  fontSize='12px'
                  fontWeight='600'
                  textTransform='uppercase'
                >
                  {t(tKeys.or)}
                </Text>
                <Button
                  onClick={handleCreateNewBracket}
                  rightIcon={<Image src={CircleAddImg} />}
                  width='fit-content'
                >
                  {t(tKeys.createNewBracket)}
                </Button>
              </Flex>

              <AssignBracketHeader />
              <AssignBracketTable
                leagueEntry={leagueEntry}
                leagueId={leagueId}
                onClose={props.onClose}
                onRefetchData={onRefetchData}
                onViewOpen={onViewOpen}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter flexDirection='column' gap='16px'>
          {isViewOpen && (
            <Button mt='18px' onClick={handleDownload}>
              {t(tKeys.downloadImage)}
            </Button>
          )}
          <Button
            onClick={isViewOpen ? onViewClose : props.onClose}
            variant='link'
          >
            {t(isViewOpen ? tKeys.goBack : tKeys.cancel)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
