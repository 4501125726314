import React from 'react';

import { Checkbox, CheckboxGroup, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { LeagueType } from '@/modules/Leagues/leagues.enums';
import { getFilterTypes } from '@/modules/Leagues/leagues.selectors';
import { TypeCheckbox } from '@/modules/Leagues/leagues.types';

type Props = {
  typeCheckboxes: TypeCheckbox[];
  onCheckboxChange: (value: LeagueType, isChecked: boolean) => void;
};

export const TypeCheckboxes: React.FC<Props> = (props) => {
  const { typeCheckboxes, onCheckboxChange } = props;
  const type: LeagueType[] = useSelector(getFilterTypes);

  return (
    <CheckboxGroup colorScheme='white' value={type}>
      <Stack direction={['row']} spacing={[5, 5]}>
        {typeCheckboxes.map((checkbox, index) => (
          <Checkbox
            key={index}
            onChange={(e) => onCheckboxChange(checkbox.value, e.target.checked)}
            value={checkbox.value}
          >
            <Text fontSize='14px'>{checkbox.text}</Text>
          </Checkbox>
        ))}
      </Stack>
    </CheckboxGroup>
  );
};
