import React from 'react';

import { Box, Button, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/chevron-left.svg';

import NhlImage from '@/assets/icons/nhl-shield.svg';

import { RoutesEnum } from '@/enums/routes.enum';

import { createPath } from '@/routes/types';

import { tKeys } from '@/i18n';

type Props = {
  leagueImageSrc: string | undefined;
  leagueName: string | undefined;
  leagueSize: string | undefined;
};

export const LeagueInfo: React.FC<Props> = (props) => {
  const { leagueImageSrc, leagueName, leagueSize } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid
      alignItems='center'
      marginBottom='24px'
      marginTop='24px'
      templateColumns='1fr 3fr 1fr'
    >
      <Flex justifyContent='flex-start'>
        <Button
          bgColor='darkBlue'
          border='2px solid'
          borderColor='gladGrey'
          borderRadius='60px'
          color='white'
          leftIcon={<ArrowLeftIcon />}
          onClick={() => navigate(createPath({ path: RoutesEnum.leagues }))}
          px='16px'
          py='4px'
          variant='unstyled'
        >
          {t(tKeys.back)}
        </Button>
      </Flex>
      <Flex alignItems='center' justifyContent='center'>
        <Image
          fallbackSrc={NhlImage}
          marginRight='12px'
          src={leagueImageSrc}
          width='100px'
        />
        <Text fontSize='24px'>{leagueName}</Text>
      </Flex>
      <Box textAlign='right'>
        <Text
          fontSize='14px'
          fontWeight='600'
          pb='4px'
          textTransform='uppercase'
        >
          {t(tKeys.members)}:
        </Text>
        <Text fontFamily='Antonio' fontSize='30px' lineHeight='100%'>
          {leagueSize}
        </Text>
      </Box>
    </Grid>
  );
};
