import { SeriesResultsType } from '@/modules/Bracket/bracket.types';
import { chooseSlotByMatchId } from '@/modules/Bracket/helpers/chooseSlotByMatchId';
import { MATCHES_TO_FILL_ID } from '@/modules/Bracket/helpers/constants';

export const updateFutureMatches = ({
  matchId,
  teamId,
  seriesResults,
}: {
  matchId: number;
  teamId: number;
  seriesResults: SeriesResultsType[];
}): SeriesResultsType[] => {
  const nextMatch = MATCHES_TO_FILL_ID.find((el) => {
    if (el.prev.includes(matchId)) {
      return el;
    }
  });

  const newMatchId = nextMatch?.match || 0;
  const key = chooseSlotByMatchId(matchId);

  return seriesResults
    .map((item) => {
      if (item.id === newMatchId) {
        return { ...item, [key]: teamId };
      }
      return item;
    })
    .map((item, index) => {
      if (matchId === 101 || matchId === 102) {
        // Remove team1 for 301 and 401 if other team selected
        if (
          (index === 12 && item.team_1_id !== teamId) ||
          (index === 14 && item.team_1_id !== teamId)
        ) {
          return { ...item, team_1_id: null };
        }
      }

      if (matchId === 103 || matchId === 104) {
        if (index === 12 && item.team_2_id !== teamId) {
          return { ...item, team_2_id: null };
        }

        if (index === 14 && item.team_1_id !== teamId) {
          return { ...item, team_1_id: null };
        }
      }

      if (matchId === 105 || matchId === 106) {
        if (index === 13 && item.team_1_id !== teamId) {
          return { ...item, team_1_id: null };
        }

        if (index === 14 && item.team_2_id !== teamId) {
          return { ...item, team_2_id: null };
        }
      }

      if (matchId === 107 || matchId === 108) {
        if (index === 13 && item.team_2_id !== teamId) {
          return { ...item, team_2_id: null };
        }
        if (index === 14 && item.team_2_id !== teamId) {
          return { ...item, team_2_id: null };
        }
      }

      if (matchId === 201 || matchId === 202) {
        if (index === 14 && item.team_1_id !== teamId) {
          return { ...item, team_1_id: null };
        }
      }

      if (matchId === 203 || matchId === 204) {
        if (index === 14 && item.team_2_id !== teamId) {
          return { ...item, team_2_id: null };
        }
      }

      return item;
    });
};
