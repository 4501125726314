import React, { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { LeagueType } from '@/modules/Leagues/leagues.enums';
import { leaguesTypeCheckboxes } from '@/modules/Leagues/leagues.mocks';
import { SET_FILTER_TYPES } from '@/modules/Leagues/leagues.slice';

import { TypeCheckboxes as TypeCheckboxesComponent } from '../components/TypeCheckboxes';

type Props = {
  onResetOffset: () => void;
};

export const TypeCheckboxes: React.FC<Props> = (props) => {
  const { onResetOffset } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const leagueType = useMemo(
    () => searchParams.get(SearchParamsEnum.leagueType),
    [searchParams],
  );

  const handleCheckboxChange = useCallback(
    (value: LeagueType, isChecked: boolean) => {
      onResetOffset();
      dispatch(SET_FILTER_TYPES({ value, isChecked }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (leagueType) {
      handleCheckboxChange(leagueType as LeagueType, true);

      searchParams.delete(SearchParamsEnum.leagueType);
      setSearchParams(searchParams);
    }
  }, [handleCheckboxChange, leagueType, searchParams, setSearchParams]);

  return (
    <TypeCheckboxesComponent
      onCheckboxChange={handleCheckboxChange}
      typeCheckboxes={leaguesTypeCheckboxes(t)}
    />
  );
};
