import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { sidebarSelector } from '@/modules/Sidebar/sidebar.selector';
import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from '@/modules/Sidebar/sidebar.slice';

export const useSidebarStore = () => {
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector(sidebarSelector);

  const handleOpenSidebar = useCallback(
    () => dispatch(OPEN_SIDEBAR()),
    [dispatch],
  );
  const handleCloseSidebar = useCallback(
    () => dispatch(CLOSE_SIDEBAR()),
    [dispatch],
  );

  return { isSidebarOpen, handleOpenSidebar, handleCloseSidebar };
};
