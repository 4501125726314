import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { useJWT } from '@/hooks/useJWT';

import { useGetFilteredLeaguesQuery } from '@/modules/Leagues/leagues.api';
import { LeagueType } from '@/modules/Leagues/leagues.enums';
import { LeaguesList } from '@/modules/Main/components/LeaguesList';

export const Leagues = () => {
  const { t } = useTranslation();
  const { jwt } = useJWT();
  const { data: partnerLeaguesResponse, isLoading: isPartnerLeaguesLoading } =
    useGetFilteredLeaguesQuery({
      type: LeagueType.Partner,
      limit: 5,
      jwt,
    });
  const { data: clubLeaguesResponse, isLoading: isClubLeaguesLoading } =
    useGetFilteredLeaguesQuery({
      type: LeagueType.Club,
      limit: 5,
      jwt,
    });

  if (isPartnerLeaguesLoading || isClubLeaguesLoading) return null;

  return (
    <Box>
      <Text
        fontFamily='Antonio'
        fontSize='48px'
        fontWeight='700'
        my='32px'
        textAlign='center'
        textTransform='uppercase'
      >
        {t(tKeys.joinLeagues)}
      </Text>

      <Flex gap='24px' wrap={{ base: 'wrap', md: 'nowrap' }}>
        <LeaguesList
          leagues={partnerLeaguesResponse?.leagues}
          title={t(tKeys.partnerLeagues)}
          type={LeagueType.Partner}
        />
        <LeaguesList
          leagues={clubLeaguesResponse?.leagues}
          title={t(tKeys.clubLeagues)}
          type={LeagueType.Club}
        />
      </Flex>
    </Box>
  );
};
