export const debounce = <T extends Function>(callback: T, wait = 100) => {
  let timeoutId: number;

  return (...args: any): Promise<any> =>
    new Promise((resolve) => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        Promise.resolve(callback(...args)).then(resolve);
      }, wait);
    });
};
