import { matchPath, PathPattern } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';

import { createPath } from '@/routes/types';

export const addLangToRoute = (pathname: string, lang: string | undefined) =>
  pathname.replace('/:lang', lang ?? ':lang');

export const addIdToRoute = (pathname: string, id: string | undefined) =>
  pathname.replace(':id', id ?? ':id');

export const isPathEqualsToPathname = (
  path: RoutesEnum | string,
  pathname: string,
  params?: Partial<PathPattern>,
) => Boolean(matchPath({ path: createPath({ path }), ...params }, pathname));

export const removeLangFromPathname = (pathname: string, lang: string) => {
  return pathname?.split(`/${lang}`)?.[1];
};
