import { FC } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import AdsBanner from '@/components/AdsBanner';

interface LimitLeaguesModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const LimitLeaguesModal: FC<LimitLeaguesModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <AdsBanner slotId='modal-ad' slotSize={[226, 81]} />
        </ModalHeader>
        <ModalBody gap='16px' textAlign='center'>
          <Text fontSize='20px' fontWeight='700' lineHeight='1.3'>
            {t(tKeys.youHaveReachedMaximumLimit)}
          </Text>
          <Text fontWeight='600'>{t(tKeys.manageYourNumberOfLeagues)}</Text>
        </ModalBody>
        <ModalFooter flexDirection='column' marginTop='10px'>
          <Button marginTop='20px' onClick={onClose} variant='link'>
            {t(tKeys.close)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
