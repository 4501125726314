import React, { FC, ReactElement } from 'react';

import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import AdsBanner from '@/components/AdsBanner';

import logo from '@/modules/Bracket/assets/modal-logo.png';

interface ModalWindowProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean;
  onClose: () => void;
  BodyContent?: ReactElement;
  FooterContent?: ReactElement;
  hasCloseButton?: boolean;
}
export const ModalWindow: FC<ModalWindowProps> = ({
  isOpen,
  onClose,
  BodyContent,
  FooterContent,
  hasCloseButton = true,
  ...modalProps
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'1200px'} {...modalProps}>
      <ModalOverlay />
      <ModalContent
        bg={'linear-gradient(141.41deg, #153449 1.32%, #3D617B 98.18%)'}
        borderRadius='4px'
        boxShadow='0px 4px 4px 0px #0000008C'
        color='white'
        maxH={['98vh', 'auto']}
        my='0'
        px='16px'
        width={['auto', '80vw']}
      >
        <ModalHeader px='0'>
          <Box h='81px' m={'0 auto'}>
            <AdsBanner slotId='modal-ad' slotSize={[226, 81]} />
          </Box>
        </ModalHeader>

        {hasCloseButton && <ModalCloseButton />}

        <ModalBody overflowY='auto' px='0'>
          {BodyContent}
        </ModalBody>
        {FooterContent && (
          <ModalFooter pb='0' px='0'>
            {FooterContent}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
