import React from 'react';

import { Flex, Text, Box, Button, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';

import { CreateLeagueModal } from '@/modules/Leagues/containers/CreateLeagueModal';
import { Search } from '@/modules/Leagues/containers/Search';
import { TypeCheckboxes } from '@/modules/Leagues/containers/TypeCheckboxes';

type Props = {
  isMobile: boolean;
  onResetOffset: () => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

export const Filter: React.FC<Props> = (props) => {
  const { isMobile, onResetOffset, setSearch } = props;
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { withAuthCheck } = useAuthContext();
  const { isOver } = useGameDate();

  const handleCreateLeague = () => {
    withAuthCheck(() => onOpen());
  };

  return (
    <Flex
      alignItems='center'
      background='linear-gradient(141.41deg, #153449 1.32%, #3D617B 98.18%)'
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'normal' : 'space-between'}
      padding={isMobile ? '16px' : '40px 24px'}
      width='100%'
    >
      <Text
        fontFamily='Antonio'
        fontSize='30px'
        fontWeight='700'
        marginBottom={isMobile ? '12px' : '0'}
        textAlign={isMobile ? 'center' : 'left'}
        textTransform={'uppercase'}
      >
        {t(tKeys.leagues)}
      </Text>

      <Search onResetOffset={onResetOffset} setSearch={setSearch} />
      <Box margin={isMobile ? '12px 0 18px 0' : '0'}>
        <TypeCheckboxes onResetOffset={onResetOffset} />
      </Box>

      <Button
        isDisabled={isOver}
        maxWidth='none'
        onClick={handleCreateLeague}
        width={isMobile ? '100%' : '180px'}
      >
        {t(tKeys.createLeague)}
      </Button>
      <CreateLeagueModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};
