import React, { useMemo } from 'react';

import { Box, Container, Flex, Grid, GridItem, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import AdsBorderLeftImage from '@/assets/images/ads-border-left.png';
import AdsBorderRightImage from '@/assets/images/ads-border-right.png';
import AdsTopLeftImage from '@/assets/images/ads-top-left.png';
import AdsTopRightImage from '@/assets/images/ads-top-right.png';
import BgDesktopImage from '@/assets/images/bg-desktop.jpg';

import AdsBanner from '@/components/AdsBanner';
import { Header } from '@/components/Layout/Header';
import { Navigation } from '@/components/Navigation';

import { RoutesEnum } from '@/enums/routes.enum';

import { useLang } from '@/hooks/useLang';
import { useSettings } from '@/hooks/useSettings';

import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';
import { SidebarContainer } from '@/modules/Sidebar/containers/SidebarContainer';

import { addIdToRoute, isPathEqualsToPathname } from '@/routes/utils';

export const WithoutBannersLayout: React.FC = () => {
  const { pathname } = useLocation();
  const { isDesktop, isTablet, isMobile, isLgDesktop } = useSettings();
  const { id } = useParams();
  const { lang } = useLang();
  const leagueData = useSelector(getLeagueData);

  const isLeagueManagePage =
    pathname === `/${lang}/${addIdToRoute(RoutesEnum.leagueManage, id)}`;
  const isRootPage = pathname === RoutesEnum.root;
  const isPageWithoutXGaps = useMemo(
    () =>
      [
        RoutesEnum.leagues,
        RoutesEnum.leagueManage,
        RoutesEnum.leaderboard,
      ].some((x) => isPathEqualsToPathname(x, pathname)),
    [pathname],
  );
  const renderLogoAd = () => {
    if (isLeagueManagePage) {
      if (leagueData)
        return (
          <AdsBanner
            h='138px'
            justifyContent='flex-start'
            mt='30px'
            slotId='populogo-ad'
            slotSize={[331, 138]}
            w='100%'
          />
        );
      return null;
    } else {
      return (
        <AdsBanner
          h='138px'
          justifyContent='flex-start'
          mt='30px'
          slotId='populogo-ad'
          slotSize={[331, 138]}
          w='100%'
        />
      );
    }
  };
  return (
    <Flex
      align='center'
      bg={`url(${BgDesktopImage})`}
      bgAttachment='fixed'
      bgPosition='center'
      bgRepeat='no-repeat'
      bgSize='cover'
      direction='column'
      minH='100vh'
      w='100%'
    >
      <SidebarContainer />
      {isMobile && <Header />}

      <Container
        alignItems='center'
        display='flex'
        flexDirection='column'
        maxW={isLgDesktop ? '1000px' : '100%'}
        px={isPageWithoutXGaps && isMobile ? '0' : '1rem'}
        w='100%'
      >
        {!isMobile && !isRootPage && renderLogoAd()}
        <Navigation />
        <Outlet />
      </Container>
    </Flex>
  );
};
