import React, { forwardRef, useEffect, useState } from 'react';

import {
  Flex,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { useGameDate } from '@/hooks/useGameDate';
import { useSettings } from '@/hooks/useSettings';

import { RemoveUser } from '@/modules/LeagueManage/containers/RemoveUser';
import { MemberType } from '@/modules/LeagueManage/leagueManage.types';
import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { leagueIcons } from '@/modules/Leagues/leagues.mocks';

import {
  formatNumberWithComma,
  nhlThinScrollbarStyles,
} from '@/utils/common.util';

type Props = {
  isManageMode: boolean;
  membersData: MemberType[] | undefined;
  ref: any;
  leagueId: string | undefined;
  handleResetMembersOffset(): void;
  offset: number;
  isFetching: boolean;
};
export const MembersTable = forwardRef<HTMLTableRowElement, Props>(
  (
    {
      isManageMode,
      membersData,
      leagueId,
      handleResetMembersOffset,
      isFetching,
      offset,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { isMobile } = useSettings();

    const { isOver } = useGameDate();
    const tableHeaders = isOver
      ? [
          { align: 'center', label: t(tKeys.rank), isHighlighted: false },
          { align: 'left', label: t(tKeys.name), isHighlighted: false },
          { align: 'center', label: t(tKeys.cupPick), isHighlighted: false },
          {
            align: 'center',
            label: t(tKeys.maxPossiblePts),
            isHighlighted: false,
          },
          {
            align: 'center',
            label: t(tKeys.totailPoints),
            isHighlighted: true,
          },
        ]
      : [
          { align: 'center', label: t(tKeys.rank), isHighlighted: false },
          { align: 'left', label: t(tKeys.name), isHighlighted: false },
          {
            align: 'center',
            label: t(tKeys.maxPossiblePts),
            isHighlighted: false,
          },
          {
            align: 'center',
            label: t(tKeys.totailPoints),
            isHighlighted: true,
          },
        ];

    return (
      <TableContainer
        height='410px'
        overflowX='auto'
        overflowY='unset'
        position='relative'
        sx={nhlThinScrollbarStyles}
      >
        <Table
          minW='max-content'
          style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}
          variant='unstyled'
        >
          <Thead>
            <Tr padding='8px'>
              {tableHeaders.map((item, idx) => (
                <Th
                  background='#192E3B'
                  color={item.isHighlighted ? 'white' : 'ignisGrey'}
                  fontSize='14px'
                  fontWeight={item.isHighlighted ? '900' : '600'}
                  key={idx}
                  position='sticky'
                  textAlign={item.align as any}
                  textTransform='capitalize'
                  top='-1px'
                  zIndex='1'
                >
                  {item.label}
                </Th>
              ))}
              {!isMobile && (
                <Th
                  background='#192E3B'
                  position='sticky'
                  top='-1px'
                  zIndex='1'
                />
              )}
            </Tr>
          </Thead>
          <Tbody>
            {membersData?.map((user, index) => (
              <Tr
                background='rgba(18, 18, 18, 0.50)'
                key={`${user.user_id}-${index}`}
                ref={index === membersData?.length - 1 ? ref : undefined}
                w='100%'
              >
                {isMobile && isManageMode ? (
                  <Td>
                    <RemoveUser
                      handleResetMembersOffset={handleResetMembersOffset}
                      leagueId={leagueId as string}
                      userId={String(user?.user_id)}
                    />
                  </Td>
                ) : (
                  <Td
                    fontFamily='Antonio'
                    fontSize='24px'
                    fontWeight='700'
                    textAlign='center'
                  >
                    {formatNumberWithComma(user?.rank) || '--'}
                  </Td>
                )}

                <Td fontSize='15px'>{user?.username || '--'}</Td>
                {isOver && (
                  <Td
                    alignItems='center'
                    display='flex'
                    justifyContent='center'
                  >
                    <Image
                      height='35px'
                      src={
                        user?.entry?.match_401_pick
                          ? `https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${user?.entry?.match_401_pick}.svg`
                          : leagueIcons[LeagueIconKey.NhlLeague]
                      }
                    />
                  </Td>
                )}

                <Td fontSize='15px' textAlign='center'>
                  {formatNumberWithComma(user?.entry?.possible_points) || '--'}
                </Td>
                <Td
                  color='white'
                  fontFamily='Antonio'
                  fontSize='24px'
                  fontWeight='700'
                  textAlign='center'
                >
                  {formatNumberWithComma(user?.entry?.points) || '--'}
                </Td>
                {!isMobile && (
                  <Td>
                    {isManageMode && (
                      <RemoveUser
                        handleResetMembersOffset={handleResetMembersOffset}
                        leagueId={leagueId as string}
                        userId={String(user?.user_id)}
                      />
                    )}
                  </Td>
                )}
              </Tr>
            ))}

            {Boolean(isFetching && offset) && (
              <Tr background='rgba(18, 18, 18, 0.50)' w='100%'>
                <Td
                  colSpan={
                    !isMobile ? tableHeaders?.length + 1 : tableHeaders?.length
                  }
                >
                  <Flex
                    alignItems='center'
                    justifyContent='center'
                    width='100%'
                  >
                    <Spinner size='lg' />
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    );
  },
);

MembersTable.displayName = 'MembersTable';
