import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { viewSCPath } from '@/modules/Bracket/bracket.helpers';
import { SET_BRACKET_MODE } from '@/modules/Bracket/bracket.slice';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

import { createPath } from '@/routes/types';

export const useExitEdit = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const bracketViewType = searchParams.get(SearchParamsEnum.viewType) as
    | BracketMode
    | undefined;

  const isNewPage = useMemo(
    () => pathname === createPath({ path: RoutesEnum.bracketsNew }),
    [pathname],
  );

  const backRoute = useMemo(() => {
    if (pathname == bracketsSecondChancePath() && bracketViewType)
      return viewSCPath();
    if (
      isNewPage ||
      (pathname == bracketsSecondChancePath() && !bracketViewType)
    )
      return createPath({ path: RoutesEnum.home });
    return `${createPath({
      path: RoutesEnum.bracketsSpecific,
      params: { id },
    })}?${SearchParamsEnum.viewType}=${BracketMode.view}`;
  }, [bracketViewType, id, isNewPage, pathname]);

  const handleExitEdit = useCallback(() => {
    dispatch(SET_BRACKET_MODE(BracketMode.view));
    navigate(backRoute);
  }, [backRoute, dispatch, navigate]);

  return { handleExitEdit };
};
