import { Helmet } from 'react-helmet-async';

import Main from '@/modules/Main';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Home | Bracket Challenge</title>
        <meta
          content='The Stanley Cup Playoffs Bracket Challenge is back for 2024! Create your bracket and show your skill.'
          name='description'
        />
      </Helmet>
      <Main />
    </>
  );
};

export default HomePage;
