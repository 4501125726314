import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useAppDispatch } from '@/store';

import { useGameDate } from '@/hooks/useGameDate';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import {
  CHANGE_BONUS_MODAL_VISIBILITY,
  CHANGE_BONUS_MODAL_VISIBILITY_SC,
  CHECK_IS_FINAL_FILLED,
  CHECK_IS_FINAL_FILLED_SC,
  OPEN_BONUS_MODAL,
  RESET_FINAL_MODAL_STATE,
  selectBracket,
  SET_ENTRY_MATCH_PICK,
  SET_ENTRY_MATCH_PICK_SC,
  UPDATE_SERIES_RESULT,
} from '@/modules/Bracket/bracket.slice';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

import { IBracketEntry, SecondChanceEntryType } from '../bracket.types';
import { MATCHES_TO_FILL_ID } from '../helpers/constants';
import { updateFutureMatches } from '../helpers/updateFutureMatches';

export const useGameHook = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { game, entry, entrySC, hasBonusValues, seriesResults, bracketMode } =
    useSelector(selectBracket);
  const { isSecondChanceLive } = useGameDate();

  const teams = game.teams;

  const updateEntry = ({
    matchId,
    teamId,
  }: {
    matchId: number;
    teamId: number;
  }): void => {
    const entryCloned =
      pathname === bracketsSecondChancePath() ? { ...entrySC } : { ...entry }; // Needs because entry state updates after the whole function is completed
    // Set teamId into Entry
    dispatch(
      pathname === bracketsSecondChancePath()
        ? SET_ENTRY_MATCH_PICK_SC({ matchId, teamId })
        : SET_ENTRY_MATCH_PICK({ matchId, teamId }),
    );
    entryCloned[`match_${matchId}_pick`] = teamId; // Needs because entry state updates after the whole function is completed

    // Remove teamId from future matches in entry
    const removeFromEntry = <T extends { [key: string]: any } | undefined>(
      matchId: number,
      teamId: number,
      entry: T,
    ) => {
      const clonedEntry = { ...entry }; // Needs because entry state updates after the whole function is completed
      const nextMatch = MATCHES_TO_FILL_ID.find((el) => {
        if (el.prev.includes(matchId)) {
          return el;
        }
      });
      if (nextMatch?.match) {
        dispatch(
          pathname === bracketsSecondChancePath()
            ? SET_ENTRY_MATCH_PICK_SC({ matchId: nextMatch?.match, teamId: 0 })
            : SET_ENTRY_MATCH_PICK({ matchId: nextMatch?.match, teamId: 0 }),
        );
        if (clonedEntry) {
          clonedEntry[`match_${nextMatch?.match}_pick`] = 0;
        }
        // Needs because entry state updates after the whole function is completed
        removeFromEntry(nextMatch?.match, teamId, entry);
      }
      return clonedEntry; // Needs because entry state updates after the whole function is completed
    };
    const entryUpdated = removeFromEntry(matchId, teamId, entryCloned);

    // Remove teamId from future matches in seriesResults
    const updatedSeriesResult = updateFutureMatches({
      matchId,
      teamId,
      seriesResults,
    });
    dispatch(UPDATE_SERIES_RESULT(updatedSeriesResult));

    dispatch(RESET_FINAL_MODAL_STATE());

    openBonusModal(matchId);
    dispatch(
      pathname === bracketsSecondChancePath()
        ? CHANGE_BONUS_MODAL_VISIBILITY_SC(matchId)
        : CHANGE_BONUS_MODAL_VISIBILITY(matchId),
    );

    if ([301, 302, 401].includes(matchId)) {
      dispatch(
        pathname === bracketsSecondChancePath()
          ? CHECK_IS_FINAL_FILLED_SC(entryUpdated as SecondChanceEntryType)
          : CHECK_IS_FINAL_FILLED(entryUpdated as IBracketEntry),
      );
    }
  };

  const openBonusModal = (clickedMatchId: number) => {
    const isFirstRound = 101 <= clickedMatchId && clickedMatchId <= 108;
    const isSecondRound = 201 <= clickedMatchId && clickedMatchId <= 204;
    if (
      pathname === bracketsSecondChancePath() ? !isSecondRound : !isFirstRound
    )
      return;

    const isWestRound = [101, 102, 103, 104].includes(clickedMatchId);
    const isWestRoundSC = [201, 202].includes(clickedMatchId);
    const isEastRound = [105, 106, 107, 108].includes(clickedMatchId);
    const isEastRoundSC = [203, 204].includes(clickedMatchId);

    if (
      (pathname === bracketsSecondChancePath() ? isWestRoundSC : isWestRound) &&
      !hasBonusValues.west
    ) {
      return dispatch(
        OPEN_BONUS_MODAL({
          matchId: clickedMatchId,
          isSecondChance: pathname === bracketsSecondChancePath(),
        }),
      );
    }

    if (
      (pathname === bracketsSecondChancePath() ? isEastRoundSC : isEastRound) &&
      !hasBonusValues.east
    ) {
      return dispatch(
        OPEN_BONUS_MODAL({
          matchId: clickedMatchId,
          isSecondChance: pathname === bracketsSecondChancePath(),
        }),
      );
    }
  };

  return {
    teams,
    updateEntry,
  };
};
