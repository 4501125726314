import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppDispatch, useAppSelector } from '@/store';

import { ModalWindow } from '@/elements/ModalWindow';

import { RoutesEnum } from '@/enums/routes.enum';

import { useCheckBlockPhrase } from '@/hooks/useCheckBlockPhrase';
import { useDebounce } from '@/hooks/useDebounce';
import { useLang } from '@/hooks/useLang';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

import { createPath } from '@/routes/types';

import { useLazyCheckEntryNameQuery } from '../../../bracket.api';
import {
  CHANGE_BRACKET_NAME,
  CHANGE_BRACKET_NAME_SC,
  selectBracket,
  SET_READ_OFF_RULES,
} from '../../../bracket.slice';

export const EditBracketNameBlock = () => {
  const { pathname } = useLocation();
  const { lang } = useLang();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'edit-bracket-name',
  });
  const dispatch = useAppDispatch();
  const { entry, entrySC } = useAppSelector(selectBracket);

  const [dirty, setDirty] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedInputValue = useDebounce(inputValue, 500);
  const isNameAllowed = useCheckBlockPhrase(String(debouncedInputValue));

  const [checkName, { isLoading }] = useLazyCheckEntryNameQuery();

  const [error, setError] = useState<string>('');

  const entryName = useMemo(
    () =>
      pathname === bracketsSecondChancePath()
        ? entrySC?.entry_name
        : entry?.entry_name,
    [entry?.entry_name, entrySC?.entry_name, pathname],
  );

  useEffect(() => {
    if (String(debouncedInputValue).trim() && inputValue && isOpen && dirty) {
      checkName({
        entry_name: String(debouncedInputValue).trim(),
      })
        .unwrap()
        .then(({ exists }) => {
          if (exists) {
            setError(t(tKeys.thisBracketNameIsTaken));
          }
        });
    }
  }, [checkName, debouncedInputValue, dirty, inputValue, isOpen]);

  const changeHandler = (value: string) => {
    setDirty(true);
    setInputValue(value);
  };

  const onSaveChanges = () => {
    dispatch(
      pathname === bracketsSecondChancePath()
        ? CHANGE_BRACKET_NAME_SC(inputValue.trim())
        : CHANGE_BRACKET_NAME(inputValue.trim()),
    );

    if (
      pathname !== createPath({ path: RoutesEnum.bracketsNew }) ||
      pathname !== createPath({ path: RoutesEnum.bracketsSecondChance })
    ) {
      dispatch(SET_READ_OFF_RULES(true));
    }

    onClose();
  };

  useEffect(() => {
    setInputValue(String(entryName));
  }, [entryName]);

  useEffect(() => {
    if (debouncedInputValue) {
      setError(isNameAllowed ? '' : tKeys.inappropriateLanguage);
    }
  }, [isNameAllowed, debouncedInputValue]);

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' px={'10px'}>
        <Text>{entryName}</Text>
        {/* Edit Button */}
        <IconButton
          aria-label='edit'
          boxShadow={'none'}
          h={'auto'}
          icon={
            <svg
              fill='none'
              height='24'
              viewBox='0 0 24 24'
              width='24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3 17.2501V21.0001H6.75L17.81 9.94006L14.06 6.19006L3 17.2501ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z'
                fill='white'
              />
            </svg>
          }
          onClick={onOpen}
          variant='unstyled'
        />
      </Flex>

      <ModalWindow
        BodyContent={
          <>
            <Heading my={'15px'} size='sm' textAlign='center'>
              {t(tKeys.editBracketName)}
            </Heading>

            <Input
              _focusVisible={{
                boxShadow: '0px 4px 10px 0px #00000082 inset',
              }}
              bg={'inputBg'}
              border='1px solid'
              borderColor={error || !isNameAllowed ? 'tomato' : 'transparent'}
              borderRadius='50px'
              boxShadow={'0px 4px 10px 0px #00000073 inset'}
              fontFamily='"Antonio", sans-serif'
              fontSize={'30px'}
              fontWeight={700}
              h={'70px'}
              maxLength={20}
              onChange={(e) => changeHandler(e.target.value)}
              outline={'none'}
              textAlign={'center'}
              type='text'
              value={inputValue}
            />
            <Text
              color={error || !isNameAllowed ? 'tomato' : 'white'}
              fontSize={'12px'}
              mt={'10px'}
              opacity={error || !isNameAllowed ? 1 : 0.5}
            >
              {t(error || tKeys.maxCharacters)}
            </Text>

            <Button
              isDisabled={
                inputValue === entryName ||
                !inputValue.trim() ||
                Boolean(error) ||
                !isNameAllowed
              }
              isLoading={isLoading}
              mt={'30px'}
              onClick={onSaveChanges}
            >
              {t(tKeys.saveChanges)}
            </Button>
            <Button mt={'15px'} onClick={onClose} variant='ghost'>
              {t(tKeys.cancel)}
            </Button>
          </>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
