import { IBracketEntry, SeriesResultsType } from '../bracket.types';

export interface MatchesByRoundsType {
  roundOne: SeriesResultsType[];
  roundOneWest: SeriesResultsType[];
  roundOneEast: SeriesResultsType[];
  roundTwoWest: SeriesResultsType[];
  roundTwoEast: SeriesResultsType[];
  roundThreeWest: SeriesResultsType[];
  roundThreeEast: SeriesResultsType[];
  roundTwo: SeriesResultsType[];
  final: SeriesResultsType;
}
export const getMatchesByRounds = (
  seriesResults: SeriesResultsType[],
): MatchesByRoundsType => {
  const roundOne = seriesResults.filter((_) => _.round_sequence === 1);
  const roundOneWest = roundOne.slice(0, 4);
  const roundOneEast = roundOne.slice(4, 8);

  const roundTwo = seriesResults.filter((_) => _.round_sequence === 2);
  const roundTwoWest = roundTwo.slice(0, 2);
  const roundTwoEast = roundTwo.slice(2, 4);

  const roundThree = seriesResults.filter((_) => _.round_sequence === 3);
  const roundThreeWest = roundThree.slice(0, 1);
  const roundThreeEast = roundThree.slice(1, 2);

  const roundFour = seriesResults.filter((_) => _.round_sequence === 4);

  return {
    roundOne,
    roundOneWest,
    roundOneEast,
    roundTwoWest,
    roundTwoEast,
    roundThreeWest,
    roundThreeEast,
    roundTwo,
    final: roundFour[0],
  };
};

export const getEntryCorrectPct = <
  T extends { [key: string]: any } | undefined,
>(
  entry: T,
  seriesResults: SeriesResultsType[],
): number => {
  const scoredMatches = seriesResults.filter((_) => _.is_scored);
  let correctCount = 0;
  for (const result of seriesResults) {
    if (result.winner_id) {
      if (
        entry?.[`match_${result.id}_pick`] &&
        entry[`match_${result.id}_pick`] == result.winner_id
      ) {
        correctCount++;
      }
    }
  }

  return Math.round((correctCount / scoredMatches.length) * 100);
};

export const getSecondChanceEntryCorrectPct = <
  T extends { [key: string]: any } | undefined,
>(
  entry: T,
  seriesResults: SeriesResultsType[],
): number => {
  const scoredMatches = seriesResults.filter((_) => _.is_scored && _.id > 200);
  let correctCount = 0;
  for (const result of seriesResults) {
    if (result.winner_id) {
      if (
        entry?.[`match_${result.id}_pick`] &&
        entry[`match_${result.id}_pick`] == result.winner_id
      ) {
        correctCount++;
      }
    }
  }

  if (scoredMatches.length == 0) {
    return 0;
  }
  return Math.round((correctCount / scoredMatches.length) * 100);
};
