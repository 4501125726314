import React, { useCallback, useMemo } from 'react';

import { Button, Flex, IconButton, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppSelector } from '@/store';

import DownloadIcon from '@/assets/icons/download.svg';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useGameDate } from '@/hooks/useGameDate';
import { useSettings } from '@/hooks/useSettings';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { editSCPath } from '@/modules/Bracket/bracket.helpers';
import {
  REVERT_DEFAULT_ENTRY_STATE,
  selectBracket,
} from '@/modules/Bracket/bracket.slice';
import { BracketMode } from '@/modules/Bracket/brackets.enums';
import { usePathToBracket } from '@/modules/Bracket/hooks/usePathToBracket';

import { createPath } from '@/routes/types';

import { BracketsDropdown } from './BracketsDropdown';

export const ViewModeDesktopHeader: React.FC<{
  handleDownload(): void;
}> = ({ handleDownload }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { withAuthCheck } = useAuthContext();
  const { bracketPathById } = usePathToBracket();
  const { isDesktop } = useSettings();
  const { isOver, isSecondChanceOver, isSecondChanceLive, isLive } =
    useGameDate();

  const { user, entry, entrySC } = useAppSelector(selectBracket);

  const isSecondChancePage = pathname === bracketsSecondChancePath();

  const handleClickNewBracket = () => {
    withAuthCheck(() => {
      dispatch(REVERT_DEFAULT_ENTRY_STATE());
      navigate(createPath({ path: RoutesEnum.bracketsNew }));
    });
  };

  const renderBracketName = () => {
    if (isSecondChancePage) {
      return <Text>{entrySC.entry_name}</Text>;
    } else {
      if (user && user.user_id == entry.user_id) {
        return <BracketsDropdown />;
      } else {
        return <Text>{entry.entry_name}</Text>;
      }
    }
  };

  const isDownloadButtonVisible = useMemo(
    () =>
      isSecondChancePage
        ? user && Number(user.user_id) === Number(entrySC.user_id)
        : user && Number(user.user_id) === Number(entry.user_id),
    [entry.user_id, entrySC.user_id, isSecondChancePage, user],
  );

  const isEditButtonVisible = useMemo(() => {
    if (isSecondChancePage) {
      return (
        Number(user?.user_id) === Number(entrySC?.user_id) && isSecondChanceLive
      );
    }
    return isLive;
  }, [
    entrySC?.user_id,
    isLive,
    isSecondChanceLive,
    isSecondChancePage,
    user?.user_id,
  ]);

  return (
    <>
      <Flex
        alignItems='center'
        bgColor='#192E3B80'
        gap='20px'
        justify={'center'}
        p='16px'
        position='relative'
        w={'100%'}
      >
        {renderBracketName()}

        {isDownloadButtonVisible && (
          <IconButton
            aria-label='download'
            icon={<Image alt='' src={DownloadIcon} />}
            onClick={handleDownload}
            position='absolute'
            right='20px'
            top='50%'
            transform='translateY(-50%)'
            variant='unstyled'
            zIndex='1'
          />
        )}

        {isEditButtonVisible && (
          <Button
            as={Link}
            to={isSecondChancePage ? editSCPath() : bracketPathById(id)}
            w={'fit-content'}
          >
            {t(tKeys.editBracket)}
          </Button>
        )}
        {/*!isOver && (
          <>
            <Button as={Link} to={bracketPathById(id)} w={'fit-content'}>
              {t(tKeys.editBracket)}
            </Button>
            <Button
              onClick={handleClickNewBracket}
              rightIcon={<Image src={HierarchyDark} />}
              width='fit-content'
            >
              {t(tKeys.createNewBracket)}
            </Button>
          </>
        )*/}
      </Flex>
    </>
  );
};
