import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getRemainTime } from '@/utils/getRemainTime';

const checkExpired = (date?: Date) =>
  !!date && date.getTime() - new Date().getTime() <= 0;

export const useTimer = (inputDate?: Date | string) => {
  const { t } = useTranslation();
  const date = useMemo(
    () => (typeof inputDate === 'string' ? new Date(inputDate) : inputDate),
    [inputDate],
  );

  const [timeLeft, setTimeLeft] = useState(getRemainTime({ date, t }));
  const [isExpired, setIsExpired] = useState(checkExpired(date));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(getRemainTime({ date, t }));
      setIsExpired(checkExpired(date));
    }, 1000);

    if (isExpired) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isExpired, date]);

  return { timeLeft, isExpired };
};
