import { FC } from 'react';

import { Box, Flex } from '@chakra-ui/react';

export const CompareLine: FC<{
  color1: string;
  color2: string;
  percent1: number;
  percent2: number;
}> = ({ color1, color2, percent1, percent2 }) => {
  return (
    <>
      <Flex h='12px' w={['200px', '100%']}>
        <Box bgColor={color1} h='100%' w={`${percent1}%`} />
        <Box bgColor={color2} h='100%' w={`${percent2}%`} />
      </Flex>
    </>
  );
};
