import React, { useEffect, useState } from 'react';

import { Image, ImageProps } from '@chakra-ui/react';

import logoSrc from '@/modules/Bracket/assets/Playoffs-logo.png';

export const NO_WINNER_TEAM_ID = 0;

interface ChampionLogoProps extends ImageProps {
  teamId: number | string;
}
export const ChampionLogo: React.FC<ChampionLogoProps> = ({
  teamId,
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`@/assets/champions-logos/${teamId}.png`);
        setImageSrc(response.default);
      } catch (err) {
        setImageSrc(logoSrc);
      } finally {
        setLoading(false);
      }
    };

    if (teamId !== NO_WINNER_TEAM_ID) {
      fetchImage();
    }
  }, [teamId]);

  return (
    <Image
      h='100%'
      src={teamId === NO_WINNER_TEAM_ID ? logoSrc : imageSrc}
      {...props}
    />
  );
};
