import React, { useMemo } from 'react';

import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

import { useSettings } from '@/hooks/useSettings';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { EditConferenceBonus } from '@/modules/Bracket/components/EditConferenceBonus';
import { FinalBonusModal } from '@/modules/Bracket/components/FinalBonusModal';
import { ModalEditButton } from '@/modules/Bracket/components/ModalEditButton';
import { usePathToBracket } from '@/modules/Bracket/hooks/usePathToBracket';

import { createPath } from '@/routes/types';

import {
  CHANGE_FINAL_BONUS_MODAL_STEP,
  selectBracket,
} from '../../../bracket.slice';
import { BracketMode } from '../../../brackets.enums';
import { ClearButton } from '../EditModeDesktopHeader/ClearButton';
import { EditBracketNameBlock } from '../EditModeDesktopHeader/EditBracketNameBlock';
import { RandomPicksButton } from '../EditModeDesktopHeader/RandomPicksButton';
import {
  MobileHeaderWrapper,
  SubHeaderWrapper,
} from '../ViewModeMobileHeader/MobileHeaderWrapper';

export const EditModeMobileHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useSettings();
  const { pathname } = useLocation();
  const {
    isOpen: isOpenFinal,
    onClose: onCloseFinal,
    onOpen: onOpenFinal,
  } = useDisclosure({
    id: 'final',
  });

  const onTiebreakerClick = () => {
    dispatch(CHANGE_FINAL_BONUS_MODAL_STEP(2));
    onOpenFinal();
  };

  const isEditBtnVisible = useMemo(
    () =>
      ![
        createPath({ path: RoutesEnum.bracketsNew }),
        bracketsSecondChancePath(),
      ].includes(pathname),
    [pathname],
  );
  return (
    <>
      <MobileHeaderWrapper>
        <EditBracketNameBlock />
        {isEditBtnVisible && <EditButton />}
      </MobileHeaderWrapper>

      {/* Sub Header */}
      <SubHeaderWrapper>
        <Flex alignItems='center' gap='20px' justifyContent='center' py='10px'>
          <RandomPicksButton size='sm' />
          <ClearButton size='sm' />
        </Flex>
        <Flex
          alignItems='center'
          fontSize='14px'
          gap='8px'
          justifyContent='center'
          py='10px'
        >
          <EditConferenceBonus />
        </Flex>
        <Flex
          alignItems='center'
          fontSize='14px'
          justifyContent='center'
          pb='10px'
          pt='6px'
        >
          <ModalEditButton
            gap={isMobile ? '4px' : '10px'}
            onClick={onTiebreakerClick}
          >
            {t(tKeys.tieBreaker)}
          </ModalEditButton>

          <FinalBonusModal isOpen={isOpenFinal} onClose={onCloseFinal} />
        </Flex>
      </SubHeaderWrapper>
    </>
  );
};

const EditButton = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { bracketMode } = useSelector(selectBracket);
  const { bracketPathById } = usePathToBracket();

  if (bracketMode !== BracketMode.view) return null;

  return (
    <Button
      as={Link}
      fontSize='16px'
      gap='8px'
      minW='94px'
      size='sm'
      to={bracketPathById(id)}
      variant='outline'
    >
      {t(tKeys.edit)}
      {/*<EditIcon />*/}
      <svg
        fill='none'
        height='24'
        viewBox='0 0 25 24'
        width='25'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.22656 17.2501V21.0001H6.97656L18.0366 9.94006L14.2866 6.19006L3.22656 17.2501ZM20.9366 7.04006C21.3266 6.65006 21.3266 6.02006 20.9366 5.63006L18.5966 3.29006C18.2066 2.90006 17.5766 2.90006 17.1866 3.29006L15.3566 5.12006L19.1066 8.87006L20.9366 7.04006Z'
          fill='white'
        />
      </svg>
    </Button>
  );
};
