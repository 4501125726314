import { useMediaQuery } from '@chakra-ui/react';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';

export const useSettings = () => {
  const [isDesktop, isMobile, isTablet, isLgDesktop] = useMediaQuery(
    [
      MediaQueriesEnum.isDesktop,
      MediaQueriesEnum.isMobile,
      MediaQueriesEnum.isTablet,
      MediaQueriesEnum.isLgDesktop,
    ],
    {
      ssr: false,
    },
  );

  return { isDesktop, isMobile, isTablet, isLgDesktop };
};
