import React, { useCallback } from 'react';

import { ButtonProps } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsManageMode } from '@/modules/LeagueManage/leagueManage.selectors';
import { TOGGLE_IS_MANAGE_MODE } from '@/modules/LeagueManage/leagueManage.slice';

import { LeagueManageButton as LeagueManageButtonComponent } from '../components/LeagueManageButton';

export const LeagueManageButton: React.FC<ButtonProps> = (props) => {
  const dispatch = useDispatch();

  const isManageMode: boolean = useSelector(getIsManageMode);

  const handleManageLeague = useCallback(() => {
    dispatch(TOGGLE_IS_MANAGE_MODE());
  }, [dispatch]);

  return (
    <LeagueManageButtonComponent
      isManageMode={isManageMode}
      onManageLeague={handleManageLeague}
      {...props}
    />
  );
};
