import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import {
  IBracketEntry,
  SecondChanceEntryType,
} from '@/modules/Bracket/bracket.types';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

import { createPath } from '@/routes/types';

export const getBracketPoints = (
  points: string,
  possiblePoints: string,
): string => {
  return `${points === '0' ? '--' : points} / ${possiblePoints}`;
};

export const getFilteredEntries = (
  entries: IBracketEntry[] | undefined,
  search: string,
): IBracketEntry[] | undefined => {
  if (entries && search) {
    return entries.filter((entry) =>
      entry.entry_name.toLowerCase().includes(search.toLowerCase()),
    );
  }

  return entries;
};

export const isAllMatchesFilledExceptChampion = (entry: IBracketEntry) => {
  const allMatchesExceptChampion = 16;
  let steps = 0;

  const {
    match_101_pick,
    match_102_pick,
    match_103_pick,
    match_104_pick,
    match_101_match_played,
    match_102_match_played,
    match_103_match_played,
    match_104_match_played,
    match_105_pick,
    match_106_pick,
    match_107_pick,
    match_108_pick,
    match_105_match_played,
    match_106_match_played,
    match_107_match_played,
    match_108_match_played,
    match_201_pick,
    match_202_pick,
    match_203_pick,
    match_204_pick,
    match_301_pick,
    match_302_pick,
  } = entry;

  if (match_101_pick > 0) {
    steps++;
  }
  if (match_102_pick > 0) {
    steps++;
  }
  if (match_103_pick > 0) {
    steps++;
  }
  if (match_104_pick > 0) {
    steps++;
  }
  if (match_105_pick > 0) {
    steps++;
  }
  if (match_106_pick > 0) {
    steps++;
  }
  if (match_107_pick > 0) {
    steps++;
  }
  if (match_108_pick > 0) {
    steps++;
  }

  if (
    match_101_match_played > 0 &&
    match_102_match_played > 0 &&
    match_103_match_played > 0 &&
    match_104_match_played > 0
  ) {
    steps++;
  }
  if (
    match_105_match_played > 0 &&
    match_106_match_played > 0 &&
    match_107_match_played > 0 &&
    match_108_match_played > 0
  ) {
    steps++;
  }

  if (match_201_pick > 0) {
    steps++;
  }
  if (match_202_pick > 0) {
    steps++;
  }
  if (match_203_pick > 0) {
    steps++;
  }
  if (match_204_pick > 0) {
    steps++;
  }
  if (match_301_pick > 0) {
    steps++;
  }
  if (match_302_pick > 0) {
    steps++;
  }

  return steps >= allMatchesExceptChampion;
};
export const isAllSCMatchesFilledExceptChampion = (
  entry: SecondChanceEntryType,
) => {
  const allMatchesExceptChampion = 8;
  let steps = 0;

  const {
    match_201_pick,
    match_202_pick,
    match_203_pick,
    match_204_pick,
    match_301_pick,
    match_302_pick,
    match_201_match_played,
    match_202_match_played,
    match_203_match_played,
    match_204_match_played,
  } = entry;

  if (match_201_pick > 0) {
    steps++;
  }
  if (match_202_pick > 0) {
    steps++;
  }
  if (match_203_pick > 0) {
    steps++;
  }
  if (match_204_pick > 0) {
    steps++;
  }
  if (match_301_pick > 0) {
    steps++;
  }
  if (match_302_pick > 0) {
    steps++;
  }

  if (match_201_match_played > 0 && match_202_match_played > 0) {
    steps++;
  }
  if (match_203_match_played > 0 && match_204_match_played > 0) {
    steps++;
  }

  return steps >= allMatchesExceptChampion;
};

export const getMainPtsByMatchId = (match_id: number, is_correct: boolean) => {
  if ([101, 102, 103, 104, 105, 106, 107, 108].includes(match_id))
    return is_correct ? 10 : 0;
  if ([201, 202, 203, 204].includes(match_id)) return is_correct ? 25 : 0;
  if ([301, 302].includes(match_id)) return is_correct ? 50 : 0;
  if ([401].includes(match_id)) return is_correct ? 100 : 0;
  return 0;
};

export const getBonusPtsByMatchId = (match_id: number, is_correct: boolean) => {
  if ([101, 102, 103, 104, 105, 106, 107, 108].includes(match_id))
    return is_correct ? 3 : 0;
  return undefined;
};

export const getBonusPtsByMatchIdSC = (
  match_id: number,
  is_correct: boolean,
) => {
  if ([201, 202, 203, 204].includes(match_id)) return is_correct ? 3 : 0;
  return undefined;
};

export const DEFAULT_ENTRY: IBracketEntry = {
  entry_id: 0,
  user_id: 0,
  points: '0',
  possible_points: '0',
  entry_name: '',
  // champion_id: 0,
  tiebreaker: '',
  match_101_pick: 0,
  match_102_pick: 0,
  match_103_pick: 0,
  match_104_pick: 0,
  match_105_pick: 0,
  match_106_pick: 0,
  match_107_pick: 0,
  match_108_pick: 0,
  match_201_pick: 0,
  match_202_pick: 0,
  match_203_pick: 0,
  match_204_pick: 0,
  match_301_pick: 0,
  match_302_pick: 0,
  match_401_pick: 0,
  match_101_match_played: 0,
  match_102_match_played: 0,
  match_103_match_played: 0,
  match_104_match_played: 0,
  match_105_match_played: 0,
  match_106_match_played: 0,
  match_107_match_played: 0,
  match_108_match_played: 0,
};

export const DEFAULT_ENTRY_SC: SecondChanceEntryType = {
  entry_id: 0,
  entry_name: '',
  champion_id: 0,
  tiebreaker: '',
  match_201_pick: 0,
  match_202_pick: 0,
  match_203_pick: 0,
  match_204_pick: 0,
  match_301_pick: 0,
  match_302_pick: 0,
  match_401_pick: 0,
  match_201_match_played: 0,
  match_202_match_played: 0,
  match_203_match_played: 0,
  match_204_match_played: 0,
};

export const editSCPath = () => {
  const searchString = window.location.search;
  const searchParams = new URLSearchParams(searchString);
  searchParams.set(SearchParamsEnum.viewType, BracketMode.edit);

  return `${createPath({ path: RoutesEnum.bracketsSecondChance })}?${searchParams.toString()}`;
};

export const viewSCPath = () => {
  const searchString = window.location.search;
  const searchParams = new URLSearchParams(searchString);
  searchParams.set(SearchParamsEnum.viewType, BracketMode.view);

  return `${createPath({ path: RoutesEnum.bracketsSecondChance })}?${searchParams.toString()}`;
};
