import React from 'react';

import {
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import searchIcon from '@/assets/icons/search.svg';
import cupImg from '@/assets/images/cup.png';

import { useSettings } from '@/hooks/useSettings';

export const AssignBracketHeader: React.FC = () => {
  const { isMobile } = useSettings();

  const { t } = useTranslation();

  if (isMobile) return null;

  return (
    <Grid
      background='#172E3E'
      gap={2}
      padding='8px 12px'
      templateColumns='repeat(10, 1fr)'
      w='100%'
    >
      <GridItem alignItems='center' colSpan={3} display='flex'>
        <Text marginRight='8px'>Name</Text>
        <InputGroup width='145px'>
          <InputLeftElement pointerEvents='none' top='-4px'>
            <Image alt='search' height='18px' src={searchIcon} width='18px' />
          </InputLeftElement>
          <Input
            background='#1E3647'
            border='none'
            borderRadius='50px'
            boxShadow='0px 4px 10px 0px rgba(0, 0, 0, 0.45) inset'
            onChange={(e) => console.log(e.target.value)}
            size='sm'
            type='text'
          />
        </InputGroup>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        justifyContent='center'
      >
        <Text>{t(tKeys.stanleyCupFinal)}</Text>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={1}
        display='flex'
        justifyContent='center'
      >
        <Image height='31' src={cupImg} width='21px' />
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        justifyContent='center'
      >
        <Text>Preview</Text>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        justifyContent='center'
      >
        <Text>Assign</Text>
      </GridItem>
    </Grid>
  );
};
