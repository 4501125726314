import React from 'react';

import { Flex, Image } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';

import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { leagueIcons } from '@/modules/Leagues/leagues.mocks';

type Props = {
  width: string;
  height: string;
  borderRadius: string;
  padding: string;
  iconKey: LeagueIconKey;
  onClick?: () => void;
  isHoverDisabled?: boolean;
  sx?: SystemStyleObject;
};

export const LeagueIconLayout: React.FC<Props> = (props) => {
  const {
    width,
    height,
    borderRadius,
    padding,
    iconKey,
    onClick,
    isHoverDisabled,
    sx,
  } = props;

  return (
    <Flex
      _hover={
        onClick && !isHoverDisabled
          ? {
              transform: 'scale(1.2)',
              boxShadow: '0 0 5px 5px rgba(51, 113, 158)',
            }
          : undefined
      }
      background='#1E3647'
      borderRadius={borderRadius}
      boxShadow='0px 2.253px 5.633px 0px rgba(0, 0, 0, 0.25) inset'
      cursor={onClick ? 'pointer' : 'default'}
      height={height}
      justifyContent='center'
      onClick={onClick}
      padding={padding}
      sx={sx}
      transition='transform .2s'
      width={width}
    >
      <Image height='100%' src={leagueIcons[iconKey]} width='fit-content' />
    </Flex>
  );
};
