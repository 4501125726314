import React from 'react';

import { useMediaQuery } from '@chakra-ui/react';

import { useSettings } from '@/hooks/useSettings';

import { Leagues as LeaguesComponent } from '../components/Leagues';

export const Leagues: React.FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 900px)');
  const { isLgDesktop } = useSettings();

  return <LeaguesComponent isLgDesktop={isLgDesktop} isMobile={isMobile} />;
};
