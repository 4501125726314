import { API_BASE_URL } from '@/core/env';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { debounce } from '@/utils/debounce';

import { UNIQUE_NAME_VALIDATION_DELAY_MS } from './common.util';
import { getStorageItem } from './localStorage.util';

export const checkLeagueName = async (name: string): Promise<boolean> => {
  const jwt = getStorageItem<string>(StorageKeysEnum.jwt);

  try {
    const valid = await fetch(
      `${API_BASE_URL}/leagues/check-name?name=${name}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return valid.json().then((resp) => !resp.exists);
  } catch (err) {
    throw err;
  }
};

export const cacheTest = (asyncValidate: (val: string) => Promise<boolean>) => {
  let _valid = false;
  let _value = '';

  return async (value: string) => {
    if (value !== _value) {
      const response = await asyncValidate(value);
      _value = value;
      _valid = response;

      return response;
    }

    return _valid;
  };
};

export const checkOnUniqeLeagueName = debounce(
  cacheTest(checkLeagueName),
  UNIQUE_NAME_VALIDATION_DELAY_MS,
);
