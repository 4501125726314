import { Flex, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { ReactComponent as NhlIcon } from '@/assets/icons/nhl-shield.svg';

export const Header = () => {
  const { t } = useTranslation();
  return (
    <Flex align='center' bg='primaryBlue' gap='16px' p='16px'>
      <Icon as={NhlIcon} h='100%' w='56px' />
      <Text fontFamily='Antonio' fontSize='30px' fontWeight='700'>
        {t(tKeys.nhlOfficialBracketLeague)}
      </Text>
    </Flex>
  );
};
