import React, { useCallback, useRef, useState } from 'react';

import { toPng, getFontEmbedCSS } from 'html-to-image';

export const useDownloadBracket = () => {
  const [isLoading, setIsLoading] = useState(false);
  const bracketRef = useRef<HTMLDivElement>(null);

  const buildPng = useCallback(async () => {
    if (!bracketRef.current) return;

    let dataUrl = '';
    const minDataLength = 2000000;
    let i = 0;
    const maxAttempts = 10;
    const fontEmbedCss = await getFontEmbedCSS(bracketRef.current);
    while (dataUrl.length < minDataLength && i < maxAttempts) {
      dataUrl = await toPng(bracketRef.current, {
        cacheBust: true,
        skipFonts: true,
        width: bracketRef.current.scrollWidth,
        fontEmbedCSS: fontEmbedCss,
        backgroundColor: '#192E3B',
      });
      i += 1;
    }

    return dataUrl;
  }, []);

  const handleDownload = useCallback(() => {
    if (!bracketRef.current) return;

    setIsLoading(true);

    buildPng()
      .then((dataUrl) => {
        if (!dataUrl) return;

        const link = document.createElement('a');
        link.download = 'bracket.png';
        link.href = dataUrl;
        link.click();
      })
      .then(() => setIsLoading(false))
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [buildPng]);

  return { handleDownload, bracketRef, isLoading };
};
