import { FC } from 'react';

import { Button, ButtonProps, Heading, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppDispatch } from '@/store';

import { ModalWindow } from '@/elements/ModalWindow';

import { useJWT } from '@/hooks/useJWT';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

import { useLazyGetGameQuery } from '../../../bracket.api';
import {
  CLEAR_ENTRY,
  CLEAR_ENTRY_SC,
  selectBracket,
  SET_EAST_BONUS_QUESTION_ANSWERED,
  SET_ENTRY_ID,
  SET_ENTRY_ID_SC,
  SET_GAME,
  SET_WEST_BONUS_QUESTION_ANSWERED,
} from '../../../bracket.slice';

export const ClearButton: FC<ButtonProps> = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { jwt } = useJWT();
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'clear-button',
  });
  const { bracketMode } = useSelector(selectBracket);
  const [getGame, { isLoading }] = useLazyGetGameQuery();

  const isSecondChancePage = pathname === bracketsSecondChancePath();

  const onClear = () => {
    // Clear entry
    dispatch(isSecondChancePage ? CLEAR_ENTRY_SC() : CLEAR_ENTRY());
    dispatch(SET_WEST_BONUS_QUESTION_ANSWERED(false));
    dispatch(SET_EAST_BONUS_QUESTION_ANSWERED(false));
    // Return entry_id into entry
    if (!isSecondChancePage) {
      dispatch(SET_ENTRY_ID(id));
    }

    // Get game
    (async () => {
      try {
        const data = await getGame({ jwt }).unwrap();
        dispatch(SET_GAME(data?.game));
      } catch (error) {
        console.error(error);
      }
    })();
    // Close modal
    onClose();
  };

  return (
    <>
      <Button
        gap={'5px'}
        onClick={onOpen}
        variant='outline'
        w={'fit-content'}
        {...props}
      >
        {t(tKeys.clear)}
        <svg
          fill='none'
          height='24'
          viewBox='0 0 24 24'
          width='24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7 11V13H17V11H7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z'
            fill='white'
          />
        </svg>
      </Button>

      <ModalWindow
        BodyContent={
          <>
            <Heading mt={'15px'} size='sm' textAlign='center'>
              {t(tKeys.areYouSureClearSelections)}
            </Heading>

            <Button isLoading={isLoading} mt={'30px'} onClick={onClear}>
              {t(tKeys.yes)}
            </Button>
            <Button mt={'15px'} onClick={onClose} variant='ghost'>
              {t(tKeys.cancel)}
            </Button>
          </>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
