import { useCallback } from 'react';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useGameDate } from '@/hooks/useGameDate';

import { BracketMode } from '@/modules/Bracket/brackets.enums';

import { createPath } from '@/routes/types';

export const usePathToBracket = () => {
  const { isOver } = useGameDate();

  const bracketPathById = useCallback(
    <T>(id: T) =>
      `${createPath({
        path: RoutesEnum.bracketsSpecific,
        params: { id },
      })}?${SearchParamsEnum.viewType}=${isOver ? BracketMode.view : BracketMode.edit}`,
    [isOver],
  );

  return { bracketPathById };
};
