import { extendTheme } from '@chakra-ui/react';
import type { Styles } from '@chakra-ui/theme-tools';

// import breakpoints from './foundations/breakpoints';
import colors from './foundations/colors';
import components from './foundations/components';
import menu from './foundations/components/menu';
import fonts from './foundations/fonts';
// import radius from './foundations/radius';
import shadows from './foundations/shadows';
// import space from './foundations/space';

const styles: Styles = {
  global: () => ({
    'html, body, #root': {
      width: '100%',
    },

    body: {
      color: 'var(--chakra-colors-white)',
      backgroundColor: 'var(--chakra-colors-cuksBlue)',
    },

    '*': {
      boxSizing: 'border-box',
    },
  }),
};

const theme = extendTheme({
  // breakpoints,
  colors,
  components,
  fonts,
  // space,
  styles,
  shadows,
  menu,
  // radii: radius,
});

export default theme;
