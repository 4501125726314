import React from 'react';

import { Button, Image, Text, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import hierarchyIcon from '@/assets/icons/hierarchy.svg';
import cupImg from '@/assets/images/cup.png';

import { IBracketEntry, TeamType } from '@/modules/Bracket/bracket.types';
import { useGameHook } from '@/modules/Bracket/hooks/useGame.hook';
import { useGameTeamHexCode } from '@/modules/Bracket/hooks/useGameTeamHexCode';
import { LeagueLeaderboardType } from '@/modules/LeagueManage/leagueManage.types';
import { AddEntryToLeagueButton } from '@/modules/Leagues/containers/AddEntryToLeagueButton';

import colors from '@/theme/foundations/colors';

type Props = {
  entry: IBracketEntry;
  leagueId: string;
  onViewBracket: (bracketId: string | number) => void;
  onClose: () => void;
  onRefetchData?: any;
  leagueEntry: LeagueLeaderboardType | null;
};

export const AssignBracketTableMobileItem: React.FC<Props> = (props) => {
  const {
    entry,
    leagueId,
    onClose,
    onRefetchData,
    onViewBracket,
    leagueEntry,
  } = props;

  const { t } = useTranslation();
  const { getHexCode } = useGameTeamHexCode();

  return (
    <Flex
      align='center'
      background='#18252f'
      borderRadius='8px'
      direction='column'
      fontSize='14px'
      gap='12px'
      p='12px'
    >
      <Flex align='center'>
        <Text>{entry.entry_name}</Text>
      </Flex>
      <Flex justify='space-evenly' p='16px' w='100%'>
        <Flex direction='column' gap='8px'>
          <Text>{t(tKeys.stanleyCupFinals)}</Text>
          <Flex gap='10px'>
            <Flex
              align='center'
              bg={getHexCode(entry.match_301_pick)}
              h='61px'
              justify='center'
              position='relative'
              w='61px'
            >
              <Image
                alt='language-flag-image'
                src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_301_pick}.svg`}
                width='61px'
              />
            </Flex>
            <Flex
              align='center'
              bg={getHexCode(entry.match_302_pick)}
              h='61px'
              justify='center'
              position='relative'
              w='61px'
            >
              <Image
                alt='language-flag-image'
                src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_302_pick}.svg`}
                width='61px'
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex align='center' direction='column' gap='8px'>
          <Flex align='center' gap='4px'>
            <Image alt='cup-image' h='16px' src={cupImg} />
            <Text>{t(tKeys.champion)}</Text>
          </Flex>
          <Flex
            align='center'
            bg={getHexCode(entry.match_401_pick)}
            h='61px'
            justify='center'
            position='relative'
            w='61px'
          >
            <Image
              alt='language-flag-image'
              src={`https://allstarvotefilesde.blob.core.windows.net/nhl-team-logos/${entry.match_401_pick}.svg`}
              width='61px'
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex gap='12px'>
        <Button
          borderRadius='60px'
          onClick={() => onViewBracket(entry.entry_id)}
          p='8px 24px'
          rightIcon={<Image src={hierarchyIcon} />}
          variant='outline'
        >
          {t(tKeys.viewBracket)}
        </Button>
        <AddEntryToLeagueButton
          entryId={entry.entry_id}
          leagueEntry={leagueEntry}
          leagueId={leagueId}
          onClose={onClose}
          onRefetchData={onRefetchData}
        />
      </Flex>
    </Flex>
  );
};
