import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PageLoader } from '@/elements/PageLoader';

import { LeagueManageContext } from '@/modules/LeagueManage/context/LeagueManageProvider';
import {
  useLeague,
  useLeagueLeaderboard,
} from '@/modules/LeagueManage/leagueManage.hooks';
import {
  getIsLeaderboardLoading,
  getIsLeagueLoading,
  getIsMembersFetching,
  getIsMembersLoading,
} from '@/modules/LeagueManage/leagueManage.selectors';
import { SET_IS_MANAGE_MODE } from '@/modules/LeagueManage/leagueManage.slice';

import { LeagueManage as LeagueManageComponent } from '../components/LeagueManage';

export const LeagueManage: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLeagueLoading: boolean = useSelector(getIsLeagueLoading);

  const { refetchLeague } = useLeague(id);

  const handleRefetchData = () => {
    refetchLeague();
  };

  useEffect(
    () => () => {
      dispatch(SET_IS_MANAGE_MODE(false));
    },
    [dispatch],
  );

  if (isLeagueLoading) {
    return <PageLoader />;
  }

  return (
    <LeagueManageContext.Provider value={{ handleRefetchData }}>
      <LeagueManageComponent />
    </LeagueManageContext.Provider>
  );
};
