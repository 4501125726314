import React, { useCallback, useEffect, useState } from 'react';

import { useMediaQuery } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

import { useToast } from '@/hooks/useToast';

import { CreateLeagueSelectImage } from '@/modules/Leagues/containers/CreateLeagueSelectImage';
import { CreateLeagueSettings } from '@/modules/Leagues/containers/CreateLeagueSettings';
import { useMakeLeagueMutation } from '@/modules/Leagues/leagues.api';
import { initialCreateLeaguesFormValues } from '@/modules/Leagues/leagues.mocks';
import { LeaguesFormValues } from '@/modules/Leagues/leagues.types';
import { createLeagueValidationSchema } from '@/modules/Leagues/leagues.validation';

import { createPath } from '@/routes/types';

import { errorHandler } from '@/utils/api.util';

import { CreateLeagueModal as CreateLeagueModalComponent } from '../components/CreateLeagueModal';

interface CreateLeagueModalProps {
  isOpen: boolean;
  onClose(): void;
}
export const CreateLeagueModal: React.FC<CreateLeagueModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const [makeLeague, { isLoading: isSubmitting }] = useMakeLeagueMutation();

  // TODO global settings
  const [isMobile] = useMediaQuery('(max-width: 900px)');

  const [isImageSelection, setImageSelection] = useState<boolean>(false);

  const methods = useForm({
    mode: 'all',
    // @ts-expect-error converted is_private to boolean
    resolver: yupResolver(createLeagueValidationSchema(t)),
    defaultValues: initialCreateLeaguesFormValues,
  });

  const { handleSubmit, reset, formState, control } = methods;

  useEffect(() => {
    if (!isOpen) {
      formState.isDirty && reset();

      if (isImageSelection) {
        setImageSelection(false);
      }
    }
  }, [isOpen, isImageSelection]);

  const handleGoToLeagueSettings = useCallback(() => {
    setImageSelection(false);
  }, []);

  const handleGoToSelectImage = useCallback(() => {
    setImageSelection(true);
  }, []);

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    try {
      const data = formData as LeaguesFormValues;
      const response = await makeLeague(data);
      // @ts-expect-error incorrect typing in response
      const leagueId: string = response.data.leagueId;
      toast('Your league successfully created', 'success');

      const path = createPath({
        path: RoutesEnum.leagueManage,
        params: {
          id: leagueId,
        },
      });

      navigate(path);
    } catch (error) {
      errorHandler({ toast, t, error });
    }
  };

  return (
    <CreateLeagueModalComponent
      isMobile={isMobile}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isImageSelection ? (
            <CreateLeagueSelectImage
              isMobile={isMobile}
              onGoToLeagueSettings={handleGoToLeagueSettings}
            />
          ) : (
            <CreateLeagueSettings
              isMobile={isMobile}
              isSubmitting={isSubmitting}
              onClose={onClose}
              onGoToSelectImage={handleGoToSelectImage}
            />
          )}
        </form>
      </FormProvider>
    </CreateLeagueModalComponent>
  );
};
