import { Helmet } from 'react-helmet-async';

import { Brackets } from '@/modules/Bracket/containers/Brackets';

const BracketsPage = () => {
  return (
    <>
      <Helmet>
        <title>Brackets | Bracket Challenge</title>
        <meta
          content='Who is your Stanley Cup Champion, create your bracket and make your picks today!'
          name='description'
        />
      </Helmet>
      <Brackets />
    </>
  );
};

export default BracketsPage;
