import { ApiTagsEnum } from '@/enums/apiTags.enum';

import { LIMIT } from '@/modules/Leagues/leagues.constants';
import {
  LeaguesFormValues,
  LeaguesQuery,
  LeaguesResponse,
} from '@/modules/Leagues/leagues.types';

import { api } from '@/services/api.service';

const leaguesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFilteredLeagues: builder.query<LeaguesResponse, LeaguesQuery>({
      query: ({ jwt, ...params }) => ({
        url: '/search-leagues',
        params: params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const last7CharactersInJWT = queryArgs.jwt?.slice(-7);
        return `${endpointName}({"search":${queryArgs.search}, "type": ${queryArgs.type}, "jwt": ${last7CharactersInJWT})`;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg?.offset) {
          currentCache.leagues.push(...newItems.leagues);
          currentCache.isLastPage = newItems.leagues.length < LIMIT;
        } else {
          currentCache.leagues = newItems.leagues;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      },
      keepUnusedDataFor: 0,
      providesTags: [ApiTagsEnum.GET_FILTERED_LEAGUES],
    }),
    makeLeague: builder.mutation<{ leagueId: string }, LeaguesFormValues>({
      query: (formData) => ({
        url: '/leagues',
        method: 'POST',
        body: formData,
      }),
    }),
    removeUserFromLeague: builder.mutation<
      any,
      { league_id: string; user_id: string }
    >({
      query: ({ league_id, user_id }) => ({
        url: `/leagues/delete/user/${league_id}`,
        method: 'POST',
        body: { user_id },
      }),
    }),
    deleteLeague: builder.mutation<any, { league_id: string }>({
      query: ({ league_id }) => ({
        url: `/leagues/${league_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetFilteredLeaguesQuery,
  useMakeLeagueMutation,
  useRemoveUserFromLeagueMutation,
  useDeleteLeagueMutation,
} = leaguesApi;

export default leaguesApi;
